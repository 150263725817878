import { Box, Button, InputLabel } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { OrgBU } from "../../../types/company";
import TimezoneSelect from "react-timezone-select";
import { json } from "stream/consumers";

interface BusinessUnitFormProps {
  isNew: boolean;
  setModal: (value: boolean, unit: OrgBU) => void;
  unit: OrgBU | null;
}

const BusinessUnitForm: React.FC<BusinessUnitFormProps> = ({
  isNew,
  setModal,
  unit,
}) => {
  // #region variables region
  //unit name
  const [unitName, setUnitName] = useState<string>(
    unit ? unit.businessUnitName : ""
  );

  //address
  const [address, setAddress] = useState<string>(
    unit ? unit.businessUnitAddress : ""
  );

  //city
  const [city, setCity] = useState<string>(unit ? unit.businessUnitCity : "");

  //state
  const [state, setState] = useState<string>(
    unit ? unit.businessUnitState : ""
  );

  //zipcode
  const [zipCode, setZipCode] = useState<string>(
    unit ? unit.businessUnitZipCode : ""
  );

  //store selected TimeZone
  const [selectedTimezone, setSelectedTimezone] = useState<any>(
    unit &&
      unit.businessUnitTimeZone &&
      unit.businessUnitTimeZone.toString().startsWith("{")
      ? JSON.parse(unit.businessUnitTimeZone.toString())
      : {}
  );

  //handle time zone validation message
  const [timezoneErrorMessage, setTimezoneErrorMessage] = useState<string>("");

  //error ui handler
  // const [hasError, setHasError] = useState<boolean>(false);

  // #endregion

  // #region methods region

  //handles submission
  const handleSubmit = (e: React.FormEvent<Element>) => {
    if (!selectedTimezone.value) {
      setTimezoneErrorMessage("Please select time zone");
    } else {
      setTimezoneErrorMessage("");

      setModal(isNew, {
        id: unit ? unit.id : "'",
        businessUnitAddress: address,
        businessUnitCity: city,
        businessUnitName: unitName,
        businessUnitState: state,
        businessUnitZipCode: zipCode,
        businessUnitTimeZone: selectedTimezone,
        createdAt: unit ? unit.createdAt : "",
        updatedAt: unit ? unit.updatedAt : "",
        tenantId: unit ? unit.tenantId : "",
      });
    }
  };

  const handleSave = (e: any) => {
    if (!selectedTimezone.value) {
      setTimezoneErrorMessage("Please select time zone");
    } else {
      setTimezoneErrorMessage("");
    }
  };

  //removes focus when enter is pressed in an input box
  const removeFocus = (id: string) => {
    document.getElementById(id)?.blur();
  };

  // #endregion
  return (
    <div>
      <ValidatorForm
        onSubmit={(e: React.FormEvent<Element>) => handleSubmit(e)}
      >
        <div className="stepperForm__inner p-30px">
          {/* <h1 className="stepperForm__title p-0">Employment Information</h1> */}
          <Grid container spacing={3}>
            <Grid size={{ xs: 12, md: 12 }}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">
                  Business Unit Name
                </InputLabel>
                <TextValidator
                  id="unitName"
                  type="text"
                  name="unitName"
                  value={unitName}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      removeFocus("unitName");
                    }
                  }}
                  onChange={(e: any) => setUnitName(e.target.value)}
                  validators={["required", "matchRegexp:^[a-zA-Z- ]*$"]}
                  errorMessages={[
                    "Please Enter Unit name",
                    "Unit name can only have letters",
                  ]}
                />
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 12 }}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">Address</InputLabel>
                <TextValidator
                  id="address"
                  type="text"
                  name="address"
                  value={address.trimStart()}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      removeFocus("address");
                    }
                  }}
                  onChange={(e: any) => setAddress(e.target.value)}
                  // validators={["required"]}
                  // errorMessages={["Please enter address"]}
                />
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">City</InputLabel>
                <TextValidator
                  id="city"
                  type="text"
                  name="city"
                  value={city.trimStart()}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      removeFocus("city");
                    }
                  }}
                  onChange={(e: any) => setCity(e.target.value)}
                  validators={["required", "matchRegexp:^[a-zA-Z- ]*$"]}
                  errorMessages={[
                    "Please enter city",
                    "City name can only have letters",
                  ]}
                />
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">State</InputLabel>
                <TextValidator
                  id="state"
                  type="text"
                  name="state"
                  value={state.trimStart()}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      removeFocus("state");
                    }
                  }}
                  onChange={(e: any) => setState(e.target.value)}
                  validators={["required", "matchRegexp:^[a-zA-Z- ]*$"]}
                  errorMessages={[
                    "Please enter state name",
                    "State name can only have letters",
                  ]}
                />
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">ZipCode</InputLabel>
                <TextValidator
                  id="zipCode"
                  type="text"
                  name="zipCode"
                  value={zipCode.trimStart()}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      removeFocus("state");
                    }
                  }}
                  onChange={(e: any) => setZipCode(e.target.value)}
                  validators={["required"]}
                  errorMessages={["Please enter ZipCode"]}
                />
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 12 }}>
              <Box className="formGroup">
                <InputLabel className="fromLabel">Timezone</InputLabel>
                <TimezoneSelect
                  className="dropdownBox"
                  value={selectedTimezone}
                  labelStyle="abbrev"
                  onChange={(e) => {
                    setTimezoneErrorMessage("");
                    setSelectedTimezone(e);
                  }}
                />
              </Box>
            </Grid>
            <span
              style={{
                color: "#d32f2f",
                marginLeft: "25px",
                fontFamily: "sans-serif",
                fontWeight: "400",
                fontSize: "0.75rem",
                lineHeight: 1.66,
                marginTop: "3px",
              }}
            >
              {timezoneErrorMessage}
            </span>
          </Grid>
        </div>
        {isNew ? (
          <Box className="stepperForm__footer b-0">
            <Box sx={{ flex: "1 1 auto" }} />
            <Button type="submit" className="button" onClick={handleSave}>
              Save
            </Button>
          </Box>
        ) : (
          <div className="largeModel__footer">
            <Button className="button" type="submit">
              Save Changes
            </Button>
          </div>
        )}
      </ValidatorForm>
    </div>
  );
};

export default BusinessUnitForm;
