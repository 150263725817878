import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import "./formTracking.scss";
import CloseIcon from "@mui/icons-material/Close";
import settingsIcon from "../../assets/images/images-svg/SettingsIcon.svg";
import IntakeModel from "./IntakeModel";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  GetFormById,
  GetFormCards,
} from "../../redux/features/forms/form-slice";
import { IFormCard } from "../../types/forms";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { useNavigate } from "react-router-dom";

import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "pure-react-carousel";
import { ReactComponent as LeftArrow } from "../../assets/images/images-svg/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../assets/images/images-svg/rightArrow.svg";
import { BusinessFormType } from "../../constants/forms";

const FormList: React.FC = () => {
  //#region variable region

  //Dispatch
  const dispatch: AppDispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  //handle has form settings
  const hasSetting = useSelector<RootState, boolean>(
    (state) => state.formSlice.hasSetting
  );

  //store value open popup
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  //handles preview modal
  const [open, setOpen] = useState<boolean>(false);

  //stores assessment form data
  const [survey, setSurvey] = useState<any>(null);

  //store URL
  const [url, setUrl] = useState<string>("");

  // //fetches New Employee Form
  // const [NewEmployeeFormlist, setNewEmployeeFormlist] = useState<{forms:
  // IFormCard[]}
  // >({
  //   forms:[]
  // });

  //used to display number cards based on screen size
  const [isDesktop, setDesktop] = useState(4);

  //set active Index for show and hide scroll buttons
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  //stores intakeFormList
  const intakeForms = useSelector<RootState, IFormCard[]>(
    (state) => state.formSlice.IntakeForms
  ).filter((form) => form.active);

  const providerForms = useSelector<RootState, IFormCard[]>(
    (state) => state.formSlice.ProviderForms
  ).filter((form) => form.active);

  //stores all the card lists
  let cardLists = [...intakeForms, ...providerForms];

  //stores selectedFormId
  // const[selectedFormId,setSelectedFormId] = useState<string>("")
  const [selectedForm, setSelectedForm] = useState<IFormCard>();

  //#endregion

  //#region method region

  //handles assessment form preview
  const handlePreview = async (id: string) => {
    const data = await dispatch(GetFormById({ formId: id }));
    setSurvey(new Model(data.payload.formSchema));
    setOpen(true);
  };

  //Left scroll button popup handle
  const funcLeft = () => {
    if (activeIndex === null) {
      return false;
    }
    if (activeIndex < isDesktop) {
      return true;
    } else {
      return true;
    }
  };

  // Right scroll button popup handle
  const funcRight = () => {
    if (activeIndex === null) {
      return false;
    }
    if (activeIndex < cardLists?.length) {
      return true;
    }
    return true;
  };

  //Right scroll button Click handle
  const handleRight = () => {
    if (activeIndex) {
      if (activeIndex + isDesktop < cardLists?.length) {
        setActiveIndex(activeIndex + isDesktop);
      } else {
        setActiveIndex(cardLists?.length - 1);
      }
    }
  };

  //Left scroll button Click handle
  const handleLeft = () => {
    if (activeIndex) {
      if (activeIndex - isDesktop + 1 >= isDesktop) {
        setActiveIndex(activeIndex - isDesktop);
      } else {
        setActiveIndex(isDesktop - 1);
      }
    }
  };
  //#endregion

  //#region useEffect region

  useEffect(() => {
    dispatch(GetFormCards({ formType: BusinessFormType.IntakePacketForm }));
    dispatch(GetFormCards({ formType: BusinessFormType.EmployeePacketForm }));
    // EmployeeForm()
  }, [dispatch, intakeForms?.length]);

  //handles survey modal display mode
  useEffect(() => {
    if (survey) {
      survey.questionsOnPageMode = "singlePage";
      survey.mode = "display";
    }
  }, [survey]);

  useEffect(() => {
    if (cardLists && cardLists.length > isDesktop) {
      setActiveIndex(isDesktop - 1);
    }
    // eslint-disable-next-line
  }, [cardLists.length, isDesktop]);

  useEffect(() => {
    if (window.innerWidth > 1240) {
      setDesktop(4);
    } else if (window.innerWidth > 991) {
      setDesktop(3);
    } else if (window.innerWidth > 650) {
      setDesktop(2);
    } else {
      setDesktop(1);
    }

    const updateMedia = () => {
      if (window.innerWidth > 1240) {
        setDesktop(4);
      } else if (window.innerWidth > 991) {
        setDesktop(3);
      } else if (window.innerWidth > 650) {
        setDesktop(2);
      } else {
        setDesktop(1);
      }
    };
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  //#endregion

  return (
    <div>
      <Grid size={{ xs: 12 }}>
        <div
          style={{
            marginBottom: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            fontWeight="bold"
            className="listingTable__title"
          >
            Live Forms
          </Typography>
          {/* {!hasSetting && ( */}
          <Button
            type="submit"
            style={{ backgroundColor: "white", gap: "5px" }}
            onClick={() =>
              navigate("/business-settings", {
                state: {
                  tab: "Communication",
                  type: "Intake Packet Form", //must match with group name!
                },
              })
            }
          >
            <img
              className="settingsIcon"
              src={settingsIcon}
              alt="settingsIcon"
            />
            <span style={{ color: "#096dd9" }}>Communication Setting</span>
          </Button>
          {/* )} */}
        </div>
      </Grid>

      <Grid container spacing={3} sx={{ mt: "-10px" }}>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={130}
          totalSlides={cardLists?.length}
          visibleSlides={isDesktop}
          step={isDesktop}
          orientation={"horizontal"}
          className="formCardSlider"
        >
          <Slider>
            {cardLists.length > 0 &&
              cardLists
                .filter((ele) => ele.active)
                .map((item, index) => (
                  <Slide
                    index={index}
                    key={index}
                    style={{ margin: "0px -1px" }}
                  >
                    <Grid
                      size={{ xs: 12 }}
                      key={item.id}
                      sx={{ ml: "15px", boxShadow: "none" }}
                    >
                      <div className="documentCard">
                        <div
                          className="documentCard__img"
                          style={{
                            backgroundColor: item.formType
                              .toLowerCase()
                              .includes("employee")
                              ? "#f1ecff"
                              : "#dafbeb",
                          }}
                        >
                          <div className="documentCard__img-card">
                            <p>{item.name}</p>
                          </div>
                        </div>
                        <div className="documentCard__btn">
                          <span
                            style={{
                              backgroundColor: "white",
                              color: "#096dd9",
                              border: "1px solid #096dd9",
                            }}
                            onClick={() => {
                              handlePreview(item.id);
                              setUrl(item.url);
                            }}
                          >
                            preview
                          </span>

                          <span
                            style={{ backgroundColor: "#096dd9" }}
                            onClick={() => {
                              setOpenPopup(true);
                              setSelectedForm(item);
                            }}
                          >
                            send
                          </span>
                        </div>
                      </div>
                    </Grid>
                  </Slide>
                ))}
          </Slider>
          {funcLeft() && (
            <ButtonBack className="sliderNavBtn" onClick={handleLeft}>
              <LeftArrow />
            </ButtonBack>
          )}
          {funcRight() && (
            <ButtonNext className="sliderNavBtn" onClick={handleRight}>
              <RightArrow />
            </ButtonNext>
          )}
        </CarouselProvider>
      </Grid>

      {openPopup && (
        <Dialog open={openPopup} className="smallModel--intake">
          <Box className="smallModel__head" style={{ padding: "12px 16px" }}>
            <Typography variant="h3" className="smallModel__title">
              {"Send Form - "}
              {selectedForm?.name}
            </Typography>
            <Button
              className="smallModel__closeBtn"
              onClick={() => {
                setOpenPopup(false);
              }}
            >
              <CloseIcon />
            </Button>
          </Box>
          {selectedForm && (
            <IntakeModel
              setOpenPopup={setOpenPopup}
              selectedFormId={selectedForm?.id}
              selectedForm={selectedForm}
            />
          )}
        </Dialog>
      )}

      {open && (
        <Dialog open={open} className="smallModel--intakeforms">
          <Box className="smallModel__head" style={{ padding: "12px 16px" }}>
            <Typography variant="h3" className="smallModel__title">
              preview
            </Typography>

            <Button
              className="smallModel__closeBtn"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon />
            </Button>
          </Box>
          {url === null ? (
            <div>
              <Survey id="id" model={survey} />;
            </div>
          ) : (
            <iframe src={url} height="3000" width="700" title="frame"></iframe>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              margin: "12px",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </Button>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default FormList;
