import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  DetailRow,
  ExcelExport,
  Filter,
  GridComponent,
  Group,
  GroupSettingsModel,
  Inject,
  Page,
  PdfExport,
  Resize,
  SelectionSettingsModel,
  Sort,
  TextWrapSettingsModel,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import SyncIcon from "@mui/icons-material/Sync";
import {
  GetLastSynced,
  GetSyncRequestbyAppName,
  setDataUpdated,
  SyncDataRequestIntegration,
} from "../../redux/features/integration-setting/integration-setting-slice";
import { ISyncDataRequests } from "../../types/businessSettings";
import { exportDataSets, JobStatus } from "../../constants/tools";
import moment from "moment";
import { getSessionToken } from "@descope/react-sdk";
import { dateToFromNowDaily } from "../../helpers/reverse-list/reverse-list";
import { SyncEventType } from "../../constants/app-integration";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};
interface ISyncRequests {
  appName: string;
}

const SyncRequest: React.FC<ISyncRequests> = ({ appName }) => {
  //#region variable region
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [sseMessage, setsseMessage] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);
  const [filterValues, setFilterValues] = useState<{
    eventType:string[];
    entityType: string[];
    status: string[];
  }>({
    eventType:[],
    entityType: [],
    status: [],
  });

  const syncRequests = useAppSelector<ISyncDataRequests[]>(
    (state) => state.integrationSettingSlice.syncDataLogs[appName]
  );
  const { isUpdated, lastSyncedAt } = useAppSelector(
    (state) => state.integrationSettingSlice
  );

  //#endregion

  //#region grid region
  let gridInstance: GridComponent;
  const groupOptions: GroupSettingsModel = { showGroupedColumn: true };
  const textWrapSettings: TextWrapSettingsModel = { wrapMode: "Content" };
  let refresh: boolean;
  function load(this: any) {
    refresh = this?.refreshing;
  }
  const pdfExportComplete = (): void => {
    if (gridInstance) {
      gridInstance.hideSpinner();
    }
  };
  const excelExportComplete = (): void => {
    if (gridInstance) {
      gridInstance.hideSpinner();
    }
  };
  function dataBound() {
    if (refresh && gridInstance) {
      gridInstance.groupColumn("subject");
      refresh = false;
      gridInstance.refresh(); //must have!! otherwise, has white-screen.
    }
  }

  const selectionSettings: SelectionSettingsModel = {
    type: "Multiple",
    mode: "Both",
  };

  // const settings: SelectionSettingsModel = {
  //   persistSelection: true,
  //   type: "Multiple",
  // };

  const datatemplate = (props: any) => {
    return (
      <div style={{ padding: "10px 0px" }}>
        {props?.column.field === "jobType" && (
          <Typography>{props.jobType}</Typography>
        )}
        {props?.column.field === "eventType" && (
          <Typography>{props.eventType}</Typography>
        )}
        {props?.column.field === "entityName" && (
          <Typography>{props?.entityName}</Typography>
        )}
        {props?.column.field === "appName" && (
          <Typography>{props?.appName}</Typography>
        )}
        {props?.column.field === "externalId" && (
          <Typography>{props?.externalId}</Typography>
        )}
        {props?.column.field === "errorMessage" && (
          <Typography>{props?.errorMessage}</Typography>
        )}
        {props?.column.field === "updatedAt" && (
          <Typography>
            {dateToFromNowDaily(new Date(`${props.updatedAt}`))}
          </Typography>
        )}
      </div>
    );
  };

  const StatusTemplate = (props: any) => {
    return (
      <div className={`history-status ${props.status.replace(" ", "")}`}>
        {props?.status}
      </div>
    );
  };

  // #endregion

  const handleSyncData = async () => {
    const token = getSessionToken();
    const BASE_URL = process.env.REACT_APP_BACKEND_SERVER_BASE_URL;

    try {
      // Construct the URL with query parameters
      const url = new URL(
        `${BASE_URL}/integration/refresh-all?appName=${appName}`
      );
      // Perform the fetch request
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.body) {
        throw new Error("No response body");
      }
      setOpen(true);
      // Process the stream data
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      new ReadableStream({
        start(controller) {
          async function read() {
            while (true) {
              const { done, value } = await reader.read();
              if (done) {
                controller.close();
                break;
              }

              // Decode and process the chunk
              const text = decoder.decode(value, { stream: true });
              const messages = text.split("\n\n");
              for (const message of messages) {
                if (message.trim() !== "") {
                  const eventData = JSON.parse(message.substring(5).trim());
                  if (eventData.type === "message") {
                    setProgress(eventData.progress);
                    setsseMessage(eventData.message);
                  } else if (eventData.type === "data") {
                    dispatch(setDataUpdated(true));
                    return;
                  }
                }
              }
              controller.enqueue(value);
            }
          }
          read().catch((error) => {
            console.error("Stream reading error:", error);
            controller.error(error);
          });
        },
      });
    } catch (error: any) {
      console.error("Error:", error);
      throw error?.response?.data || error;
    }
  };

  const filteredData = (): ISyncDataRequests[] => {
    return syncRequests?.filter((item) => {
      const matchesEntityType =
        filterValues.entityType.length === 0 ||
        filterValues.entityType.includes(item.entityType);
      const matchesStatus =
        filterValues.status.length === 0 ||
        filterValues.status.includes(item.status);
        const matchesEventType =
        filterValues.eventType.length === 0 ||
        filterValues.eventType.includes(item.eventType);
      return matchesEntityType && matchesStatus && matchesEventType;
    });
  };

  const gridComponent = useMemo(
    () => (
      <GridComponent
        id="grid" //must have!!
        className="gridTable"
        dataSource={filteredData()}
        dataBound={dataBound.bind(this)}
        allowPaging={true}
        ref={(grid) => (gridInstance = grid as GridComponent)}
        statelessTemplates={["directiveTemplates"]}
        load={load}
        width="auto"
        height="650"
        selectionSettings={selectionSettings}
        allowGrouping={true}
        groupSettings={groupOptions}
        allowFiltering={true}
        allowSorting={true}
        allowResizing={true}
        allowTextWrap={true}
        filterSettings={{ type: "Menu" }}
        textWrapSettings={textWrapSettings}
        allowPdfExport={true}
        allowExcelExport={true}
        pdfExportComplete={pdfExportComplete}
        excelExportComplete={excelExportComplete}

      >
        <ColumnsDirective>
          <ColumnDirective
            field="id"
            visible={false}
            headerText="ID"
            isPrimaryKey={true}
            width="80"
          />
          <ColumnDirective type="checkbox" width="50" textAlign="Left" />
          <ColumnDirective
            headerText="Job Type"
            width="80"
            textAlign="Left"
            field="jobType"
            template={datatemplate}
          />
          <ColumnDirective
            headerText="App Name"
            width="80"
            textAlign="Left"
            field="appName"
            template={datatemplate}
          />
          <ColumnDirective
            headerText="EventType"
            width="100"
            textAlign="Left"
            field="eventType"
            template={datatemplate}
          />
          <ColumnDirective
            headerText="Entity Name"
            width="100"
            textAlign="Left"
            field="entityName"
            template={datatemplate}
          />
          <ColumnDirective
            headerText="status"
            width="50"
            textAlign="Left"
            field="status"
            template={StatusTemplate}
          />
          <ColumnDirective
            headerText="External Id"
            width="80"
            textAlign="Left"
            field="externalId"
            template={datatemplate}
          />
          <ColumnDirective
            headerText="Errors"
            width="80"
            textAlign="Left"
            field="errorMessage"
            template={datatemplate}
          />
          <ColumnDirective
            headerText="Updated On"
            width="120"
            textAlign="Left"
            field="updatedAt"
            template={datatemplate}
          />
        </ColumnsDirective>
        <Inject
          services={[
            Page,
            Group,
            ContextMenu,
            Toolbar,
            Resize,
            DetailRow,
            Filter,
            Sort,
            ExcelExport,
            PdfExport,
          ]}
        />
      </GridComponent>
    ),
    [syncRequests,filterValues]
  );
  //#region useEffect region
  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setProgress(0);
      }, 1000);
    }
  }, [progress]);

  useEffect(() => {
    if (syncRequests === undefined || isUpdated)
      dispatch(GetSyncRequestbyAppName({ appName: appName }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated]);

  useEffect(() => {
    dispatch(GetLastSynced({ appName }));
  }, []);

  //#endregion
  return (
    <div className="SyncRequest">
      <div className="header-syncRequest">
        <div>
          <div className="referesh">
            <Typography className="refresh-title">
              Sync Data Requests
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                const selectedRecords: any = gridInstance.getSelectedRecords();
              if (selectedRecords.length > 0) {
                const selectedRequestIds = selectedRecords
                  .filter(
                    (item: ISyncDataRequests) => item.status !== "Complete"
                  )
                  .map((item: ISyncDataRequests) => {
                    return { id: item.id, entityType: item.entityType };
                  });
                dispatch(
                  SyncDataRequestIntegration({
                    appName: appName,
                    syncRequestIds: selectedRequestIds,
                  })
                );
                gridInstance.clearSelection();
              }
            }}
          >
            Send
            </Button>
            <Button
              onClick={() => {
                handleSyncData();
              }}
            >
              <SyncIcon color="primary" fontSize="large" />
            </Button>

          </div>
          {lastSyncedAt && (
            <Typography className="sync-description">
              last Sync : {moment(lastSyncedAt[appName]).format("LLL")}
            </Typography>
          )}
        </div>

        <div className="filter-syncRequest">
        <FormControl sx={{ m: 1, width: 250 }} size="small">
            <InputLabel id="demo-multiple-checkbox-label">
              Event Type
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={filterValues.eventType}
              onChange={(e) => {
                setFilterValues({
                  ...filterValues,
                  eventType: e.target.value as string[],
                });
              }}
              input={<OutlinedInput label="event Type" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {Object.values(SyncEventType).map((item, i) => (
                <MenuItem value={item} key={i}>
                  <Checkbox
                    checked={filterValues.eventType.indexOf(item) > -1}
                  />
                  <ListItemText primary={item} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* turn off this. 
          <FormControl sx={{ m: 1, width: 250 }} size="small">
            <InputLabel id="demo-multiple-checkbox-label">
              Entity Type
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={filterValues.entityType}
              onChange={(e) => {
                setFilterValues({
                  ...filterValues,
                  entityType: e.target.value as string[],
                });
              }}
              input={<OutlinedInput label="Entity Type" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {exportDataSets.map((item, i) => (
                <MenuItem value={item.value} key={i}>
                  <Checkbox
                    checked={filterValues.entityType.indexOf(item.value) > -1}
                  />
                  <ListItemText primary={item.entityType} />
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          <FormControl sx={{ m: 1, width: 250 }} size="small">
            <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={filterValues.status}
              onChange={(e) => {
                setFilterValues({
                  ...filterValues,
                  status: e.target.value as string[],
                });
              }}
              input={<OutlinedInput label="Status" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {Object.values(JobStatus).map((status) => (
                <MenuItem key={status} value={status}>
                  <Checkbox
                    checked={filterValues.status.indexOf(status) > -1}
                  />
                  <ListItemText primary={status} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        </div>
      </div>
      {gridComponent}
      {open && (
        <Dialog open={open} className="tools-Dialog">
          <DialogContent className="content">
            {progress !== 0 && (
              <CircularProgress variant="determinate" value={progress} />
            )}
            <Typography className="sse-Message">{sseMessage}</Typography>
            <Button
              className="close-btn"
              variant="contained"
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </Button>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default SyncRequest;
