import { Box } from "@mui/material";
import { Dispatch, SetStateAction, useEffect } from "react";
import MessageClientList from "./MessageClientList";
import MessageProviderList from "./MessageProviderList";
import { ClientForms, utilizationdata } from "../../../types/client";
import { ProviderForms } from "../../../types/provider";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState, useAppSelector } from "../../../redux/store";
import {
  GetUnreadUserId,
  setPreviousSelectedUser,
} from "../../../redux/features/messages/message-slice";
import { GetAllClientData } from "../../../redux/features/client-main/client-list-slice";
import { GetAllProviders } from "../../../redux/features/providers-main/provider-list-slice";
import MessageAdminList from "./MessageAdminList";

interface MessageUserListProps {
  isMobileView: boolean;
  setSelectedTab: Dispatch<SetStateAction<string>>;
  selectedTab: string;
  setIsMobileView: Dispatch<SetStateAction<boolean>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setSelectedUser: Dispatch<
    SetStateAction<{
      id: string;
      name: string;
      entityType: string;
    }>
  >;
  selectedUser: {
    id: string;
    name: string;
    entityType: string;
  };
}

const MessageUserList: React.FC<MessageUserListProps> = ({
  selectedUser,
  setSelectedUser,
  isMobileView,
  setIsMobileView,
  selectedTab,
  setSelectedTab,
  setIsLoading,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  //Client Data fetching
  const clientdata = useSelector<RootState, ClientForms[]>(
    (state) => state.clientListSlice.listData
  );
  //stores client data according utlization date range
  const UtilizationClientData = useSelector<RootState, utilizationdata>(
    (state) => state.clientListSlice.utilizationListData
  );

  // handle change in client data
  const isDataUpdate = useSelector<RootState, boolean>(
    (state) => state.clientListSlice.dataUpdate
  );

  //Provider Data fetching
  const providerData = useSelector<RootState, ProviderForms[]>(
    (state) => state.providerListSlice.listData
  );

  const previousSelectedUser = useAppSelector(
    (state) => state.messageSlice.selectedUser
  );

  const sortMessageData = (data: any) => {
    let tempClientData: ClientForms[];
    let tempProviderData: ProviderForms[];

    if (isDataUpdate) {
      tempClientData = UtilizationClientData.Clients;
      tempProviderData = UtilizationClientData.Provider;
    } else {
      tempClientData = clientdata;
      tempProviderData = providerData;
    }
    if (data === tempClientData && data.length > 0) {
      let result = data.slice().sort((a: ClientForms, b: ClientForms) => {
        return (
          b.clientBasicDetails.lastMessage - a.clientBasicDetails.lastMessage
        );
      });
      return result;
    }

    if (data === tempProviderData) {
      let result = data.slice().sort((a: ProviderForms, b: ProviderForms) => {
        return (
          b.employmentDetails.lastMessage - a.employmentDetails.lastMessage
        );
      });
      return result;
    }
  };

  const storePreviouslySelectedUser = (type: string) => {
    if (selectedUser.entityType === "Client") {
      dispatch(
        setPreviousSelectedUser({
          ...previousSelectedUser,
          client: selectedUser,
        })
      );
    } else if (selectedUser.entityType === "Provider") {
      dispatch(
        setPreviousSelectedUser({
          ...previousSelectedUser,
          provider: selectedUser,
        })
      );
    } else if (selectedUser.entityType === "Admin" || selectedUser.entityType === "Owner") {
      dispatch(
        setPreviousSelectedUser({
          ...previousSelectedUser,
          admin: selectedUser,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(GetAllClientData());
    dispatch(GetAllProviders());
    dispatch(GetUnreadUserId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!clientdata.length || !providerData.length) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientdata.length, providerData.length]);

  return (
    <>
      <Box display={"flex"}>
        <ul className="innerTabs">
          <li className="innerTabs__item">
            <button
              onClick={() => {
                setSelectedTab("Clients");
                storePreviouslySelectedUser("client");
              }}
              className={selectedTab === "Clients" ? "active" : ""}
            >
              Clients
            </button>
          </li>
          <li className="innerTabs__item">
            <button
              onClick={() => {
                setSelectedTab("Employees");
                storePreviouslySelectedUser("provider");
              }}
              className={selectedTab === "Employees" ? "active" : ""}
            >
              Employees
            </button>
          </li>
          <li className="innerTabs__item">
            <button
              onClick={() => {
                setSelectedTab("Admins");
                storePreviouslySelectedUser("admin");
              }}
              className={selectedTab === "Admins" ? "active" : ""}
            >
              Admins
            </button>
          </li>
        </ul>
      </Box>
      <div>
        {selectedTab === "Clients" && (
          <MessageClientList
            clientsListData={
              sortMessageData(
                isDataUpdate ? UtilizationClientData.Clients : clientdata
              ) || []
            }
            setSelectedUser={setSelectedUser}
            selectedUser={selectedUser}
            isMobileView={isMobileView}
            setIsMobileView={setIsMobileView}
          />
        )}
        {selectedTab === "Employees" && (
          <MessageProviderList
            setSelectedUser={setSelectedUser}
            selectedUser={selectedUser}
            providerListData={sortMessageData(
              isDataUpdate ? UtilizationClientData.Provider : providerData
            )}
            isMobileView={isMobileView}
          />
        )}
        {selectedTab === "Admins" && (
          <MessageAdminList
            setSelectedUser={setSelectedUser}
            selectedUser={selectedUser}
            isMobileView={isMobileView}
          />
        )}
      </div>
    </>
  );
};

export default MessageUserList;
