import { Box, Button, Card, Dialog, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BusinessFormGroup, BusinessFormType } from "../../constants/forms";
import Eye from "../../assets/images/images-svg/Eye.svg";
import Reload from "../../assets/images/images-svg/Reload.svg";
import Download from "../../assets/images/images-svg/Download.svg";
import "./resourceHub.scss";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  InstallOrUpdateResourceById,
} from "../../redux/features/resourceHub/resourceHub-slice";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { Survey } from "survey-react-ui";
import { Model } from "survey-core";
import { useLocation } from "react-router-dom";
import { IResource } from "../../types/resourceHub";


const ResourceHubForms = () => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  //filters form group
  const [filterFormGroup, setFilterGroup] = useState<string>(
    state?.group || "Intake"
  );
  const [survey, setSurvey] = useState<any>(null);
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const resourceForms = useAppSelector((state) =>
    state.resourceHubSlice.resourceForms
  );

  const clientFormGroups = ["Intake", "Assessment", "Session"];

  const filteredResourceForms = (): IResource[] => {
    return resourceForms?.filter((item) => {
      if (filterFormGroup === BusinessFormGroup.Intake) {
        return (
          item.resourceObject.type === BusinessFormType.IntakeClientForm ||
          item.resourceObject.type === BusinessFormType.IntakePacketForm
        );
      }
      if (filterFormGroup === BusinessFormGroup.Assessment) {
        return (
          item.resourceObject.type === BusinessFormType.AssessmentForm ||
          item.resourceObject.type === BusinessFormType.ShareableAssessmentForm
        );
      }

      if (filterFormGroup === BusinessFormGroup.Onboarding) {
        return (
          item.resourceObject.type === BusinessFormType.EmployeePacketForm ||
          item.resourceObject.type === BusinessFormType.NewEmployeeForm
        );
      }
      if (filterFormGroup === BusinessFormGroup.Assessment) {
        return (
          item.resourceObject.type === BusinessFormType.AssessmentForm ||
          item.resourceObject.type === BusinessFormType.ShareableAssessmentForm
        );
      }
      if (filterFormGroup === BusinessFormGroup.Session) {
        return item.resourceObject.type === BusinessFormType.SessionForm;
      }
      return item;
    });
  };

  return (
    <div>
      <div className="listingTable__header" style={{ paddingBottom: "20px" }}>
        <Box
          sx={{
            display: { xs: "block", md: "flex" },
            gap: "15px",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Typography
            className="listingTable__title"
            gutterBottom
            style={{ fontWeight: 500, fontSize: "20px" }}
          >
            Select Group:
          </Typography>
          <Box sx={{ display: "flex", gap: "15px" }} className="form-tabs">
            {Object.values(BusinessFormGroup)?.map((item, index) => (
              <Box className="fromGroup-chips" key={item}>
                <Card
                  key={index}
                  className={
                    filterFormGroup === item ? "fromGroup-chip-active" : ""
                  }
                  onClick={() => {
                    filterFormGroup === item
                      ? setFilterGroup("")
                      : setFilterGroup(item);
                  }}
                >
                  {item}
                </Card>
              </Box>
            ))}
          </Box>
        </Box>
      </div>
      <div className="resource">
        <Grid container>
          {filteredResourceForms()?.map((item) => (
            <Grid item xs={6} md={4} lg={3} className="resourceForm_cards">
              <div className="documentCard">
                <div
                  className={
                    clientFormGroups.includes(filterFormGroup)
                      ? "documentCard__img client"
                      : "documentCard__img provider"
                  }
                >
                  <div className="documentCard__img-card">
                    <p>{item.name}</p>
                    <p className="version">
                      Version: {item.version}
                    </p>
                    <p className="updateAt">
                      Update: {moment(item.updatedAt).format("MM/DD/YYYY")}
                    </p>
                  </div>
                </div>
                <div className="documentCard__btn">
                  <span
                    onClick={() => {
                      const surveydata = new Model(item.resourceObject.json);
                      surveydata.showCompleteButton = false;
                      setSurvey(surveydata);
                      setOpenPreview(true);
                    }}
                  >
                    <img src={Eye} alt="" />
                    Preview
                  </span>
                  {item?.isUpdateAvailable && 
                    <span onClick={() => {
                      dispatch(
                        InstallOrUpdateResourceById({
                          formId: item.id,
                          resourceType: "Form",
                        })
                      );
                    }}>
                      <img src={Reload} alt="" />
                      Update
                    </span>
                  }
                  {!item?.isUpdateAvailable && item.businessForms.length === 0 && (
                    <span
                      onClick={() => {
                        dispatch(
                          InstallOrUpdateResourceById({
                            formId: item.id,
                            resourceType: "Form",
                          })
                        );
                      }}
                    >
                      <img src={Download} alt="" />
                      Install
                    </span>
                  )}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
      <Dialog
        open={openPreview}
        className="smallModel--intakeforms resourceHub-preview"
      >
        <Box className="smallModel__head" style={{ padding: "12px 16px" }}>
          <Typography variant="h3" className="smallModel__title">
            preview
          </Typography>

          <Button
            className="smallModel__closeBtn"
            onClick={() => {
              setOpenPreview(false);
            }}
          >
            <CloseIcon />
          </Button>
        </Box>

        <div>
          <Survey id="id" model={survey} />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            margin: "12px",
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setOpenPreview(false);
            }}
          >
            Close
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default ResourceHubForms;
