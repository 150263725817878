import {
  Box,
  Button,
  Card,
  Checkbox,
  FormHelperText,
  InputLabel,
  Popover,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useState } from "react";
import MessageEditor from "./MessageEditor";
import {
  Audience,
  messageMethods,
} from "../../../../constants/parent-settings";
import "./messageTemplateNew.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  PostMessageTemplateForm,
  getAllTemplateVariables,
  updateMessageTemplateById,
} from "../../../../redux/features/communication/messageTemplate-slice";
import { useNavigate } from "react-router-dom";
import {
  EditTemplate,
  ITemplateVariable,
} from "../../../../types/businessSettings";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import MenuItem from "@mui/material/MenuItem";
import { RootState } from "../../../../redux/store";
import { MenuProps } from "../../../../constants/providers";
import { IUserProfile } from "../../../../types/user";

const MessageTemplateNew: React.FC = () => {
  //#region variable region

  //used to dispatch
  const dispatch = useDispatch();

  //used to stored path
  const navigate = useNavigate();

  //getCurrent template
  const currentTemplate = useSelector<RootState, EditTemplate>(
    (state) => state.dataCatalogSlice.currentMessageTemplate
  );

  //used to store  name
  const [name, setName] = useState<string>(
    currentTemplate?.isEdit ? currentTemplate.templateData.name : ""
  );

  //used to subject name
  const [subjects, setSubject] = useState<string>(
    currentTemplate?.isEdit ? currentTemplate.templateData.subject : ""
  );

  //used to sender name
  const [sender, setSender] = useState<string>(
    currentTemplate?.isEdit ? currentTemplate.templateData.from : ""
  );

  //used to store selected Audience
  const [audience, setAudience] = useState<string>(
    currentTemplate?.isEdit ? currentTemplate.templateData.audience : ""
  );

  //used to messageCategory
  const [methods, setMethods] = useState<string>(
    currentTemplate?.isEdit ? currentTemplate.templateData.methods : ""
  );

  //used to store message body
  const [textfields, setTextField] = useState<string>(
    currentTemplate?.isEdit ? currentTemplate.templateData.message : ""
  );

  //used to subject name
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [active, setActive] = useState<boolean>(
    currentTemplate?.isEdit ? currentTemplate.templateData.active : false
  );

  //store adminuser Ids
  const [adminUser, setAdminUser] = useState<string[]>(
    currentTemplate?.isEdit ? currentTemplate.templateData.admins || [] : []
  );

  //redux for all tenantUsers from ABA UserProfile
  const tenantUsers = useSelector<RootState, IUserProfile[] | undefined | any>(
    (state) => state.user.tenantUsers
  );

  //filter admin user data
  let adminUsersdata: IUserProfile[] | undefined | any = tenantUsers.filter(
    (i: any) => i.authRole === "Admin" || i.authRole === "Owner"
  );

  //function that returns one. It's used to set the position of the popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  //if true Popover component show
  let open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //store clicked arrow state
  const [clicked, setClicked] = useState(false);

  //stores Personalization Dropdown data
  const [messageTemplateData, setMessageTemplateData] = useState<string>("");

  //stores error messages
  const [hasError, setHasError] = useState<boolean>(false);

  //store templateVaribles
  const TemplateVaribles = useSelector<RootState, ITemplateVariable[]>(
    (state) => state.communicationSlice.templateVariables
  );

  //stores changes in personalization selection
  const [ispersonalizationChanged, setPersonalizationChanged] =
    useState<boolean>(false);
  //#endregion

  //#region method region

  //handle save Template
  const handleSaveTemplate = (e: any) => {
    if (
      !methods ||
      !audience ||
      !sender.trim() ||
      !name.trim() ||
      !subjects.trim() ||
      !textfields.trim()
    ) {
      setHasError(true);
      return;
    }

    if (currentTemplate?.isEdit) {
      dispatch(
        updateMessageTemplateById({
          id: currentTemplate?.templateData.id,
          name: name,
          audience: audience,
          methods: methods,
          from: sender,
          message: textfields,
          subject: subjects,
          active: active,
          admins: adminUser,
        })
      );
    } else {
      dispatch(
        PostMessageTemplateForm({
          name: name,
          audience: audience,
          methods: methods,
          from: sender,
          message: textfields,
          subject: subjects,
          active: active,
          admins: adminUser,
        })
      );
    }

    navigate("/business-settings", {
      state: {
        tab: "MessageTemplates",
      },
    });
  };

  //handles personalisation dropdown
  const handlePersonalisation = (item: string) => {
    if (clicked) {
      setSubject(subjects + "   {{" + item + "}}");
    } else {
      setMessageTemplateData(item);
    }
    setAnchorEl(null);
    setPersonalizationChanged(!ispersonalizationChanged);
  };

  //return admin user name
  const getAdminUserName = (values: string[]) => {
    let arr: string[] = [];
    values.forEach((i) => {
      let data = adminUsersdata.find((item: any) => item.id === i);
      if (data) arr.push(`${data.firstName}${""}${data.lastName}`);
    });
    return arr.join(", ");
  };

  //#endregion

  //#region useEffect region
  //featch Template variables data
  useEffect(() => {
    console.log("loading message teamplate...");
    dispatch(getAllTemplateVariables());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#endregion

  return (
    <>
      <div>
        <Box className="pageTemplate massageTemplate">
          <Grid
            container
            spacing={2}
            // rowSpacing={2}
            // columnSpacing={{ xs: 0, sm: 0, md: 8 }}
          >
            <Grid size={{ xs: 12, sm: 9 }}>
              <Box className="template-heading">
                <Button
                  onClick={() => {
                    navigate("/business-settings", {
                      state: {
                        tab: "MessageTemplates",
                      },
                    });
                  }}
                  className="backBtn"
                  startIcon={<KeyboardBackspaceIcon />}
                  style={{ marginBottom: 0 }}
                >
                  Back
                </Button>
                <Box className="pageHeader">
                  <Typography className="pageHeader__title">
                    {currentTemplate?.isEdit
                      ? "Edit Message Template"
                      : "Add Message Template"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, sm: 3 }} className="save-temp">
              <Button onClick={handleSaveTemplate} variant="contained">
                Save template
              </Button>
            </Grid>
            <Grid size={{ xs: 12, md: 8 }} className="p-10">
              <Box mb={"30px"}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <InputLabel className="fromLabel">Subject</InputLabel>
                  <div
                    style={{ display: "flex", cursor: "pointer" }}
                    onClick={(e: any) => {
                      setClicked(true);
                      setAnchorEl(e.currentTarget);
                    }}
                  >
                    <Typography color={"primary"}>Personalization</Typography>
                    {open && clicked ? (
                      <ExpandLessOutlinedIcon />
                    ) : (
                      <ExpandMoreOutlinedIcon />
                    )}
                  </div>
                </Box>
                <Box className="fromGroup">
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter message subject"
                    value={subjects}
                    onChange={(e) => {
                      setSubject(e.target.value);
                    }}
                  />
                </Box>
                {hasError && !subjects.trimStart() && (
                  <FormHelperText style={{ color: "#d32f2f" }}>
                    Please enter subject
                  </FormHelperText>
                )}
              </Box>
              <Box mb={"30px"}>
                <Box
                  mb={"10px"}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <InputLabel className="fromLabel">Body</InputLabel>
                  <div
                    style={{ display: "flex", cursor: "pointer" }}
                    onClick={(e: any) => {
                      setClicked(false);
                      setAnchorEl(e.currentTarget);
                    }}
                  >
                    <Typography color={"primary"}>Personalization</Typography>
                    {open && clicked === false ? (
                      <ExpandLessOutlinedIcon />
                    ) : (
                      <ExpandMoreOutlinedIcon />
                    )}
                  </div>
                  <Popover
                    id={id}
                    open={open}
                    onClose={() => {
                      setAnchorEl(null);
                      setMessageTemplateData("");
                    }}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    {TemplateVaribles.map((item) => {
                      return (
                        <MenuItem
                          key={item.id}
                          style={{ backgroundColor: "white" }}
                          onClick={() =>
                            handlePersonalisation(item.conceptValue)
                          }
                        >
                          {item.conceptValue}
                        </MenuItem>
                      );
                    })}
                  </Popover>
                </Box>
                {hasError && !textfields.trimStart() && (
                  <FormHelperText style={{ color: "#d32f2f" }}>
                    Please enter message
                  </FormHelperText>
                )}
                <MessageEditor
                  setTextField={setTextField}
                  messageTemplateData={messageTemplateData}
                  isDataChanged={ispersonalizationChanged}
                />
              </Box>
            </Grid>
            <Grid
              size={{ xs: 12, md: 4 }}
              position={"relative"}
              pb={"75px"}
              className="p-10"
            >
              <Box mb={"30px"}>
                <InputLabel className="fromLabel">Template Name</InputLabel>
                <Box className="fromGroup">
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter message subject"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </Box>
                {hasError && !name.trimStart() && (
                  <FormHelperText style={{ color: "#d32f2f" }}>
                    Please enter template name
                  </FormHelperText>
                )}
              </Box>
              <Box mb={"30px"}>
                <InputLabel className="fromLabel">Sender Name</InputLabel>
                <Box className="fromGroup">
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter message sender"
                    value={sender}
                    onChange={(e) => {
                      setSender(e.target.value);
                    }}
                  />
                </Box>
                {hasError && !sender.trimStart() && (
                  <FormHelperText style={{ color: "#d32f2f" }}>
                    Please enter sender name
                  </FormHelperText>
                )}
              </Box>
              <Box mb={"40px"}>
                <InputLabel className="fromLabel me-3">Category</InputLabel>
                <div className="fromGroup-chips">
                  {messageMethods.map((item: string, index: number) => {
                    return (
                      <Card
                        className={
                          methods === item ? "fromGroup-chip-active" : ""
                        }
                        onClick={(
                          e: React.MouseEvent<HTMLDivElement, MouseEvent>
                        ) => {
                          setMethods(item);
                        }}
                        key={item}
                      >
                        {item}
                      </Card>
                    );
                  })}
                </div>
                {hasError && !methods && (
                  <FormHelperText style={{ color: "#d32f2f" }}>
                    Please select category
                  </FormHelperText>
                )}
              </Box>
              <Box>
                <InputLabel className="fromLabel">Audience</InputLabel>
                <div className="fromGroup-chips">
                  {Audience.map((item: string, index: number) => {
                    return (
                      <Card
                        className={
                          audience === item ? "fromGroup-chip-active" : ""
                        }
                        onClick={(
                          e: React.MouseEvent<HTMLDivElement, MouseEvent>
                        ) => {
                          setAudience(item);
                        }}
                        key={item}
                      >
                        {item}
                      </Card>
                    );
                  })}
                </div>
                {hasError && !audience && (
                  <FormHelperText style={{ color: "#d32f2f" }}>
                    Please select audience
                  </FormHelperText>
                )}
              </Box>
              {audience === "Admin" && (
                <Box className="fromGroup" style={{ marginTop: "20px" }}>
                  <InputLabel className="fromLabel">
                    Select Admin Users
                  </InputLabel>
                  <Select
                    multiple
                    className="form-control"
                    value={adminUser}
                    // placeholder="Select"
                    renderValue={(selected) => getAdminUserName(selected)}
                    onChange={(e: SelectChangeEvent<string[]>) => {
                      setAdminUser(e.target.value as string[]);
                    }}
                    MenuProps={MenuProps}
                  >
                    {tenantUsers
                      .filter(
                        (i: any) =>
                          i.authRole === "Admin" || i.authRole === "Owner"
                      )
                      .map((item: any) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            <Checkbox
                              checked={adminUser?.indexOf(item.id) > -1}
                            />
                            {`${item.firstName} ${" "} ${item.lastName}`}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default MessageTemplateNew;
