import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Close, Delete, Search } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  GetAllTaskType,
  PostTask,
  UpdateTaskbyId,
} from "../../../../redux/features/project/taskManager-slice";
import AssignTaskModal from "./AssignTaskModal";
import { MenuProps } from "../../../../constants/providers";
import { Priority, Status } from "../../../../constants/taskmanagement";
import { ITask, checklist } from "../../../../types/taskManager";
import { Iuser } from "../../../messages/message-form/Compose-message";
import ClientImg from "../../../../assets/images/images-svg/client-large.svg";
import CloseIcon from "@mui/icons-material/Close";
import { IUsers } from "./BoardTask";
import { getAllUserProfiles } from "../../../../redux/features/user-slice";
import { useUser } from "@descope/react-sdk";
import dayjs, { Dayjs } from "dayjs";
interface ITaskData {
  name: string;
  description: string;
  type: string;
  priority: string;
  status: string;
  dueDate: Date;
  checklist: checklist[];
}

const Checklist: React.FC<{
  item: checklist;
  updateChecklist: () => void;
  deleteCheckList: () => void;
  provider: DraggableProvided;
}> = ({ item, updateChecklist, deleteCheckList, provider }) => {
  return (
    <Box
      ref={provider.innerRef}
      {...provider.dragHandleProps}
      {...provider.draggableProps}
      className="checklist"
    >
      <FormControlLabel
        control={<Checkbox checked={item.checked} onChange={updateChecklist} />}
        label={item.value}
      />
      <Delete
        onClick={deleteCheckList}
        sx={{ cursor: "pointer" }}
        style={{ color: "#096DD9" }}
      />
    </Box>
  );
};

const AddTaskModal: React.FC<{
  setAddTaskModal: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit: boolean;
  currentTask?: ITask | undefined;
}> = ({ setAddTaskModal, isEdit, currentTask }) => {
  /* Descope Integration */
  const { user } = useUser();

  //dispatch
  const dispatch = useAppDispatch();

  //stores current project id
  const projectId = useAppSelector(
    (state) => state.taskManagerSlice.currentProject?.id
  );

  //stores all taskType from data Catalog
  const AllTaskType = useAppSelector(
    (state) => state.taskManagerSlice.AllTaskType
  );
  const Allclients = useAppSelector((state) => state.clientListSlice.listData);
  const Allproviders = useAppSelector(
    (state) => state.providerListSlice.listData
  );
  const users = useAppSelector((state) =>
    state.user.tenantUsers
      .filter(
        (item: any) => item.authRole === "Owner" || item.authRole === "Admin"
      )
      .map((item: any) => ({
        id: item.id,
        name: item.firstName + " " + item.lastName,
        profilePictureUrl: item.profilePictureUrl,
      }))
  );
  //assign To
  const [assignedTo, setAssignedTo] = useState<IUsers | null>();

  //stores feilds for task
  const [task, setTask] = useState<ITaskData>({
    name: isEdit && currentTask ? currentTask.name : "",
    description: isEdit && currentTask ? currentTask.description : "",
    type: isEdit && currentTask ? currentTask.type : "",
    priority: isEdit && currentTask ? currentTask.priority : "Low",
    checklist: isEdit && currentTask ? currentTask.checklist : [],
    status: isEdit && currentTask ? currentTask.status : "Not Started",
    dueDate: isEdit && currentTask ? currentTask?.dueDate : new Date(),
  });

  //stores single checklist to be added
  const [list, setlist] = useState<checklist>({
    checked: false,
    value: "",
  });

  const [taskFor, setTaskFor] = useState<Iuser>({
    entityType: "",
    id: "",
    name: "",
  });

  //handles errors
  const [hasError, setHasError] = useState<boolean>(false);

  const [taskForSearchList, setTaskForSearchList] = useState<Iuser[]>([]);

  const [searchValue, setSearchValue] = useState<string>("");

  //handle add checklist
  const AddChecklist = () => {
    if (list.value !== "") {
      setTask({ ...task, checklist: [...task.checklist, list] });
      setlist({
        checked: false,
        value: "",
      });
    }
  };

  //handles update checklist
  const updateChecklist = (checklistName: string, index: number) => {
    const updatedChecklist = task.checklist.map((item, i) => {
      if (i === index && checklistName === item.value) {
        return { ...item, checked: !item.checked };
      } else {
        return item;
      }
    });
    // Set the updated checklist in the task state
    setTask({ ...task, checklist: updatedChecklist });
  };

  const deleteCheckList = (index: number) => {
    setTask((pre) => ({
      ...pre,
      checklist: pre.checklist.filter((_, i) => i !== index),
    }));
  };

  const reorderCheckList = ({ destination, source }: DropResult) => {
    if (!destination) return;
    if (source.index === destination.index) return;
    const draggedItem = task.checklist[source.index];
    const newCheckList = task.checklist.filter((_, i) => i !== source.index);
    newCheckList.splice(destination.index, 0, draggedItem);
    setTask((pre) => ({ ...pre, checklist: newCheckList }));
  };

  const handleSelectDueDate = (value: Dayjs | null) => {
    if (value !== null) {
      // const date = dayjs(value).format();
      setTask({ ...task, dueDate: value.toDate() });
    }
  };

  //handle post and update task
  const handleSubmit = () => {
    if (
      task.name !== ""
      // || task.priority !== "" || task.type !== "" ||task.checklist.length !== 0
    ) {
      if (projectId && !isEdit) {
        dispatch(
          PostTask({
            name: task.name,
            description: task.description,
            type: task.type,
            priority: task.priority,
            status: task.status,
            checklist: task.checklist,
            projectId: projectId,
            dueDate: task.dueDate,
            assignTo: assignedTo ? assignedTo.id : null,
            entityId: taskFor.id !== "" ? taskFor.id : null,
            entityType: taskFor.id !== "" ? taskFor.entityType : null,
          })
        );
      } else if (isEdit && currentTask) {
        dispatch(
          UpdateTaskbyId({
            id: currentTask.id,
            name: task.name,
            description: task.description,
            type: task.type,
            priority: task.priority,
            status: task.status,
            checklist: task.checklist,
            dueDate: task.dueDate,
            assignTo: assignedTo ? assignedTo.id : null,
            entityId: taskFor.id !== "" ? taskFor.id : null,
            entityType: taskFor.id !== "" ? taskFor.entityType : null,
          })
        );
      }
      setAddTaskModal(false);
    } else {
      setHasError(true);
      return;
    }
  };
  const getMatchData = (item: any, searchVal: string): boolean => {
    if (
      searchVal &&
      !item.toLowerCase().includes(searchVal.toLowerCase().trim())
    ) {
      return false;
    }
    if (searchVal === "") {
      return false;
    }
    return true;
  };
  const handleSearchResult = (searchValue: string, searchType: string) => {
    let result: any[] = [];
    let providers: Iuser[] = [];
    let clients: Iuser[] = [];
    if (searchType === "taskFor") {
      clients = Allclients.map((ele) => {
        return {
          id: ele.clientBasicDetails?.id,
          name:
            ele.clientBasicDetails.childFirstName +
            " " +
            ele.clientBasicDetails.childLastName,
          entityType: "Client",
        };
      });

      providers = Allproviders.map((ele) => {
        return {
          id: ele.employmentDetails?.id,
          name:
            ele.employmentDetails.firstName +
            " " +
            ele.employmentDetails.lastName,
          entityType: "Provider",
        };
      });

      [...clients, ...providers, ...users].map((item) => {
        return getMatchData(item.name, searchValue) && result.push(item);
      });
      setTaskForSearchList(result);
    }
  };

  useEffect(() => {
    dispatch(GetAllTaskType());
    dispatch(
      getAllUserProfiles({
        authTenantId: user.customAttributes?.currentTenantId,
        callerEmail: user.email as string,
        callerName: user.name as string,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentTask) {
      setAssignedTo({
        id: currentTask.assignedTo?.id,
        name: `${currentTask.assignedTo?.firstName} ${currentTask.assignedTo?.lastName}`,
        profilePictureUrl: currentTask.assignedTo?.profilePictureUrl,
      });

      if (currentTask.entityId) {
        let name = "";
        if (currentTask?.entityType === "Client") {
          Allclients.forEach((item) => {
            if (item.clientBasicDetails.id === currentTask.entityId) {
              name =
                item.clientBasicDetails.childFirstName +
                " " +
                item.clientBasicDetails.childLastName;
            }
          });
        } else {
          Allproviders.forEach((item) => {
            if (item.employmentDetails.id === currentTask.entityId) {
              name =
                item.employmentDetails.firstName +
                " " +
                item.employmentDetails.lastName;
              return;
            }
          });
        }

        setTaskFor({
          id: currentTask.entityId,
          name: name!,
          entityType: currentTask.entityType,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  return (
    <div>
      <div>
        <Box className="largeModel__head">
          <Typography variant="h5" className="largeModel__title">
            {task?.name?.length === 0 ? "Create Task" : "Update Task"}
          </Typography>
          <Button
            className="largeModel__closeBtn"
            onClick={() => setAddTaskModal(false)}
          >
            <Close />
          </Button>
        </Box>
      </div>
      <div className="create-new-board p-30px">
        {/* <Grid className="create-new-board-inner"> */}
        <Grid
          container
          spacing={2}
          size={{ xs: 12 }}
          className="task-addContainer"
        >
          {/* Task Name */}
          <Grid size={{ xs: 4, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel" variant="standard">
                Task Name
              </InputLabel>
              <FormControl>
                <TextField
                  value={task?.name}
                  onChange={({ target: { value } }) =>
                    setTask({ ...task, name: value })
                  }
                />
              </FormControl>
              {task.name === "" && hasError && (
                <FormHelperText style={{ color: "#d32f2f" }}>
                  Please enter name
                </FormHelperText>
              )}
            </Box>
          </Grid>
          {/* Task For */}
          <Grid size={{ xs: 6 }} className="taskFor">
            <div className="mb-18 relative">
              <InputLabel className="fromLabel">Task For</InputLabel>
              <Box className="recipents-search">
                {taskFor.id !== "" && (
                  <div className="profilePart userInfo-Card">
                    <img src={ClientImg} alt="" />
                    <Typography>{taskFor.name}</Typography>
                    <CloseIcon
                      style={{ cursor: "pointer" }}
                      color="primary"
                      onClick={() => {
                        setTaskFor({
                          id: "",
                          entityType: "",
                          name: "",
                        });
                      }}
                    />
                  </div>
                )}

                <FormControl fullWidth sx={{ m: 1 }}>
                  <TextField
                    size="medium"
                    placeholder=""
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      handleSearchResult(e.target.value, "taskFor");
                    }}
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                  />
                </FormControl>

                <Search color="primary" />
              </Box>
              {taskForSearchList?.length > 0 && (
                <Box className="board-modelSearch-data">
                  {taskForSearchList.map((ele) => (
                    <div
                      className="profilePart userlist"
                      onClick={() => {
                        setTaskFor({
                          id: ele.id,
                          entityType: ele.entityType,
                          name: ele.name,
                        });
                        setSearchValue("");
                        setTaskForSearchList([]);
                      }}
                    >
                      <img src={ClientImg} alt="" />
                      <Typography>{ele.name}</Typography>
                    </div>
                  ))}
                </Box>
              )}
            </div>
          </Grid>
          {/* Due Date */}
          <Grid size={{ xs: 2, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel id="demo-simple-select-label" className="fromLabel">
                Due Date
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="singleDatePicker"
                  value={task.dueDate ? dayjs(task.dueDate) : null}
                  onChange={(e: dayjs.Dayjs | null) => {
                    handleSelectDueDate(e);
                  }}
                  // renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          {/* Assign To */}
          <Grid size={{ xs: 6 }} className="taskFor">
            <InputLabel className="fromLabel">Assign To</InputLabel>
            <AssignTaskModal
              assignedTo={assignedTo}
              setAssignedTo={setAssignedTo}
              fromModal={true}
            />
          </Grid>
          {/* Task Type */}
          <Grid size={{ xs: 4, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel id="demo-simple-select-label" className="fromLabel">
                Type
              </InputLabel>
              <FormControl>
                <Select
                  className="form-control"
                  value={task?.type}
                  MenuProps={MenuProps}
                  onChange={({ target: { value } }) =>
                    setTask({ ...task, type: value })
                  }
                >
                  {AllTaskType.map((type) => (
                    <MenuItem value={type.conceptValue} key={type.id}>
                      {type?.conceptValue}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* make it as optional
              {task.type === "" && hasError && (
                <FormHelperText style={{ color: "#d32f2f" }}>
                  Please select atleast one type
                </FormHelperText>
              )} */}
            </Box>
          </Grid>

          <Grid size={{ xs: 12 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel" variant="standard">
                Status
              </InputLabel>
              <div className="fromGroup-chips">
                {Object.values(Status).map((item: string, index: number) => (
                  <Card
                    className={
                      task?.status === item ? "fromGroup-chip-active" : ""
                    }
                    onClick={(
                      e: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => setTask({ ...task, status: item })}
                    key={index}
                  >
                    {item}
                  </Card>
                ))}
              </div>
            </Box>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Box className="fromGroup">
              <InputLabel id="demo-simple-select-label" className="fromLabel">
                Priority
              </InputLabel>
              <div className="fromGroup-chips">
                {Object.keys(Priority).map((item: string, index: number) => (
                  <Card
                    className={
                      task?.priority === item ? "fromGroup-chip-active" : ""
                    }
                    onClick={(
                      e: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => setTask({ ...task, priority: item })}
                    key={index}
                  >
                    {item}
                  </Card>
                ))}
              </div>
              {task.priority === "" && hasError && (
                <FormHelperText style={{ color: "#d32f2f" }}>
                  Please select atleast one priority
                </FormHelperText>
              )}
            </Box>
          </Grid>

          <Grid size={{ xs: 12 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Description</InputLabel>
              <FormControl>
                <TextField
                  value={task?.description}
                  onChange={({ target: { value } }) =>
                    setTask({ ...task, description: value })
                  }
                  rows={2}
                  multiline
                />
              </FormControl>
            </Box>
          </Grid>

          <Grid size={{ xs: 12 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Checklist</InputLabel>
              <FormControl sx={{ position: "relative" }}>
                <TextField
                  value={list.value}
                  onChange={(e) =>
                    setlist({ value: e.target.value, checked: false })
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      AddChecklist();
                    }
                  }}
                />
                <Button
                  sx={{
                    position: "absolute",
                    right: "4px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    height: "30px",
                  }}
                  variant="contained"
                  onClick={() => AddChecklist()}
                >
                  Add
                </Button>
              </FormControl>
            </Box>
          </Grid>
          {/* make this optional
            {task.checklist.length === 0 && hasError && (
            <FormHelperText style={{ color: "#d32f2f", marginLeft: "18px" }}>
              Please add atleast one checklist
            </FormHelperText>
          )} */}
          <Grid size={{ xs: 12 }}>
            <DragDropContext onDragEnd={reorderCheckList}>
              <Droppable
                droppableId="droppableId"
                direction="vertical"
                children={(provider) => (
                  <Box
                    minHeight={task.checklist.length * 50}
                    ref={provider.innerRef}
                    {...provider.droppableProps}
                  >
                    {task.checklist.map((item, index) => (
                      <Draggable
                        index={index}
                        key={index}
                        draggableId={index.toString()}
                        children={(provider) => (
                          <Checklist
                            provider={provider}
                            item={item}
                            deleteCheckList={deleteCheckList.bind(null, index)}
                            updateChecklist={updateChecklist.bind(
                              null,
                              item.value,
                              index
                            )}
                          />
                        )}
                      />
                    ))}
                  </Box>
                )}
              />
            </DragDropContext>
          </Grid>
        </Grid>
        <Box className="create-new-board-footer">
          <Button onClick={() => handleSubmit()} variant="contained">
            Save Task
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default AddTaskModal;
