import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Edit, Upload, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  FormHelperText,
  IconButton,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import { AppDispatch, RootState } from "../../../../redux/store";
import {
  setEmploymentDetails,
  setInitialState,
  updateProviderEmploymentById,
  updateProviderProfile,
} from "../../../../redux/features/providers-main/provider-slice";
import {
  getNonSupervisor,
  getSupervisor,
} from "../../../../redux/features/providers-main/provider-supervisor-slice";

import EmploymentDetailsForm from "../../providers-forms/EmploymentDetailsForm";
import SupervisorForm from "../../providers-forms/SupervisorForm";

import {
  EmploymentDetails,
  ProviderList,
  supervisors,
} from "../../../../types/provider";
import { OrgBU } from "../../../../types/company";

import { ReactComponent as UserVerified } from "../../../../assets/images/images-svg/verified.svg";
import ProviderImg from "../../../../assets/images/images-svg/provider-large-vertical.svg";
import { getBusinessUnitName } from "../../../../utils/EntityUtils";
import { isValidFileUploaded } from "../../../../utils/Image-validation";
import Loader from "../../../../layouts/loader/Loader";

interface SubEmploymentDetailsProps {
  employmentDetails: EmploymentDetails;
  id: string;
}

const SubEmploymentDetails: React.FC<SubEmploymentDetailsProps> = ({
  employmentDetails,
  id,
}) => {
  // #region variables region

  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();

  //business units
  const businessUnits = useSelector<RootState, OrgBU[]>(
    (state) => state.businessUnitsSlice.businessUnits
  );

  //fetch provider list
  const ProviderLists = useSelector<RootState, ProviderList[]>(
    (state) => state.providerSupervisorSlice.providerList
  );

  //fetch supervisor list
  const SupervisorLists = useSelector<RootState, ProviderList[]>(
    (state) => state.providerSupervisorSlice.supervisorList
  );

  //stores all provider list
  const AllProvidersList = [...ProviderLists, ...SupervisorLists];

  //handles the image error
  const [imageError, setImageError] = useState<string>("");

  //handles the popup for editing employment details
  const [editEmploymentDetails, setEditEmploymentDetails] =
    useState<boolean>(false);

  //handles loading
  const isLoading = useSelector<RootState, boolean>(
    (state) => state.responseSlice.pending
  );

  //stores supervisor data
  const providerform = useSelector<RootState, supervisors>(
    (state) => state.providerListSlice.singleProvider.supervisor
  );

  //store value open popup
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  //store value case popUp
  const [openPopupCases, setOpenPopupCases] = useState<boolean>(false);

  //handle supervisor team
  const filterprovider = providerform
    ? AllProvidersList.filter((i: any) => providerform.team?.includes(i.id))
    : [];

  // #endregion

  // #region methods region

  //employment details update handler
  const editEmploymentDetailsUpdater = () => {
    dispatch(updateProviderEmploymentById({}));
    setEditEmploymentDetails(false);
  };

  //handles filling form for editing employment details
  const setEmploymentEditHandler = (): void => {
    if (employmentDetails.languages === null) {
      dispatch(
        setEmploymentDetails({
          ...employmentDetails,
          languages: [],
          clientList: employmentDetails.clientList || [],
        })
      );
    } else {
      dispatch(
        setEmploymentDetails({
          ...employmentDetails,
        })
      );
    }

    if (openPopup) {
      setOpenPopup(false);
    } else {
      setEditEmploymentDetails(true);
    }
  };

  const setSupervisorHandler = (): void => {
    if (employmentDetails.languages === null) {
      dispatch(
        setEmploymentDetails({
          ...employmentDetails,
          languages: [],
          clientList: employmentDetails.clientList || [],
        })
      );
    } else {
      dispatch(
        setEmploymentDetails({
          ...employmentDetails,
          clientList: employmentDetails.clientList || [],
        })
      );
    }

    if (openPopup) {
      setOpenPopup(false);
    }
    // else {
    //   setEditEmploymentDetails(true);
    // }
  };

  //handles file upload and change for profile picture.
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files) {
      if (isValidFileUploaded(e.target.files[0])) {
        setImageError("");
        dispatch(updateProviderProfile({ fileObj: e.target.files[0], id: id }));
      } else {
        setImageError("please Select Valid Image");
      }
    }
  };

  // #endregion

  //#region useEffect region

  useEffect(() => {
    dispatch(getNonSupervisor());
    dispatch(getSupervisor());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employmentDetails.id]);

  //#endregion

  return (
    <Grid size={{ xs: 12, md: 6 }}>
      <Box className="detailsCard BasicInfoBox-client">
        <Box className="detailsCard__header">
          <Typography className="detailsCard__title">
            Employment Information
          </Typography>
          <IconButton
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
              setEmploymentEditHandler()
            }
            className="detailsCard__headBtn"
            size="small"
            edge="start"
          >
            <Edit />
          </IconButton>
        </Box>
        <Box className="detailsCard__body">
          <div className="userBasicInfoBox" style={{ position: "relative" }}>
            <div className="userBasicInfoBox__img">
              <Box className="uploadProfile">
                <div className="uploadProfile__img">
                  {isLoading ? (
                    <Loader isShow={isLoading} />
                  ) : (
                    <img
                      src={
                        employmentDetails?.providerProfile?.url || ProviderImg
                      }
                      className="img-fluid"
                      alt="image_not_found"
                    />
                  )}
                </div>
                <Button
                  variant="contained"
                  component="label"
                  className="uploadProfile__btn"
                  startIcon={<Upload />}
                >
                  Update Profile Picture
                  <input
                    type="file"
                    hidden
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleFileChange(e)
                    }
                  />
                </Button>
              </Box>
              <FormHelperText
                style={{
                  color: "#d32f2f",
                  position: "absolute",

                  left: "10px",
                }}
              >
                {imageError}
              </FormHelperText>
            </div>
            <div className="userBasicInfoBox__details">
              <Grid container spacing={0}>
                <Grid size={{ xs: 12, md: 12 }}>
                  <div className="detailsCard-part">
                    <Typography className="detailsCard-part__title">
                      Employee Name
                    </Typography>
                    <Typography className="detailsCard-part__text">
                      {employmentDetails?.firstName}{" "}
                      {employmentDetails?.lastName}
                      {employmentDetails.isSupervisor && <UserVerified />}
                    </Typography>
                  </div>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <div className="detailsCard-part">
                    <Typography className="detailsCard-part__title">
                      Employee Type
                    </Typography>
                    <Typography className="detailsCard-part__text">
                      {employmentDetails?.type}
                    </Typography>
                  </div>
                  <div className="detailsCard-part">
                    <Typography className="detailsCard-part__title">
                      Business Unit
                    </Typography>
                    <Typography className="detailsCard-part__text">
                      {getBusinessUnitName(
                        businessUnits,
                        employmentDetails?.businessUnit
                      )?.map((res: string, index: number) => {
                        return `${index ? "," : ""} ${res}`;
                      })}
                    </Typography>
                  </div>
                  {employmentDetails.isSupervisor &&
                  providerform !== undefined ? (
                    <div
                      className="detailsCard-part"
                      onClick={() => {
                        setOpenPopupCases(true);
                        setOpenPopup(true);
                        setSupervisorHandler();
                      }}
                    >
                      <Typography className="detailsCard-part__title">
                        Direct Cases
                      </Typography>
                      <Typography
                        className="detailsCard-part__text"
                        style={{ color: "#096dd9", cursor: "pointer" }}
                      >
                        {providerform?.client?.length}{" "}
                        {providerform?.client?.length === 0 ||
                        providerform?.client?.length === 1
                          ? "case"
                          : "cases"}
                      </Typography>
                    </div>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <div className="detailsCard-part">
                    <Typography className="detailsCard-part__title">
                      Employee Status
                    </Typography>
                    <Typography className="detailsCard-part__text">
                      {employmentDetails?.employmentStatus}
                    </Typography>
                  </div>
                  <div className="detailsCard-part">
                    <Typography className="detailsCard-part__title">
                      Languages
                    </Typography>
                    {employmentDetails?.languages?.map((item: string) => (
                      <Typography className="detailsCard-part__text" key={item}>
                        {item}
                      </Typography>
                    ))}
                  </div>
                  {employmentDetails.isSupervisor &&
                  filterprovider !== undefined ? (
                    <div
                      className="detailsCard-part"
                      onClick={() => {
                        setOpenPopup(!openPopup);
                        setSupervisorHandler();
                      }}
                    >
                      <Typography className="detailsCard-part__title">
                        Direct Team
                      </Typography>
                      <Typography
                        className="detailsCard-part__text"
                        style={{ color: "#096dd9", cursor: "pointer" }}
                      >
                        {filterprovider.length}{" "}
                        {filterprovider.length === 0 ||
                        filterprovider.length === 1
                          ? "employee"
                          : "employees"}
                      </Typography>
                    </div>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid size={{ xs: 12, md: 12 }}>
                  <div className="detailsCard-part">
                    <Typography className="detailsCard-part__title">
                      Specialty
                    </Typography>
                    <Typography className="detailsCard-part__text">
                      {employmentDetails?.specialities.join(" , ")}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Box>
      </Box>
      {editEmploymentDetails && (
        <Dialog open={editEmploymentDetails} className="largeModel">
          <Box className="largeModel__body">
            {/*<Box className="stepperForm">*/}
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Edit Employee
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={() => {
                  setEditEmploymentDetails(false);
                  dispatch(setInitialState(null));
                }}
              >
                <Close />
              </Button>
            </Box>
            <EmploymentDetailsForm
              isNew={false}
              updateHandler={editEmploymentDetailsUpdater}
            />
          </Box>
        </Dialog>
      )}
      {openPopup && (
        <Dialog open={openPopup} className="smallModel smallModel--edit">
          <Box className="smallModel__head">
            <Typography variant="h2" className="smallModel__title">
              {openPopupCases ? "Cases" : "Employees"}
              {" Under - "}
              {employmentDetails?.firstName} {employmentDetails?.lastName}
            </Typography>
            <Button
              className="smallModel__closeBtn"
              onClick={() => {
                dispatch(setInitialState(null));
                setOpenPopup(false);
                setOpenPopupCases(false);
              }}
            >
              <Close />
            </Button>
          </Box>
          <SupervisorForm
            employmentDetails={employmentDetails}
            setOpenPopup={setOpenPopup}
            setOpenPopupCases={setOpenPopupCases}
            openPopupCases={openPopupCases}
            id={id}
          />
        </Dialog>
      )}
    </Grid>
  );
};

export default SubEmploymentDetails;
