import React, { useEffect } from "react";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useDispatch, useSelector } from "react-redux";
import {
  AppDispatch,
  RootState,
  // useAppSelector,
} from "../../../../redux/store";
import {
  getEmployeeScheduleSettings,
  updateParentScheduling,
} from "../../../../redux/features/data-catalog/data-catalog-slice";
import { EmployeeSetting } from "../../../../types/businessSettings";
// import {
//   GetSessionSetting,
//   UpdateSessionSettings,
// } from "../../../../redux/features/session/session-slice";

const BusinessEmployeePortal = () => {
  //for dispatch
  const dispatch = useDispatch<AppDispatch>();

  //stores settings for employee schedule portal
  const { allowEmployeeToSeeAvailablity, allowEmployeeToUpdateAvailablity } =
    useSelector<RootState, EmployeeSetting>(
      (state) => state.dataCatalogSlice.EmployeeSetting
    );

  //deprecate this setting by Hui on 2024-12-08
  // const {
  //   requireCompletionAtTheBegningOfSession,
  //   requireCompletionAtTheEndOfSession,
  // } = useAppSelector((state) => state.sessionSlice.sessionSetting);

  //useEffect
  useEffect(() => {
    dispatch(getEmployeeScheduleSettings());
    // dispatch(GetSessionSetting());
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, lg: 6 }}>
        <Box className="detailsCard">
          <Box className="detailsCard__header">
            <Typography className="detailsCard__title">
              Employee Availability
            </Typography>
          </Box>
          <Box className="detailsCard__body">
            <Box className="settingBox">
              <div className="settingBox__part">
                <FormGroup className="settingBox__field">
                  <h6 className="settingBox__field-title">
                    Allow Employee to see availability
                  </h6>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={allowEmployeeToSeeAvailablity}
                        onChange={() =>
                          dispatch(
                            updateParentScheduling({
                              type: "employee",
                              data: {
                                allowEmployeeToSeeAvailablity:
                                  !allowEmployeeToSeeAvailablity,
                                allowEmployeeToUpdateAvailablity,
                              },
                            })
                          )
                        }
                      />
                    }
                    label={allowEmployeeToSeeAvailablity ? "On" : "Off"}
                  />
                </FormGroup>
                <FormGroup className="settingBox__field">
                  <h6 className="settingBox__field-title">
                    Allow Employee to update availability
                  </h6>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={allowEmployeeToUpdateAvailablity}
                        onChange={() =>
                          dispatch(
                            updateParentScheduling({
                              type: "employee",
                              data: {
                                allowEmployeeToUpdateAvailablity:
                                  !allowEmployeeToUpdateAvailablity,
                                allowEmployeeToSeeAvailablity,
                              },
                            })
                          )
                        }
                      />
                    }
                    label={allowEmployeeToUpdateAvailablity ? "On" : "Off"}
                  />
                </FormGroup>
              </div>
            </Box>
          </Box>
        </Box>
      </Grid>
      {/* deprecate this setting by Hui on 2024-12-08 */}
      {/* <Grid item xs={12} lg={6}>
        <Box className="detailsCard">
          <Box className="detailsCard__header">
            <Typography className="detailsCard__title">
              Session Setting
            </Typography>
          </Box>
          <Box className="detailsCard__body">
            <Box className="settingBox">
              <div className="settingBox__part">
                <FormGroup className="settingBox__field">
                  <h6 className="settingBox__field-title">
                    If you use Sign-In Note, require completion at the beginning
                    of session
                  </h6>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={requireCompletionAtTheBegningOfSession}
                        onChange={() =>
                          dispatch(
                            UpdateSessionSettings({
                              requireCompletionAtTheEndOfSession,
                              requireCompletionAtTheBegningOfSession:
                                !requireCompletionAtTheBegningOfSession,
                            })
                          )
                        }
                      />
                    }
                    label={requireCompletionAtTheBegningOfSession ? "On" : "Off"}
                  />
                </FormGroup>
                <FormGroup className="settingBox__field">
                  <h6 className="settingBox__field-title">
                    If you use Sign-Out Note, require completion at the end of
                    session
                  </h6>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={requireCompletionAtTheEndOfSession}
                        onChange={() =>
                          dispatch(
                            UpdateSessionSettings({
                              requireCompletionAtTheBegningOfSession,
                              requireCompletionAtTheEndOfSession:
                                !requireCompletionAtTheEndOfSession,
                            })
                          )
                        }
                      />
                    }
                    label={requireCompletionAtTheEndOfSession ? "On" : "Off"}
                  />
                </FormGroup>
              </div>
            </Box>
          </Box>
        </Box>
      </Grid> */}
    </Grid>
  );
};

export default BusinessEmployeePortal;
