import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  getParentPortalSchedule,
  updateParentScheduling,
} from "../../../../redux/features/data-catalog/data-catalog-slice";
import {
  NewDataCatalog,
  ParentSchedule,
} from "../../../../types/businessSettings";

interface IParentScheduleData {
  minHrsToCancel: number;
  maxProviderToRebook: number;
  maxBookingToCancel: number;
  maxDaysToRebook: number;
}

export enum RebookingOptions {
  ProviderAndTime = "Show Providers and Time",
  DateAndTime = "Show Date and Time only",
}

const BusinessScheduling: React.FC = () => {
  //#region variable region
  //dispatch
  const dispatch = useDispatch<AppDispatch>();

  //stores data from parent portal
  const parentSchedule = useSelector<RootState, ParentSchedule>(
    (state) => state.dataCatalogSlice.ParentSchedule
  );

  const serviceTypes = useSelector<RootState, NewDataCatalog[]>(
    (state) => state.dataCatalogSlice.BillableCatalog
  );

  //stores data
  const [parentScheduleData, setparentScheduleData] =
    useState<IParentScheduleData>({
      minHrsToCancel: 1,
      maxProviderToRebook: 1,
      maxBookingToCancel: 1,
      maxDaysToRebook: 1,
    });

  //store service type
  const [serviceType, setServiceType] = useState<string[]>(
    parentSchedule.serviceType
  );

  //#endregion

  //#region methods region

  //handles service type updation
  const handleServiceType = (item: string) => {
    if (serviceType?.includes(item)) {
      const type = serviceType.filter((ele) => ele !== item);
      setServiceType(type);
      dispatch(
        updateParentScheduling({
          type: "parent",
          data: {
            ...parentSchedule,
            serviceType: type,
          },
        })
      );
    } else {
      setServiceType(serviceType ? [...serviceType, item] : [item]);
      dispatch(
        updateParentScheduling({
          type: "parent",
          data: {
            ...parentSchedule,
            serviceType: serviceType ? [...serviceType, item] : [item],
          },
        })
      );
    }
  };

  //handles update Minimum hours of notice required
  const handleMaxhrstoCancel = () => {
    if (parentSchedule.minHrsToCancel !== parentScheduleData.minHrsToCancel) {
      dispatch(
        updateParentScheduling({
          type: "parent",
          data: {
            ...parentSchedule,
            minHrsToCancel: parentScheduleData.minHrsToCancel,
          },
        })
      );
    }
  };

  //handles update Minimum hours of notice required
  const handleBookingstoCancel = () => {
    if (
      parentSchedule.maxBookingToCancel !==
      parentScheduleData.maxBookingToCancel
    ) {
      dispatch(
        updateParentScheduling({
          type: "parent",
          data: {
            ...parentSchedule,
            maxBookingToCancel: parentScheduleData.maxBookingToCancel,
          },
        })
      );
    }
  };

  //handles update new providers to show when rebooking
  const handleAfterBookonBlur = () => {
    if (
      parentSchedule.maxProviderToRebook !==
      parentScheduleData.maxProviderToRebook
    ) {
      dispatch(
        updateParentScheduling({
          type: "parent",
          data: {
            ...parentSchedule,
            maxProviderToRebook: parentScheduleData.maxProviderToRebook,
          },
        })
      );
    }
  };

  //handles maximum days to re-book
  const handlecheckAvailabilityCancel = () => {
    if (parentSchedule.maxDaysToRebook !== parentScheduleData.maxDaysToRebook) {
      dispatch(
        updateParentScheduling({
          type: "parent",
          data: {
            ...parentSchedule,
            maxDaysToRebook: parentScheduleData.maxDaysToRebook,
          },
        })
      );
    }
  };

  //handles re-book provider type
  const handleRebookProviderType = (value: string) => {
    if (parentSchedule.allowParentToRebook) {
      dispatch(
        updateParentScheduling({
          type: "parent",
          data: {
            ...parentSchedule,
            rebookProviderType: value,
          },
        })
      );
    }
  };

  //handles select types of re-booking options
  const handleShowRebookingOptions = (item: string) => {
    if (parentSchedule.allowParentToCancel) {
      if (item === RebookingOptions.ProviderAndTime) {
        dispatch(
          updateParentScheduling({
            type: "parent",
            data: {
              ...parentSchedule,
              showProvider: true,
            },
          })
        );
      } else if (item === RebookingOptions.DateAndTime) {
        dispatch(
          updateParentScheduling({
            type: "parent",
            data: {
              ...parentSchedule,
              showProvider: false,
            },
          })
        );
      }
    }
  };

  //#endregion

  //#region useEffect region
  //used to fetch parent portal data
  useEffect(() => {
    dispatch(getParentPortalSchedule());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setparentScheduleData({
      minHrsToCancel: parentSchedule.minHrsToCancel,
      maxProviderToRebook: parentSchedule.maxProviderToRebook,
      maxBookingToCancel: parentSchedule.maxBookingToCancel,
      maxDaysToRebook: parentSchedule.maxDaysToRebook,
    });
    setServiceType(parentSchedule.serviceType);
  }, [parentSchedule]);
  //#endregion

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, lg: 6 }}>
        <Box className="detailsCard">
          <Box className="detailsCard__header">
            <Typography className="detailsCard__title">
              Parent Cancellation
            </Typography>
          </Box>
          <Box className="detailsCard__body">
            <Box className="settingBox">
              <div className="settingBox__part">
                <Box className="settingBox__part-inner">
                  <FormGroup className="settingBox__field">
                    <h6 className="settingBox__field-title">
                      Allow parents to cancel bookings through the Parent
                      Portal.
                    </h6>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={parentSchedule.allowParentToCancel}
                          onChange={() =>
                            dispatch(
                              updateParentScheduling({
                                type: "parent",
                                data: {
                                  ...parentSchedule,
                                  allowParentToCancel:
                                    !parentSchedule.allowParentToCancel,
                                  allowParentToRebook: false,
                                },
                              })
                            )
                          }
                        />
                      }
                      label={parentSchedule.allowParentToCancel ? "On" : "Off"}
                    />
                  </FormGroup>

                  <FormGroup className="settingBox__field">
                    <h6 className="settingBox__field-title">
                      Minimum hours of notice required for parent cancellations:
                    </h6>
                    <input
                      disabled={!parentSchedule.allowParentToCancel}
                      type="number"
                      min="1"
                      value={parentScheduleData.minHrsToCancel}
                      onChange={(e) => {
                        if (["+", "-", "0"].includes(e.target.value)) {
                          e.preventDefault();
                        } else {
                          setparentScheduleData({
                            ...parentScheduleData,
                            minHrsToCancel: parseInt(e.target.value),
                          });
                        }
                      }}
                      onBlur={handleMaxhrstoCancel}
                    />
                  </FormGroup>
                  <FormGroup className="settingBox__field">
                    <h6 className="settingBox__field-title">
                      How often to allow parent to cancel per month:
                    </h6>
                    <input
                      disabled={!parentSchedule.allowParentToCancel}
                      type="number"
                      min="1"
                      value={parentScheduleData.maxBookingToCancel}
                      onChange={(e) => {
                        if (["+", "-", "0"].includes(e.target.value)) {
                          e.preventDefault();
                        } else {
                          setparentScheduleData({
                            ...parentScheduleData,
                            maxBookingToCancel: parseInt(e.target.value),
                          });
                        }
                      }}
                      onBlur={handleBookingstoCancel}
                    />
                  </FormGroup>
                  <FormGroup className="settingBox__field">
                    <Box className="fromGroup">
                      <h6 className="settingBox__field-title">
                        Service types allowed for parent cancellations:
                      </h6>
                      <div className="fromGroup-chips">
                        {serviceTypes?.map((item) => (
                          <Card
                            key={item.id}
                            className={
                              serviceType?.includes(item.conceptValue)
                                ? "fromGroup-chip-active"
                                : "fromGroup-chips"
                            }
                            onClick={() => {
                              if (parentSchedule.allowParentToCancel) {
                                handleServiceType(item.conceptValue);
                              }
                            }}
                          >
                            {item.conceptValue}
                          </Card>
                        ))}
                      </div>
                    </Box>
                  </FormGroup>
                </Box>
              </div>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid size={{ xs: 12, lg: 6 }}>
        <Box className="detailsCard">
          <Box className="detailsCard__header">
            <Typography className="detailsCard__title">
              Parent Rebooking
            </Typography>
          </Box>
          <Box className="detailsCard__body">
            <Box className="settingBox">
              <div className="settingBox__part">
                <FormGroup className="settingBox__field">
                  <h6 className="settingBox__field-title">
                    Allow parents to re-book through the Parent Portal.
                  </h6>
                  <FormControlLabel
                    disabled={!parentSchedule.allowParentToCancel}
                    control={
                      <Switch
                        checked={parentSchedule.allowParentToRebook}
                        onChange={() =>
                          dispatch(
                            updateParentScheduling({
                              type: "parent",
                              data: {
                                ...parentSchedule,
                                allowParentToRebook:
                                  !parentSchedule.allowParentToRebook,
                              },
                            })
                          )
                        }
                      />
                    }
                    label={parentSchedule.allowParentToRebook ? "On" : "Off"}
                  />
                </FormGroup>
                <FormGroup className="settingBox__field">
                  <Box className="fromGroup">
                    <h6 className="settingBox__field-title">
                      How you want to show rebooking options:
                    </h6>
                    <div className="fromGroup-chips">
                      {Object.values(RebookingOptions).map((item, index) => (
                        <Card
                          key={index}
                          className={
                            parentSchedule.showProvider &&
                            item === RebookingOptions.ProviderAndTime
                              ? "fromGroup-chip-active"
                              : !parentSchedule.showProvider &&
                                item === RebookingOptions.DateAndTime
                              ? "fromGroup-chip-active"
                              : ""
                          }
                          onClick={() => {
                            if (parentSchedule.allowParentToRebook) {
                              handleShowRebookingOptions(item);
                            }
                          }}
                        >
                          {item}
                        </Card>
                      ))}
                    </div>
                  </Box>
                </FormGroup>
                <FormGroup>
                  <FormControl>
                    <h6 className="settingBox__field-title">
                      Who are eligible as the re-booking providers:
                    </h6>
                    <div style={{ paddingLeft: "10px" }}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={
                          parentSchedule?.rebookProviderType
                            ? parentSchedule.rebookProviderType
                            : ""
                        }
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="same"
                          control={<Radio />}
                          label="Same last provider"
                          onClick={() => handleRebookProviderType("same")}
                        />
                        <FormControlLabel
                          value="team"
                          control={<Radio />}
                          label="Any provider on child's supervisor team"
                          onClick={() => handleRebookProviderType("team")}
                        />
                        <FormControlLabel
                          value="BU"
                          control={<Radio />}
                          label="Any provider for the same BU"
                          onClick={() => handleRebookProviderType("BU")}
                        />
                        <FormControlLabel
                          value="Org"
                          control={<Radio />}
                          label="Any provider for the organization"
                          onClick={() => handleRebookProviderType("Org")}
                        />
                      </RadioGroup>
                    </div>
                  </FormControl>
                </FormGroup>

                <FormGroup className="settingBox__field">
                  <h6 className="settingBox__field-title">
                    Number of days to check availability since cancelled date :
                  </h6>
                  <input
                    disabled={!parentSchedule.allowParentToRebook}
                    type="number"
                    min="1"
                    value={parentScheduleData.maxDaysToRebook}
                    onChange={(e) => {
                      if (["+", "-", "0"].includes(e.target.value)) {
                        e.preventDefault();
                      } else {
                        setparentScheduleData({
                          ...parentScheduleData,
                          maxDaysToRebook: parseInt(e.target.value),
                        });
                      }
                    }}
                    onBlur={handlecheckAvailabilityCancel}
                  />
                </FormGroup>

                <FormGroup className="settingBox__field">
                  <h6 className="settingBox__field-title">
                    Number of new providers to show when rebooking:
                  </h6>
                  <input
                    disabled={!parentSchedule.allowParentToRebook}
                    type="number"
                    min="1"
                    value={parentScheduleData.maxProviderToRebook}
                    onChange={(e) => {
                      if (["+", "-", "0"].includes(e.target.value)) {
                        e.preventDefault();
                      } else {
                        setparentScheduleData({
                          ...parentScheduleData,
                          maxProviderToRebook: parseInt(e.target.value),
                        });
                      }
                    }}
                    onBlur={handleAfterBookonBlur}
                  />
                </FormGroup>
              </div>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default BusinessScheduling;
