import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import {
  Button,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { BookedSchedule } from "../../../types/schedule";
import { ClientForms } from "../../../types/client";
import DiscoverBookingEditModal from "../booking-scheduler/DiscoverBookingEditModal";
import { RootState } from "../../../redux/store";
import { convertTime12to24 } from "../../../constants/schedule";
import { deleteMultiSchedules } from "../../../service/schedules.service";
import { getAllBookingsData } from "../../../redux/features/schedules-main/schedule-list-slice";
import { setListDataChange } from "../../../redux/features/client-main/client-list-slice";
import { OrgBU } from "../../../types/company";
import { getBusinessUnitName } from "../../../utils/EntityUtils";

// Custom Table Col style
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  padding: "5px 20px",
}));

// Custom Table
const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
}));

interface Props {
  clientData: ClientForms;
  isDataEdited: boolean;
  setIsDataEdited: Dispatch<SetStateAction<boolean>>;
  setCounter(val: number): void;
}

const SingleScheduleList: React.FC<Props> = ({
  clientData,
  isDataEdited,
  setIsDataEdited,
  setCounter,
}) => {
  // #region variables region

  //dispatch for redux
  const dispatch = useDispatch();

  //control the delete booking modal
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  //control the edit booking modal
  const [editModal, setEditModal] = useState<boolean>(false);

  //business units
  const businessUnits = useSelector<RootState, OrgBU[]>(
    (state) => state.businessUnitsSlice.businessUnits
  );

  //store argsData
  const [argsdata, setargsData] = useState<Record<string, any>>();

  //store async order data
  const [asydata, setAsyData] = useState<BookedSchedule[]>([]);

  //selected schedule to edit or delete
  const [selectedSchedule, setSelectedSchedule] =
    useState<BookedSchedule | null>(null);

  //handle select checkbox
  const [multiCheckbox, setMeltiCheckbox] = useState<boolean>(false);

  //handle checkbox ids
  const [checked, setChecked] = useState<string[]>([]);

  //handles the deletePopup
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  //handle sorting data according to dates and time leteast
  const sortAsc = (val: BookedSchedule[]): BookedSchedule[] => {
    let sorted = val.sort(
      (a, b) =>
        parseInt(convertTime12to24(a.fromTime)) -
        parseInt(convertTime12to24(b.fromTime))
    );

    let sortDate = sorted.sort((a: BookedSchedule, b: BookedSchedule) => {
      return (
        (new Date(b.specificDate) as any) - (new Date(a.specificDate) as any)
      );
    });

    setAsyData(sortDate);

    return sortDate;
  };
  // #endregion

  //#region method region

  //handle select all checkbox
  const onSelectAllClick = () => {
    let arr: string[] = [];
    if (!(asydata.length === checked.length)) {
      asydata.forEach((item) => {
        arr.push(item.id);
      });
      setChecked(arr);
    } else {
      localStorage.removeItem("checked");
      setChecked([]);
    }
  };

  // Select each checkbox manually
  const onSelectCheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: string
  ) => {
    let result: string[] = [];

    if (checked.includes(item)) {
      result = checked.filter((ids) => {
        return ids !== item;
      });

      setChecked([...result]);
      if (result.length === 0) {
        localStorage.removeItem("checked");
      }
    } else {
      setChecked([...checked, item]);
    }
  };

  const createObj = () => {
    let specificDate = new Date(
      moment.utc(selectedSchedule?.specificDate).format("MM/DD/YYYY")
    );
    let fromTimeStr = convertTime12to24(selectedSchedule?.fromTime);
    let toTimeStr = convertTime12to24(selectedSchedule?.toTime);
    const [fromHour, fromMin, fromSec] = fromTimeStr.split(":");
    const [toHour, toMin, toSec] = toTimeStr.split(":");

    let fromDateTime = new Date(
      specificDate.getFullYear(),
      specificDate.getMonth(),
      specificDate.getDate(),
      +fromHour,
      +fromMin,
      +fromSec
    );

    let toDateTime = new Date(
      specificDate.getFullYear(),
      specificDate.getMonth(),
      specificDate.getDate(),
      +toHour,
      +toMin,
      +toSec
    );

    var bookedBlock = {
      Subject: `${selectedSchedule?.bookedClientId.childFirstName}${" "}${
        selectedSchedule?.bookedClientId.childLastName
      }${" - "}${selectedSchedule?.bookedProviderId.firstName}${" "}${
        selectedSchedule?.bookedProviderId.lastName
      }`,
      StartTime: fromDateTime.toISOString(),
      EndTime: toDateTime.toISOString(),

      bookedClientId: selectedSchedule?.bookedClientId,
      bookedProviderId: selectedSchedule?.bookedProviderId,
      //repeatOn: bookedSlots[i].repeatOn,
      repeatWorkDays: selectedSchedule?.repeatWorkDays,
      repeatCustomEnd: selectedSchedule?.repeatCustomEnd,
      serviceID: selectedSchedule?.id,
      specificDate: selectedSchedule?.specificDate,
      whoCancelled: selectedSchedule?.whoCancelled,
      cancellationReason: selectedSchedule?.cancellationReason,
      authId: selectedSchedule?.authId,
      authCode: selectedSchedule?.authCode,

      clientId: clientData.clientBasicDetails?.id,

      Repeat: selectedSchedule?.repeatService,
      ServiceType: selectedSchedule?.serviceType,
      Location: selectedSchedule?.serviceLocation,
      Description: selectedSchedule?.description,
      status: selectedSchedule?.status,

      isAllDay: false,
      IsBlock: false, //clientOrProvider === "CLIENT" ? true : false,
      id: selectedSchedule?.id,
    };

    setargsData(bookedBlock);
  };

  //fetch delete api
  const GetMultiDelete = useCallback(async (checked: string[]) => {
    try {
      const { data } = await deleteMultiSchedules(checked);

      if (data) {
        setTimeout(() => {
          batch(() => {
            dispatch(getAllBookingsData());
            setIsDataEdited(true); //trigger for calendar data change
            dispatch(setListDataChange()); //trigger to refresh getClient
          });
        }, 3000);
      }
    } catch (e: any) {
      console.log(e, "error");
    } finally {
      console.log("Done");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#endregion

  //#region useEffect region

  //re-fetching asyn order list
  useEffect(() => {
    if (clientData?.bookedServices?.length > 0) {
      sortAsc([...clientData?.bookedServices]);
    }
  }, [clientData, multiCheckbox]);

  //re-fetching create object data
  useEffect(() => {
    if (selectedSchedule) {
      createObj();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchedule, isDataEdited, multiCheckbox]);

  //re-Fetch counter value
  useEffect(() => {
    const data = window.localStorage.getItem("setCounter");
    if (data !== null) setCounter(JSON.parse(data));

    if (checked.length) {
      localStorage.setItem("checked", JSON.stringify(checked));
    }
  }, [checked, checked.length, setCounter]);

  //gets data from local storage .
  useEffect(() => {
    const checkData: any = localStorage.getItem("checked");
    const parsedCheckedData = JSON.parse(checkData);
    if (checked.length === 0 && parsedCheckedData) {
      setChecked(parsedCheckedData);
    }
  }, [checked.length]);

  //#endregion

  return (
    <div className="listingTable bookingTable">
      <div className="listingTable__header">
        <Typography className="listingTable__title" gutterBottom variant="h6">
          All Schedules For Client -{" "}
          {clientData?.clientBasicDetails?.childFirstName}{" "}
          {clientData?.clientBasicDetails?.childLastName}
        </Typography>
        <Grid size={{ xs: 12 }} className="text-right">
          <Button
            className="button button--large"
            onClick={() => {
              setCounter(1);
            }}
          >
            Match Providers
          </Button>
        </Grid>
      </div>
      <Grid container spacing={0}>
        <Grid size={{ xs: 6, md: 6 }} className="matchBtnBlock">
          <Button
            color="inherit"
            type="button"
            className="button button--large button-border"
            onClick={() => {
              if (checked.length > 0) {
                setConfirmDelete(true);
              }
            }}
          >
            Multi-Delete
          </Button>
        </Grid>
      </Grid>

      <TableContainer className="tableResponsive">
        <StyledTable
          sx={{
            minWidth: 820,
            borderCollapse: "separate",
            cursor: "pointer",
          }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Checkbox
                  color="primary"
                  checked={asydata.length === checked.length}
                  onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center">BU</StyledTableCell>
              <StyledTableCell align="center">Provider</StyledTableCell>
              <StyledTableCell align="center">Type</StyledTableCell>
              <StyledTableCell align="center">Activity</StyledTableCell>
              <StyledTableCell align="center">Repeat</StyledTableCell>
              <StyledTableCell align="center">Date</StyledTableCell>
              <StyledTableCell align="center">Time</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Updated On</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          {clientData && clientData.bookedServices?.length > 0 ? (
            <TableBody>
              {asydata !== undefined &&
                asydata.map((item: BookedSchedule, index: number) => {
                  return (
                    <TableRow key={index}>
                      <StyledTableCell>
                        <Checkbox
                          color="primary"
                          checked={checked?.includes(item.id)}
                          onChange={(e) => {
                            onSelectCheck(e, item.id);
                          }}
                          inputProps={{
                            "aria-label": "select all desserts",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell scope="row" align="center">
                        {getBusinessUnitName(
                          businessUnits,
                          item?.bookedClientId?.businessUnit
                        )?.map((res: string, index: number) => {
                          return <span>{`${index ? "," : ""} ${res}`} </span>;
                        })}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.bookedProviderId.firstName} {}
                        {item.bookedProviderId.lastName}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.bookedProviderId.type},{" "}
                        {item.bookedProviderId.employmentStatus}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.serviceType}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.repeatService === 2
                          ? "Auth End: " +
                            moment.utc(item.repeatEndsOn).format("MM/DD/YYYY")
                          : item.repeatService === 1
                          ? "Month End: " +
                            moment.utc(item.repeatEndsOn).format("MM/DD/YYYY")
                          : item.repeatService === 3
                          ? "Custom End: " +
                            moment.utc(item.repeatEndsOn).format("MM/DD/YYYY")
                          : item.repeatService === 0
                          ? "Single"
                          : ""}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {moment.utc(item.specificDate).format("MM/DD/YYYY")} -{" "}
                        {item.repeatWorkDays}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.fromTime} - {item.toTime}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.status}
                      </StyledTableCell>
                      <StyledTableCell scope="row" align="center">
                        {moment.utc(item.updatedAt).format("MM/DD/YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div className="setDate">
                          <span
                            onClick={() => {
                              setSelectedSchedule(item);
                              setTimeout(() => {
                                setEditModal(true);
                              }, 10);
                            }}
                          >
                            <CalendarTodayIcon className="text-blue" />
                          </span>
                          <span
                            onClick={() => {
                              setSelectedSchedule(item);
                              setTimeout(() => {
                                setDeleteModal(true);
                              }, 10);
                            }}
                          >
                            <DeleteIcon />
                          </span>
                        </div>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          ) : (
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan={12}>
                  <div className="matchAppointment-Block">
                    No booked schedule yet.
                  </div>
                </StyledTableCell>
              </TableRow>
            </TableHead>
          )}
        </StyledTable>
      </TableContainer>
      {deleteModal && (
        <DiscoverBookingEditModal
          pageTitle={"Cancel Booking"}
          clientData={clientData}
          // provider={[]}
          isOpen={true}
          isEdit={false}
          isNew={false}
          isDelete={true}
          closePopup={setDeleteModal}
          argsdata={argsdata!}
          // Bookeddata={Bookeddata}
          isDataEdited={isDataEdited}
          setIsDataEdited={setIsDataEdited}
          allSelectedClients={[clientData]}
          providersData={[]}
          fromPlanning={false}
          isAutoPlanned={false}
        />
      )}
      {editModal && (
        <>
          <DiscoverBookingEditModal
            pageTitle={`Update Booking -
            ${moment.utc(selectedSchedule!.specificDate).format("MM/DD/YYYY")}`}
            clientData={clientData}
            // provider={[]}
            isOpen={true}
            isEdit={true}
            isNew={false}
            closePopup={setEditModal}
            argsdata={argsdata!}
            // Bookeddata={Bookeddata}
            isDataEdited={isDataEdited}
            setIsDataEdited={setIsDataEdited}
            allSelectedClients={[clientData]}
            providersData={[]}
            fromPlanning={false}
            isAutoPlanned={false}
          />
        </>
      )}

      {checked.length > 0 && confirmDelete && (
        <Dialog open={confirmDelete} className="smallModel">
          <div>
            <DialogContent>Are you sure want to delete ?</DialogContent>
            <DialogActions>
              <Button
                className="border-button"
                variant="contained"
                onClick={() => setConfirmDelete(false)}
                color="secondary"
              >
                No
              </Button>
              <Button
                className="button"
                variant="contained"
                onClick={() => {
                  setMeltiCheckbox(!multiCheckbox);
                  GetMultiDelete(checked);
                  setConfirmDelete(false);
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default SingleScheduleList;
