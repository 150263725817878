import { useState } from "react";
import { Box, Button, Dialog, Typography, InputLabel } from "@mui/material";
import { Close } from "@mui/icons-material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import CryptoJS from "crypto-js";

import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  ConnectApp,
  // ConnectMotivityApp,
} from "../../redux/features/integration-setting/integration-setting-slice";

interface AppIntegrationModalProps {
  appName: string;
  isModalOpen: boolean;
  closeModal: () => void;
}

const AppIntegrationModal = ({
  appName,
  isModalOpen,
  closeModal,
}: AppIntegrationModalProps) => {
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState<string>("UserLogin");
  const integrationSetting = useAppSelector(
    (state) => state.integrationSettingSlice.integrationSettings
  );
  const appCredential = integrationSetting.find(
    (setting) => setting.appName.toLowerCase() === appName.toLowerCase()
  );
  const secretKey = process.env.REACT_APP_SECRET_KEY || "secret key";
  const cipherText = appCredential?.credential;
  const decryptedData =
    cipherText &&
    CryptoJS.AES.decrypt(cipherText, secretKey).toString(CryptoJS.enc.Utf8) &&
    JSON.parse(
      CryptoJS.AES.decrypt(cipherText, secretKey).toString(CryptoJS.enc.Utf8)
    );

  const [credential, setCredential] = useState<any>(decryptedData || {});
  const handleChange = (e: any) => {
    setCredential({
      ...credential,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async () => {
    dispatch(
      ConnectApp({
        appName,
        appCredential: credential,
        testLogin: false,
      })
    );

    closeModal();
  };
  const handleTestLogin = () => {
    dispatch(
      ConnectApp({
        appName,
        appCredential: credential,
        testLogin: true,
      })
    );
  };
  return (
    <>
      <Dialog open={isModalOpen} className="largeModel">
        <Box className="largeModel__head">
          <Typography variant="h5" className="largeModel__title">
            Configure Integration - {appName}
          </Typography>
          <Button className="largeModel__closeBtn" onClick={closeModal}>
            <Close />
          </Button>
        </Box>
        <Box className="stepperForm">
          <div className="tab">
            <ul className="innerTabs ">
              <li className="innerTabs__item">
                <button
                  onClick={() => setSelectedTab("UserLogin")}
                  className={selectedTab === "UserLogin" ? "active" : ""}
                >
                  User Login
                </button>
              </li>
              <li
                className="innerTabs__item"
                onClick={() => {
                  setSelectedTab("APIKey");
                }}
              >
                <button className={selectedTab === "APIKey" ? "active" : ""}>
                  API Key
                </button>
              </li>
            </ul>
          </div>
          <ValidatorForm onSubmit={handleSubmit}>
            <div className="tab">
              {selectedTab === "UserLogin" && (
                <div>
                  <Box className="formGroup-login">
                    <InputLabel className="fromLabel" sx={{ flex: 1 }}>
                      User
                    </InputLabel>
                    <Box sx={{ width: "100%", flex: 5 }}>
                      <TextValidator
                        id="email"
                        type="text"
                        name="email"
                        value={credential?.email || ""}
                        placeholder="login Username"
                        onChange={handleChange}
                        validators={["required"]}
                        errorMessages={["userName is required"]}
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Box className="formGroup-login">
                    <InputLabel className="fromLabel" sx={{ flex: 1 }}>
                      Password
                    </InputLabel>
                    <Box sx={{ width: "100%", flex: 5 }}>
                      <TextValidator
                        id="password"
                        type="password"
                        name="password"
                        value={credential?.password || ""}
                        placeholder="login Password"
                        onChange={handleChange}
                        validators={["required"]}
                        errorMessages={["Password is required"]}
                        fullWidth
                      />
                    </Box>
                  </Box>
                </div>
              )}
              {selectedTab === "APIKey" && (
                <div>
                  {appName === "CentralReach" && (
                    <Box className="formGroup-login">
                      <InputLabel className="fromLabel" sx={{ flex: 1 }}>
                        API Key
                      </InputLabel>
                      <Box sx={{ width: "100%", flex: 5 }}>
                        <TextValidator
                          id="apiKey"
                          type="text"
                          name="apiKey"
                          value={credential?.apiKey || ""}
                          placeholder="API Key"
                          onChange={handleChange}
                          validators={["required"]}
                          errorMessages={["api key is required"]}
                          fullWidth
                        />
                      </Box>
                    </Box>
                  )}
                  <Box className="formGroup-login">
                    <InputLabel className="fromLabel" sx={{ flex: 1 }}>
                      Client Id
                    </InputLabel>
                    <Box sx={{ width: "100%", flex: 5 }}>
                      <TextValidator
                        id="clientId"
                        type="text"
                        name="clientId"
                        value={credential?.clientId || ""}
                        placeholder="Client Id"
                        onChange={handleChange}
                        validators={["required"]}
                        errorMessages={["client id is required"]}
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Box className="formGroup-login">
                    <InputLabel className="fromLabel" sx={{ flex: 1 }}>
                      Secret
                    </InputLabel>
                    <Box sx={{ width: "100%", flex: 5 }}>
                      <TextValidator
                        id="clientSecret"
                        type="password"
                        name="clientSecret"
                        value={credential?.clientSecret || ""}
                        placeholder="API Secret"
                        onChange={handleChange}
                        validators={["required"]}
                        errorMessages={["client secret is required"]}
                        fullWidth
                      />
                    </Box>
                  </Box>
                </div>
              )}
            </div>

            <Box className="stepperForm__footer b-0">
              <Button variant="outlined" onClick={handleTestLogin}>
                Test Login
              </Button>
              <Button type="submit" variant="contained" onClick={handleSubmit}>
                Connect
              </Button>
            </Box>
          </ValidatorForm>
        </Box>
      </Dialog>
    </>
  );
};

export default AppIntegrationModal;
