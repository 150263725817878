import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  InputLabel,
  TextField,
  FormHelperText,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Popover,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { PostComposeMessage } from "../../../redux/features/messages/message-slice";
// import FileUpload from "react-material-file-upload";
import { MuiFileInput } from "mui-file-input";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./compose-message.scss";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import MessageEditor from "../../settings/business-settings/message-template/MessageEditor";
import ClientImg from "../../../assets/images/images-svg/client-large.svg";
import CloseIcon from "@mui/icons-material/Close";
import {
  GetAllClientData,
  getClientFilesById,
} from "../../../redux/features/client-main/client-list-slice";
import { ClientForms } from "../../../types/client";
import { GetAllProviders } from "../../../redux/features/providers-main/provider-list-slice";
import { ProviderForms } from "../../../types/provider";
import "./compose-message.scss";
import { useNavigate } from "react-router";
import { Delete, Search } from "@mui/icons-material";
import { resetClientFiles } from "../../../redux/features/client-main/client-slice";
import { getAllUserProfiles } from "../../../redux/features/user-slice";
import { useUser } from "@descope/react-sdk";
import { getAllTemplateVariables } from "../../../redux/features/communication/messageTemplate-slice";

export interface Iuser {
  id: string;
  name: string;
  entityType: string;
}
const ComposeMessage: React.FC = () => {
  //#region variable region

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useUser();
  const [from, setFrom] = useState<string>("");
  const [subjects, setSubject] = useState<string>("");
  const [textfields, setTextField] = useState<string>("");
  const [newFiles, setNewFiles] = useState<any[]>([]);
  const [messageTemplateData, setMessageTemplateData] = useState<string>("");
  const [librarySelectedFiles, setLibrarySelectedFiles] = useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<Iuser[]>([]);
  const [searchedUsers, setSearchedUsers] = useState<Iuser[]>([]);
  const [clicked, setClicked] = useState(false);
  const [haserror, setHasError] = useState<boolean>(false);
  const [fileError, setFileError] = useState<{
    size: boolean;
    length: boolean;
  }>({ size: false, length: false });
  const [selectedFileTab, setSelectedFileTab] = useState<string>("Library");
  const [groupFilter, setGroupFilter] = useState<string[]>([]);
  const [searchList, setSearchList] = useState<Iuser[]>([]);
  const [searchValue, setSearchValue] = useState<{
    file: string;
    receipent: string;
  }>({ file: "", receipent: "" });
  const [fileSearchedUser, setFileSearchedUser] = useState<Iuser>();
  const [fileSearchedList, setFileSearchedList] = useState<Iuser[]>([]);
  const [groupFilterMenu, setGroupFilterMenu] = useState<
    { name: string; id: string }[]
  >([]);
  const [ispersonalizationChanged, setPersonalizationChanged] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  let open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //store templateVaribles
  const TemplateVaribles = useAppSelector(
    (state) => state.communicationSlice.templateVariables
  );

  //business units
  const businessUnits = useAppSelector(
    (state) => state.businessUnitsSlice.businessUnits
  );

  const clientList = useAppSelector((state) => state.clientListSlice.listData);

  const providerList = useAppSelector(
    (state) => state.providerListSlice.listData
  );

  const clientFile = useAppSelector((state) => state.client.clientFiles);
  const users = useAppSelector((state) =>
    state.user.tenantUsers
      .filter(
        (item: any) => item.authRole === "Owner" || item.authRole === "Admin"
      )
      .map((item: any) => ({
        id: item.id,
        name: item.firstName + " " + item.lastName,
        entityType: item.authRole,
      }))
  );
  //#endregion

  const handleGroupFilter = (filterValue: string[]) => {
    if (
      filterValue.includes("All") &&
      groupFilterMenu &&
      groupFilter.length < groupFilterMenu.length
    ) {
      setGroupFilter([...groupFilterMenu.map((item) => item.name), "All"]);
    } else if (groupFilter.includes("All") && !filterValue.includes("All")) {
      setGroupFilter([]);
    } else if (
      filterValue.length === groupFilterMenu!.length &&
      !filterValue.includes("All")
    ) {
      setGroupFilter([...filterValue, "All"]);
    } else {
      setGroupFilter(filterValue.filter((item) => item !== "All"));
    }
  };

  //get match value
  const getMatchData = (item: any, searchVal: string): boolean => {
    if (
      searchVal &&
      !item.toLowerCase().includes(searchVal.toLowerCase().trim())
    ) {
      return false;
    }
    if (searchVal === "") {
      return false;
    }
    return true;
  };

  //search
  const handleSearchResult = (searchVal: string, type: string) => {
    let result: any[] = [];
    let list: Iuser[] = [];
    if (type === "file") {
      setSearchValue({
        receipent: searchValue.receipent,
        file: searchVal,
      });
    } else {
      setSearchValue({
        receipent: searchVal,
        file: searchValue.file,
      });
    }
    if (searchVal !== "") {
      list = mapUser(clientList, providerList);
      if (type === "file") {
        [...list, ...users].map((item) => {
          return getMatchData(item.name, searchVal) && result.push(item);
        });
        setFileSearchedList(result);
      } else {
        [...list, ...users].map((item) => {
          return getMatchData(item.name, searchVal) && result.push(item);
        });

        setSearchList(result);
      }
    } else {
      setSearchList([]);
      setFileSearchedList([]);
    }
  };

  //handles personalisation dropdown
  const handlePersonalisation = (item: string) => {
    if (clicked) {
      setSubject(subjects + " " + "{{" + item + "}}");
    } else {
      setMessageTemplateData(item);
    }
    setAnchorEl(null);
    setPersonalizationChanged(!ispersonalizationChanged);
  };

  //create compose message
  const handleComposeMessage = async () => {
    if (
      from === "" ||
      (selectedUsers.length === 0 && searchedUsers.length === 0)
    ) {
      setHasError(true);
      return;
    }

    let recipents: { id: string; entityType: string }[] = [];
    const fileId = librarySelectedFiles.map((file) => file?.id);
    recipents = [...selectedUsers, ...searchedUsers].map((item) => {
      return { id: item.id, entityType: item.entityType };
    });
    recipents = [...new Set(recipents)];

    await dispatch(
      PostComposeMessage({
        users: recipents,
        subject: subjects,
        message: textfields,
        whoCanView: ["admin", "parent"],
        from: from,
        files: newFiles ? newFiles : [],
        messagetype: "externalCount",
        libraryFiles: fileId || [],
      })
    );

    navigate("/message-center", {
      state: { selectedTab: "External" },
    });
  };

  //file upload with validation
  const handleFileUpload = (e: File[]) => {
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
    const islimitExceed = e.some((file) => file.size > maxSize);

    if (newFiles.length + e.length <= 5 && !islimitExceed) {
      setNewFiles([...newFiles, ...e]);
    } else {
      if (newFiles.length + e.length > 5) {
        setFileError({ ...fileError, length: true });
      } else if (islimitExceed) {
        setFileError({ ...fileError, size: true });
      }
      setTimeout(() => {
        setFileError({ length: false, size: false });
      }, 3000);
    }
  };

  const mapUser = (
    clients: ClientForms[],
    providers: ProviderForms[]
  ): Iuser[] => {
    const users = [
      ...clients.map((ele) => ({
        id: ele.clientBasicDetails.id,
        name: `${ele.clientBasicDetails.childFirstName} ${ele.clientBasicDetails.childLastName}`,
        entityType: "Client",
      })),
      ...providers.map((ele) => ({
        id: ele.employmentDetails.id,
        name: `${ele.employmentDetails.firstName} ${ele.employmentDetails.lastName}`,
        entityType: "Provider",
      })),
    ];

    return users;
  };
  //need to work on this filter
  useEffect(() => {
    let user: React.SetStateAction<Iuser[]> = [];
    let client: ClientForms[] = [];
    let provider: ProviderForms[] = [];
    if (groupFilter.includes("All")) {
      user = mapUser(clientList, providerList);
    } else {
      let ClientFilter = groupFilterMenu
        .filter(
          (bu) => groupFilter.includes(bu.name) && bu.name.includes("Clients")
        )
        .map((item) => item.id);

      let ProviderFilter = groupFilterMenu
        .filter(
          (bu) => groupFilter.includes(bu.name) && bu.name.includes("Providers")
        )
        .map((item) => item.id);
      client = clientList.filter((item: ClientForms) =>
        ClientFilter.some((group) =>
          item.clientBasicDetails.businessUnit.includes(group)
        )
      );
      provider = providerList.filter((item: ProviderForms) =>
        ProviderFilter.some((group) =>
          item.employmentDetails.businessUnit.includes(group)
        )
      );
      user = mapUser(client, provider);
    }
    setSelectedUsers(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupFilter?.length]);

  useEffect(() => {
    dispatch(getAllTemplateVariables());
    dispatch(
      getAllUserProfiles({
        authTenantId: user.customAttributes?.currentTenantId,
        callerEmail: user.email as string,
        callerName: user.name as string,
      })
    );
    dispatch(GetAllClientData());
    dispatch(GetAllProviders());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchedUsers?.length > 0 && searchedUsers[0]?.id) {
      dispatch(getClientFilesById({ clientId: searchedUsers[0]?.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedUsers[0]?.id, searchedUsers?.length]);

  useEffect(() => {
    if (fileSearchedUser?.id) {
      dispatch(getClientFilesById({ clientId: fileSearchedUser?.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileSearchedUser?.id]);

  useEffect(() => {
    const allBus = businessUnits.flatMap((item) => [
      { name: `${item.businessUnitName} - Clients`, id: item.id },
      { name: `${item.businessUnitName} - Providers`, id: item.id },
    ]);

    setGroupFilterMenu(allBus);
  }, [businessUnits]);

  return (
    <>
      <div className="pageTemplate">
        <div className="ComposeMessage_header">
          <div className="header_firstBlock">
            <Button
              onClick={() =>
                navigate("/message-center", {
                  state: { selectedTab: "External" },
                })
              }
              className="backBtn"
              startIcon={<KeyboardBackspaceIcon />}
              style={{ marginBottom: 0 }}
            >
              Back
            </Button>

            <Typography className="title">Compose In-Mail</Typography>
          </div>

          <Button variant="contained" onClick={() => handleComposeMessage()}>
            Send
          </Button>
        </div>
        <div className="composeMessage_content">
          <div className="editor-section ">
            <div className="message-actions-1">
              <Typography className="textLabel pr-55">Sender</Typography>
              <TextField
                placeholder="Enter sender name"
                className="sender-input"
                fullWidth
                sx={{
                  "& fieldset": { border: "none" },
                }}
                value={from}
                onChange={(e) => setFrom(e.target.value)}
              />
            </div>
            {from === "" && haserror && (
              <FormHelperText style={{ color: "#d32f2f", marginLeft: "109px" }}>
                Please enter sender name
              </FormHelperText>
            )}

            <div className="message-actions-2">
              <Typography className="textLabel pr-55">Recipients</Typography>
              <Box sx={{ minWidth: 50 }}>
                <FormControl fullWidth>
                  <Select
                    id="demo-simple-select"
                    multiple
                    value={groupFilter}
                    renderValue={(selected: any) => {
                      selected = selected.map((select: any) => {
                        if (select === "All") {
                          return "All";
                        } else {
                          return select;
                        }
                      });
                      return selected.join(", ");
                    }}
                    onChange={(e) => {
                      handleGroupFilter(e.target.value as string[]);
                    }}
                  >
                    <MenuItem value={"All"}>
                      <Checkbox
                        checked={
                          groupFilter?.length === groupFilterMenu!.length + 1
                        }
                      />
                      All BU
                    </MenuItem>
                    {groupFilterMenu &&
                      groupFilterMenu.map((item) => (
                        <MenuItem value={item.name}>
                          <Checkbox
                            checked={groupFilter.indexOf(item.name) > -1}
                          />
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="mt-18 mb-18 relative">
              <Box className="recipents-search">
                {searchedUsers.length > 0 &&
                  searchedUsers.map((item) => (
                    <div className="profilePart userInfo-Card">
                      <img src={ClientImg} alt="" />
                      <Typography>{item?.name}</Typography>
                      <CloseIcon
                        style={{ cursor: "pointer" }}
                        color="primary"
                        onClick={() =>
                          setSearchedUsers(
                            searchedUsers.filter((user) => user.id !== item.id)
                          )
                        }
                      />
                    </div>
                  ))}

                <FormControl fullWidth sx={{ m: 1 }}>
                  <TextField
                    size="medium"
                    placeholder="search recipents by name here..."
                    value={searchValue.receipent}
                    onChange={(e) => {
                      handleSearchResult(e.target.value, "recipents");
                    }}
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                  />
                </FormControl>

                <Search color="primary" />
              </Box>
              {searchList?.length > 0 && (
                <Box className="searchList">
                  {searchList.map((ele) => (
                    <div
                      className="profilePart userlist"
                      onClick={() => {
                        if (!searchedUsers.some((item) => item.id === ele.id)) {
                          setSearchedUsers([...searchedUsers!, ele]);
                          setSearchList([]);
                        }
                        setSearchValue({
                          ...searchValue,
                          receipent: "",
                        });
                      }}
                    >
                      <img src={ClientImg} alt="" />
                      <Typography>{ele.name}</Typography>
                    </div>
                  ))}
                </Box>
              )}
            </div>
            {selectedUsers.length === 0 &&
              searchedUsers.length === 0 &&
              haserror && (
                <FormHelperText
                  style={{ color: "#d32f2f", marginLeft: "109px" }}
                >
                  Please select atleast one user
                </FormHelperText>
              )}
            <div className="message-editor">
              <Grid size={{ xs: 12, md: 8 }}>
                <Box mb={"30px"}>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <InputLabel className="fromLabel">Subject</InputLabel>
                    <div
                      style={{ display: "flex", cursor: "pointer" }}
                      onClick={(e: any) => {
                        setClicked(true);
                        setAnchorEl(e.currentTarget);
                      }}
                    >
                      <Typography color={"primary"}>Personalization</Typography>
                      {open && clicked ? (
                        <ExpandLessOutlinedIcon />
                      ) : (
                        <ExpandMoreOutlinedIcon />
                      )}
                    </div>
                  </Box>
                  <Box className="fromGroup">
                    <TextField
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      placeholder="Enter message subject"
                      value={subjects}
                      onChange={(e) => {
                        setSubject(e.target.value);
                      }}
                    />
                  </Box>
                </Box>
                <Box mb={"30px"}>
                  <Box
                    mb={"10px"}
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    <InputLabel className="fromLabel">Body</InputLabel>
                    <div
                      style={{ display: "flex", cursor: "pointer" }}
                      onClick={(e: any) => {
                        setAnchorEl(e.currentTarget);
                        setClicked(false);
                      }}
                    >
                      <Typography color={"primary"}>Personalization</Typography>
                      {open && clicked === false ? (
                        <ExpandLessOutlinedIcon />
                      ) : (
                        <ExpandMoreOutlinedIcon />
                      )}
                    </div>
                  </Box>
                  <Popover
                    id={id}
                    open={open}
                    onClose={() => {
                      setAnchorEl(null);
                    }}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    {TemplateVaribles.map((item) => {
                      return (
                        <MenuItem
                          key={item.id}
                          style={{ backgroundColor: "white" }}
                          onClick={() => {
                            handlePersonalisation(item.conceptValue);
                          }}
                        >
                          {item.conceptValue}
                        </MenuItem>
                      );
                    })}
                  </Popover>
                  <MessageEditor
                    setTextField={setTextField}
                    messageTemplateData={messageTemplateData}
                    isDataChanged={false}
                  />
                </Box>
              </Grid>
            </div>
          </div>

          <div className="fileSection">
            <div>
              <ul className="fileTabs mb-18">
                <li className="filetab-item">
                  <button
                    onClick={() => setSelectedFileTab("Library")}
                    className={selectedFileTab === "Library" ? "active" : ""}
                  >
                    Library{" "}
                  </button>
                </li>
                <li className="filetab-item">
                  <button
                    onClick={() => setSelectedFileTab("Local")}
                    className={selectedFileTab === "Local" ? "active" : ""}
                  >
                    Local{" "}
                  </button>
                </li>
              </ul>

              {fileError.length && (
                <FormHelperText style={{ color: "#d32f2f" }}>
                  Cannot upload more than 5 files
                </FormHelperText>
              )}
              {fileError.size && (
                <FormHelperText style={{ color: "#d32f2f" }}>
                  Cannot upload file of size more than 5 MB
                </FormHelperText>
              )}
              {selectedFileTab === "Local" ? (
                <div>
                  <Typography className="mb-18 tab-Description">
                    {" "}
                    (up to 5, max size (5 MB))
                  </Typography>
                  <Box className="fileUpload-block">
                    <MuiFileInput
                      multiple
                      value={newFiles}
                      onChange={(e: File[]) => {
                        handleFileUpload(e);
                      }}
                    />
                  </Box>
                </div>
              ) : (
                <div className="Library-Search">
                  <Typography className="tab-Description mb-18">
                    Search client or employee files
                  </Typography>
                  <div className="mt-18 relative">
                    <Box className="recipents-search">
                      {fileSearchedUser !== undefined && (
                        <div className="profilePart userInfo-Card">
                          <img src={ClientImg} alt="" />
                          <Typography>{fileSearchedUser?.name}</Typography>
                          <CloseIcon
                            style={{ cursor: "pointer" }}
                            color="primary"
                            onClick={() => {
                              setFileSearchedUser(undefined);
                              dispatch(resetClientFiles());
                            }}
                          />
                        </div>
                      )}

                      <FormControl fullWidth sx={{ m: 1 }}>
                        <TextField
                          size="medium"
                          placeholder="Search client or employee name..."
                          value={searchValue.file}
                          onChange={(e) => {
                            handleSearchResult(e.target.value, "file");
                          }}
                          sx={{
                            "& fieldset": { border: "none" },
                          }}
                        />
                      </FormControl>

                      <Search color="primary" />
                    </Box>
                    {fileSearchedList?.length > 0 && (
                      <Box className="searchList">
                        {fileSearchedList.map((ele) => (
                          <div
                            className="profilePart userlist"
                            onClick={() => {
                              setFileSearchedUser(ele);
                              setFileSearchedList([]);
                              setSearchValue({ ...searchValue, file: "" });
                            }}
                          >
                            <img src={ClientImg} alt="" />
                            <Typography>{ele.name}</Typography>
                          </div>
                        ))}
                      </Box>
                    )}
                  </div>
                  <Box className="searchedFile-List">
                    <ul className="client-fileList">
                      {clientFile.length > 0 &&
                      fileSearchedUser !== undefined ? (
                        clientFile.map((file) => (
                          <li>
                            <Checkbox
                              checked={
                                librarySelectedFiles
                                  .map((item) => item?.id)
                                  .indexOf(file?.id) > -1
                              }
                              onClick={() => {
                                const isExist = librarySelectedFiles.some(
                                  (item) => item?.id === file?.id
                                );
                                if (isExist) {
                                  setLibrarySelectedFiles(
                                    librarySelectedFiles.filter(
                                      (ele) => ele?.id !== file?.id
                                    )
                                  );
                                } else {
                                  setLibrarySelectedFiles([
                                    ...librarySelectedFiles,
                                    file,
                                  ]);
                                }
                              }}
                            />{" "}
                            <Typography> {file?.fileName}</Typography>
                          </li>
                        ))
                      ) : (
                        <li>No files found.</li>
                      )}
                    </ul>
                  </Box>
                </div>
              )}
            </div>
            <div>
              <Typography className="textLabel mb-18 mt-18">
                Attachments
              </Typography>
              <Box className="attachments">
                <div className="uploadProfile__img selectedIn-fileBox">
                  {(newFiles.length > 0 || librarySelectedFiles.length > 0) &&
                    [...newFiles, ...librarySelectedFiles].map(
                      (file, index) => {
                        return (
                          <div className="selectedIn-file">
                            <h1>{file.name || file.fileName}</h1>
                            <span>
                              <Delete
                                onClick={() => {
                                  if (file?.id) {
                                    setLibrarySelectedFiles(
                                      librarySelectedFiles.filter(
                                        (item) => item?.id !== file?.id
                                      )
                                    );
                                  } else {
                                    setNewFiles(
                                      newFiles.filter(
                                        (_, fileIndex) => fileIndex !== index
                                      )
                                    );
                                  }
                                }}
                              />
                            </span>
                          </div>
                        );
                      }
                    )}
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComposeMessage;
