import { ArrowLeft } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SubscriptionCard from "../../../components/payment/SubscriptionCard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  IStripeProduct,
  ITenantUsage,
  ICurrentPlan,
} from "../../../types/product";
import {
  getProducts,
  getTenantUsage,
} from "../../../redux/features/plan-billing/plan-billing-slice";

const ViewPlan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [subscriptionProducts, setSubscriptionProducts] = useState<any[]>([]);
  // const [tenatUsage, setTenatUsage] = useState<ItenatUsage>({
  //   adminCount: 0,
  //   clientCount: 0,
  //   providerCount: 0,
  //   currentPlan: {
  //     productId: "",
  //     title: "",
  //     features: [],
  //     price: 0,
  //     isPopular: false,
  //     clientLimit: 0,
  //     adminLimit: 0,
  //     nextProductId: "",
  //     additionalAdminPlan: "",
  //   },
  // });
  const orgData = useSelector(
    (state: RootState) => state.organizationSlice.orgData
  );
  const products = useSelector<RootState, IStripeProduct[]>(
    (state) => state.planBillingSlice.products
  );
  const tenantUsage = useSelector<RootState, ITenantUsage>(
    (state) => state.planBillingSlice.tenantUsage
  );
  const currentPlan = useSelector<RootState, ICurrentPlan>(
    (state) => state.planBillingSlice.currentPlan
  );

  const [adminSeats, setAdminSeats] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState<any | null>(null);

  // const onCheckoutPayment = (productId: string, adminSeats: number) => {
  //   const plan = products.find((item) => item.productId === productId);
  //   setSelectedPlan(plan);
  //   setAdminSeats(adminSeats);
  //   checkoutPayment(productId, adminSeats);
  // };

  useEffect(() => {
    dispatch(getTenantUsage());
  }, [orgData]);
  useEffect(() => {
    dispatch(getProducts());
  }, []);

  // console.log("tenantUsage, products, orgData:", tenantUsage, products, orgData);

  return (
    <div className="pageTemplate">
      <div className="backHead">
        <Button
          className="backBtn"
          startIcon={<ArrowLeft />}
          style={{ marginBottom: 0 }}
          onClick={() => navigate("/my-plan")}
        >
          <Typography variant="h6" className="DetailsPageTitle">
            All Plans
          </Typography>
        </Button>
      </div>
      <Grid container spacing={2} sx={{ p: "0px 100px" }}>
        {products.map((product: IStripeProduct, index: number) => (
          <SubscriptionCard
            key={product.productId}
            product={product}
            currentPrice={currentPlan?.planDetail?.price}
            tenantUsage={tenantUsage}
            adminSeats={adminSeats}
            setAdminSeats={setAdminSeats}
          />
        ))}
      </Grid>
    </div>
  );
};

export default ViewPlan;
