import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import taskManagerSlice from "../project/taskManager-slice";
import { setResponseValue } from "../api-response/api-response";
import { resourceHubService } from "../../../service/resourcehub.service";
import { IResource } from "../../../types/resourceHub";
import { set } from "lodash";

interface IResourceSlice {
  allResources: IResource[];
  resourceForms: IResource[],
  resourceMessageTemplate: IResource[]
}

const initialState: IResourceSlice = {
  allResources: [],
  resourceForms: [],
  resourceMessageTemplate: []
};

const resourceHubSlice = createSlice({
  name: "resourceHubSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetAllResource.fulfilled, (state, action) => {
      if (action.payload === undefined) return;
      state.resourceForms = action.payload.filter((item: any) => item.resourceType === "Form");
      state.resourceMessageTemplate = action.payload.filter((item: any) => item.resourceType === "Message");
      state.allResources = action.payload;
    });
    builder.addCase(InstallOrUpdateResourceById.fulfilled, (state, action) => {
      const resourceId = action.payload.resource.id;
      const Type = action.payload.resource.resourceType;
      delete action.payload.resource;
      switch (Type) {
        case "Form":
          state.resourceForms = state.resourceForms.map((item) => {
            if (item.id === resourceId) {
              return {
                ...item,
                isUpdateAvailable:false,
                businessForms: [action.payload],
              };
            }
            return item;
          })
          break;
        case "Message":
          state.resourceMessageTemplate = state.resourceMessageTemplate.map((item) => {
            if (item.id === resourceId) {
              return {
                ...item,
                isUpdateAvailable:false,
                messageTemplates: [action.payload],
              };
            }
            return item;
          })
          break;
      }
    });
  },
});
//gets all projects
export const GetAllResource = createAsyncThunk(
  "getAllResources",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await resourceHubService.getAllResource();
      return data;
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//install or update Resource by Id
export const InstallOrUpdateResourceById = createAsyncThunk(
  "install/update-Resource",
  async (
    { formId, resourceType }: { formId: string; resourceType: string },
    { dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, message } =
        await resourceHubService.installOrUpdateResource(formId, resourceType);
      if (data) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return data;
      }
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export default resourceHubSlice;
