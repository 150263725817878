import {
  Box,
  Button,
  Dialog,
  FormHelperText,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ClientImage from "../../../../assets/images/images-svg/client-large.svg";
import UploadIcon from "@mui/icons-material/Upload";
import { ClientSubBasicDetailsProps } from "./ClientSubBasicDetails";
import { isValidFileUploaded } from "../../../../utils/Image-validation";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { setResponseValue } from "../../../../redux/features/api-response/api-response";
import { uploadClientParentImage } from "../../../../service/client.service";
import { getClientBoards } from "../../../../redux/features/client-main/client-board-slice";
import { setListDataChange } from "../../../../redux/features/client-main/client-list-slice";
import EditIcon from "@mui/icons-material/Edit";
import {
  setClientBasicDetails,
  setEditClient,
} from "../../../../redux/features/client-main/client-slice";
import CloseIcon from "@mui/icons-material/Close";
import ClientParentDetailsForm from "../../client-stepper/ClientParentDetailForm";
import { CarouselProvider, Slide, Slider } from "pure-react-carousel";

const ClientParentDetail: React.FC<ClientSubBasicDetailsProps> = ({
  clientBasicDetails,
}) => {
  const dispatch = useDispatch();
  const [imageError, setImageError] = useState("");
  const [isEditParentInfo, setEditParentInfo] = useState(false);
  const [parentIndex, setParentIndex] = useState(0);

  //handles profile picture upload
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setImageError("Error Occured");
      return;
    }
    if (!isValidFileUploaded(e.target.files[0])) {
      setImageError("Please Select Valid Image");
      return;
    }

    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const data = await uploadClientParentImage(
        e.target.files[0],
        clientBasicDetails.id as string,
        parentIndex.toString()
      );
      if (data.status) {
        dispatch(getClientBoards({ useLoader: false }));
        dispatch(setListDataChange());
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: data.message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };

  //handles the data setting in redux for client basic details
  const handleEditBasicClick = () => {
    dispatch(setClientBasicDetails({ ...clientBasicDetails, isEdit: true }));
    dispatch(setEditClient(true));
    setEditParentInfo(true);
  };

  return (
    <Grid size={{ xs: 12, lg: 6, xl: 4 }}>
      <Box className="detailsCard BasicInfoBox-client">
        <Box className="detailsCard__header">
          <Typography className="detailsCard__title">
            Parent Information
          </Typography>
          <IconButton
            onClick={() => handleEditBasicClick()}
            className="detailsCard__headBtn"
            size="small"
            edge="start"
          >
            <EditIcon />
          </IconButton>
        </Box>
        <Box className="detailsCard__body client_new_slider">
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={100}
            totalSlides={2}
            visibleSlides={1}
            step={1}
            currentSlide={parentIndex}
            // infinite={true}
            orientation={"horizontal"}
            className="cardSlider parentSlider"
          >
            <Slider>
              <Slide index={0} key={0}>
                <div
                  className="userBasicInfoBox"
                  style={{ position: "relative" }}
                >
                  <div className="userBasicInfoBox__img">
                    <Box className="uploadProfile">
                      <div className="uploadProfile__img">
                        <img
                          src={
                            clientBasicDetails?.parentProfile[0]?.url ||
                            ClientImage
                          }
                          className="img-fluid"
                          alt="image_not_found"
                        />
                      </div>
                      <Button
                        variant="contained"
                        component="label"
                        className="uploadProfile__btn"
                        startIcon={<UploadIcon />}
                      >
                        Update Parent Picture
                        <input
                          type="file"
                          hidden
                          accept="image/*"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleFileChange(e)
                          }
                        />
                      </Button>
                    </Box>
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                        position: "absolute",

                        left: "10px",
                      }}
                    >
                      {imageError}
                    </FormHelperText>
                  </div>
                  <div className="userBasicInfoBox__details">
                    {/* <Typography variant="h6" sx={{ margin: "10px 0px" }}>
                      1st Parent Information
                    </Typography> */}
                    <Grid container spacing={0}>
                      <Grid size={{ xs: 12, sm: 12 }}>
                        <div className="detailsCard-part">
                          <Typography className="detailsCard-part__title">
                            Parent/Guardian 1
                          </Typography>
                          <Typography className="detailsCard-part__text">
                            {`${clientBasicDetails?.parentFirstName || ""} ${
                              clientBasicDetails?.parentLastName || ""
                            }`}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Slide>
              <Slide index={1} key={1}>
                <div
                  className="userBasicInfoBox"
                  style={{ position: "relative" }}
                >
                  <div className="userBasicInfoBox__img">
                    <Box className="uploadProfile">
                      <div className="uploadProfile__img">
                        <img
                          src={
                            clientBasicDetails?.parentProfile[1]?.url ||
                            ClientImage
                          }
                          className="img-fluid"
                          alt="image_not_found"
                        />
                      </div>
                      <Button
                        variant="contained"
                        component="label"
                        className="uploadProfile__btn"
                        startIcon={<UploadIcon />}
                      >
                        Update Parent Picture
                        <input
                          type="file"
                          hidden
                          accept="image/*"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleFileChange(e)
                          }
                        />
                      </Button>
                    </Box>
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                        position: "absolute",

                        left: "10px",
                      }}
                    >
                      {imageError}
                    </FormHelperText>
                  </div>
                  <div className="userBasicInfoBox__details">
                    {/* <Typography variant="h6" sx={{ margin: "10px 0px" }}>
                      2nd Parent Information
                    </Typography> */}
                    <Grid container spacing={0}>
                      <Grid size={{ xs: 12, sm: 12 }}>
                        <div className="detailsCard-part">
                          <Typography className="detailsCard-part__title">
                            Parent/Guardian 2
                          </Typography>
                          <Typography className="detailsCard-part__text">
                            {`${clientBasicDetails?.parent2FirstName || ""} ${
                              clientBasicDetails?.parent2LastName || ""
                            }`}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Slide>
            </Slider>
          </CarouselProvider>
          <div className="parentSlide">
            <span
              className={parentIndex === 0 ? "active" : ""}
              onClick={() => setParentIndex(0)}
            />
            <span
              className={parentIndex === 1 ? "active" : ""}
              onClick={() => setParentIndex(1)}
            />
          </div>
        </Box>
      </Box>
      {isEditParentInfo && (
        <Dialog open={isEditParentInfo} className="largeModel largeModel--edit">
          <Box className="largeModel__head">
            <Typography variant="h5" className="largeModel__title">
              Edit Client - Parent Information
            </Typography>
            <Button
              className="largeModel__closeBtn"
              onClick={() => setEditParentInfo(false)}
            >
              <CloseIcon />
            </Button>
          </Box>
          <ClientParentDetailsForm setEditParentInfo={setEditParentInfo} />
        </Dialog>
      )}
    </Grid>
  );
};

export default ClientParentDetail;
