import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  setRole,
  setUserValue,
} from "../../redux/features/user/add-user-slice";
import { useAppSelector, useAppDispatch } from "../../redux/store";
import { OrgBU } from "../../types/company";
import { IUserProfile } from "../../types/user";
import { Role } from "../../types/descope";
import { GetAllClientData } from "../../redux/features/client-main/client-list-slice";
import { ClientForms } from "../../types/client";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface AddUserProps {
  dialogClose: (
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    authRole: string,
    businessUnit: string,
    clientIds?: string[]
  ) => void;
  isEdit: boolean;
}
const AddUser: React.FC<AddUserProps> = ({ dialogClose, isEdit }) => {
  // #region variables region

  // // Descope Roles - API data
  const roles = useAppSelector<Role[]>(
    (state) => state.descopeSlice.tenantRoles
  );
  const clientList = useAppSelector<ClientForms[]>(
    (state) => state.clientListSlice.listData
  );

  //user data from redux
  const userData = useAppSelector<IUserProfile>(
    (state) => state.companyUserSlice
  );
  //business units
  const businessUnits = useAppSelector<OrgBU[]>(
    (state) => state.businessUnitsSlice.businessUnits
  );

  //error handler
  const [hasError, setHasError] = useState<boolean>(false);

  //dispatch for redux
  const dispatch = useAppDispatch();

  // #endregion

  // #region methods region

  /* STRIPE INTEGRATION */
  //temporarily comment out
  // const customerSubscriptions = useSelector<RootState, ISubscriptionData>(
  //   (state) => state.customerSlice.subscriptionDetails
  // );

  //removes focus when enter is pressed in an input box
  // const removeFocus = (id: string): void => {
  //   console.log("removeFocus")
  //   document.getElementById(id)?.blur();
  // };

  //used to handle product list
  // const products = useSelector<RootState, IStripePlansData>(
  //   (state) => state.productSlice.products
  // );

  //store plan name
  // eslint-disable-next-line
  const [planName, setPlanName] = useState<string>("");

  //handles the submission of the form
  const handleSubmit = (): void => {
    if (!userData.authRole) {
      setHasError(true);
      return;
    }
    if (userData.authRole === "Parent" && userData.clientIds !== undefined) {
      dialogClose(
        userData.id,
        userData.firstName,
        userData.lastName,
        userData.email,
        userData.authRole,
        userData.businessUnit,
        userData?.clientIds
      );
    } else {
      dialogClose(
        userData.id,
        userData.firstName,
        userData.lastName,
        userData.email,
        userData.authRole,
        userData.businessUnit
      );
    }
  };

  const renderClientName = (ids: string[]) => {
    let clients: string[] = [];
    clientList.forEach((item) => {
      if (ids.includes(item.clientBasicDetails.id)) {
        clients.push(
          `${item.clientBasicDetails.childFirstName} 
            ${item.clientBasicDetails.childLastName}`
        );
      }
    });
    return clients.join(", ");
  };
  // useEffect(() => {
  //   const fetchData = async () => {

  //     dispatch(getAllDescopeRoles());
  //   }
  //   fetchData();
  // }, []);
  // #endregion

  //#region useEffect region
  /* STRIPE INTEGRATION */
  //temporarily comment out
  // useEffect(() => {
  //   if (
  //     customerSubscriptions &&
  //     products !== undefined &&
  //     products.data[0].id !== ""
  //   ) {
  //     let name: string | undefined = products.data.find(
  //       (i) =>
  //         i.id === customerSubscriptions?.data[0].items?.data[0]?.plan?.product
  //     )?.name;
  //     if (name !== undefined) {
  //       setPlanName(name);
  //     }
  //   }
  // }, [customerSubscriptions, products]);
  useEffect(() => {
    if (clientList.length === 0) {
      dispatch(GetAllClientData());
    }
  }, []);

  //#endregion
  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <div className="stepperForm__inner p-30px">
        <h1 className="stepperForm__title p-0">User Information</h1>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">First Name</InputLabel>
              <TextValidator
                value={userData.firstName}
                id="firstName"
                onChange={(e: any) =>
                  dispatch(
                    setUserValue({
                      name: "firstName",
                      value: e.target.value,
                    })
                  )
                }
                name="firstName"
                validators={["required", "matchRegexp:^[a-zA-Z- ]*$"]}
                errorMessages={[
                  "Please Enter First Name",
                  "Please enter valid first name",
                ]}
              />
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Last Name</InputLabel>
              <TextValidator
                id="lastName"
                type="text"
                name="lastName"
                value={userData?.lastName}
                onChange={(e: any) =>
                  dispatch(
                    setUserValue({
                      name: "lastName",
                      value: e.target.value,
                    })
                  )
                }
                validators={["required", "matchRegexp:^[a-zA-Z- ]*$"]}
                errorMessages={[
                  "Please Enter Last Name",
                  "Please enter valid last name",
                ]}
              />
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Email</InputLabel>
              <TextValidator
                disabled={isEdit}
                className="form-control"
                id="email"
                // onKeyDown={(e: React.KeyboardEvent<{}>) => {
                //   if (e.key === "Enter") {
                //     e.preventDefault();
                //     removeFocus("email");
                //   }
                // }}
                onChange={(e: any) =>
                  dispatch(
                    setUserValue({
                      name: "email",
                      value: e.target.value,
                    })
                  )
                }
                name="email"
                value={userData.email}
                validators={["required", "isEmail"]}
                errorMessages={["Please enter email", "email is not valid"]}
              />
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Role</InputLabel>
              <Select
                //disabled={isEdit}
                className="form-control"
                value={userData.authRole}
                name="role"
                id="role"
                // onKeyDown={(e) => {
                //   if (e.key === "Enter") {
                //     e.preventDefault();
                //     removeFocus("role");
                //   }
                // }}
                onChange={(e: SelectChangeEvent<string>) => {
                  dispatch(
                    setUserValue({ name: "role", value: e.target.value })
                  );
                  if (e.target.value !== "Parent") {
                    dispatch(setUserValue({ name: "clientIds", value: [] }));
                  }
                }}
                MenuProps={MenuProps}
              >
                {roles
                  ?.filter(
                    (item: any) =>
                      item.name !== "Tenant Admin" &&
                      item.name !== "ABAEngine Admin"
                  )
                  .map((item: any, index: number) => {
                    return (
                      <MenuItem
                        key={`${item.name}-${index}`}
                        value={item.name}
                        onClick={() => {
                          dispatch(setRole(item.name));
                        }}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Box>
            {hasError && !userData.authRole && (
              <FormHelperText style={{ color: "#d32f2f" }}>
                Please select a role
              </FormHelperText>
            )}
          </Grid>
          <Grid size={{ xs: 6 }}>
            <Box className="fromGroup">
              <div className="detailsCard-part">
                <InputLabel className="fromLabel">Business Unit</InputLabel>
                <Select
                  multiple
                  className="form-control"
                  value={userData.businessUnit ? [userData.businessUnit] : []}
                  name="businessUnit"
                  id="businessUnit"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (userData.businessUnit === value) {
                      dispatch(
                        setUserValue({ name: "businessUnit", value: "" })
                      ); // Deselect if already selected
                    } else {
                      dispatch(
                        setUserValue({
                          name: "businessUnit",
                          value: value[value.length - 1],
                        })
                      );
                    }
                  }}
                  renderValue={(selected) => selected}
                >
                  {businessUnits.map((item: OrgBU) => (
                    <MenuItem key={item.id} value={item.businessUnitName}>
                      <Checkbox
                        checked={
                          item.businessUnitName === userData.businessUnit
                        }
                      />
                      <ListItemText primary={item.businessUnitName} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Box>
          </Grid>
          <Grid size={{ xs: 6 }}>
            <Box className="fromGroup">
              <div className="detailsCard-part">
                <InputLabel className="fromLabel">Client List</InputLabel>
                <Select
                  disabled={userData.authRole !== "Parent"}
                  multiple
                  className="form-control"
                  value={userData.clientIds ? userData.clientIds : []}
                  name="clientList"
                  id="clientList"
                  onChange={(e) => {
                    const value = e.target.value as string[];
                    dispatch(
                      setUserValue({
                        name: "clientIds",
                        value,
                      })
                    );
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, // Set the maximum height of the dropdown
                      },
                    },
                  }}
                  renderValue={(selected) => renderClientName(selected)}
                >
                  {clientList.map((item: ClientForms) => (
                    <MenuItem
                      key={item.clientBasicDetails.id}
                      value={item.clientBasicDetails.id}
                    >
                      <Checkbox
                        checked={userData.clientIds?.includes(
                          item.clientBasicDetails.id
                        )}
                      />
                      <ListItemText
                        primary={`${item.clientBasicDetails.childFirstName} 
                          ${item.clientBasicDetails.childLastName}`}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Box>
          </Grid>
        </Grid>

        {/* <Grid item xs={12}>
          <Box className="fromGroup">
            <InputLabel className="fromLabel">Business Unit</InputLabel>
            <Select
              labelId="business-unit"
              id="businessUnitOption"
              value={userData.businessUnit}
              label=""
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  removeFocus("businessUnitOption");
                }
              }}
              onChange={(e) =>
                dispatch(
                  setUserValue({
                    name: "businessUnit",
                    value: e.target.value,
                  })
                )
              }
              MenuProps={MenuProps}
            >
              {organizations.map((item: any) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          {hasError && !userData.businessUnit && (
            <FormHelperText style={{ color: "#d32f2f" }}>
              Please select a business unit
            </FormHelperText>
          )}
        </Grid> */}
      </div>
      <Box className="stepperForm__footer b-0">
        <Box sx={{ flex: "1 1 auto" }} />

        <Button
          type="submit"
          className="button"
          onClick={() => setHasError(true)}
        >
          {isEdit ? "Save Changes" : "Add User"}
        </Button>
      </Box>
    </ValidatorForm>
  );
};

export default AddUser;
