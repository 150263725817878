// surveyComponent code

export const surveyComponent = {
  markup: ` <!DOCTYPE html>
  <html>
    <body>
    <embed id="embedFrame" src="" />
    <script>
      let embedFrame = document.getElementById("embedFrame");
      embedFrame.setAttribute("width", window.innerWidth);
      embedFrame.setAttribute("height", window.innerHeight);
    </script>
    </body>
  </html>`,
};

//default question list for client Intake form
export const ClientIntakeFormDefaultQuestion = [
  "childName",
  "childDateOfBirth",
  "phoneNumber",
  "email",
  "parentName",
  "childGender",
  "address",
  "city",
  "state",
  "zipCode",
  "childLanguages",
  "contactMethod",
  "bestTimeToContact",
  "hearAboutUs",
  "concerns",
  "question15",
  "question16",
  "question17",
  "question18",
  "question19",
  "question20",
  "insuranceCards",
];

//default question list for New Employee form
export const NewEmployeeFormDefaultQuestion = [
  "firstName",
  "lastName",
  "phoneNumber",
  "email", 
  "employmentStatus",
  "languages",
  "type",
  "specialities",
  "fullAddress",
  "city",
  "state",
  "zipCode",
  "contactMethod",
  "bestTimeToContact",
];
