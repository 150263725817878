import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { AllUserMessage, IMessage } from "../../../types/message";
import { sortData } from "../../../constants/message";
import { dateToFromNowDaily } from "../../../helpers/reverse-list/reverse-list";
import ClientImg from "../../../assets/images/images-svg/client-large.svg";
import { useUser } from "@descope/react-sdk";
import system from "../../../assets/images/images-png/setting.png";

interface SmsMessageProps {
  smsComment: AllUserMessage[];
}

const SmsMessageComponent: React.FC<{
  message: AllUserMessage;
  userId: string;
}> = ({ message, userId }) => {
  return (
    <Box
      className={
        !message.seenUserId.includes(userId)
          ? "commentListItem generated light-blue-bg"
          : "commentListItem generated"
      }
      key={message.id}
    >
      <Box className="compose-block">
        <span className="commentListItem__user-img">
          <img
            src={
              message.fromUser === null
                ? system
                : message?.fromUser?.profilePictureUrl || ClientImg
            }
            className="userSmallCircle"
            alt=""
          />
        </span>
        <div className="commentListItem__user-info">
          <div className="compose-user-heading">
            <h5 className="compose-user-name">
              {message.fromUser === null
                ? "System Generated "
                : message.fromUser?.firstName?.length > 0
                ? message.fromUser?.firstName + " " + message.fromUser?.lastName
                : message.fromUser?.email}
            </h5>
            <h6 className="compose-user-time">
              <span>{dateToFromNowDaily(message?.createdOn)}</span>
            </h6>
          </div>

          <div
            className="compose-user-comment"
            style={{ whiteSpace: "pre-line" }}
          >
            {message?.message}
          </div>
        </div>
      </Box>
    </Box>
  );
};

const SmsMessage: React.FC<SmsMessageProps> = ({ smsComment }) => {
  const {
    user: { userId },
  } = useUser();

  return (
    <Grid className="scroll-bar">
      {smsComment.length > 0 ? (
        sortData(smsComment).map((item) => {
          return (
            <SmsMessageComponent userId={userId} key={item.id} message={item} />
          );
        })
      ) : (
        <Box>No Notice Found</Box>
      )}
    </Grid>
  );
};

export default SmsMessage;
