import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { RootState, AppDispatch } from "../../../redux/store";
import {
  setLanguages,
  setEmploymentDetailsFromForm,
} from "../../../redux/features/providers-main/provider-slice";
import { setActiveSteps } from "../../../redux/features/active-steps/active-slice";
import {
  GetProviderTypes,
  GetProviderSpecialty,
} from "../../../redux/features/providers-main/provider-list-slice";
import { OrgBU } from "../../../types/company";
import {
  EmploymentDetails,
  ProviderList,
  ProviderType,
} from "../../../types/provider";
import {
  EmploymentStatus,
  Languages,
  MenuProps,
} from "../../../constants/providers";
import { getBussinessName } from "../../../constants/common";

//Component Props type
interface ProviderEmploymentProps {
  isNew: boolean;
  updateHandler?: () => void;
}

const EmploymentDetailsForm: React.FC<ProviderEmploymentProps> = ({
  isNew,
  updateHandler,
}) => {
  // #region variables region

  //App dispatch for redux
  const dispatch: AppDispatch = useDispatch<AppDispatch>();

  //business units
  const businessUnits = useSelector<RootState, OrgBU[]>(
    (state) => state.businessUnitsSlice.businessUnits
  );

  //Gets the values from redux  for employmentDetails
  const employmentDetails = useSelector<RootState, EmploymentDetails>(
    (state) => state.providerSlice.employmentDetails
  );

  //Validation errors for business unit, languages and provider types
  const [hasError, setHasError] = useState<boolean>(false);

  //Gets value of the active step from redux to manage the stepper
  const { activeStep }: { activeStep: number } = useSelector(
    (state: RootState) => state.activeStep
  );

  //store handle is supervisor
  const [isSupervisor, setIsSupervisor] = useState<boolean>(
    employmentDetails.isSupervisor
  );

  //Provider dropdown list
  const ProviderTypeList = useSelector<RootState, ProviderType[]>(
    (state) => state.providerListSlice.ProviderType
  );

  //provider Specialty dropDown list
  const ProviderSpecialtyList = useSelector<RootState, ProviderType[]>(
    (state) => state.providerListSlice.ProviderSpecialty
  );

  //store podnames
  // eslint-disable-next-line
  const [nameslist, setNamesList] = useState<ProviderList[]>([]);

  // #endregion

  // #region methods region

  //handles the form submit
  const handleSubmit = (e: any): void => {
    // if (employmentDetails.businessUnit === "") {
    //   setHasError(true);
    // } else
    if (employmentDetails.type === "") {
      setHasError(true);
    } else if (employmentDetails.employmentStatus === "") {
      setHasError(true);
    } else if (employmentDetails.languages.length === 0) {
      setHasError(true);
    } else {
      if (!isNew && updateHandler) {
        updateHandler();
        return;
      }
      setHasError(false);
      dispatch(setActiveSteps(activeStep + 1));
    }
  };

  //removes focus when enter is pressed in an input box
  const removeFocus = (id: string) => {
    document.getElementById(id)?.blur();
  };

  //#endregion

  //#region useEffect region

  useEffect(() => {
    dispatch(GetProviderTypes());
    dispatch(GetProviderSpecialty());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#endregion

  return (
    <div>
      <ValidatorForm onSubmit={(e: any) => handleSubmit(e)}>
        <div className="stepperForm__inner p-10px">
          <Grid container spacing={3}>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">First Name</InputLabel>
                <TextValidator
                  id="firstName"
                  type="text"
                  name="firstName"
                  value={employmentDetails.firstName}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      removeFocus("firstName");
                    }
                  }}
                  onChange={(e: any) => {
                    if (e.target.value.trim().length !== 0) {
                      dispatch(
                        setEmploymentDetailsFromForm({
                          name: "firstName",
                          value: e.target.value,
                        })
                      );
                    } else {
                      dispatch(
                        setEmploymentDetailsFromForm({
                          name: "firstName",
                          value: "",
                        })
                      );
                    }
                  }}
                  validators={["required", "matchRegexp:^[a-zA-Z- ]*$"]}
                  errorMessages={[
                    "Please Enter First Name",
                    "Please enter valid first name",
                  ]}
                />
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">Last Name</InputLabel>
                <TextValidator
                  id="lastName"
                  type="text"
                  name="lastName"
                  value={employmentDetails?.lastName}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      removeFocus("lastName");
                    }
                  }}
                  onChange={(e: any) => {
                    if (e.target.value.trim().length !== 0) {
                      dispatch(
                        setEmploymentDetailsFromForm({
                          name: "lastName",
                          value: e.target.value,
                        })
                      );
                    } else {
                      dispatch(
                        setEmploymentDetailsFromForm({
                          name: "lastName",
                          value: "",
                        })
                      );
                    }
                  }}
                  validators={["required", "matchRegexp:^[a-zA-Z- ]*$"]}
                  errorMessages={[
                    "Please Enter Last Name",
                    "Please enter valid last name",
                  ]}
                />
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">Business Unit</InputLabel>
                <Select
                  labelId="business-unit"
                  id="businessUnitOption"
                  multiple
                  value={
                    employmentDetails.businessUnit?.length > 0
                      ? employmentDetails.businessUnit
                      : []
                  }
                  label=""
                  onChange={(e) =>
                    dispatch(
                      setEmploymentDetailsFromForm({
                        name: "businessUnit",
                        value: e.target.value,
                      })
                    )
                  }
                  renderValue={(selected: any) => {
                    selected = selected.map((select: any) => {
                      return getBussinessName(select, businessUnits);
                    });
                    return selected.join(",");
                  }}
                  MenuProps={MenuProps}
                >
                  {businessUnits.map((item: OrgBU) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        <Checkbox
                          checked={
                            employmentDetails.businessUnit.indexOf(item.id) > -1
                          }
                        />
                        <ListItemText primary={item.businessUnitName} />
                      </MenuItem>
                    );
                  })}
                </Select>
                {/* {hasError && !employmentDetails.businessUnit ? (
                  <FormHelperText style={{ color: "#d32f2f" }}>
                    Please select business unit
                  </FormHelperText>
                ) : (
                  ""
                )} */}
              </Box>
            </Grid>

            <Grid size={{ xs: 12, md: 6 }}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">Employment Status</InputLabel>
                <div className="fromGroup-chips">
                  {EmploymentStatus.map((item) => (
                    <Card
                      className={
                        employmentDetails.employmentStatus === item.value
                          ? "fromGroup-chip-active"
                          : ""
                      }
                      onClick={() =>
                        dispatch(
                          setEmploymentDetailsFromForm({
                            name: "employmentStatus",
                            value: item.value,
                          })
                        )
                      }
                      key={item.key}
                    >
                      {item.value}
                    </Card>
                  ))}
                </div>
                {hasError && !employmentDetails.employmentStatus ? (
                  <FormHelperText style={{ color: "#d32f2f" }}>
                    Please select an employment status
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Box>
            </Grid>

            <Grid size={{ xs: 12, md: 6 }}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">Provider Type</InputLabel>
                <Select
                  labelId="providerType"
                  id="type"
                  value={employmentDetails.type}
                  label=""
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      removeFocus("type");
                    }
                  }}
                  onChange={(e) => {
                    dispatch(
                      setEmploymentDetailsFromForm({
                        name: "type",
                        value: e.target.value,
                      })
                    );
                  }}
                  MenuProps={MenuProps}
                >
                  {ProviderTypeList.map((item: ProviderType) => {
                    return (
                      <MenuItem key={item.id} value={item.conceptValue}>
                        {item.conceptValue}
                      </MenuItem>
                    );
                  })}
                </Select>
                {hasError && !employmentDetails.type ? (
                  <FormHelperText style={{ color: "#d32f2f" }}>
                    Please select a employee type
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Box>
            </Grid>

            <Grid size={{ xs: 12, md: 6 }}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">Languages</InputLabel>
                <div className="fromGroup-chips">
                  {Languages.map((item) => (
                    <Card
                      className={
                        employmentDetails?.languages?.includes(item.value)
                          ? "fromGroup-chip-active"
                          : ""
                      }
                      onClick={() => dispatch(setLanguages(item.value))}
                      key={item.key}
                    >
                      {item.value}
                    </Card>
                  ))}
                </div>
                {hasError && employmentDetails?.languages?.length === 0 ? (
                  <FormHelperText style={{ color: "#d32f2f" }}>
                    Please select at least one language
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Box>
            </Grid>

            <Grid size={{ xs: 12 }}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">Specialty</InputLabel>

                <FormControl>
                  <Select
                    labelId="specialities"
                    id="specialities"
                    multiple
                    value={employmentDetails.specialities}
                    onChange={(e) => {
                      dispatch(
                        setEmploymentDetailsFromForm({
                          name: "specialities",
                          value: e.target.value,
                        })
                      );
                    }}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {ProviderSpecialtyList.map((unit, index) => (
                      <MenuItem key={index} value={unit.conceptValue}>
                        <Checkbox
                          checked={employmentDetails.specialities.includes(
                            unit.conceptValue
                          )}
                        />
                        <ListItemText primary={unit.conceptValue} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid size={{ xs: 12, md: 6 }}>
              <Box>
                <InputLabel className="fromLabel">Supervisor?</InputLabel>
                <Switch
                  checked={isSupervisor}
                  name="CompleteCustomIntakeForm"
                  onChange={(e) => {
                    setIsSupervisor(!isSupervisor);
                    dispatch(
                      setEmploymentDetailsFromForm({
                        name: "isSupervisor",
                        value: e.target.checked,
                      })
                    );
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Box>
            </Grid>
          </Grid>
        </div>
        {isNew ? (
          <Box className="stepperForm__footer b-0">
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              type="submit"
              className="button"
              onClick={() => setHasError(true)}
            >
              Next
            </Button>
          </Box>
        ) : (
          <div className="largeModel__footer">
            <Button className="button" type="submit">
              Save Changes
            </Button>
          </div>
        )}
      </ValidatorForm>
    </div>
  );
};

export default EmploymentDetailsForm;
