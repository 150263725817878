import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import ClientService from "../../../service/client.service";
import CommonService from "../../../service/common.service";
import { AvailabilityDetails } from "../../../types/availability";
import {
  ClientForms,
  ClientAuthPayload,
  InsuranceProviders,
  ClientAuthCode,
  ClientAuthorization,
  // ClientIntakeStages,
} from "../../../types/client";

import { getClientFilesById, setListDataChange } from "./client-list-slice";
import { setResponseValue } from "../api-response/api-response";
import { getClientBoards } from "./client-board-slice";
import {
  ClientInitialState,
  defeaultAuthorization,
} from "../../../constants/client";
// import { FileModelType } from "../../../types/files";
import { formattedDate } from "../../../constants/common";
import BoardService from "../../../service/customBoard.service";
import AvailabilityService from "../../../service/availability.service";

export const initialState: ClientForms = {
  ...ClientInitialState,
};

const clientSlice = createSlice({
  name: "client",
  initialState: initialState,
  reducers: {
    // setClientFilterData:(state, action) => {

    // },

    setEditClient: (state, action) => {
      state.isEditClient = action.payload;
    },
    setInitialState: (state, action) => {
      return {
        ...ClientInitialState,
      };
    },

    /********Client Basic **************************/
    setClientBasicDetails: (state, action) => {
      if (action.payload.isEdit) {
        return {
          ...state,
          clientBasicDetails: {
            ...state.clientBasicDetails,
            ...action.payload,
          },
        };
      } else {
        return {
          ...state,
          clientBasicDetails: {
            ...state.clientBasicDetails,
            [action.payload.name]: action.payload.value,
          },
        };
      }
    },
    setClientContactDetails: (state, action) => {
      if (action.payload.isEdit) {
        return {
          ...state,
          clientContactDetails: {
            ...state.clientContactDetails,
            ...action.payload,
          },
        };
      } else {
        return {
          ...state,
          clientContactDetails: {
            ...state.clientContactDetails,
            [action.payload.name]: action.payload.value,
          },
        };
      }
    },
    setAddressForClient: (state, action) => {
      return {
        ...state,
        clientContactDetails: {
          ...state.clientContactDetails,
          address: {
            //...state.clientContactDetails.address,
            //fullAddress: action.payload,
            ...action.payload,
          },
        },
      };
    },
    setSchoolAddressForClient: (state, action) => {
      return {
        ...state,
        clientContactDetails: {
          ...state.clientContactDetails,
          schoolAddress: {
            ...action.payload,
          },
        },
      };
    },
    resetSchoolAddress: (state, action) => {
      state.clientContactDetails.schoolAddress.fullAddress = "";
      state.clientContactDetails.schoolAddress.lat = 0;
      state.clientContactDetails.schoolAddress.lng = 0;
      state.clientContactDetails.schoolCity = "";
      state.clientContactDetails.schoolState = "";
      state.clientContactDetails.schoolZipCode = "";
      return state;
    },

    addOtherAddresses: (state, action) => {
      return {
        ...state,
        clientContactDetails: {
          ...state.clientContactDetails,
          otherAddresses: [
            ...state.clientContactDetails.otherAddresses,
            {
              address: {
                ...action.payload.address,
              },
              state: action.payload.state,
              city: action.payload.city,
              zipCode: action.payload.zipCode,
            },
          ],
        },
      };
    },
    setClientContactDetailsWithOtherAddresses: (state, action) => {
      return {
        ...state,
        clientContactDetails: {
          ...action.payload,
          otherAddresses: [
            ...action.payload.otherAddresses,
            ...state.clientContactDetails.otherAddresses,
          ],
        },
      };
    },

    /********Client Availability **************************/
    removeAvailability: (state, action) => {
      let newAvailability: AvailabilityDetails[] = [];
      if (action.payload?.id !== undefined) {
        newAvailability = state.availabilityDetails.filter(
          (item) => item.id !== action.payload.id
        );
      } else {
        newAvailability = state.availabilityDetails.filter(
          (_, index: number) => index !== action.payload.index
        );
      }
      state.availabilityDetails = newAvailability;
    },
    addNewAvailability: (state, action) => {
      return {
        ...state,
        availabilityDetails: [
          ...state.availabilityDetails,
          {
            id: "",
            entityType: "Client",
            entityId: null, //MUST BE null, cannot be "",
            beginDate: action.payload.beginDate,
            endDate: action.payload.endDate,
            createdAt: "",
            updatedAt: "",
            selectedDays: [
              {
                dayOfWeek: "",
                startTime: "",
                endTime: "",
              },
            ],
            heatMap: [],
            timeSlots: [],
            availabilitySelectedDays: [],
            availabilityName: "",
          },
        ],
      };
    },
    setAvailabilityDetails: (state, action) => {
      // state.availabilityDetails = action.payload.data

      let data: AvailabilityDetails[] = [];
      if (action.payload.data.length > 0) {
        data = [...action.payload.data].sort(
          (a, b) =>
            (new Date(formattedDate(a.beginDate)) as any) -
            (new Date(formattedDate(b.beginDate)) as any)
        );
      }
      return {
        ...state,
        availabilityDetails: data,
      };
    },

    setBeginDate: (
      state,
      action: PayloadAction<{ availabilityIndex: number; beginDate: string }>
    ) => {
      if (action.payload !== undefined) {
        state.availabilityDetails[action.payload.availabilityIndex].beginDate =
          action.payload.beginDate;
        return state;
      }
    },

    setEndDate: (
      state,
      action: PayloadAction<{ availabilityIndex: number; endDate: string }>
    ) => {
      if (action.payload !== undefined) {
        state.availabilityDetails[action.payload.availabilityIndex].endDate =
          action.payload.endDate;
        return state;
      }
    },

    setClientSelectedDays: (state, action) => {
      state.availabilityDetails[action.payload.availabilityIndex].selectedDays =
        action.payload.newAvailability;
      return state;
    },
    // setIsAvailable: (state, action) => {
    //   state.availabilityDetails.selectedDays[action.payload].isAvailable =
    //     !state.availabilityDetails.selectedDays[action.payload].isAvailable;
    //   return state;
    // },
    setFromTime: (state, action) => {
      state.availabilityDetails[action.payload.availabilityIndex].selectedDays[
        action.payload.index
      ].startTime = action.payload.value;
      return state;
    },
    setToTime: (state, action) => {
      state.availabilityDetails[action.payload.availabilityIndex].selectedDays[
        action.payload.index
      ].endTime = action.payload.value;
      return state;
    },

    // deleteAvailability: (state, action) => {
    //   const newAuthorization = state.authorizations.filter(
    //     (_, index) => index !== action.payload.index
    //   );
    //   state.availabilityDetails =
    //   return {
    //     ...state,
    //     authorizations: newAuthorization,
    //   };
    // },

    setEntityId: (state, action) => {
      // const newarr: any[] = [];
      //  action.payload.ClientData.availabilityDetails.forEach((item:AvailabilityDetails)=>{
      //   // if(!item.entityId){
      //   //   i
      //   // }
      //   item.entityId = action.payload.ClientData.clientBasicDetails.id
      // })

      state.availabilityDetails[0].entityId = action.payload.id;
      return state;
    },

    /********Client Insurance **************************/
    addNewInsuranceProvider: (state, action) => {
      return {
        ...state,
        clientInsurance: [
          ...state.clientInsurance,
          {
            isPrimary: false,
            insuranceProviderName: "",
            insuranceId: "",
            clientId: "",
            id: "",
          },
        ],
      };
    },
    setClientInsurance: (state, action) => {
      state.clientInsurance[action.payload.index].insuranceId =
        action.payload.insuranceId;
      state.clientInsurance[action.payload.index].insuranceProviderName =
        action.payload.insuranceProviderName;
      return state;
    },
    deleteClientInsurance: (state, action) => {
      const foundInsurance = state.clientInsurance.filter(
        (_, index) => index !== action.payload.index
      );
      return {
        ...state,
        clientInsurance: foundInsurance,
      };
    },
    setClientInsurancePrimary: (state, action) => {
      state.clientInsurance[action.payload.index].isPrimary =
        !state.clientInsurance[action.payload.index].isPrimary;
      return state;
    },
    setClientInsuranceDetails: (state, action) => {
      if (action.payload.isEdit) {
        return {
          ...state,
          clientInsurance: [...action.payload.clientInsurance],
        };
      } else {
        return {
          ...state,
          clientInsurance: [...action.payload.clientInsurance],
        };
      }
    },

    /********Client Authorization **************************/
    removeAuthorization: (state, action) => {
      const num =
        state.authorizations.length > 0 ? state.authorizations.length - 1 : 0;
      let newAuthorizations: ClientAuthPayload[] = [];
      if (num > -1) {
        newAuthorizations = state.authorizations.filter(
          (_, index: number) => num !== index
        );
      }
      state.authorizations = newAuthorizations;
    },
    addAuthorization: (state, action) => {
      return {
        ...state,
        authorizations: [...state.authorizations, defeaultAuthorization],
      };
    },
    setAuthorizationName: (state, action) => {
      state.authorizations[action.payload.index].authorizationName =
        action.payload.value;
      return state;
    },
    setAuthorizationNumber: (state, action) => {
      state.authorizations[action.payload.index].authorizationNumber =
        action.payload.value;
      return state;
    },
    setAuthorizationInsuranceProvider: (state, action) => {
      state.authorizations[action.payload.index].insuranceId =
        action.payload.value;
      return state;
    },
    setAuthorizationAuthCodes: (state, action) => {
      state.authorizations[action.payload.index].auth[
        action.payload.authCodeIndex
      ].authCode = action.payload.value;
      return state;
    },
    setAuthorizationAuthCodeUnits: (state, action) => {
      state.authorizations[action.payload.index].auth[
        action.payload.authCodeIndex
      ].units = action.payload.value;
      return state;
    },
    setAuthorizationDateRange: (state, action) => {
      state.authorizations[action.payload.index].authPeriod.startDate =
        action.payload.startDate;
      state.authorizations[action.payload.index].authPeriod.endDate =
        action.payload.endDate;
      return state;
    },
    setAuthorizationFiles: (state, action) => {
      state.authorizations[action.payload.index].files = action.payload.files;
      return state;
    },
    deleteAuthorization: (state, action) => {
      const newAuthorization = state.authorizations.filter(
        (_, index) => index !== action.payload.index
      );
      return {
        ...state,
        authorizations: newAuthorization,
      };
    },
    addAuthCodeInAuthorization: (
      state,
      action: PayloadAction<{ index: number }>
    ) => {
      state.authorizations[action.payload.index].auth.push({
        authCode: "",
        units: "",
        authApprovalUnit: "",
      });
      return state;
    },

    deleteAuthCodeAuthorization: (
      state,
      action: PayloadAction<{ index: number; authindex: number }>
    ) => {
      state.authorizations[action.payload.index].auth.splice(
        action.payload.authindex,
        1
      );
      return state;
    },

    setAuthorizationDetails: (state, action) => {
      return {
        ...state,
        authorizations: [...action.payload.authorization],
      };
    },
    deleteAuthorizationFiles: (state, action) => {
      state.authorizations[action.payload.index].files = state.authorizations[
        action.payload.index
      ].files.filter((_, index: number) => index !== action.payload.fileIndex);
      return state;
    },
    setInitialStateForAuthorizationEdit: (state, action) => {
      return {
        ...ClientInitialState,
        isEditClient: action.payload.isEditClient,
      };
    },
    setDay: (
      state,
      action: PayloadAction<{
        availabilityIndex: number;
        index: number;
        value: string;
      }>
    ) => {
      state.availabilityDetails[action.payload.availabilityIndex].selectedDays[
        action.payload.index
      ].dayOfWeek = action.payload.value;
      return state;
    },
    addAvailability: (state, action: PayloadAction<null>) => {
      return {
        ...state,
        availabilityDetails: {
          ...state.availabilityDetails,
          selectedDays: [
            ...state.availabilityDetails[0].selectedDays,
            {
              dayOfWeek: "",
              startTime: "",
              endTime: "",
            },
          ],
        },
      };
    },
    deleteAvailability: (
      state,
      action: PayloadAction<{
        index: number;
        availabilityIndex: number;
      }>
    ) => {
      const newAvailability = state.availabilityDetails[
        action.payload.availabilityIndex
      ].selectedDays.filter(
        (_, index: number) => action.payload.index !== index
      );
      state.availabilityDetails[action.payload.availabilityIndex].selectedDays =
        newAvailability;
      // return {
      //   ...state,
      //   availabilityDetails: {
      //     ...state.availabilityDetails,
      //     selectedDays: newAvailability,
      //   },
      // };
      return state;
    },
    setClientData: (state, action) => {
      return {
        isEditClient: state.isEditClient,
        ...action.payload,
        clientFiles: state.clientFiles,
        clientMessages: state.clientMessages,
      };
    },
    setClientId: (state, action) => {
      state.clientBasicDetails.id = action.payload.id;
      return state;
    },
    setClientFiles: (state, action) => {
      state.clientFiles = [...state.clientFiles, ...action.payload];
      return state;
    },
    deleteClientFile: (state, action) => {
      state.clientFiles = state.clientFiles.filter(
        (item, index) => item.id !== action.payload
      );
      return state;
    },
    setClientFilesFromResponse: (state, action) => {
      state.clientFiles = [...action.payload];
      return state;
    },
    setClientFileCategory: (state, action) => {
      state.clientFiles = state.clientFiles.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            category: action.payload.value,
          };
        } else {
          return item;
        }
      });
      return state;
    },
    setClientMessages: (state, action) => {
      state.clientMessages = [...action.payload];
      return state;
    },
    resetClientFiles: (state) => {
      state.clientFiles = [];
      return state;
    },
  },
});

// Add client
export const addClientReducer = createAsyncThunk(
  "client/addClient",
  async ({ isBoardPage }: { isBoardPage: boolean }, { getState, dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      let id: string | null = null;
      let { client } = getState() as { client: ClientForms };

      //-----------------------------------------
      //validate inputs
      let validBasic = true;
      let validContact = true;
      let validInsurance = true;
      let validAuth = true;
      let validAvailability = true;
      if (
        !client.clientBasicDetails.childFirstName ||
        !client.clientBasicDetails.childLastName ||
        !client.clientBasicDetails.childDateOfBirth ||
        !client.clientBasicDetails.childGender
      ) {
        validBasic = false;
        throw new Error("Child name, DOB, and gender are required");
      }
      if (client.clientContactDetails && !client.clientContactDetails.email) {
        validContact = false;
        // throw new Error("Email is required"); //optional
      }
      client.clientInsurance.forEach((item: InsuranceProviders) => {
        if (!(item.insuranceId || item.insuranceProviderName)) {
          validInsurance = false;
          // throw new Error("Insurance provider name is required");
        }
      });
      client.authorizations.forEach((item: ClientAuthPayload) => {
        //only check if auth provides a date range
        if (item.authPeriod.startDate || item.authPeriod.endDate) {
          if (
            !item.insuranceId ||
            !item.authorizationName ||
            !(item.authPeriod.startDate && item.authPeriod.endDate)
          )
            validAuth = false;
          // throw new Error("For auth data, Insurance, auth name, date range are required");
        }
        item.auth.forEach((auth: ClientAuthCode) => {
          if (!(auth.authCode && auth.units)) {
            validAuth = false;
            // throw new Error("Auth data, auth code and units are required");
          }
        });
      });
      client.availabilityDetails.forEach((item) => {
        if (
          !item.beginDate ||
          !item.endDate ||
          item.selectedDays.length === 0
        ) {
          validAvailability = false;
          // throw new Error("For availability data, Begin date, end date, and selected days are required");
        }
      });

      //-----------------------------------------
      //create basic detail
      const basicData = await ClientService.createClientWithBasicDetails({
        ...client.clientBasicDetails,
      });
      if (!basicData.status) {
        throw new Error(basicData.message);
      }

      //get client Id
      id = basicData.data.id;

      //-----------------------------------------
      //creating contact (optional)
      if (validContact && client.clientContactDetails.email) {
        const data = await ClientService.createClientWithContactDetails({
          ...client.clientContactDetails,
          entityId: id,
        });
        if (!data.status) {
          throw new Error(data.message);
        }
      }

      //-----------------------------------------
      //create clientInsurance List (optional)
      const insuranceProvidersData: any = [];
      if (
        validInsurance &&
        client.clientInsurance &&
        client.clientInsurance.length > 0
      ) {
        client.clientInsurance.forEach((item: any) => {
          const newObj: any = {};
          newObj.clientId = id;
          if (item.insuranceId.toString().length < 3) {
            newObj.insuranceId = "";
          } else {
            newObj.insuranceId = item.insuranceId;
          }
          newObj.insuranceProviderName = item.insuranceProviderName;
          newObj.isPrimary = item.isPrimary;
          insuranceProvidersData.push(newObj);
        });
        const insuranceData = await ClientService.createClientInsurance(
          insuranceProvidersData
        );
        if (!insuranceData.status) {
          throw new Error(insuranceData.message);
        }
      }

      //-----------------------------------------
      //create clientAuthorization List (optional)
      const newAuthorizationData: any[] = [];
      if (
        validAuth &&
        client.authorizations &&
        client.authorizations.length > 0
      ) {
        client.authorizations.forEach((item) => {
          const newObj: any = {};
          newObj.insuranceId = item.insuranceId;
          newObj.authorizationName = item.authorizationName;
          newObj.authorizationNumber = item.authorizationNumber;
          newObj.authPeriod = { ...item.authPeriod };
          newObj.auth = [...item.auth];
          newObj.files = [...item.files];
          newObj.clientId = id;

          newAuthorizationData.push(newObj);
        });

        const authData = await ClientService.insuranceAuthorization(
          newAuthorizationData
        );
        if (!authData.status) {
          throw new Error(authData.message);
        }
      }

      //-----------------------------------------
      //create availability List (optional)
      var newAvailabilityArray: any[] = [];
      if (
        validAvailability &&
        client.availabilityDetails &&
        client.availabilityDetails.length > 0
      ) {
        client.availabilityDetails.forEach((item) => {
          const newObj: any = {
            entityId: id,
            beginDate: item.beginDate,
            endDate: item.endDate,
            selectedDays: item.selectedDays,
            availabilitySelectedDay: item.availabilitySelectedDays,

            entityType: "Client",
            heatMap: item.heatMap,
            id: item.id,
            timeSlots: item.timeSlots,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
          };
          newAvailabilityArray.push(newObj);
        });

        const availabilityData = await AvailabilityService.createAvailability(
          newAvailabilityArray
        );
        if (!availabilityData.status) {
          throw new Error(availabilityData.message);
        }
      }

      //Note: no need to create clientCard, since it will be handled in the backend.

      //finally, refresh data, show success message, and reset variables
      dispatch(setListDataChange());
      dispatch(getClientBoards({ useLoader: isBoardPage }));
      dispatch(setResponseValue({ name: "success", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: "Client created successfully",
        })
      );
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setInitialState({})); //reset state always
      if (!isBoardPage) {
        dispatch(setResponseValue({ name: "pending", value: false }));
      }
    }
  }
);

export const updateClientBasicDetailById = createAsyncThunk(
  "client/updateClient",
  async (
    //{ boardId, files }: { boardId?: string; files?: Object[] }, commented by Hui
    { stageId }: { stageId?: string },
    { getState, dispatch }
  ) => {
    let { client } = getState() as { client: ClientForms };
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const data = await ClientService.updateClientWithBasicDetails({
        clientId: client.clientBasicDetails.id,
        ...client.clientBasicDetails,
      });
      if (data.status) {
        dispatch(setListDataChange());
        dispatch(setInitialState({}));
        dispatch(getClientBoards({ useLoader: true }));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: data.message }));
      }
      if (stageId) {
        const { status } = await BoardService.postCardToBoardStage(
          "Client", //entityType
          client.clientBasicDetails.id,
          stageId
        );
        if (status) {
          dispatch(getClientBoards({ useLoader: false }));
        }
        /*if (files && files.length > 0) {
          const { status } = await CommonService.postFiles(
            "Intake",
            "Client",
            FileModelType.Client,
            client.clientBasicDetails.id,
            files
          );
          if (status) {
            //just waiting for the api to finish
          }
        }*/
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const updateClientContactDetailById = createAsyncThunk(
  "client/updateContactClient",
  async (_, { getState, dispatch }) => {
    let { client } = getState() as { client: ClientForms };
    try {
      const { status, message } =
        await ClientService.updateClientWithContactDetails({
          ...client.clientContactDetails,
          entityId: client.clientBasicDetails.id,
          createdAt: new Date(), //TODO: revisit this dtm
        });
      if (status) {
        dispatch(setInitialState({}));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        dispatch(setListDataChange());
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const updateClientInsuranceInfoById = createAsyncThunk(
  "client/updateInsurance",
  async (_, { getState, dispatch }) => {
    let { client } = getState() as { client: ClientForms };
    try {
      const { status } = await ClientService.updateClientInsuranceInfoById(
        client.clientBasicDetails.id,
        [...client.clientInsurance]
      );
      if (status) {
        dispatch(setInitialState({}));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: "Successfully updated client insurances",
          })
        );
        dispatch(setListDataChange());
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setInitialState({})); //always cleanup the state
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const updateClientAvailabilityDetailById = createAsyncThunk(
  "client/updateAvailabilityDetails",
  async (_, { getState, dispatch }) => {
    try {
      let { client } = getState() as { client: ClientForms };
      dispatch(setResponseValue({ name: "pending", value: true }));
      var newAvailabilityArray: any[] = [];
      client.availabilityDetails.forEach((item) => {
        if (item.entityId === undefined || item.entityId === null) {
          const newObj: any = {
            entityId: client.clientBasicDetails.id,
            beginDate: item.beginDate,
            endDate: item.endDate,
            selectedDays: item.selectedDays,
            availabilitySelectedDay: item.availabilitySelectedDays,

            entityType: "Client",
            heatMap: item.heatMap,
            id: item.id,
            timeSlots: item.timeSlots,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
          };

          newAvailabilityArray.push(newObj);
        } else {
          newAvailabilityArray.push(item);
        }
      });

      const { status, message } =
        await AvailabilityService.updateAvailabilityDetails(
          newAvailabilityArray
        );
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        dispatch(setInitialState({}));
        dispatch(setListDataChange());
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const updateClientAuthorizations = createAsyncThunk(
  "client/updateClientAuthorizations",
  async (
    { clientAuthId, clientAuthorization }: { clientAuthId: string; clientAuthorization: ClientAuthorization },
    { getState, dispatch }
  ) => {
    let { client } = getState() as { client: ClientForms };
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      let { status } = await ClientService.updateClientAuthorizations(
        clientAuthId, clientAuthorization
      );
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: "Successfully updated client authorizations",
          })
        );
        dispatch(setInitialState({}));
        dispatch(setListDataChange());
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const createClientAuthorizations = createAsyncThunk(
  "client/createClientAuthorizations",
  async (
    {
      clientId,
      clientAuthorization,
    }: { clientId: string; clientAuthorization: ClientAuthorization },
    { getState, dispatch }
  ) => {
    let { client } = getState() as { client: ClientForms };
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      let { status } = await ClientService.createClientAuthorizations(clientId, clientAuthorization);
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: "Successfully created client authorizations",
          })
        );
        dispatch(setInitialState({}));
        dispatch(setListDataChange());
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//duplicate authorization
export const CopyAuthorizationById = createAsyncThunk(
  "/copyAuthorizationById",
  async (
    {
      id,
    }: {
      id: string;
    },
    { dispatch, getState }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message, data } =
        await ClientService.copyAuthorizationById(id);
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        dispatch(setListDataChange());

        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const updateClientIntakeStage = createAsyncThunk(
  "client/updateClientIntakeStage",
  async (client: ClientForms, { getState, dispatch }) => {
    // let { client } = getState() as { client: ClientForms };
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      // const newAuthorizationsArray: any[] = [];
      // client.authorizations.forEach((item: ClientAuthPayload) => {
      //   if (!item.id) {
      //     const newObj: any = {
      //       clientId: clientId,
      //       auth: [...item.auth],
      //       authPeriod: {
      //         ...item.authPeriod,
      //       },
      //       authorizationName: item.authorizationName,
      //       insuranceId: item.insuranceId,
      //       files: [...item.files],
      //     };
      //     newAuthorizationsArray.push(newObj);
      //   } else {
      //     newAuthorizationsArray.push(item);
      //   }
      // });
      // dispatch(setResponseValue({ name: "pending", value: true }));
      // let { status } = await ClientService.updateClientAuthorizations(
      //   newAuthorizationsArray
      // );
      // if (status) {
      //   dispatch(setResponseValue({ name: "success", value: true }));
      //   dispatch(
      //     setResponseValue({
      //       name: "message",
      //       value: "Successfully updated client authorizations",
      //     })
      //   );
      //   dispatch(setInitialState({}));
      //   dispatch(setListDataChange({}));
      // }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const updateClientProfile = createAsyncThunk(
  "client/updateClient",
  async (
    { fileObj, id }: { fileObj: any; id: any },
    { getState, dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message } = await ClientService.uploadClientImage(
        fileObj,
        id
      );
      if (status) {
        dispatch(setListDataChange());
        dispatch(setInitialState({}));
        dispatch(getClientBoards({ useLoader: true }));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const deleteClientById = createAsyncThunk(
  "client/deleteClient",
  async ({ clientId }: { clientId: string }, { dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message } = await ClientService.deleteClientById(
        clientId
      );
      if (status) {
        dispatch(setListDataChange());
        dispatch(getClientBoards({ useLoader: true }));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: message,
          })
        );
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//handles deleting an availability

export const deleteSingleAvailability = createAsyncThunk(
  "/deleteSingleAvailability",
  async ({ availableId }: { availableId: string }, { dispatch, getState }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    let { client } = getState() as { client: ClientForms };

    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message } =
        await AvailabilityService.deleteAvailabilityById(availableId);
      if (status) {
        const newAvailability = client.availabilityDetails.filter(
          (item) => item.id !== availableId
        );
        dispatch(setAvailabilityDetails({ data: newAvailability }));
        dispatch(setListDataChange());
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const deleteSingleAuthorization = createAsyncThunk(
  "/deleteSingleAuthorization",
  async ({ authId }: { authId: string }, { dispatch, getState }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message } = await ClientService.deleteAuthorizationById(
        authId
      );
      if (status) {
        dispatch(setListDataChange());
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const deleteClientFileById = createAsyncThunk(
  "client/deleteClientFile",
  async ({ fileId }: { fileId: string }, { dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message } = await CommonService.deleteFileById(fileId);
      if (status) {
        dispatch(deleteClientFile(fileId));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: message,
          })
        );
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const updateClientFileNameById = createAsyncThunk(
  "client/updateClientFileName",
  async (
    {
      id,
      fileName,
      clientId,
    }: { id: string; fileName: string; clientId: string },
    { dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message } = await CommonService.updateFileNameById(
        id,
        fileName
      );
      if (status) {
        dispatch(getClientFilesById({ clientId }));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: message,
          })
        );
      }
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const {
  //Client Basic
  setClientBasicDetails,
  setClientContactDetails,
  resetSchoolAddress,
  setSchoolAddressForClient,
  setAddressForClient,
  setEditClient,

  //Client Intake
  setInitialState,

  //Client Availability
  setBeginDate,
  setEndDate,
  setClientSelectedDays,
  // setIsAvailable,
  setFromTime,
  setToTime,
  setAvailabilityDetails,
  removeAvailability,
  addNewAvailability,
  setEntityId,
  //Client Insurance
  addNewInsuranceProvider,
  deleteClientInsurance,
  setClientInsurancePrimary,
  setClientInsuranceDetails,
  setClientInsurance, //remember selected insurance in dropdown

  //Client Authorization
  addAuthorization,
  setAuthorizationName,
  setAuthorizationNumber,
  setAuthorizationInsuranceProvider,
  setAuthorizationAuthCodes,
  setAuthorizationAuthCodeUnits,
  setAuthorizationDateRange,
  setAuthorizationFiles,
  deleteAuthorization,
  deleteAuthCodeAuthorization,
  addAuthCodeInAuthorization,
  setAuthorizationDetails,
  deleteAuthorizationFiles,
  setInitialStateForAuthorizationEdit,
  setDay,
  addAvailability,
  deleteAvailability,
  // setProfilePicture,
  addOtherAddresses,
  setClientContactDetailsWithOtherAddresses,
  removeAuthorization,
  setClientData,
  setClientId,
  setClientFiles,
  deleteClientFile,
  setClientFilesFromResponse,
  setClientFileCategory,
  setClientMessages,
  resetClientFiles,
} = clientSlice.actions;

export default clientSlice;
