import axios from "../axios/axios";

export const getInsuranceProviders = async () => {
  try {
    const url = "/insuranceProvider";
    const { data } = await axios.get(url);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const insuranceProviderService = {
  getInsuranceProviders
}

export default insuranceProviderService