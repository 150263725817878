import {
  Box,
  Button,
  FormHelperText,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  tableCellClasses,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  EmploymentDetails,
  ProviderForms,
  ProviderList,
} from "../../../types/provider";
import { useDispatch, useSelector } from "react-redux";
// import { MenuProps } from "../../../constants/providers";

import {
  postSupervisorCases,
  postSupervisorTeam,
  setEmploymentDetailsFromForm,
  updateProviderEmploymentById,
} from "../../../redux/features/providers-main/provider-slice";
import {
  // getNonSupervisedClients,
  getNonSupervisor,
  getSupervisor,
} from "../../../redux/features/providers-main/provider-supervisor-slice";
import { ClientForms } from "../../../types/client";
import { GetAllClientData } from "../../../redux/features/client-main/client-list-slice";

import useAutocomplete, {
  AutocompleteGetTagProps,
} from "@mui/base/useAutocomplete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import { ReactComponent as Search } from "../../../assets/images/images-svg/searchIcon.svg";
import { searchClientText } from "../../../utils/FilterUtils";

// import { TableBody } from "material-ui";

// import { getNonSupervisor } from "../../../../redux/features/providers-main/provider-supervisor-slice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  padding: "5px 20px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF6FF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    height: "40px",
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
  height: "50px",
}));

const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
}));

interface ISupervisor {
  employmentDetails?: EmploymentDetails;
  setOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenPopupCases: React.Dispatch<React.SetStateAction<boolean>>;
  openPopupCases: boolean; //true: cases; false: team
  id: string;
}

const InputWrapper = styled("div")(
  ({ theme }) => `
  width: 300px;
  border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
  }

  &.focused {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    color: ${
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,0.65)"
        : "rgba(0,0,0,.85)"
    };
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
}

function Tag(props: TagProps) {
  const { label, onDelete, ...other } = props;

  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

const StyledTag = styled(Tag)<TagProps>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"
  };
  border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);

const Listbox = styled("ul")(
  ({ theme }) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

const SupervisorForm: React.FC<ISupervisor> = ({
  employmentDetails,
  setOpenPopup,
  setOpenPopupCases,
  openPopupCases,
  id,
}) => {
  //#region variable region

  //used to store the duplication data
  // eslint-disable-next-line
  const [remainData, setRemainData] = useState<ClientForms[]>([]);

  //fetch client list
  const ClientList = useSelector<RootState, ClientForms[]>(
    (state) => state.clientListSlice.listData
    // (state) => state.providerSupervisorSlice.clientList
  );

  //fetch provider list
  const ProviderLists = useSelector<RootState, ProviderList[]>(
    (state) => state.providerSupervisorSlice.providerList
  );

  //fetch supervisor list
  const SupervisorLists = useSelector<RootState, ProviderList[]>(
    (state) => state.providerSupervisorSlice.supervisorList
  );

  //stores all provider list
  const AllProvidersList = [...ProviderLists, ...SupervisorLists];

  //exclude self and store other providers
  const FilteredProviders = AllProvidersList.filter((item) => item.id !== id);

  //Store client data
  const [clientData, setClientData] = useState<ClientForms[]>([]);

  // eslint-disable-next-line
  const [list, setList] = useState<ProviderList[]>([]);

  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();

  //Gets the values from redux  for employmentDetails
  // const employmentDetails = useSelector<RootState, EmploymentDetails>(
  //   (state) => state.providerSlice.employmentDetails
  // );

  const providers = useSelector<RootState, ProviderForms[]>(
    (state) => state.providerListSlice.listData
  );

  //variable to store data value by default
  // eslint-disable-next-line
  const [valuesdata, setValuesdata] = useState<ProviderList[]>([]);

  //get provider
  // eslint-disable-next-line
  const getProvider = (item: string) => {
    // let result = ProviderLists.filter(
    //   (items) => items.canBeSupervisor === true
    // );
    let data = ProviderLists.filter((unit) => {
      return unit.id === item;
    });
    return data[0];
  };

  // selected client data
  const [selectedClientData, setSelectedClientData] = useState<ClientForms[]>(
    []
  );

  // store clients Ids list
  const [clientsId, setclientsId] = useState<string[]>([]);

  // selected provider data
  const [selectedProviderData, setSelectedProviderData] = useState<
    ProviderList[]
  >([]);

  // store providers Ids list
  const [providerId, setProviderId] = useState<string[]>([]);

  //used to store mapping client data list
  const [dataList, setDataList] = useState<ClientForms[]>([]);

  //used to store mapping Provider data list
  const [dataProviderList, setDataProviderList] = useState<ProviderList[]>([]);

  // used to store warning aleart
  // eslint-disable-next-line
  const [warning, setWarning] = useState<boolean>(false);

  // const {
  //   //getRootProps,
  //   //getInputProps,
  //   //getTagProps,
  //   // getListboxProps,
  //   //getOptionProps,
  //   //groupedOptions,
  //   value,
  //   //focused,
  //   //setAnchorEl,
  // } = useAutocomplete({
  //   id: "customized-hook-demo",

  //   value: valuesdata,
  //   multiple: true,
  //   options: list,

  //   getOptionLabel: (option) => option.firstName,
  //   onChange(event, value, reason, details) {
  //     if (details !== undefined) {
  //       let arr: string[] = [];
  //       for (let i = 0; value.length > i; i++) {
  //         arr.push(value[i].id);
  //       }
  //       dispatch(
  //         setEmploymentDetailsFromForm({
  //           name: "providerList",
  //           value: arr,
  //         })
  //       );
  //     }
  //   },
  // });

  //#endregion

  //#region  method region

  const getSelectedClientList = (ID: string) => {
    let arr: ClientForms[] = [];

    let result = clientData.filter(
      (unit) => unit.clientBasicDetails.id === ID
    )[0];

    let val = selectedClientData?.find(
      (item) => item.clientBasicDetails.id === ID
    );
    arr.push(result);

    if (!val) {
      setSelectedClientData([...arr, ...selectedClientData]);
      setclientsId([ID, ...clientsId]);
    }

    return arr;
  };

  // used to checked availability available or not
  // eslint-disable-next-line
  const getNonAvailabilityData = (ID: string) => {
    let result = false;

    let value = providers.filter(
      (item) => item.employmentDetails?.id === ID
    )[0];
    if (value?.availabilityDetails.length > 0) {
      result = true;
    }
    return result;
  };

  const getSelectedProviderList = (ID: string) => {
    let arr: any[] = [];

    // if (getNonAvailabilityData(ID)) {
    let result = FilteredProviders.filter((unit) => unit.id === ID)[0];
    arr.push(result);
    let val = selectedProviderData?.find(
      (item) => !item || item.id === arr[0].id
    );
    if (val === undefined) {
      setSelectedProviderData([...arr, ...selectedProviderData]);
      setProviderId([ID, ...providerId]);
    }
    // } else {
    //   setWarning(true);

    //   setTimeout(() => {
    //     setWarning(false);
    //   }, 2000);
    // }

    return arr;
  };

  const getSelectId = (values: string) => {
    let result: string[] | string = [];
    if (employmentDetails) {
      let val = employmentDetails.clientList.filter((item) => item === values);

      if (val.length === 0) {
        result = [...employmentDetails.clientList, values];
      } else {
        result = [...employmentDetails.clientList];
      }
    }
    return result;
  };

  // get Provider selected Ids
  // eslint-disable-next-line
  const getProviderSelectId = (values: string) => {
    let result: string[] | string = [];
    if (employmentDetails) {
      let val = employmentDetails.providerList.filter(
        (item) => item === values
      );

      if (val.length === 0) {
        result = [...employmentDetails.providerList, values];
      } else {
        result = [...employmentDetails.providerList];
      }
    }
    return result;
  };

  //handle Upadate data
  const handleSubmit = () => {
    if (employmentDetails) {
      if (openPopupCases) {
        //true: update cases
        dispatch(
          postSupervisorCases({
            supervisorId: employmentDetails.id,
            providerList: [],
            clientList: clientsId,
          })
        );
      } else {
        //true: update team
        dispatch(
          postSupervisorTeam({
            supervisorId: employmentDetails.id,
            providerList: providerId,
            clientList: [],
          })
        );
      }
    }

    dispatch(updateProviderEmploymentById({}));
    setOpenPopup(false);
    setOpenPopupCases(false);
  };

  //get match value
  const getMatchData = (item: ClientForms, searchVal: string): boolean => {
    const searchText = searchClientText(searchVal, item);
    if (!searchText) {
      return false;
    }
    if (searchVal === "") {
      return false;
    }
    return true;
  };

  //get client match value
  const handleResult = (searchVal: string) => {
    let result: any[] = [];
    if (searchVal !== undefined) {
      if (searchVal !== "") {
        clientData.map((item: ClientForms) => {
          return getMatchData(item, searchVal) && result.push(item);
        });
      } else {
        result = [...clientData];
      }
    }

    setDataList(result);
  };

  //get provider match value
  const getProviderMatchData = (
    item: ProviderList,
    searchVal: string
  ): boolean => {
    const fullName = `${item.firstName} ${item.lastName}`;
    if (
      searchVal &&
      !fullName.toLowerCase().includes(searchVal.toLowerCase().trim())
    ) {
      return false;
    }

    if (searchVal === "") {
      return false;
    }
    return true;
  };

  //match provider data
  const handleProviderResult = (searchVal: string) => {
    let result: any[] = [];
    if (searchVal !== undefined) {
      if (searchVal !== "") {
        FilteredProviders.map((item: ProviderList) => {
          return getProviderMatchData(item, searchVal) && result.push(item);
        });
      } else {
        result = [...FilteredProviders];
      }
    }

    setDataProviderList(result);
  };

  //handle clients cases lists
  // eslint-disable-next-line
  const handlelistChange = (values: string) => {
    if (values !== undefined) {
      dispatch(
        setEmploymentDetailsFromForm({
          name: "clientList",
          value: getSelectId(values),
        })
      );
    }
  };

  //handle delete cases lists
  /*const handleDelateChange = (values: string) => {
    let result = employmentDetails.clientList.filter((unit) => unit !== values);

    dispatch(
      setEmploymentDetailsFromForm({
        name: "clientList",
        value: result,
      })
    );
  };*/

  // const handleDeleteProvider = (values: string) => {
  //   let result = employmentDetails.providerList.filter(
  //     (unit) => unit !== values
  //   );

  //   let data = selectedProviderData.filter((item) => item.id !== values);

  //   setDataProviderList(data);
  // };
  //#endregion

  //#region  useEffect region

  //fetch supervisor
  useEffect(() => {
    dispatch(getNonSupervisor());
    dispatch(getSupervisor());
    // dispatch(getNonSupervisedClients());
    dispatch(GetAllClientData()); //TODO: Ideally do not call APIs to fetch clients!!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //filter duplication data
  useEffect(() => {
    let arr: ClientForms[] = [];
    if (ClientList) {
      ClientList.forEach((item) => {
        if (
          item.clientBasicDetails?.supervisor?.supervisorId ===
          employmentDetails?.id
        ) {
          return arr.push(item);
        }
        if (!item.clientBasicDetails?.supervisor) {
          arr.push(item);
        }
      });
    }
    setRemainData(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ClientList]);

  // sort data
  useEffect(() => {
    let dataClient: ClientForms[] = [];
    let selectedClient: ClientForms[] = [];
    let nonselectedClient: ClientForms[] = [];
    let value: boolean = false;
    ClientList.forEach((item: ClientForms) => {
      if (item.clientBasicDetails.supervisor) {
        if (
          item.clientBasicDetails?.supervisor?.supervisorId ===
          employmentDetails?.id
        ) {
          value = true;
        }
        selectedClient.push(item);
      } else {
        nonselectedClient.push(item);
      }
    });
    value
      ? (dataClient = [...selectedClient, ...nonselectedClient])
      : (dataClient = [...nonselectedClient, ...selectedClient]);

    setClientData(dataClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ClientList, employmentDetails?.id]);

  //get inital provider list
  const getPrividerList = () => {
    let arr: any[] = [];

    if (FilteredProviders && employmentDetails) {
      for (let i = 0; employmentDetails.providerList.length > i; i++) {
        let result = FilteredProviders.filter(
          (unit) => unit.id === employmentDetails?.providerList[i]
        )[0];
        if (result) arr.push(result);
      }
      setProviderId([...employmentDetails.providerList]);
    }
    setSelectedProviderData([...arr]);
  };

  //get initial Client list
  const getClientList = () => {
    let arr: any[] = [];
    let Ids: string[] = [];

    if (ClientList && employmentDetails) {
      for (let i = 0; employmentDetails.clientList.length > i; i++) {
        let result = ClientList.filter(
          (unit) =>
            unit.clientBasicDetails.id === employmentDetails?.clientList[i]
        )[0];
        if (result) arr.push(result);
      }
      setclientsId([...employmentDetails.clientList]);
      setSelectedClientData([...arr]);
    }
  };

  useEffect(() => {
    getPrividerList();
    getClientList();
  }, [clientData]);

  //#endregion

  return (
    <Grid>
      <Grid>
        {!openPopupCases ? (
          <>
            <Box className="fromGroup">
              {/*<InputLabel className="fromLabel" style={{ color: "#096DD9" }}>
                {" "}
                Search cases by name
              </InputLabel>*/}

              <Box className="innerModelSearch">
                <Search
                  style={{
                    position: "absolute",
                    left: "7px",
                    top: "7px",
                    zIndex: 1,
                  }}
                />
                <TextField
                  id="input-with-sx"
                  label=""
                  placeholder="search employee name"
                  variant="standard"
                  onChange={(e) => {
                    handleProviderResult(e.target.value);
                  }}
                  onFocus={(e) => {
                    setDataProviderList(FilteredProviders);
                  }}
                />
              </Box>
              <Box className="modelSearch-data">
                {dataProviderList.length > 0 && (
                  <>
                    {dataProviderList.map((item) => {
                      return (
                        <>
                          <Box
                            onClick={() => {
                              getSelectedProviderList(item.id);
                            }}
                          >
                            {item.firstName} {item.lastName}
                            <span>
                              {item.id
                                ? providerId.includes(item.id)
                                  ? "Added"
                                  : "Add"
                                : "Add"}
                            </span>
                          </Box>
                          {/* <span>
                            {getNonAvailabilityData(item.id)
                              ? " "
                              : warning && (
                                  <FormHelperText
                                    style={{
                                      color: "#d32f2f",
                                      paddingLeft: "8%",
                                    }}
                                  >
                                    Please first add availability
                                  </FormHelperText>
                                )}
                          </span> */}
                        </>
                      );
                    })}
                  </>
                )}
              </Box>
            </Box>
            <Box className="fromGroup">
              <InputLabel className="fromLabel" style={{ color: "#096DD9" }}>
                Added Employees
              </InputLabel>
              <TableContainer
                component={Paper}
                className="tableResponsive"
                style={{ maxHeight: "160px", overflow: "auto" }}
              >
                <StyledTable
                  sx={{
                    borderCollapse: "separate",
                    cursor: "pointer",
                  }}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>Onboarding Stage</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {selectedProviderData.length > 0 ? (
                    <TableBody>
                      {selectedProviderData.map((item) => {
                        return (
                          <StyledTableRow style={{ borderRadius: 20 }}>
                            <StyledTableCell>
                              {item?.firstName} {item?.lastName}
                            </StyledTableCell>
                            <StyledTableCell>
                              {item?.onBoardingStage}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={(e) => {
                                // handleDeleteProvider(item.id);
                                // let result =
                                //   employmentDetails.providerList.filter(
                                //     (unit) => unit !== item.id
                                //   );
                                let result = selectedProviderData.filter(
                                  (unit) => unit.id !== item.id
                                );
                                let val = providerId.filter(
                                  (id) => id !== item.id
                                );
                                setSelectedProviderData(result);
                                setProviderId(val);
                              }}
                            >
                              <DeleteIcon />
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  ) : (
                    <TableHead>
                      <TableRow>
                        <StyledTableCell colSpan={12}>
                          <div className="notFound-Block">
                            No providers added yet
                          </div>
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                  )}
                </StyledTable>
              </TableContainer>
            </Box>
          </>
        ) : (
          <>
            <Box
              className="fromGroup"
              // onBlur={() => {
              //   setDataList([]);
              // }}
            >
              {/*<InputLabel className="fromLabel" style={{ color: "#096DD9" }}>
                {" "}
                Search cases by name
              </InputLabel>*/}
              <Box className="innerModelSearch">
                <Search
                  style={{
                    position: "absolute",
                    left: "7px",
                    top: "9px",
                    zIndex: 1,
                  }}
                />
                <TextField
                  id="input-with-sx"
                  label=""
                  placeholder="search client name"
                  variant="standard"
                  onChange={(e) => {
                    handleResult(e.target.value);
                  }}
                  onFocus={() => {
                    setDataList(clientData);
                  }}
                  // onBlur={() => {
                  //   setDataList([]);
                  // }}
                />
              </Box>
              <Box className="modelSearch-data">
                {dataList.length > 0 && (
                  <>
                    {dataList.map((item) => {
                      return (
                        <Box
                          /*className={
                            item.clientBasicDetails?.supervisor
                              ? item.clientBasicDetails?.supervisor
                                  ?.supervisorId === employmentDetails.id
                                ? ""
                                : "disabled"
                              : ""
                          }*/
                          onClick={() => {
                            // item.clientBasicDetails.id
                            //   ? !employmentDetails.clientList.includes(
                            //       item.clientBasicDetails.id
                            //     ) &&
                            //     handlelistChange(item.clientBasicDetails.id)
                            //   : handlelistChange(item.clientBasicDetails.id);
                            getSelectedClientList(item.clientBasicDetails.id);
                          }}
                        >
                          {item.clientBasicDetails?.childFirstName}{" "}
                          {item.clientBasicDetails?.childLastName}
                          <span>
                            {item.clientBasicDetails.id
                              ? clientsId.includes(item.clientBasicDetails.id)
                                ? // <DeleteIcon />
                                  "Added"
                                : "Add"
                              : "Add"}
                          </span>
                        </Box>
                      );
                    })}
                  </>
                )}
              </Box>
            </Box>
            <Box className="fromGroup">
              <InputLabel className="fromLabel" style={{ color: "#096DD9" }}>
                Added Cases
              </InputLabel>
              <TableContainer
                component={Paper}
                className="tableResponsive"
                style={{ maxHeight: "160px", overflow: "auto" }}
              >
                <StyledTable
                  sx={{
                    borderCollapse: "separate",
                    cursor: "pointer",
                  }}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Client Name</StyledTableCell>
                      <StyledTableCell>Intake Stage</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {selectedClientData.length > 0 ? (
                    <TableBody>
                      {selectedClientData.map((item) => {
                        return (
                          <StyledTableRow style={{ borderRadius: 20 }}>
                            <StyledTableCell>
                              {item?.clientBasicDetails.childFirstName}{" "}
                              {item?.clientBasicDetails.childLastName}
                            </StyledTableCell>
                            <StyledTableCell>
                              {item?.clientBasicDetails.intakeStage}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={(e) => {
                                // let result =
                                //   employmentDetails.clientList.filter(
                                //     (unit) =>
                                //       unit !== item.clientBasicDetails.id
                                //   );

                                // dispatch(
                                //   setEmploymentDetailsFromForm({
                                //     name: "clientList",
                                //     value: result,
                                //   })
                                // );
                                let result = selectedClientData.filter(
                                  (unit) =>
                                    unit.clientBasicDetails.id !==
                                    item.clientBasicDetails.id
                                );

                                let val = clientsId.filter(
                                  (id) => id !== item.clientBasicDetails.id
                                );

                                setSelectedClientData(result);
                                setclientsId(val);
                              }}
                            >
                              <DeleteIcon />
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  ) : (
                    <TableHead>
                      <TableRow>
                        <StyledTableCell colSpan={12}>
                          <div className="notFound-Block">
                            no cases added yet
                          </div>
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                  )}
                </StyledTable>
              </TableContainer>
            </Box>
          </>
        )}
      </Grid>
      <Grid>
        <div className="smallModel__footer">
          <Button className="button" type="submit" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default SupervisorForm;
