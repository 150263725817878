import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import { Check, Close } from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "../../redux/store";
import { getOrgInfo } from "../../redux/features/organization/organization-slice";
import {
  CheckoutPayment,
  UpdateSubscription,
} from "../../redux/features/plan-billing/plan-billing-slice";

import { IStripeProduct, ITenantUsage } from "../../types/product";

import "./payment.scss";

interface SubscriptionCardProps {
  product: IStripeProduct;
  currentPrice?: number;
  tenantUsage: ITenantUsage;
  adminSeats: number;
  setAdminSeats: (adminSeats: number) => void;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  product,
  currentPrice,
  adminSeats,
  setAdminSeats,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openAdminDialog, setOpenAdminDialog] = useState(false);
  const [selectAdmins, setSelectAdmins] = useState(false);

  //Note: currentPlan must be populated already!!
  const currentPlan = useAppSelector(
    (state) => state.planBillingSlice.currentPlan
  );
  const orgData = useAppSelector((state) => state.organizationSlice.orgData);
  const [buttonText, setButtonText] = useState(
    product?.price > 0
      ? currentPrice && product.price
        ? currentPrice === product.price
          ? "Your Current Plan"
          : currentPrice > product.price
          ? "Downgrade"
          : "Upgrade"
        : "Select"
      : currentPlan && currentPlan?.planDetail?.title === "Enterprise"
      ? orgData?.enterpriseRequestCount > 0
        ? "Request Received"
        : "Submit Request"
      : "Submit Request"
  );
  // console.log("buttonText", buttonText, currentPlan?.planDetail?.title, orgData?.enterpriseRequestCount);

  return (
    <>
      {product && (
        <>
          <Grid size={{ xs: 12, md: 6, lg: 3 }} mt={10}>
            <div className="planMainCard">
              {product.isPopular && (
                <div className="popularPlanBox">Most Popular</div>
              )}
              <div className="planMainCard__body">
                <div className="planMainCard__heading">
                  <Typography className="planMainCard__title">
                    {product.title}
                  </Typography>
                  <Typography className="planMainCard__price">
                    {product.price ? (
                      <>
                        <strong>${product.price}</strong> / mo
                      </>
                    ) : (
                      "Contact Us"
                    )}
                  </Typography>
                </div>
                <ul className="planMainCard__list">
                  {product.features.map((feature: any, index: number) => {
                    return (
                      <li className="planMainCard__list-item" key={index}>
                        <Check />
                        <div>{feature}</div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="planMainCard__footer">
                <Button
                  onClick={() => {
                    setOpenAdminDialog(true);
                    if (
                      currentPlan &&
                      currentPlan.adminSeats > product.adminCovered
                    ) {
                      //populate admin seats!
                      setAdminSeats(currentPlan.adminSeats);
                    } else {
                      //no purchased plan
                      setAdminSeats(product.adminCovered);
                    }
                  }}
                  // onClick={checkOutPayment}
                  variant={"text"}
                  className={
                    currentPrice && product.price
                      ? currentPrice === product.price
                        ? "selectedPlan"
                        : currentPrice > (product.price || 0)
                        ? "downGrad"
                        : ""
                      : buttonText === "Request Received"
                      ? "requestReceived"
                      : ""
                  }
                  disabled={
                    currentPrice && currentPrice === product.price
                      ? true
                      : false
                  }
                >
                  {buttonText}
                  {/* {product.price
                    ? currentPrice && product.price
                      ? currentPrice === product.price
                        ? "Your Current Plan"
                        : currentPrice > product.price
                        ? "Downgrade"
                        : "Upgrade"
                      : "Select"
                    : currentPlan && currentPlan?.planDetail?.title === "Enterprise"
                      ? orgData?.enterpriseRequestCount > 0
                        ? "Request Received"
                        : "Submit Request"
                      : "Submit Request"} */}
                </Button>
              </div>
            </div>
          </Grid>
          <Dialog open={openAdminDialog} className="smallModel upgradeModal">
            <div>
              <Grid>
                <Box className="largeModel__head">
                  <Typography variant="h5" className="largeModel__title">
                    Checkout Plan - {product.title}
                  </Typography>
                  <Button
                    className="largeModel__closeBtn"
                    onClick={() => {
                      setSelectAdmins(false);
                      setOpenAdminDialog(false);
                    }}
                  >
                    <Close />
                  </Button>
                </Box>
              </Grid>

              <DialogContent>
                <Box>
                  You are about to checkout for the{" "}
                  <b>{`${product.title}`} Plan. </b>${product.price} / month, up
                  to {product.clientLimit} clients,{" "}
                  {product.adminCovered === 1
                    ? "1 admin seat"
                    : product.adminCovered + " admin seats"}
                  . You can add more admin seats if you want.
                  <p></p>
                </Box>

                <Box mt={1}>
                  <TextField
                    value={adminSeats}
                    onChange={(e) => {
                      const count = parseInt(e.target.value);
                      if (product && adminSeats < product.adminCovered) {
                        //use plan admin seats
                        setAdminSeats(product.adminCovered);
                      } else {
                        setAdminSeats(count);
                      }
                    }}
                    label="Total Admin Seats"
                    variant="outlined"
                    type="number"
                  />
                </Box>
              </DialogContent>

              <DialogActions>
                <Button
                  className="border-button"
                  variant="contained"
                  onClick={() => {
                    setSelectAdmins(false);
                    setOpenAdminDialog(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="button"
                  variant="contained"
                  onClick={async () => {
                    //first checkout & update plan
                    // console.log("currentPrice", currentPrice, product);
                    if (currentPrice && product.price) {
                      dispatch(
                        UpdateSubscription({
                          productId: product.productId,
                          adminSeats: adminSeats,
                        })
                      )
                        .then(() => {
                          navigate("/my-plan");
                        })
                        .finally(() => {
                          dispatch(getOrgInfo({ useLoader: true }));
                          setSelectAdmins(false);
                          setOpenAdminDialog(false);
                        });
                    } else {
                      // console.log("CheckoutPayment", product.productId, adminSeats);
                      dispatch(
                        CheckoutPayment({
                          productId: product.productId,
                          adminSeats,
                        })
                      )
                        .then(() => {
                          setButtonText("Request Received");
                        })
                        .finally(() => {
                          dispatch(getOrgInfo({ useLoader: true }));
                          setSelectAdmins(false);
                          setOpenAdminDialog(false);
                        });
                    }
                  }}
                >
                  Continue
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </>
      )}
    </>
  );
};

export default SubscriptionCard;
