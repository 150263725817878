import {
  Button,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Dialog,
  DialogContent,
  Typography,
  CircularProgress,
  DialogActions,
  FormControlLabel,
  FormGroup,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useState } from "react";

// import { useNavigate } from "react-router-dom";
import {
  StyledTable,
  StyledTableCell,
  StyledTableRow,
} from "../../settings/business-settings/BusinessForm";
import { ReactComponent as Download } from "../../../assets/images/images-svg/Download.svg";
import { getSessionToken } from "@descope/react-sdk";
import { exportDataSets } from "../../../constants/tools";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  GetExportTemplates,
  setExportedData,
} from "../../../redux/features/import-export/import-export-slice";
import { IExportTemplate } from "../../../types/tools";
import { Close } from "@mui/icons-material";

const ExportClientsProviderDetails: React.FC = () => {
  //#region variable region
  const dispatch = useAppDispatch();
  const [sseMessage, setsseMessage] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [selectedDataset, setSelectedDataSet] = useState<string[]>([]);
  const [confirmationPopUp, setConfirmationPopUp] = useState<boolean>(false);
  const [agreedPolicy, setAgreedPolicy] = useState<boolean>(false);
  const templates = useAppSelector<IExportTemplate[]>(
    (state) => state.importExportSlice.template
  );
  const exportedData = useAppSelector(
    (state) => state.importExportSlice.exportedData
  );
  // #endregion

  // #region methods region
  const exportHandler = async (fileFormat: string) => {
    const token = getSessionToken();
    const BASE_URL = process.env.REACT_APP_BACKEND_SERVER_BASE_URL;

    try {
      // Convert the array to a comma-separated string
      const entityTypesString = selectedDataset.join(",");

      // Construct the URL with query parameters
      const url = new URL(`${BASE_URL}/export`);
      url.searchParams.append("entityTypes", entityTypesString);
      url.searchParams.append("fileFormat", fileFormat);

      // Perform the fetch request
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.body) {
        throw new Error("No response body");
      }
      setOpen(true);
      // Process the stream data
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      new ReadableStream({
        start(controller) {
          async function read() {
            while (true) {
              const { done, value } = await reader.read();
              if (done) {
                controller.close();
                break;
              }

              // Decode and process the chunk
              const text = decoder.decode(value, { stream: true });
              const messages = text.split("\n\n");
              for (const message of messages) {
                if (message.trim() !== "") {
                  const eventData = JSON.parse(message.substring(5).trim());
                  if (eventData.type === "message") {
                    console.log("Received update:", eventData.message);
                    setProgress(eventData.progress);
                    setsseMessage(eventData.message);
                  } else if (eventData.type === "data") {
                    // Final JSON response
                    console.log(
                      "Final JSON response:",
                      JSON.parse(eventData.message)
                    );
                    const response = JSON.parse(eventData.message).data;
                    dispatch(setExportedData(response));
                    return;
                  }
                }
              }
              controller.enqueue(value);
            }
          }
          read().catch((error) => {
            console.error("Stream reading error:", error);
            controller.error(error);
          });
        },
      });
    } catch (error: any) {
      console.error("Error:", error);
      throw error?.response?.data || error;
    }
  };

  const handleSelectTable = () => {
    if (selectedDataset.length === 0) {
      setSelectedDataSet(exportDataSets.map((item) => item.value));
    } else {
      setSelectedDataSet([]);
    }
  };

  // #endregion

  //#region useEffect region
  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setProgress(0);
      }, 1000);
    }
  }, [progress]);

  useEffect(() => {
    if (templates?.length === 0) {
      dispatch(GetExportTemplates());
    }
  }, [templates]);

  //#endregion
  return (
    <div>
      <div className="export-btn ">
        {/* <Button variant="contained" onClick={() => exportHandler("CSV")}>
          Export to CSV
        </Button> */}
        <Button
          variant="contained"
          onClick={() => {
            if (!selectedDataset.length) {
              return;
            }
            setConfirmationPopUp(true);
          }}
        >
          Export to Excel
        </Button>
      </div>
      <Grid>
        <Grid size={{ xs: 12 }} className="listingTable">
          <TableContainer component={Paper} className="tableResponsive">
            <StyledTable
              sx={{
                minWidth: 820,
                borderCollapse: "separate",
                cursor: "pointer",
              }}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    <Checkbox
                      onChange={() => handleSelectTable()}
                      checked={selectedDataset.length === exportDataSets.length}
                    />{" "}
                    All
                  </StyledTableCell>
                  <StyledTableCell align="center">Entity Type</StyledTableCell>
                  <StyledTableCell align="center">Version</StyledTableCell>
                  <StyledTableCell align="center">Template</StyledTableCell>
                  <StyledTableCell align="center">Link</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {exportDataSets.map((item, i) => (
                  <StyledTableRow style={{ borderRadius: 20 }} key={i}>
                    <StyledTableCell
                      style={{
                        display: "flex",
                        gap: "12px",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        checked={selectedDataset.includes(item.value)}
                        onChange={() => {
                          if (selectedDataset.includes(item.value)) {
                            setSelectedDataSet(
                              selectedDataset.filter(
                                (ele) => ele !== item.value
                              )
                            );
                          } else {
                            setSelectedDataSet([
                              ...selectedDataset,
                              item.value,
                            ]);
                          }
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>{item.entityType}</StyledTableCell>
                    <StyledTableCell>{item.version}</StyledTableCell>
                    <StyledTableCell>
                      {templates
                        .filter(
                          (template) => template.entityType === item.value
                        )
                        .map((ele) => (
                          <a href={ele.url} style={{ textDecoration: "none" }}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Download />
                              <Button>Download Template</Button>
                            </div>
                          </a>
                        ))}
                    </StyledTableCell>
                    <StyledTableCell>
                      {exportedData
                        .filter((data) => data.entityType === item.entityType)
                        .map((ele, index) => (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                            key={index}
                          >
                            <a href={ele.url} style={{ color: "#1976d2" }}>
                              {ele.fileName}
                            </a>
                          </div>
                        ))}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Grid>
        {open && (
          <Dialog open={open} className="tools-Dialog">
            <DialogContent className="content">
              {progress !== 0 && (
                <CircularProgress variant="determinate" value={progress} />
              )}
              <Typography className="sse-Message">{sseMessage}</Typography>
              <Button
                className="close-btn"
                variant="contained"
                onClick={() => {
                  setOpen(false);
                  setSelectedDataSet([]);
                }}
              >
                Close
              </Button>
            </DialogContent>
          </Dialog>
        )}

        <Dialog open={confirmationPopUp} className="smallModel confirmExport">
          <div>
            <Box className="smallModel__head" style={{ padding: "8px 16px" }}>
              <Typography variant="h3" className="smallModel__title">
                Confirmation
              </Typography>
              <Button
                className="smallModel__closeBtn"
                onClick={() => {
                  setConfirmationPopUp(false);
                  setAgreedPolicy(false);
                }}
              >
                <Close />
              </Button>
            </Box>
            <DialogContent>
              <Typography>
                Check here to confirm you have acknowledged HIPPA policy for
                managing healthcare data properly.
              </Typography>

              <span className="agree-checkBox">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreedPolicy}
                      onChange={() => setAgreedPolicy(!agreedPolicy)}
                    />
                  }
                  label="I Agree."
                />
              </span>
            </DialogContent>

            <DialogActions>
              {/* <Button
                className="border-button"
                variant="outlined"
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  e.preventDefault();
                  setConfirmationPopUp(false);
                }}
              >
                Close
              </Button> */}
              <Button
                disabled={!agreedPolicy}
                // className="button"
                variant="contained"
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  e.preventDefault();
                  exportHandler("EXCEL");
                  setConfirmationPopUp(false);
                  setAgreedPolicy(false);
                }}
              >
                Export
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </Grid>
    </div>
  );
};

export default ExportClientsProviderDetails;
