import React, { useState } from "react";
import { Box, Dialog, Button, Typography, ButtonGroup } from "@mui/material";
import { Close } from "@mui/icons-material";
import { ReactComponent as FilterIcon } from "../../../assets/images/images-svg/filterIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";

import { setMapsFilterValues } from "../../../redux/features/maps/maps-filter-slice";
import MapFilter from "../../filter-main/MapFilter";
import { OrgBU } from "../../../types/company";
import { getBussinessName } from "../../../constants/common";
import { MapsFilter } from "../../../types/map";
import { getBusinessUnitName } from "../../../utils/EntityUtils";
import CloseIcon from "@mui/icons-material/Close";
import { dataUpdated } from "../../../redux/features/client-main/client-list-slice";

const MapsHeading: React.FC = () => {
  // #region variables region

  const dispatch = useDispatch<AppDispatch>();

  //controls filter modal visibility
  const [filterModal, setFilterModal] = useState<boolean>(false);

  //filter values
  const filterTypes = useSelector<RootState, MapsFilter>(
    (state) => state.mapsFilterSlice
  );

  //business units
  const businessUnits = useSelector<RootState, OrgBU[]>(
    (state) => state.businessUnitsSlice.businessUnits
  );

  // #endregion

  // #region methods region

  //handles close single filter
  const handleCloseSingleFilter = (key: string, item: string) => {
    if (key === "insuranceProvider") {
      let value = filterTypes.insuranceProvider.filter((i) => i !== item);
      dispatch(
        setMapsFilterValues({ ...filterTypes, insuranceProvider: value })
      );
    } else if (key === "businessUnit") {
      let value = filterTypes.businessUnit.filter((i) => i !== item);
      dispatch(setMapsFilterValues({ ...filterTypes, businessUnit: value }));
    } else if (key === "clientStatus") {
      let value = filterTypes.clientStatus.filter((i) => i !== item);
      dispatch(setMapsFilterValues({ ...filterTypes, clientStatus: value }));
    } else if (key === "providerStatus") {
      let value = filterTypes.providerStatus.filter((i) => i !== item);
      dispatch(setMapsFilterValues({ ...filterTypes, providerStatus: value }));
    } else if (key === "intakeStage") {
      let value = filterTypes.intakeStage.filter((i) => i !== item);
      dispatch(setMapsFilterValues({ ...filterTypes, intakeStage: value }));
    } else if (key === "clientConcerns") {
      let value = filterTypes.clientConcerns.filter((i) => i !== item);
      dispatch(setMapsFilterValues({ ...filterTypes, clientConcerns: value }));
    } else if (key === "onBoardingStage") {
      let value = filterTypes.onBoardingStage.filter((i) => i !== item);
      dispatch(setMapsFilterValues({ ...filterTypes, onBoardingStage: value }));
    } else if (key === "providerTasks") {
      let value = filterTypes.providerTasks.filter((i) => i !== item);
      dispatch(setMapsFilterValues({ ...filterTypes, providerTasks: value }));
    } else if (key === "ScheduleStatus") {
      dispatch(setMapsFilterValues({ ...filterTypes, ScheduleStatus: "" }));
    } else if (key === "startDate") {
      dispatch(setMapsFilterValues({ ...filterTypes, startDate: null }));
      dispatch(dataUpdated({ dataUpdate: false }));
    } else if (key === "endDate") {
      dispatch(setMapsFilterValues({ ...filterTypes, endDate: null }));
      dispatch(dataUpdated({ dataUpdate: false }));
    } else if (key === "ageFrom") {
      dispatch(setMapsFilterValues({ ...filterTypes, ageFrom: null }));
    } else if (key === "ageTo") {
      dispatch(setMapsFilterValues({ ...filterTypes, ageTo: null }));
    }
  };

  // #endregion

  return (
    <Box sx={{ marginBottom: "4px" }}>
      <div className="listingTable__header">
        <Typography className="listingTable__title" gutterBottom variant="h6">
          Discover
        </Typography>
        <ButtonGroup
          disableElevation
          variant="contained"
          className="filterGroup-btn"
        >
          <Button
            className="filterGroup-btn"
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              setFilterModal(true);
            }}
          >
            <FilterIcon />
          </Button>
        </ButtonGroup>
      </div>
      <div className="selected-filtersButtons">
        {Object.entries(filterTypes)
          .filter(([_, value], idx) => {
            if (value && typeof value === "string") return true;
            if (value?.length > 0 && typeof value === "object") return true;
            return false;
          })
          .map(([key, value], index) => {
            if (typeof value === "object") {
              return value.map((subItem: string, subIndex: number) => {
                if (
                  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
                    subItem
                  )
                ) {
                  return (
                    <>
                      <Button className="border-button" key={index}>
                        {getBussinessName(subItem, businessUnits)}
                        <span className="closeBtn">
                          <CloseIcon
                            fontSize="small"
                            onClick={() => {
                              handleCloseSingleFilter(key, subItem);
                            }}
                          />
                        </span>
                      </Button>
                    </>
                  );
                }

                return (
                  <>
                    <Button className="border-button" key={subIndex}>
                      {subItem}
                      <span className="closeBtn">
                        <CloseIcon
                          fontSize="small"
                          onClick={() => {
                            handleCloseSingleFilter(key, subItem);
                          }}
                        />
                      </span>
                    </Button>
                  </>
                );
              });
            } else {
              return (
                <>
                  <Button className="border-button" key={index}>
                    {key === "businessUnit"
                      ? getBusinessUnitName(businessUnits, [value])
                      : key === "age"
                      ? `Age:${value}`
                      : value}
                    <span className="closeBtn">
                      <CloseIcon
                        fontSize="small"
                        onClick={() => {
                          handleCloseSingleFilter(key, value);
                        }}
                      />
                    </span>
                  </Button>
                </>
              );
            }
          })}
      </div>
      <Dialog open={filterModal} className="largeModel">
        <Box className="largeModel__head">
          <Typography variant="h5" className="largeModel__title">
            Filter Map
          </Typography>
          <Button
            className="largeModel__closeBtn"
            onClick={() => setFilterModal(false)}
          >
            <Close />
          </Button>
        </Box>
        <MapFilter
          closeModal={(val: boolean, filterObject: MapsFilter) => {
            dispatch(setMapsFilterValues({ ...filterObject }));
            setFilterModal(val);
          }}
        />
      </Dialog>
    </Box>
  );
};

export default MapsHeading;
