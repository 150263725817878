import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import CloseIcon from "@mui/icons-material/Close";
import { WhoCancelled } from "../../../../constants/schedule";
import { SetStateAction, useEffect, useState } from "react";
import { MenuProps } from "../../../../constants/tools";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { NewDataCatalog } from "../../../../types/businessSettings";
import {
  GetRebookingOptions,
  clearRebookings,
} from "../../../../redux/features/schedules-main/booking-scheduler-slice";
import { ReBookingOptionsResult, TimeSlot } from "../../../../types/schedule";
import moment from "moment";
import { setResponseValue } from "../../../../redux/features/api-response/api-response";
import {
  bookService,
  cancelService,
} from "../../../../service/schedules.service";
import { has } from "lodash";
import { GetClientById } from "../../../../redux/features/client-main/client-list-slice";
import { GetProviderById } from "../../../../redux/features/providers-main/provider-list-slice";
import Provider from "../../../../assets/images/images-svg/provider-large-vertical.svg";
import Loader from "../../../../layouts/loader/Loader";

export interface IRebookingModal {
  isOpen: boolean;
  entityType: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  argsData: Record<string, any> | undefined;
  setIsDataEdited: React.Dispatch<React.SetStateAction<boolean>>;
}
export interface ISelectedTime {
  timeSlot: TimeSlot;
  index: number;
}

export interface ISheduleRebookError {
  cancelReason: boolean;
  timeSlots: {
    error: boolean;
    indexNo: number;
  };
}
const RebookingModal: React.FC<IRebookingModal> = ({
  isOpen,
  setIsOpen,
  entityType,
  argsData,
  setIsDataEdited,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  // store who's cancelled
  const [whoCancelled, setWhoCancelled] = useState<string>("Client");

  // store cancellation reasion
  const [cancellationReason, setCancellationReason] = useState<string>("");

  //handle selected timeslots
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<ISelectedTime[]>([]);

  //handle rebooking error
  const [hasError, setHasError] = useState<ISheduleRebookError>({
    cancelReason: false,
    timeSlots: {
      error: false,
      indexNo: 0,
    },
  });

  // all cancellation reasons
  const ProviderReasonCatalog = useSelector<RootState, NewDataCatalog[]>(
    (state) => state.dataCatalogSlice.ProviderReasonCatalog
  );

  const ClientReasonCatalog = useSelector<RootState, NewDataCatalog[]>(
    (state) => state.dataCatalogSlice.ClientReasonCatalog
  );

  const rebookingOptions = useSelector<RootState, ReBookingOptionsResult>(
    (state) => state.bookingSchedulerSlice.rebookingOptions
  );

  const isLoading = useSelector<RootState, boolean>(
    (state) => state.bookingSchedulerSlice.isLoading
  );

  //store all billable data
  const BillableCatalog = useSelector<RootState, NewDataCatalog[]>(
    (state) => state.dataCatalogSlice.BillableCatalog
  );
  //store all non billable data
  const NonBillableCatalog = useSelector<RootState, NewDataCatalog[]>(
    (state) => state.dataCatalogSlice.NonBillableCatalog
  );

  const sortData = () => {
    let data = rebookingOptions.options.dateOptions.sort((a, b) => {
      return (new Date(a.date) as any) - (new Date(b.date) as any);
    });
    return data;
  };

  //on submitting the form API calling
  const onSubmitRebook = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    timeSlot: TimeSlot | undefined,
    index: number
  ) => {
    if (timeSlot === undefined || cancellationReason === "") {
      let error = {
        whoCancelled: false,
        cancelReason: false,
        timeSlots: {
          error: false,
          indexNo: 0,
        },
      };
      if (timeSlot === undefined) {
        error.timeSlots = {
          error: true,
          indexNo: index,
        };
      }
      if (cancellationReason === "") {
        error.cancelReason = true;
      }
      if (timeSlot === undefined || cancellationReason === "") {
        setHasError(error);
      }
      return;
    }
    e.preventDefault();
    dispatch(setResponseValue({ name: "pending", value: true }));
    let bookingData;

    // console.log("isEdit, isDelete:", isEdit, isDelete, argsData?.bookedClientId);
    try {
      const day = moment(timeSlot.date).format("dddd");

      let category = "";
      // find category in BillableCatalog
      const billable = BillableCatalog.find(
        (item) => item.conceptValue === argsData?.ServiceType
      );
      if (billable) {
        category = "Billable";
      } else {
        const nonBillable = NonBillableCatalog.find(
          (item) => item.conceptValue === argsData?.ServiceType
        );
        if (nonBillable) {
          category = "Non Billable";
        }
      }
      // console.log("category:", category);
      bookingData = {
        //client, provider
        bookedClientId: argsData?.bookedClientId?.id,
        bookedProviderId: argsData?.bookedProviderId.id,

        //schedule
        specificDate: moment(timeSlot.date).toString(), //must pass it as local dtm!!!
        fromTime: timeSlot.startTime, //use same original time
        toTime: timeSlot.endTime, //use same original time
        updateOption: 0, //should be 0
        repeatWorkDays: [day],
        repeatService: 0, //rebooking only for current event!!
        repeatCustomEnd: null,
        userTodayDate: moment(new Date()).format("ddd YYYY-MM-DD"),

        //same as original one
        serviceId: argsData?.serviceID,
        serviceType: argsData?.ServiceType,
        serviceLocation: argsData?.Location,
        description: argsData?.description,
        authId: argsData?.authId,
        authCode: argsData?.authCode,
        category: category,

        createdBy: argsData?.bookedClientId.id,
      };
      await bookService(bookingData, false);

      //cancel old one
      bookingData = {
        serviceId: argsData?.serviceID,
        cancelOption: 0, //should be 0 as current
        specificDate: argsData?.specificDate,
        bookedClientId: argsData?.bookedClientId?.id,
        bookedProviderId: argsData?.bookedProviderId.id,
        whoCancelled: whoCancelled,
        cancellationReason: "Re-schedule",
      };

      await cancelService(bookingData, false);
      if (entityType === "client") {
        dispatch(GetClientById({ clientId: argsData?.clientId }));
      } else {
        dispatch(
          GetProviderById({ providerId: argsData?.bookedProviderId?.id })
        );
      }
      setIsDataEdited(true);
      dispatch(setResponseValue({ name: "success", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: "re-schedule successfully",
        })
      );
    } catch (e: any) {
      console.log("scheduling error:", e);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (argsData && whoCancelled) {
      dispatch(
        GetRebookingOptions({
          cancelRequestBy: whoCancelled,
          bookingId: argsData.serviceID,
        })
      );
    }
  }, [argsData, whoCancelled]);

  // console.log("rebookingOptions:", sortData);

  return (
    <Dialog open={isOpen} className="smallModel--intakeforms">
      <Box className="smallModel__head">
        <Typography variant="h5" className="smallModel__title">
          Rebooking
        </Typography>
        <Button
          className="smallModel__closeBtn"
          onClick={() => {
            setIsOpen(false);
          }}
          style={{ color: "white" }}
        >
          <CloseIcon />
        </Button>
      </Box>
      <DialogContent>
        <Grid size={{ xs: 12 }} className={"fromGroup"}>
          <Grid size={{ xs: 12 }}>
            <InputLabel className="fromLabel">
              Who request to cancel the appointment?
            </InputLabel>
            <div className="fromGroup-chips">
              {WhoCancelled.map((item: string, index: number) => (
                <Card
                  className={
                    whoCancelled === item ? "fromGroup-chip-active" : ""
                  }
                  onClick={() => {
                    setWhoCancelled(item);
                    clearRebookings({});
                  }}
                  key={item}
                >
                  {item}
                </Card>
              ))}
            </div>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <InputLabel className="fromLabel">Cancel Reason:</InputLabel>
            <Grid className="fromGroup-chips">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="reason of cancellation"
                MenuProps={MenuProps}
                value={cancellationReason}
                onChange={(e: SelectChangeEvent<string>) => {
                  setCancellationReason(e.target.value);
                }}
              >
                {(whoCancelled === "Client"
                  ? ClientReasonCatalog
                  : whoCancelled === "Employee"
                  ? ProviderReasonCatalog
                  : []
                )?.map((item: any) => {
                  return (
                    <MenuItem key={item.id} value={item.conceptValue}>
                      {item.conceptValue}
                    </MenuItem>
                  );
                })}
              </Select>
              {cancellationReason === "" && hasError.cancelReason && (
                <FormHelperText style={{ color: "red" }}>
                  Please Select Time Slots
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Box className="description-rebook">
            <Typography className="fromLabel">Begin to search...</Typography>
            <Typography className="fromLabel">
              Service: <span>{argsData?.ServiceType}</span>
            </Typography>
            <Typography className="fromLabel">
              {whoCancelled} Time:{" "}
              <span>
                {moment.utc(rebookingOptions.beginDate).format("ll")}{" "}
              </span>
              <span>
                {moment(argsData?.StartTime).format("hh:mm A")} -{" "}
                {moment(argsData?.EndTime).format("hh:mm A")}
              </span>
            </Typography>
            <Typography className="fromLabel">
              Search Date Range:{" "}
              <span>
                {moment.utc(rebookingOptions.beginDate).format("ll")} -{" "}
                {moment.utc(rebookingOptions.endDate).format("ll")}
              </span>
            </Typography>
            <Typography className="fromLabel">
              Potential Providers:{" "}
              <span>{rebookingOptions.baseProvidersCount} found</span>
            </Typography>
          </Box>
          {isLoading && (
            <div className="rebook-loader">
              <CircularProgress color="primary" />
            </div>
          )}
          {rebookingOptions.options.dateOptions.length > 0 ? (
            rebookingOptions.options.dateOptions.map(
              (item: any, index: number) => (
                <Card
                  sx={{
                    display: "flex",
                    padding: "10px",
                    justifyContent: "space-between",
                    marginTop: "15px",
                  }}
                >
                  <Box display={"flex"} alignItems={"center"}>
                    <Typography className="fromLabel profilePart">
                      <img
                        src={item.provider.providerProfile.url || Provider}
                        alt=""
                      />
                      {item.provider.firstName} {item.provider.lastName}:{" "}
                      {moment.utc(item.date).format("ddd")}{" "}
                      {moment.utc(item.date).format("ll")}
                    </Typography>
                  </Box>
                  <Box width={"245px"}>
                    {!selectedTimeSlot[index]?.timeSlot &&
                      hasError.timeSlots.error &&
                      hasError.timeSlots.indexNo === index && (
                        <FormHelperText style={{ color: "red" }}>
                          Please Select Time Slots
                        </FormHelperText>
                      )}
                    <Select
                      className="demo-simple-select"
                      labelId="demo-simple-select-label"
                      MenuProps={MenuProps}
                      value={
                        selectedTimeSlot.length > 0 &&
                        selectedTimeSlot[index]?.index === index
                          ? `${selectedTimeSlot[index]?.timeSlot.startTime} - ${selectedTimeSlot[index]?.timeSlot.endTime}`
                          : ""
                      }
                      onChange={(e: any) => {
                        // Find the time slot that matches the value selected
                        const newSelectedTimeSlot = item?.fitBookSlots?.find(
                          (timeSlot: any) =>
                            `${timeSlot.startTime} - ${timeSlot.endTime}` ===
                            e.target.value
                        );
                        if (newSelectedTimeSlot !== undefined) {
                          setSelectedTimeSlot((prevSelectedTimeSlot) => {
                            if (!prevSelectedTimeSlot) return [];

                            const newState = [...prevSelectedTimeSlot];
                            newState[index] = {
                              timeSlot: newSelectedTimeSlot,
                              index,
                            };

                            return newState;
                          });
                        }
                      }}
                    >
                      {item?.fitBookSlots &&
                        item?.fitBookSlots?.map(
                          (timeSlot: any, index: number) => (
                            <MenuItem
                              value={`${timeSlot.startTime} - ${timeSlot.endTime}`}
                              key={index}
                              sx={{
                                gap: "10px",
                              }}
                            >
                              {timeSlot.startTime} - {timeSlot.endTime}
                            </MenuItem>
                          )
                        )}
                    </Select>
                    <Button
                      variant="contained"
                      sx={{ marginTop: "10px", float: "inline-end" }}
                      onClick={(e) => {
                        onSubmitRebook(
                          e,
                          selectedTimeSlot[index]?.timeSlot,
                          index
                        );
                      }}
                    >
                      Book
                    </Button>
                  </Box>
                </Card>
              )
            )
          ) : (
            <>No options Found</>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default RebookingModal;
