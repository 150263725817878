import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useState, useEffect, useRef } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import {
  addNewInsuranceProvider,
  setClientInsurancePrimary,
  deleteClientInsurance,
  setClientInsurance,
} from "../../../redux/features/client-main/client-slice";
import { RootState, AppDispatch } from "../../../redux/store";
import { InsuranceProviders } from "../../../types/client";
import DeleteIcon from "@mui/icons-material/Delete";
import { setActiveSteps } from "../../../redux/features/active-steps/active-slice";
import { InsuranceProvider } from "../../../types/response";

import { setUpdatedInsuranceProviders } from "../../../redux/features/client-main/client-insurance-slice";
import { getClientInsuranceProviders } from "../../../redux/features/client-main/client-insurance-slice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Props {
  onSubmit?: () => void;
  deleteInsuranceProviderById?: (id: string) => Promise<void>;
}

const InsuranceDetails: React.FC<Props> = ({
  onSubmit,
  deleteInsuranceProviderById,
}) => {
  // #region variable region
  //used for storing error message
  const [errorMessage, setErrorMessage] = useState<string>("");

  //used to check for insurance error
  const [hasErrorInsurance, setHasErrorInsurance] = useState<boolean>(false);

  //store active steps
  const { activeStep } = useSelector((state: RootState) => state.activeStep);

  //used for storing editing state
  const { isEditClient } = useSelector((state: RootState) => state.client);

  //used for error handling
  const [hasError, setHasError] = useState<boolean>(false);

  //stores insurance providers data
  const insuranceProviders = useSelector<RootState, InsuranceProvider[]>(
    (state) => state.clientInsuranceSlice.clientInsurance
  );

  //stores input value
  const [inputValue, setInputValue] = useState<string>("");

  //stores new insurance index
  const [newInsuranceIndex, setNewInsuranceIndex] = useState<number>(0);

  //handles input show for new insurance
  const [inputShow, setInputShow] = useState<boolean>(false);

  //stores clients insurance data
  const clientInsurances = useSelector<RootState, InsuranceProviders[]>(
    (state) => state.client.clientInsurance
  );

  // dispatch for redux
  const dispatch = useDispatch<AppDispatch>();
  const dispatchAction = bindActionCreators(
    {
      getClientInsuranceProviders,
    },
    dispatch
  );
  const dispatchActionRef = useRef(dispatchAction);

  // #endregion

  // #region methods region

  //used for submit insurance details
  const handleSubmit = () => {
    setHasError(false);
    let primaryCount = 0;
    let nameCount = 0;
    clientInsurances.forEach((item: InsuranceProviders) => {
      if (!item.insuranceProviderName) {
        nameCount++;
      }
    });
    clientInsurances.forEach((item: InsuranceProviders) => {
      if (item.isPrimary) {
        primaryCount++;
      }
    });
    if (primaryCount === 0) {
      setHasError(true);
      setErrorMessage("At least one provider should be primary");
    } else if (primaryCount > 1) {
      setHasError(true);
      setErrorMessage("Only one insurance provider can be primary");
    } else if (nameCount > 0) {
      setHasError(true);
      setErrorMessage("Insurance provider name cannot be empty");
    } else {
      if (isEditClient) {
        if (onSubmit) {
          onSubmit();
        }
      } else {
        dispatch(setActiveSteps(activeStep + 1));
      }
    }
  };

  //used for adding new insurance
  const handleAddNewInsurance = (): void => {
    let count: number = 0;
    while (count === 0) {
      for (let i = 0; i < clientInsurances.length; i++) {
        if (!clientInsurances[i].insuranceId) {
          count = 1;
        }
      }
      break;
    }
    if (count === 0) {
      if (hasError) {
        setHasError(false);
      }
      dispatch(addNewInsuranceProvider({}));
    } else {
      setHasError(true);
      setErrorMessage("Please select Insurance Provider");
      setTimeout(() => {
        setHasError(false);
        setErrorMessage("");
      }, 2000);
    }
  };

  //used for reoving focus
  const removeFocus = (id: string) => {
    document.getElementById(id)?.blur();
  };

  // #endregion

  // #region useEffect region
  useEffect(() => {
    dispatchActionRef.current.getClientInsuranceProviders();
  }, []);

  // #endregion

  return (
    <Box className="stepperForm">
      <ValidatorForm onSubmit={handleSubmit}>
        <div className="stepperForm__inner">
          <Grid container spacing={2} className="InsuranceHeader">
            <Grid size={{ xs: 12, lg: 6 }}>
              <h1 className="stepperForm__title">Insurance Information</h1>
            </Grid>
            <Grid size={{ xs: 12, lg: 6 }} className="InsuranceHeader__btn">
              <Button
                onClick={() => {
                  handleAddNewInsurance();
                }}
              >
                + Add Client Insurance
              </Button>
            </Grid>
          </Grid>
          <Box className="fromGroup Insurance-form">
            <Grid container spacing={3}>
              <Grid size={{ xs: 6, sm: 6, lg: 6 }}>
                <InputLabel id="demo-simple-select-label" className="fromLabel">
                  Insurance Provider
                </InputLabel>
              </Grid>
              <Grid size={{ xs: 2, sm: 2, lg: 2 }}>
                <InputLabel id="demo-simple-select-label" className="fromLabel">
                  Primary
                </InputLabel>
              </Grid>
              <Grid size={{ xs: 2, sm: 2, lg: 2 }}>
                <InputLabel id="demo-simple-select-label" className="fromLabel">
                  Secondary
                </InputLabel>
              </Grid>
              <Grid size={{ xs: 2, sm: 2, lg: 2 }}></Grid>
            </Grid>

            {clientInsurances.map((item: InsuranceProviders, index: number) => (
              <Grid
                container
                spacing={3}
                alignItems="center"
                sx={{ mb: "10px" }}
                key={index}
              >
                <Grid size={{ xs: 6, sm: 6, lg: 6 }}>
                  {/* Insurance Provider Dropdown */}
                  <Select
                    className="form-control"
                    value={
                      insuranceProviders.find(
                        (inc) =>
                          inc.insuranceProviderName ===
                          clientInsurances[index].insuranceProviderName
                      )?.id
                      // clientInsurances[index].insuranceId ||
                      // clientInsurances[index].insuranceProviderName
                    }
                    name="clientInsurance"
                    id="clientInsurance"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        removeFocus("clientInsurance");
                      }
                    }}
                    onChange={(e: SelectChangeEvent<string>) => {
                      //find item in InsuranceProvider List
                      const item: InsuranceProvider | undefined =
                        insuranceProviders.find(
                          (item: InsuranceProvider) =>
                            item.id === e.target.value
                        );

                      setHasError(false);
                      if (item) {
                        //find clientInsurance in clientInsurance List
                        const clientInsuranceId:
                          | InsuranceProviders
                          | undefined = clientInsurances.find(
                          (val) => val.insuranceId === e.target.value
                        );

                        if (!clientInsuranceId) {
                          dispatch(
                            setClientInsurance({
                              index: index,
                              insuranceId: item.id,
                              insuranceProviderName: item.insuranceProviderName,
                            })
                          );
                        } else {
                          setHasErrorInsurance(true);
                        }
                      }
                    }}
                    MenuProps={MenuProps}
                  >
                    {insuranceProviders.map((item: InsuranceProvider) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.insuranceProviderName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid size={{ xs: 2, sm: 2, lg: 2 }}>
                  <Box className="checkBox-group">
                    <FormControlLabel
                      label={""}
                      control={
                        <Checkbox
                          checked={item.isPrimary}
                          value={item.isPrimary}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setHasError(false);
                            dispatch(
                              setClientInsurancePrimary({
                                index: index,
                              })
                            );
                          }}
                        />
                      }
                    />
                  </Box>
                </Grid>
                <Grid size={{ xs: 2, sm: 2, lg: 2 }}>
                  <Box className="checkBox-group">
                    <FormControlLabel
                      label={""}
                      control={
                        <Checkbox
                          checked={!item.isPrimary}
                          value={item.isPrimary}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setHasError(false);
                            dispatch(
                              setClientInsurancePrimary({
                                index: index,
                              })
                            );
                          }}
                        />
                      }
                    />
                  </Box>
                </Grid>
                <Grid size={{ xs: 2, sm: 2, lg: 2 }}>
                  <Box className="detailsPageHeader__btn">
                    <Button
                      variant="contained"
                      startIcon={<DeleteIcon />}
                      className="button"
                      onClick={() => {
                        if (clientInsurances.length > 0) {
                          if (item.id) {
                            if (deleteInsuranceProviderById) {
                              deleteInsuranceProviderById(item.id);
                            }
                          } else {
                            dispatch(
                              deleteClientInsurance({
                                index: index,
                              })
                            );
                          }
                        } else {
                          setHasError(true);
                          setErrorMessage(
                            "At least one insurance provider should be added"
                          );
                          setTimeout(() => {
                            setHasError(false);
                            setErrorMessage("");
                          }, 2000);
                        }
                      }}
                    ></Button>
                  </Box>
                </Grid>
              </Grid>
            ))}
            {hasErrorInsurance && (
              <FormHelperText style={{ color: "#d32f2f" }}>
                "You have already added Insurance Provider please choice
                antherOne"
              </FormHelperText>
            )}
            {/* handle custom input for insurance provider */}
            {inputShow && (
              <Grid container spacing={3}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <TextField
                    autoFocus={true}
                    type="text"
                    placeholder={"Enter new insurance & hit enter key"}
                    value={inputValue}
                    id="input"
                    onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                      if (e.key === "Enter" && inputValue?.length > 0) {
                        document.getElementById("input")?.blur();

                        const newInsuranceList: InsuranceProvider[] = [
                          ...insuranceProviders,
                        ];
                        if (inputValue.trim().length > 0) {
                          const newInsuranceProvider: InsuranceProvider = {
                            id: `${newInsuranceIndex}`,
                            insuranceProviderName: inputValue,
                          };
                          newInsuranceList.push(newInsuranceProvider);
                          dispatch(
                            setUpdatedInsuranceProviders(newInsuranceList)
                          );

                          dispatch(
                            setClientInsurance({
                              index: newInsuranceIndex,
                              insuranceId: `${newInsuranceIndex}`,
                              insuranceProviderName: inputValue,
                            })
                          );
                          setInputShow(false);
                          setNewInsuranceIndex(newInsuranceIndex + 1);
                          setInputValue("");
                          setHasError(false);
                        }
                      }
                    }}
                    onChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      if (!(e.target.value[0] === " ")) {
                        setInputValue(e.target.value);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
          {hasError && !inputValue && (
            <FormHelperText style={{ color: "#d32f2f" }}>
              {errorMessage}
            </FormHelperText>
          )}
        </div>
        <Box className="stepperForm__footer">
          {!isEditClient ? (
            <Button
              type="submit"
              color="inherit"
              disabled={activeStep === 0 ? true : false}
              className="border-button"
              onClick={() => dispatch(setActiveSteps(activeStep - 1))}
            >
              Previous
            </Button>
          ) : (
            ""
          )}

          <Box sx={{ flex: "1 1 auto" }} />

          <Button
            type="submit"
            className="button"
            onClick={() => setHasError(true)}
          >
            {isEditClient ? "Save Changes" : "Next"}
          </Button>
        </Box>
      </ValidatorForm>
    </Box>
  );
};

export default InsuranceDetails;
