import React, { useEffect, useState, useMemo } from "react";
import { useUser, useDescope } from "@descope/react-sdk";
import {
  Paper,
  Table,
  styled,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  tableCellClasses,
  Button,
  Dialog,
  Box,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  Tooltip,
  Checkbox,
  ListItemText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { FormControl } from "@mui/base";
import { Add, BorderColor, Close, Delete, Send } from "@mui/icons-material";
import moment from "moment";

import { useAppSelector, useAppDispatch } from "../../redux/store";
import { setResponseValue } from "../../redux/features/api-response/api-response";
import {
  updateUserProfile,
  createUserProfile,
  getAllUserProfiles,
  deleteMultipleUserProfile,
  inviteMultipleUser,
} from "../../redux/features/user-slice";
import {
  resetInitialState,
  setUserStateValue,
} from "../../redux/features/user/add-user-slice";
import {
  switchTenantForUser,
  getAllDescopeRoles,
} from "../../redux/features/descope/descope-slice";
import { setBusinessUnitsDataChange } from "../../redux/features/business-unit/business-unit";

import { getRolesForTenant } from "../../utils/getRolesForTenant";

import UserManagementDeleteConfirmation from "../../layouts/delete-confirmation/UserManagementDeleteConfirmation";
import AddUser from "./AddUser";

import { OrgBU } from "../../types/company";
import { Role } from "../../types/descope";

import { UserStatus } from "../../constants/company-user";

import { filterUsers } from "../../helpers/userManagement-filter-helper/user-filter-helper";

import ProfileImg from "../../assets/images/defaultProfile.png";

import "./UserManagement.scss";
import "../providers/provider/Providers.scss";
import { IUserProfile } from "../../types/user";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  padding: "5px 20px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF6FF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    height: "40px",
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
  height: "50px",
}));

const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export interface IFilterUserManagement {
  businessUnits: string[];
  role: string[];
  status: string[];
}

interface IUser {
  id: string;
  authUserId: string;
  authRole: string;
  firstName: string;
  lastName: string;
  email: string;
}

const UserManagement: React.FC = () => {
  // #region variables region

  //loader controller
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // const users = useSelector((state: RootState) => state)
  //users change
  const [usersChange, setUsersChange] = useState<boolean>(false);

  //handles delete popup
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  //confirmation link user to child popup
  const [confirmationPopUp, setConfirmationPopUp] = useState<boolean>(false);

  /* Descope Integration */
  const { user } = useUser();
  const sdk = useDescope();

  // const currentTenantId = user?.customAttributes?.currentTenantId;
  const [currentTenantId, setCurrentTenant] = useState<string>(
    user?.customAttributes?.currentTenantId
  );
  //access current Roles
  const currentRoles = useMemo(() => {
    return getRolesForTenant(user, currentTenantId);
  }, [user, currentTenantId]);

  //stores the delete user id
  const [deleteUser, setDeleteUser] = useState<
    {
      id: string;
      firstName: string;
      lastName: string;
      email: string;
    }[]
  >([]);

  //stores the delete user email
  // eslint-disable-next-line
  // const [deleteUserEmail, setDeleteUserEmail] = useState<string>("");

  //handles add/edit user
  const [isEdit, setIsEdit] = useState<boolean>(false);

  //controls visibility of add user form
  const [isAddOpen, setIsAddOpen] = useState<boolean>(false);

  //select and un-select multiple users
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);

  //----------------------------------------------------------
  //user data from redux
  const userData = useAppSelector<IUserProfile>(
    (state) => state.companyUserSlice
  );

  //search value
  const { searchValue } = useAppSelector((state) => state.activeStep);

  //business Unit
  const businessUnit = useAppSelector<OrgBU[]>(
    (state) => state.businessUnitsSlice.businessUnits
  );

  //Roles
  const roles = useAppSelector<Role[]>(
    (state) => state.descopeSlice.tenantRoles
  );

  const [filterValues, setFilterValues] = useState<IFilterUserManagement>({
    businessUnits: [],
    role: [],
    status: [],
  });

  //dispatch for redux
  const dispatch = useAppDispatch();

  // #endregion

  // #region methods region

  //redux for all tenantUsers from ABA UserProfile
  const tenantUsers = useAppSelector((state) => state.user.tenantUsers);
  const filteredUsers = filterUsers(filterValues, searchValue, tenantUsers);

  //Descope Migration
  const handleSwitchTenant = async (tenantId: string) => {
    if (tenantId) {
      try {
        //update user.currentTenantId first!!!
        await dispatch(
          switchTenantForUser({
            userId: user.userId,
            email: user.email!,
            tenantId: tenantId,
          })
        );

        //update token currentTenantId & refresh token in cookie
        await sdk.selectTenant(tenantId);
        await sdk.refresh(sdk.getRefreshToken());
        await sdk.me();

        dispatch(setBusinessUnitsDataChange(null));

        setUsersChange(true); //must call this!!!
        setIsLoading(false);

        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: "Switch Tenant Successfully!",
          })
        );
      } catch (error: any) {
        console.error("Error switching tenant:", error);
        dispatch(setResponseValue({ name: "error", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: error?.response?.data?.message || error?.message,
          })
        );
      } finally {
        dispatch(setResponseValue({ name: "pending", value: false }));
      }
    }
  };

  /* Code Review: 
      - Hui, 1.10.2024
  */
  //handle Remove user
  // const handleRemoveUser = async (value: boolean, type: boolean) => {
  //   setIsDelete(false);
  //   if (value) {
  //     await dispatch(
  //       deleteUserProfile({
  //         id: deleteUser?.id,
  //         email: deleteUser?.email,
  //         authTenantId: currentTenantId,
  //         permanentDelete: type,
  //       })
  //     );

  //     setUsersChange(true);
  //   }
  // };

  //handles multiple user remove
  const handleRemoveMultipleUser = async (
    value: boolean,
    type: boolean,
    userIds: string[]
  ) => {
    setIsDeleteModalOpen(false);
    if (value) {
      await dispatch(
        deleteMultipleUserProfile({
          ids: userIds,
          authTenantId: currentTenantId,
          permanentDelete: type,
        })
      );
      setUsersChange(true);
      setSelectedUsers([]);
    }
  };

  /* Code Review: 
      - Hui, 1.10.2024
  */
  //handle Edit user
  const handleEditUser = async () => {
    if (userData.authRole === "Parent") {
      await dispatch(
        updateUserProfile({
          id: userData.id,
          email: userData.email,
          firstName: userData.firstName,
          lastName: userData.lastName,
          role: userData.authRole,
          authTenantId: currentTenantId,
          businessUnit: userData.businessUnit,
          clientIds: userData?.clientIds,
        })
      );
    } else {
      await dispatch(
        updateUserProfile({
          id: userData.id,
          email: userData.email,
          firstName: userData.firstName,
          lastName: userData.lastName,
          role: userData.authRole,
          authTenantId: currentTenantId,
          businessUnit: userData.businessUnit,
        })
      );
    }
    setUsersChange(true);
  };

  /* Code Review: 
      - Hui, 1.10.2024
  */
  //handles invitation link
  const handleInvite = async (): Promise<void> => {
    if (userData.authRole === "Parent") {
      await dispatch(
        createUserProfile({
          authTenantId: currentTenantId,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          role: userData.authRole,
          businessUnit: userData.businessUnit,
          caller: user.name as string,
          clientIds: userData.clientIds,
        })
      );
    } else {
      await dispatch(
        createUserProfile({
          authTenantId: currentTenantId,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          role: userData.authRole,
          businessUnit: userData.businessUnit,
          caller: user.name as string,
        })
      );
    }

    setUsersChange(true);
  };

  //handles multiple re-send invitation
  const resendInvitation = async (ids: string[]) => {
    await dispatch(inviteMultipleUser({ ids }));
    setUsersChange(true);
  };

  const handleChange = ({
    name,
    value,
  }: {
    name: string;
    value: string[];
  }): void => {
    if (name === "businessUnits") {
      if (value.includes("all")) {
        if (filterValues.businessUnits.length !== businessUnit.length) {
          return setFilterValues((previousFilter) => {
            return {
              ...previousFilter,
              businessUnits: businessUnit.map((bu) => bu.businessUnitName),
            };
          });
        }
        return setFilterValues((previousFilter) => {
          return {
            ...previousFilter,
            businessUnits: [],
          };
        });
      }
      return setFilterValues((previousFilter) => {
        return { ...previousFilter, businessUnits: value };
      });
    }
    if (name === "role") {
      if (value.includes("all")) {
        if (filterValues.role.length !== roles.length) {
          return setFilterValues((previousFilter) => {
            return {
              ...previousFilter,
              role: roles.map((bu) => bu.name),
            };
          });
        }
        return setFilterValues((previousFilter) => {
          return {
            ...previousFilter,
            role: [],
          };
        });
      }
      setFilterValues((previousFilter) => {
        return { ...previousFilter, role: value };
      });
    }
    if (name === "status") {
      const status = Object.values(UserStatus);
      if (value.includes("all")) {
        if (filterValues.status.length !== status.length) {
          return setFilterValues((previousFilter) => {
            return {
              ...previousFilter,
              status: status.map((ele) => ele),
            };
          });
        }
        return setFilterValues((previousFilter) => {
          return {
            ...previousFilter,
            status: [],
          };
        });
      }
      setFilterValues((previousFilter) => {
        return { ...previousFilter, status: value };
      });
    }

    if (name === "")
      setFilterValues((previousFilter) => {
        return { ...previousFilter };
      });
  };

  //handles user select
  const handleUserSelect = (user: IUser) => {
    if (selectedUsers.includes(user)) {
      setSelectedUsers(
        selectedUsers.filter((selectedUser) => selectedUser !== user)
      );
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  //handles select all users
  const handleSelectAll = () => {
    const invitedUsers = tenantUsers.filter(
      (user: { authRole: string }) => user.authRole !== "Owner"
    );
    if (selectedUsers.length === invitedUsers.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(invitedUsers);
    }
  };

  //handles send invitations
  const handleSendEmails = (userIds: string[]) => {
    resendInvitation(userIds);
    setSelectedUsers([]);
  };

  // #endregion
  //#region UseEffect region
  useEffect(() => {
    const populateData = async () => {
      await dispatch(
        getAllUserProfiles({
          authTenantId: currentTenantId,
          callerEmail: user.email as string,
          callerName: user.name as string,
        })
      );
      setIsLoading(false);
      setUsersChange(false);
    };

    if (user && (isLoading === true || usersChange === true)) {
      setTimeout(() => populateData(), 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, usersChange, isLoading, currentTenantId]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getAllDescopeRoles());
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // #endregion

  // console.log("user", currentTenantId, user, isLoading, currentTenantId);

  return (
    <>
      <div className="pageTemplate">
        <Grid container spacing={3}>
          <Grid size={{ xs: 12 }}>
            <Typography variant="h6" className="UserManagementTitle">
              User Management
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 2 }}>
            <Box
              className="fromGroup"
              style={{
                width: "200px",
                marginBottom: "22px",
                display: "flex",
                gap: "10px",
              }}
            >
              <FormControl>
                <InputLabel className="fromLabel">Current Company</InputLabel>
                <Select
                  className="form-control"
                  value={currentTenantId}
                  onChange={(e: SelectChangeEvent<string>) => {
                    const newTenant = user?.userTenants?.find(
                      (item) => item.tenantId === e.target.value
                    );
                    if (newTenant) {
                      setIsLoading(true);
                      setCurrentTenant(e.target.value);
                      handleSwitchTenant(e.target.value);
                    }
                  }}
                  MenuProps={MenuProps}
                  style={{ width: "200px", height: "40px" }}
                >
                  {
                    /* below code is robust to ensure uncessary visibility & switch */
                    user?.userTenants
                      ?.filter(
                        (userTenant) =>
                          userTenant.roleNames?.includes("Owner") ||
                          userTenant.roleNames?.includes("Admin") ||
                          userTenant.roleNames?.includes("ABAEngine Admin")
                      )
                      ?.map((tenant) => {
                        return (
                          <MenuItem
                            key={tenant.tenantId}
                            value={tenant.tenantId}
                          >
                            {tenant.tenantName}
                          </MenuItem>
                        );
                      })
                  }
                </Select>
              </FormControl>

              {/* temporary hide business unit filter
              <FormControl>
                <InputLabel className="fromLabel">Business Unit</InputLabel>
                <Select
                  multiple
                  value={filterValues?.businessUnits}
                  placeholder="All BU"
                  MenuProps={MenuProps}
                  onChange={(e: any) => {
                    handleChange({
                      name: "businessUnits",
                      value: e.target.value,
                    });
                  }}
                  renderValue={(selected: any) => {
                    return selected.length
                      ? selected.join(", ")
                      : "Business Unit";
                  }}
                  style={{ width: "200px", height: "40px" }}
                  labelId="business-unit-label"
                >
                  <MenuItem key="all" value="all">
                    <Checkbox
                      checked={
                        filterValues.businessUnits.length ===
                        businessUnit.length
                      }
                    />
                    <ListItemText primary="All BU" />
                  </MenuItem>
                  {businessUnit.map((item, index) => (
                    <MenuItem key={index} value={item.businessUnitName}>
                      <Checkbox
                        checked={
                          filterValues.businessUnits?.indexOf(
                            item.businessUnitName
                          ) > -1
                        }
                      />
                      <ListItemText primary={item.businessUnitName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}

              <FormControl>
                <InputLabel className="fromLabel">Role</InputLabel>
                <Select
                  className="form-control"
                  value={filterValues?.role}
                  multiple
                  MenuProps={MenuProps}
                  onChange={(e: any) => {
                    handleChange({
                      name: "role",
                      value: e.target.value,
                    });
                  }}
                  renderValue={(selected: any) => {
                    return selected.join(", ");
                  }}
                  style={{ width: "200px", height: "40px" }}
                >
                  <MenuItem key="all" value="all">
                    <Checkbox
                      checked={filterValues.role.length === roles.length}
                    />
                    <ListItemText primary="All Roles" />
                  </MenuItem>
                  {roles.map((item, index) => (
                    <MenuItem key={index} value={item.name}>
                      <Checkbox
                        checked={filterValues.role?.indexOf(item.name) > -1}
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel className="fromLabel">Status</InputLabel>
                <Select
                  className="form-control"
                  value={filterValues?.status}
                  multiple
                  MenuProps={MenuProps}
                  onChange={(e: any) => {
                    handleChange({
                      name: "status",
                      value: e.target.value,
                    });
                  }}
                  renderValue={(selected: any) => {
                    return selected.join(", ");
                  }}
                  style={{ width: "200px", height: "40px" }}
                >
                  <MenuItem key="all" value="all">
                    <Checkbox
                      checked={
                        filterValues.status.length ===
                        Object.values(UserStatus).length
                      }
                    />
                    <ListItemText primary="All Statuses" />
                  </MenuItem>
                  {Object.values(UserStatus).map((item, index) => (
                    <MenuItem key={index} value={item}>
                      <Checkbox
                        checked={filterValues.status?.indexOf(item) > -1}
                      />
                      <ListItemText
                        primary={
                          item === UserStatus.Activated //"enabled"
                            ? "Activated"
                            : item === UserStatus.Disabled
                            ? "Disabled"
                            : item === UserStatus.Invited
                            ? "Invited"
                            : ""
                        }
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid size={{ xs: 12, md: 10 }} textAlign="right">
            {currentRoles?.includes("Owner") ||
            currentRoles?.includes("Admin") ||
            currentRoles?.includes("ABAEngine Admin") ? (
              <Button
                type="submit"
                variant="contained"
                startIcon={<Add />}
                className="button"
                onClick={() => setIsAddOpen(true)}
                style={{ marginTop: "0" }}
              >
                Add User
              </Button>
            ) : null}
          </Grid>

          <Grid size={{ xs: 12 }} className="listingTable">
            <TableContainer component={Paper} className="tableResponsive">
              <StyledTable
                sx={{
                  minWidth: 820,
                  borderCollapse: "separate",
                  cursor: "pointer",
                }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Checkbox
                        checked={
                          selectedUsers.length > 0 &&
                          selectedUsers.length ===
                            tenantUsers.filter(
                              (user: { authRole: string }) =>
                                user.authRole !== "Owner"
                            ).length
                        }
                        onChange={handleSelectAll}
                        color="primary"
                        inputProps={{
                          "aria-label": "select all invited users",
                        }}
                      />
                      {selectedUsers.length > 0 && (
                        <>
                          <Send
                            style={{ color: "#096dd9" }}
                            onClick={() =>
                              handleSendEmails(
                                selectedUsers.map((user) => user.id)
                              )
                            }
                          />
                          <Delete
                            style={{ color: "#096dd9" }}
                            onClick={() => {
                              setDeleteUser([...selectedUsers]);
                              setIsDeleteModalOpen(true);
                            }}
                          />
                        </>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>Role</StyledTableCell>
                    <StyledTableCell>Last Login</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Business Unit</StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tenantUsers?.length > 0 &&
                    filterUsers(filterValues, searchValue, tenantUsers).map(
                      (user: any, index: number) => {
                        return (
                          <StyledTableRow
                            style={{ borderRadius: 20 }}
                            key={index}
                          >
                            <StyledTableCell>
                              <Checkbox
                                disabled={user.authRole === "Owner"}
                                color="primary"
                                inputProps={{
                                  "aria-label": "select all desserts",
                                }}
                                style={{
                                  display:
                                    user.authRole === "Owner"
                                      ? "none"
                                      : "inline-flex",
                                }}
                                checked={selectedUsers.includes(user)}
                                onChange={() => handleUserSelect(user)}
                              />
                            </StyledTableCell>
                            <StyledTableCell component="th">
                              <div className="userList-name">
                                <img
                                  src={
                                    user?.picture?.length > 0
                                      ? user?.picture
                                      : ProfileImg
                                  }
                                  className="userSmallCircle"
                                  alt=""
                                />
                                <span style={{ marginLeft: 10 }}>
                                  {user.firstName} {user.lastName}
                                </span>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell>{user.email}</StyledTableCell>
                            <StyledTableCell scope="row">
                              {user.authRole}
                            </StyledTableCell>
                            <StyledTableCell>
                              {user.updatedAt &&
                                `${moment(user.updatedAt).format("lll")}`}
                            </StyledTableCell>
                            <StyledTableCell>
                              {/* {user?.emailVerified ?  "Activated" : "Pending"} */}
                              {user?.status === "enabled"
                                ? "activated"
                                : user?.status}
                              {/* turn off this email activation flow due to FrontEgg limitation 5.30.2023
                              !(user.activatedForTenant && user.verifiedEmail) && (
                                <span
                                  className="colIcon"
                                  onClick={() => {
                                    resendInvitation(user.email);
                                  }}
                                >
                                  <RefreshMail />
                                </span>
                                )*/}
                            </StyledTableCell>

                            <StyledTableCell>
                              {user.businessUnit}
                            </StyledTableCell>
                            {currentRoles?.includes("Owner") ||
                            currentRoles?.includes("Admin") ||
                            currentRoles?.includes("ABAEngine Admin") ? (
                              <StyledTableCell align="right">
                                <div className="setDate">
                                  {/* frontegg version {!user?.emailVerified && ( */}
                                  {
                                    /* descope version */
                                    user?.status === "invited" && (
                                      <span>
                                        <Tooltip
                                          title="Send Invitation Email"
                                          placement="top-start"
                                        >
                                          <Send
                                            onClick={() =>
                                              handleSendEmails([user.id])
                                            }
                                          />
                                        </Tooltip>
                                      </span>
                                    )
                                  }
                                  <span>
                                    <BorderColor
                                      onClick={() => {
                                        if (tenantUsers) {
                                          const selectedUserProfile =
                                            filteredUsers[index];
                                          dispatch(
                                            setUserStateValue({
                                              id: selectedUserProfile?.id,
                                              firstName:
                                                selectedUserProfile?.firstName,
                                              lastName:
                                                selectedUserProfile?.lastName,
                                              businessUnit:
                                                selectedUserProfile?.businessUnit,
                                              email: selectedUserProfile?.email,
                                              emailVerified:
                                                selectedUserProfile?.emailVerified,

                                              authTenantId:
                                                selectedUserProfile?.authTenantId,
                                              authUserId:
                                                selectedUserProfile?.authUserId,
                                              authRole:
                                                selectedUserProfile?.authRole,

                                              createdBy:
                                                selectedUserProfile?.createdBy,
                                              updatedBy:
                                                selectedUserProfile?.updatedBy,
                                              profilePictureUrl:
                                                selectedUserProfile?.profilePictureUrl,

                                              createdAt:
                                                selectedUserProfile?.createdAt,
                                              updatedAt:
                                                selectedUserProfile?.updatedAt,
                                              status: "",
                                              clientIds:
                                                selectedUserProfile.clientIds,
                                            })
                                          );
                                        }
                                        setIsAddOpen(true);
                                        setIsEdit(true);
                                      }}
                                    />
                                  </span>
                                  <span>
                                    {/* do not allow to delete owner */}
                                    {user?.authRole !== "Owner" && (
                                      <Delete
                                        onClick={() => {
                                          setDeleteUser([
                                            {
                                              id: user.id,
                                              firstName: user.firstName,
                                              lastName: user.lastName,
                                              email: user.email,
                                            },
                                          ]);
                                          setIsDeleteModalOpen(true);
                                        }}
                                      />
                                    )}
                                  </span>
                                </div>
                              </StyledTableCell>
                            ) : null}
                          </StyledTableRow>
                        );
                      }
                    )}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </Grid>
          {isAddOpen && (
            <Dialog
              open={isAddOpen}
              onClose={() => {
                dispatch(resetInitialState(null));
                setIsAddOpen(false);
              }}
              className="largeModel"
            >
              <Box className="largeModel__head">
                <Typography variant="h5" className="largeModel__title">
                  {isEdit ? "Edit User" : "Add New User"}
                </Typography>
                <Button
                  className="largeModel__closeBtn"
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    if (isEdit) {
                      setIsEdit(false);
                    }
                    dispatch(resetInitialState(null));
                    setIsAddOpen(false);
                  }}
                >
                  <Close />
                </Button>
              </Box>
              <Box className="stepperForm">
                <AddUser
                  isEdit={isEdit}
                  dialogClose={(
                    id: string,
                    firstName: string,
                    lastName: string,
                    email: string,
                    role: string,
                    businessUnit: string,
                    clientIds?: string[]
                  ) => {
                    setIsLoading(true);
                    if (clientIds?.length) {
                      setIsAddOpen(false);
                      setConfirmationPopUp(true);
                      return;
                    }
                    if (isEdit) {
                      handleEditUser();
                      setIsEdit(false);
                    } else {
                      //invite user, caller must be login first!
                      handleInvite();
                    }
                    dispatch(resetInitialState(null));
                    setIsAddOpen(false);
                  }}
                />
              </Box>
            </Dialog>
          )}
          {confirmationPopUp && (
            <Dialog open={true} className="smallModel">
              <div>
                <DialogContent>
                  Are you sure you want grant User Permission ( {userData.email}{" "}
                  ) to access this Client Data?
                </DialogContent>

                <DialogActions>
                  <Button
                    className="border-button"
                    variant="contained"
                    onClick={(
                      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      e.preventDefault();
                      setConfirmationPopUp(false);
                    }}
                    color="secondary"
                  >
                    No
                  </Button>
                  <Button
                    className="button"
                    variant="contained"
                    onClick={(
                      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      e.preventDefault();
                      if (isEdit) {
                        handleEditUser();
                      } else {
                        handleInvite();
                      }
                      setConfirmationPopUp(false);
                      dispatch(resetInitialState(null));
                    }}
                  >
                    Yes
                  </Button>
                </DialogActions>
              </div>
            </Dialog>
          )}
          {isDeleteModalOpen && (
            <UserManagementDeleteConfirmation
              setModal={(value: boolean, type: boolean) => {
                if (value) {
                  setIsLoading(true);
                }
                //inside: do all the tasks
                // handleRemoveUser(value, type);
                handleRemoveMultipleUser(
                  value,
                  type,
                  deleteUser.map((user) => user.id)
                );
              }}
              userDetails={deleteUser}
            />
          )}
        </Grid>
      </div>
    </>
  );
};

export default UserManagement;
