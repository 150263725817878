import {
  IPlanDetail,
  IPlanListData,
  ISchedules,
  INodes,
  ICreatePlan,
  IPlannerInfo,
  IPlanningFilter,
  //IPlanChart,
} from "../types/planning";

export const defaultPlan: ICreatePlan = {
  planName: "",
  providerList: [],
  clientList: [],
  // schedulesIds: [],
};

export const INodesPlan: INodes = {
  id: "",
  data: {
    label: "",
    super: false,
    profile: {
      // url: "",
      // key: "",
      // containerName: "",
      // fileName: "",
      // fileId: "",
    },
    bookedHours: "",
    remainingHours: "",
  },
  position: {
    x: 0,
    y: 0,
  },
  type: "",
};

export const planner: IPlannerInfo = {
  sub: "",
  name: "",
  email: "",
  email_verified: false,
  metadata: {},
  roles: [],
  permissions: [],
  tenantId: "",
  tenantIds: [],
  profilePictureUrl: "",
  sid: "",
  type: "",
  aud: "",
  iss: "",
  iat: 0,
  exp: 0,
  id: "",
};

export const schedules: ISchedules = {
  id: "",
  frontegg_tenantId: "",
  descope_tenantId: "",
  bookedProviderId: {
    id: "",
    frontegg_tenantId: "",
    descope_tenantId: "",
    firstName: "",
    lastName: "",
    businessUnit: "",
    employmentStatus: "",
    languages: [],
    type: "",
    providerType: "Onboarding",
    onBoardingStage: "",
    checklistHistory: [],
    files: {},
    providerProfile: {},
    isSupervisor: false,
    createdAt: "",
    updatedAt: "",
  },
  bookedClientId: {
    id: "",
    frontegg_tenantId: "",
    descope_tenantId: "",
    parentFirstName: "",
    parentLastName: "",
    childFirstName: "",
    childLastName: "",
    childDateOfBirth: "",
    childGender: "",
    childLanguages: [],
    businessUnit: "",
    clientType: "",
    onTrackStage: "",
    checklistHistory: [],
    intakeStage: "",
    concerns: "",
    hearAboutUs: "",
    files: null,
    clientProfile: {},
    stage: "",
    createdAt: "",
    updatedAt: "",
  },
  status: "",
  specificDate: "",
  fromTime: "",
  toTime: "",
  repeatWorkDay: "",
  repeatEndsOn: "",
  repeatService: 0,
  serviceType: "",
  serviceLocation: "",
  description: "",
  authId: "",
  authCode: "",
  whoCancelled: null,
  cancellationReason: null,
  createdBy: "",
  createdAt: "",
  updatedAt: "",
  session: null
};

// export const defaultPlanChart: IPlanChart = {
//   Edges: [
//     {
//       id: "",
//       source: "",
//       target: "",
//     },
//   ],

//   Nodes: [
//     {
//       id: "",
//       data: {
//         label: "",
//         super: false,
//         profile: {
//           url: "",
//           key: "",
//           containerName: "",
//           fileName: "",
//           fileId: "",
//         },
//         bookedHours: "",
//         remainingHours: "",
//       },
//       position: {
//         x: 0,
//         y: 0,
//       },
//       type: "",
//     },
//   ],
//   planDeatils: {
//     id: "",
//     status: false,
//     planName: "",
//     planner: planner,
//     providers: [],
//     clients: [],
//     schedules: null,
//     // supervisor: {
//     //   id: "",
//     //   planName: "",
//     //   planner: {
//     //     sub: "",
//     //     name: "",
//     //     email: "",
//     //     email_verified: false,
//     //     metadata: {},
//     //     roles: [],
//     //     permissions: [],
//     //     tenantId: "",
//     //     tenantIds: [],
//     //     profilePictureUrl: "",
//     //     sid: "",
//     //     type: "",
//     //     aud: "",
//     //     iss: "",
//     //     iat: 0,
//     //     exp: 0,
//     //     id: "",
//     //   },
//     //   supervisor: [
//     //     {
//     //       id: "",
//     //       frontegg_tenantId: "",
//     //       firstName: "",
//     //       lastName: "",
//     //       businessUnit: "",
//     //       employmentStatus: "",
//     //       languages: [],
//     //       type: "",
//     //       providerType: "",
//     //       onBoardingStage: "",
//     //       checklist: [],
//     //       providerProfile: {
//     //         url: "",
//     //         key: "",
//     //         containerName: "",
//     //         fileName: "",
//     //         fileId: "",
//     //       },
//     //       isSupervisor: false,
//     //       createdAt: "",
//     //       updatedAt: "",
//     //     },
//     //   ],
//     //   createdAt: "",
//     //   updatedAt: "",
//     //   status: false,
//     // },
//     createdAt: "",
//     updatedAt: "",
//     booking: 0,
//     supervisorCount: 0,
//     providerCount: 0,
//     clientCount: 0,
//     utilization: 0,
//   },
//   schedules: [schedules],
// };

export const defaultPlanDetail: IPlanDetail = {
  plan: {
    id: "",
    tenantId: "",
    planName: "",

    planner: {
      sub: "",
      name: "",
      email: "",
      email_verified: false,
      metadata: {},
      roles: [],
      permissions: [],
      tenantId: "",
      tenantIds: [],
      profilePictureUrl: "",
      sid: "",
      type: "",
      aud: "",
      iss: "",
      iat: 0,
      exp: 0,
      id: "",
    },

    providers: [],
    clients: [],
    schedules: [],
    status: false,
    createdAt: "",
    updatedAt: "",
    appliedFilters: null
  },

  supervisorCount: 0,
  providerCount: 0,
  clientCount: 0,
  pendingCount: 0,
  // bookingCount: 0,
  clientAuthUtilizationPcnt: 0,
  providerUtilizationPcnt: 0,

  Edges: [
    {
      id: "",
      source: "",
      target: "",
    },
  ],

  Nodes: [
    {
      id: "",
      data: {
        id: "",
        label: "",
        super: false,
        profile: {
          url: "",
          key: "",
          containerName: "",
          fileName: "",
          fileId: "",
        },
        bookedHours: "",
        remainingHours: "",
      },
      position: {
        x: 0,
        y: 0,
      },
      type: "",
    },
  ],
  bookingData: {},
  planName: null
};

export const defaultPlanListData: IPlanListData = {
  //remember current plan detail
  currentPlan: defaultPlanDetail,

  //remember all plans data
  allPlanList: [],

  //remember created plan data
  id: "",
  planName: "",
  providerList: [],
  clientList: [],
  // schedulesIds: [],

  //remember event flags
  planIsCreated: false,
  planIsReset: false,
  updateApi: false,

  // planIsChanged: {
  //   id: "",
  //   planName: "",
  //   planner: {
  //     sub: "",
  //     name: "",
  //     email: "",
  //     email_verified: true,
  //     metadata: {},
  //     roles: [],
  //     permissions: [],
  //     tenantId: "",
  //     tenantIds: [],
  //     profilePictureUrl: "",
  //     sid: "",
  //     type: "userToken",
  //     aud: "",
  //     iss: "",
  //     iat: 0,
  //     exp: 0,
  //     id: "",
  //   },
  //   providers: [],
  //   clients: [],
  //   status: false,
  //   createdAt: "",
  //   updatedAt: "",
  // },
};

export const FilterMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const defaultPlanningFilter: IPlanningFilter = {
  businessUnit: [],
  insuranceProvider: [],
  clientStatus: [],
  providerStatus: [],
  intakeStage: [],
  ScheduleStatus: "",
  clientConcerns: [],
  onBoardingStage: [],
  providerTasks: [],
  startDate: null,
  endDate: null,
  renderDate: "",
  providerType: [],
  providerSpeciality: [],
  months: [],
  authCode: []
};
