import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import DeleteIcon from "@mui/icons-material/Delete";
import { ValidatorForm } from "react-material-ui-form-validator";
// import FileUpload from "react-material-file-upload";
import { MuiFileInput } from "mui-file-input";
import { AppDispatch, RootState } from "../../../redux/store";
import { Credentials } from "../../../types/provider";
import { InsuranceProvider } from "../../../types/response";
import {
  deleteCredentialFiles,
  setCredentialCheckBox,
  setCredentialFiles,
  setCredentialInsuranceProvider,
  setExpirationDate,
} from "../../../redux/features/providers-main/provider-slice";
import { setUpdatedInsuranceProviders } from "../../../redux/features/client-main/client-insurance-slice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ProviderAddNewInsuranceProps {
  onSubmit: () => void;
  authIndex: number;
  activeTabForAuthorization: string;
  deleteSingleProviderInsurance: (id: string) => Promise<void>;
  providerInsurance: Credentials[];
  title: string;
}

const ProviderAddNewInsuranceProviderForm: React.FC<
  ProviderAddNewInsuranceProps
> = ({
  onSubmit,
  authIndex,
  activeTabForAuthorization,
  deleteSingleProviderInsurance,
  //deleteCredentialFile,
  providerInsurance,
  title,
}) => {
  // #region variables region

  //defined dispatch
  const dispatch = useDispatch<AppDispatch>();

  //insurance Providers Data from redux
  const insuranceProviders = useSelector<RootState, InsuranceProvider[]>(
    (state) => state.clientInsuranceSlice.clientInsurance
  );

  //handle the Error to show the warnings
  const [hasError, setHasError] = useState<boolean>(false);

  //show for warning message
  const [errorMessage, setErrorMessage] = useState<string>("");

  //duplicate error image
  // eslint-disable-next-line
  const [duplicateErrorImage, setDuplicateErrorImage] =
    useState<boolean>(false);

  //stores date input
  const [selectedDate, setSelectedDate] = useState<string>(
    providerInsurance[authIndex]?.expirationDate
  );

  //duplicate error image
  // eslint-disable-next-line
  const [duplicateErrorImageMessage, setDuplicateErrorImageMessage] =
    useState<string>("");

  //keeps track of activeTab for particular authorization
  const [activeTab, setActiveTab] = useState<string>(
    activeTabForAuthorization || "basic"
  );

  //track new added insurance index
  const [newInsuranceIndex, setNewInsuranceIndex] = useState<number>(0);

  //displays the input bar
  const [inputShow, setInputShow] = useState<boolean>(false);

  //stores the Files
  // eslint-disable-next-line
  const [files] = useState<File[]>([]);

  //store the input provider name from the input
  const [inputValue, setInputValue] = useState<string>("");

  //delete popup handler
  const [deletePopup, setDeletePopup] = useState<boolean>(false);

  //store credential Id
  const [credentialId, setCredentialId] = useState<string>("");

  //store insurance name
  const [selectedInsurance, setSelectedInsurance] = useState<{
    id: string;
    name: string;
  }>({
    id: providerInsurance[authIndex]?.insuranceId,
    name: providerInsurance[authIndex]?.name,
  });

  // #endregion

  // #region methods region

  // handle the form submition
  const handleSubmit = (e: React.FormEvent<Element>) => {
    e.preventDefault();
    let error = false;

    if (
      !selectedInsurance.name ||
      !selectedDate
      // new Date(selectedDate) < new Date()
      //turn off by Hui... relax logic to allow partial save
      //|| !item.authPeriod.endDate
      //|| !item.authPeriod.startDate
    ) {
      error = true;
    }

    //turn off by Hui... relax logic to allow partial save
    // item.auth.forEach((item: ClientAuth) => {
    //   if (!item.authCode || !item.units) {
    //     error = true;
    //   }
    // });

    if (error) {
      setHasError(true);
    } else {
      dispatch(
        setCredentialInsuranceProvider({
          index: authIndex,
          value: selectedInsurance.id,
          name: selectedInsurance.name,
        })
      );
      dispatch(
        setExpirationDate({
          index: authIndex,
          value: selectedDate,
        })
      );
      onSubmit();
    }
  };

  //handles deleting providerInsurance
  const deleteInsurance = (credentialId: string) => {
    if (providerInsurance[providerInsurance.length - 1].id !== "") {
      if (deleteSingleProviderInsurance) {
        deleteSingleProviderInsurance(credentialId);
      }
    } else {
      setErrorMessage("At least One credential should be added");
      setHasError(true);
      setTimeout(() => {
        setErrorMessage("");
        setHasError(false);
      }, 2000);
    }
    setDeletePopup(false);
  };

  //remove focus on input fields
  const removeFocus = (id: string) => {
    document.getElementById(id)?.blur();
  };

  //returns the insurance provider name
  const returnInsuranceProviderName = (id: string): string => {
    let returnName: string = "";
    insuranceProviders.forEach((item: InsuranceProvider) => {
      if (item.id === id) {
        returnName = item.insuranceProviderName;
        return;
      }
    });
    return returnName;
  };

  // #endregion

  // #region useEffect region

  // #endregion

  return (
    <>
      <Box className="stepperForm">
        <ValidatorForm
          onSubmit={(e: React.FormEvent<Element>) => handleSubmit(e)}
        >
          <div
            className="stepperForm__inner p-30px"
            style={{ marginBottom: 0 }}
          >
            <Grid container spacing={2} className="InsuranceHeader">
              <Grid size={{ xs: 12, lg: 6 }}>
                <h1 className="stepperForm__title p-0">{title}</h1>
              </Grid>
            </Grid>
            {errorMessage && hasError && (
              <FormHelperText style={{ color: "#d32f2f" }}>
                {errorMessage}
              </FormHelperText>
            )}
          </div>

          <div className="stepperForm__inner">
            <Grid container spacing={3} sx={{ position: "relative" }}>
              <Grid size={{ xs: 12, lg: 4 }}>
                <div className="authListBox">
                  <div className="authListBox__item">
                    <div className="authListBox__head">
                      <p className="authListBox__title">
                        {providerInsurance[authIndex]?.insuranceId
                          ? providerInsurance[authIndex].name
                          : "Insurance Provider"}
                      </p>
                      <span className="authListBox__removeBtn">
                        <DeleteIcon
                          onClick={() => {
                            setCredentialId(providerInsurance[authIndex].id);
                            setDeletePopup(true);
                          }}
                        />
                      </span>
                    </div>
                    <div className="authListBox__body">
                      <span
                        className={
                          activeTab === "basic"
                            ? "authListBox__link activeLink"
                            : "authListBox__link"
                        }
                        onClick={() => {
                          setActiveTab("basic");
                        }}
                      >
                        Basic
                      </span>
                      <span
                        className={
                          activeTab === "files"
                            ? "authListBox__link activeLink"
                            : "authListBox__link"
                        }
                        onClick={() => {
                          setActiveTab("files");
                        }}
                      >
                        Files
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>

              {activeTab === "basic" && (
                <Grid size={{ xs: 12, lg: 8 }}>
                  <Grid container spacing={3}>
                    <Grid size={{ xs: 12, sm: 9 }}>
                      <Box className="fromGroup">
                        <InputLabel
                          id="demo-simple-select-label"
                          className="fromLabel"
                        >
                          Insurance Provider
                        </InputLabel>
                        <Select
                          className="form-control"
                          value={
                            insuranceProviders.find(
                              (item) =>
                                item.insuranceProviderName ===
                                selectedInsurance.name
                            )?.id || ""
                          }
                          // placeholder="Select"
                          id="authInsuranceProvider"
                          onChange={(e: SelectChangeEvent<string>) => {
                            if (e.target.value !== "Insurance Provider") {
                              let name = returnInsuranceProviderName(
                                e.target.value
                              );
                              setSelectedInsurance({
                                id: e.target.value,
                                name: name,
                              });
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              removeFocus("authInsuranceProvider");
                            }
                          }}
                          MenuProps={MenuProps}
                        >
                          {insuranceProviders?.map(
                            (item: InsuranceProvider, index: number) => {
                              return (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.insuranceProviderName}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>

                        {!errorMessage &&
                        hasError &&
                        !selectedInsurance.name ? (
                          <FormHelperText style={{ color: "#d32f2f" }}>
                            Please select insurance provider
                          </FormHelperText>
                        ) : null}
                      </Box>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 3 }}>
                      <InputLabel
                        id="demo-simple-select-label"
                        className="fromLabel"
                      >
                        Complete
                      </InputLabel>
                      <Box className="checkBox-group">
                        <FormControlLabel
                          label={""}
                          control={
                            <Checkbox
                              checked={providerInsurance[authIndex]?.complete}
                              onChange={(e) => {
                                dispatch(
                                  setCredentialCheckBox({
                                    index: authIndex,
                                    value: e.target.checked,
                                  })
                                );
                              }}
                            />
                          }
                        />
                      </Box>
                    </Grid>
                    {inputShow && (
                      <Grid size={{ xs: 12, md: 9 }}>
                        <Box className="fromGroup">
                          <TextField
                            autoFocus={true}
                            type="text"
                            placeholder={"Enter new insurance & hit enter key"}
                            value={inputValue}
                            id="input"
                            onKeyDown={(
                              //e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
                              e: React.KeyboardEvent<HTMLDivElement>
                            ) => {
                              if (e.key === "Enter" && inputValue?.length > 0) {
                                document.getElementById("input")?.blur();

                                const newInsuranceList: InsuranceProvider[] =
                                  [];
                                insuranceProviders.forEach(
                                  (item: InsuranceProvider) => {
                                    const newObj: InsuranceProvider = {
                                      id: item.id,
                                      insuranceProviderName:
                                        item.insuranceProviderName,
                                    };
                                    newInsuranceList.push(newObj);
                                  }
                                );

                                newInsuranceList.push({
                                  id: `${newInsuranceIndex}`,
                                  insuranceProviderName: inputValue,
                                });
                                dispatch(
                                  setUpdatedInsuranceProviders(newInsuranceList)
                                );

                                dispatch(
                                  setCredentialInsuranceProvider({
                                    index: newInsuranceIndex,
                                    value: `${newInsuranceIndex}`,
                                    name: inputValue,
                                  })
                                );
                                setInputShow(false);
                                setNewInsuranceIndex(newInsuranceIndex + 1);
                                setInputValue("");
                              }
                            }}
                            onChange={(
                              e: React.ChangeEvent<
                                HTMLTextAreaElement | HTMLInputElement
                              >
                            ) => {
                              setInputValue(e.target.value);
                            }}
                          />
                        </Box>
                      </Grid>
                    )}

                    <Grid size={{ xs: 12, sm: 9 }}>
                      <Box className="fromGroup">
                        <InputLabel className="fromLabel">
                          Expiration Date
                        </InputLabel>
                        <TextField
                          id="date"
                          placeholder="Select Date"
                          type="date"
                          value={selectedDate}
                          sx={{ width: 220 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            setSelectedDate(e.target.value);
                            if (new Date(e.target.value) < new Date()) {
                              setHasError(false);
                            }
                          }}
                        />
                      </Box>
                      {!errorMessage && hasError && !selectedDate ? (
                        <FormHelperText style={{ color: "#d32f2f" }}>
                          Please select Expiration Date
                        </FormHelperText>
                      ) : null}
                      {/* {!errorMessage &&
                      hasError &&
                      new Date(selectedDate) < new Date() ? (
                        <FormHelperText style={{ color: "#d32f2f" }}>
                          Expiration date should be today or later
                        </FormHelperText>
                      ) : (
                        ""
                      )} */}
                    </Grid>

                    <Grid size={{ xs: 12 }} sx={{ textAlign: "center" }}>
                      <Button onClick={() => setActiveTab("files")}>
                        Upload Files
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {activeTab === "files" && (
                <Grid size={{ xs: 12, lg: 8 }}>
                  <Box className="fromGroup">
                    <InputLabel
                      id="demo-simple-select-label"
                      className="fromLabel"
                    ></InputLabel>
                    {duplicateErrorImage && (
                      <FormHelperText style={{ color: "#d32f2f" }}>
                        {duplicateErrorImageMessage}
                      </FormHelperText>
                    )}
                    <Grid container spacing={3}>
                      <Grid
                        size={{ xs: 12, md: 6 }}
                        className="fileUploadBlock"
                      >
                        <MuiFileInput
                          multiple
                          value={providerInsurance[authIndex]?.files}
                          onChange={(e: File[]) => {
                            let temp: any = [
                              ...providerInsurance[authIndex]?.files,
                              ...e,
                            ];

                            dispatch(
                              setCredentialFiles({
                                index: authIndex,
                                files: temp,
                              })
                            );
                          }}
                        />
                      </Grid>
                      <Grid size={{ xs: 12, md: 6 }}>
                        <div className="uploadProfile__img selectedIn-fileBox">
                          {providerInsurance[authIndex]?.files?.length > 0 ? (
                            providerInsurance[authIndex].files.map(
                              (item: any, index: number) => {
                                return (
                                  <div className="selectedIn-file" key={index}>
                                    <h1>
                                      {
                                        item.fileName
                                          ? item.fileName //for existing file
                                          : item.name //for just uploaded one
                                      }
                                    </h1>
                                    <span>
                                      <DeleteIcon
                                        onClick={() => {
                                          dispatch(
                                            deleteCredentialFiles({
                                              index: authIndex,
                                              fileIndex: index,
                                            })
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <p className="selectedIn-fileBlank">
                              Upload insurance and assessment documents here.
                            </p>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )}
            </Grid>
          </div>
          {/* handle custom input for insurance provider */}

          <Box className="stepperForm__footer">
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              type="submit"
              className="button"
              onClick={() => setHasError(true)}
            >
              {"Save Changes"}
            </Button>
          </Box>
        </ValidatorForm>
        <Dialog open={deletePopup} className="smallModel">
          <div>
            <DialogContent>
              {/* Are you sure want to delete this client ? */}
              Do you want to delete this credential record ?
            </DialogContent>
            <DialogActions>
              <Button
                className="border-button"
                variant="contained"
                onClick={() => setDeletePopup(false)}
                color="secondary"
              >
                No
              </Button>
              <Button
                className="button"
                variant="contained"
                onClick={() => {
                  deleteInsurance(credentialId as string);
                  setDeletePopup(false);
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </Box>
    </>
  );
};

export default ProviderAddNewInsuranceProviderForm;
