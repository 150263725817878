import {
  Box,
  Button,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { Dispatch, SetStateAction, useState, useMemo } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { updateOrg } from "../../../redux/features/organization/organization-slice";
import { OrgService } from "../../../constants/company-user";
import { useDescope, useUser } from "@descope/react-sdk";

interface IOrganizationEditForm {
  name: string;
  url: string;
  email: string;
  service: OrgService[];
  closeModal: Dispatch<SetStateAction<boolean>>;
}

const OrganizationEditForm: React.FC<IOrganizationEditForm> = ({
  name,
  url,
  service,
  email,
  closeModal,
}) => {
  // #region variables region
  const { user, isUserLoading } = useUser();

  const sdk = useDescope();

  const currentTenantId = user?.customAttributes?.currentTenantId;

  //org name
  const [orgName, setOrgName] = useState<string>(name || "");

  //org url
  const [orgUrl, setOrgUrl] = useState<string>(url || "");

  //org type
  const [orgService, setOrgService] = useState(service || []);

  //organization supportEmail
  const [supportEmail, setSupportEmail] = useState<string>(email || "");

  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();

  // #endregion
  // #region methods region

  //handles form submission
  const handleSubmit = async () => {
    const { payload } = await dispatch(
      updateOrg({
        name: orgName,
        url: orgUrl,
        tenantId: currentTenantId,
        service: orgService,
        supportEmail: supportEmail,
      })
    );

    if (payload === 1) {
      await sdk.me();
    }
    closeModal(false);
  };

  //removes focus when enter is pressed
  const removeFocus = (id: string) => {
    document.getElementById(id)?.blur();
  };

  // #endregion
  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <div className="stepperForm__inner p-30px">
        {/* <h1 className="stepperForm__title p-0">Employment Information</h1> */}
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Company Name</InputLabel>
              <TextValidator
                id="orgName"
                type="text"
                name="orgName"
                value={orgName}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    removeFocus("orgName");
                  }
                }}
                onChange={(e: any) => setOrgName(e.target.value)}
                //validators={["required", "matchRegexp:^[a-zA-Z- ]*$"]}
                validators={["required"]}
                errorMessages={["Organization name is required"]}
              />
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Company Url</InputLabel>
              <TextValidator
                id="orgUrl"
                type="text"
                name="orgUrl"
                value={orgUrl}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    removeFocus("orgUrl");
                  }
                }}
                onChange={(e: any) => setOrgUrl(e.target.value)}
              />
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Company Services</InputLabel>
              <Select
                multiple
                id="orgUrl"
                type="text"
                name="orgUrl"
                value={orgService}
                renderValue={(value) => value.join(",")}
                onChange={(e: any) => setOrgService(e.target.value)}
              >
                {Object.values(OrgService).map((item) => (
                  <MenuItem key={item} value={item}>
                    <span>
                      <Checkbox checked={orgService.includes(item)} /> {item}
                    </span>
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Support Email</InputLabel>
              <TextValidator
                id="supportEmail"
                type="text"
                name="supportEmail"
                value={supportEmail}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    removeFocus("supportEmail");
                  }
                }}
                onChange={(e: any) => setSupportEmail(e.target.value)}
                validators={["required", "isEmail"]}
                // validators={["required"]}
                errorMessages={["supportEmail name is required"]}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
      <Box className="stepperForm__footer b-0">
        <Box sx={{ flex: "1 1 auto" }} />
        <Button type="submit" className="button">
          Save Changes
        </Button>
      </Box>
    </ValidatorForm>
  );
};

export default OrganizationEditForm;
