import axios from "../axios/axios";

//fetch forms
const getAllResource = async () => {
  try {
    const { data } = await axios.get("/resources");
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//Install or update resouce by Id
const installOrUpdateResource = async (
  formId: string,
  resourceType: string
) => {
  try {
    const { data } = await axios.post(`/resource/install/${formId}`, {
      resourceType,
    });
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const resourceHubService = {
  getAllResource,
  installOrUpdateResource,
};
