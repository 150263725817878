import { Box, Card, CardContent, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CalendarIcon from "../../assets/images/images-png/CalendarIcon.png";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ClientImg from "../../assets/images/images-svg/client-large.svg";
import Schedule from "../../assets/images/images-png/Schedule.png";
import "./session.scss";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import moment from "moment";
import { GetSessionById } from "../../redux/features/session/session-slice";
import "./session.scss";
import SessionForms from "./SessionForms";
import { SessionStatus } from "../../constants/session";

const SessionDetail = () => {
  //#region variable region
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const schedulePath = localStorage.getItem("schedulePath");
  const [selectedTab, setSelectedTab] = useState<string>("Summary");
  const DetailsTab = ["Summary", "Forms"];
  const currentSession = useAppSelector(
    (state) => state.sessionSlice.currentSession
  );
  //#endregion

  //#region useEffect region
  useEffect(() => {
    if (id) {
      dispatch(GetSessionById({ id }));
    }
    localStorage.setItem("previousPath", location.pathname);
    const selectedDetailTab = localStorage.getItem("sessionDetailsTab");
    if (selectedDetailTab) {
      setSelectedTab(selectedDetailTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  //#endregion

  console.log("currentSession", currentSession);

  return (
    <Box className="sessionDetails">
      <Box className="details-header">
        <Box
          className="back-btn"
          onClick={() => {
            // navigate("/sessions");
            schedulePath?.includes("planning-detail") ? navigate(schedulePath) : navigate("/sessions");
            localStorage.removeItem("schedulePath");
            localStorage.removeItem("previousPath");
            localStorage.removeItem("sessionDetailsTab");
          }}
        >
          <KeyboardBackspaceIcon color="primary" />
          <Typography>Back</Typography>
        </Box>

        <Typography className="session-Title">
          {`Session: ${currentSession?.serviceSchedule?.bookedProviderId.firstName} ${currentSession?.serviceSchedule?.bookedProviderId.lastName}
               - ${currentSession?.serviceSchedule?.bookedClientId.childFirstName} ${currentSession?.serviceSchedule?.bookedClientId.childLastName}`}
        </Typography>
      </Box>

      {/* {selectedTab !== "Updates" &&
            !currentSession?.sessionSummeryData.sessionEndTime && (
              <Box className="closeSession">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpenPopup(true);
                    const currentTime = new Date();
                    const hours = currentTime
                      .getHours()
                      .toString()
                      .padStart(2, "0");
                    let minutes = currentTime
                      .getMinutes()
                      .toString()
                      .padStart(2, "0");
    
                    setSessionEndTime(`${hours}:${minutes}`);
                  }}
                >
                  <img src={EndIcon} alt="" />
                  Close Session
                </Button>
              </Box>
            )} */}

      <Box className="details-tab">
        <ul className="tabs">
          {DetailsTab.map((item, i) => (
            <li
              className={selectedTab === item ? "active-li" : ""}
              key={i}
              onClick={() => {
                setSelectedTab(item);
                localStorage.setItem("sessionDetailsTab", item);
              }}
            >
              {item}
            </li>
          ))}
        </ul>
      </Box>
      {selectedTab === "Summary" && (
        <Box className="session-summary">
          <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid size={{ xs: 12, md: 8, lg: 8 }} rowSpacing={2}>
              <Box className="stepperStage">
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    paddingLeft: "24px",
                    paddingTop: "15px",
                  }}
                >
                  <img src={Schedule} alt="" width={"26px"} height={"27px"} />
                  <Box>
                    <Typography className="card-title">
                      Session Process
                    </Typography>
                    <Typography className="carDescription">
                      Keep track of session progress and important steps.
                    </Typography>
                  </Box>
                </Box>
                <ul>
                  {Object.values(SessionStatus)
                    .filter((status) => status !== SessionStatus.NOT_STARTED)
                    .map((item, index) => (
                      <li
                        key={index}
                        className={
                          item === currentSession?.status
                            ? "stepperStage_item active-onBoard"
                            : "stepperStage_item"
                        }
                      >
                        <div className="stepperStage_item-link">
                          <span> {item}</span>
                        </div>
                      </li>
                    ))}
                </ul>
              </Box>
              <Box className="stepperStage p-25 ">
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <img src={Schedule} alt="" width={"26px"} height={"27px"} />
                  <Box>
                    <Typography className="card-title">
                      Sign In Procedure
                    </Typography>
                    <Box className="signIn-procedure">
                      <Typography>
                        1. Click "Patient Sign In" to collect Sign-In form.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="stepperStage p-25">
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <img src={Schedule} alt="" width={"26px"} height={"27px"} />
                  <Box>
                    <Typography className="card-title">
                      Sign Out Procedure
                    </Typography>
                    <Box className="signOut-procedure">
                      <Typography>
                        1. Click “Close Session” to finalize start and end time.
                      </Typography>
                    </Box>
                    <Box className="signOut-procedure">
                      <Typography>
                        2. Click "Parent Sign Out" to collect Sign Out Form.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="stepperStage p-25">
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <img src={Schedule} alt="" width={"26px"} height={"27px"} />
                  <Box>
                    <Typography className="card-title">
                      Finalize session Notes.
                    </Typography>
                    <Typography margin={"20px 0px"}>
                      1. As Employee, go to “
                      <span
                        className="linkForm"
                        onClick={() => setSelectedTab("Forms")}
                      >
                        Forms
                      </span>
                      ” and open Session Note Form. You’ll need to complete and
                      submit Session Note Form.
                    </Typography>
                    <Typography>
                      2. As Supervisor, go to “
                      <span
                        className="linkForm"
                        onClick={() => setSelectedTab("Forms")}
                      >
                        Forms
                      </span>
                      ” and see submitted forms for review. Click on the link
                      button to Start your review process.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid size={{ xs: 12, md: 4, lg: 4 }}>
              <Card className="summary-card">
                <CardContent>
                  <Box className="card-header">Session Summary</Box>
                  <Box marginTop={"38px"}>
                    <Box className="sheduleDetails">
                      <Box>
                        <img src={CalendarIcon} alt="" />
                      </Box>
                      <Box>
                        <Typography className="sub-list">Schedule</Typography>
                        <Box className="timeDetails">
                          <Typography>
                            {moment(
                              currentSession?.serviceSchedule.specificDate
                            ).format("dddd, M/D/YYYY")}
                          </Typography>
                          <Typography>
                            {currentSession?.serviceSchedule.fromTime} -{" "}
                            {currentSession?.serviceSchedule.toTime}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="sheduleDetails">
                      <Box>
                        <AccessTimeIcon color="primary" />
                      </Box>
                      <Box>
                        <Typography className="sub-list">Actual</Typography>
                        <Box className="timeDetails">
                          <Typography>
                            {moment(currentSession?.sessionBeginDtm).format(
                              "dddd, M/D/YYYY"
                            )}
                          </Typography>
                          {currentSession?.sessionEndTime ? (
                            <Typography>
                              {currentSession?.sessionBeginTime} -{" "}
                              {currentSession.sessionEndTime}
                            </Typography>
                          ) : (
                            <Typography>
                              {currentSession?.sessionBeginTime} - ongoing
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box className="sheduleDetails">
                      <Box>
                        <PersonOutlineIcon color="primary" />
                      </Box>
                      <Box>
                        <Typography className="sub-list">
                          Participants
                        </Typography>
                        <Box className="userDetails">
                          <span className="userMapCart__img">
                            <img
                              src={
                                currentSession?.serviceSchedule.bookedClientId
                                  .clientProfile?.url || ClientImg
                              }
                              className="userSmallCircle"
                              alt={""}
                            />
                          </span>
                          <Typography className="ClientName">
                            {
                              currentSession?.serviceSchedule?.bookedClientId
                                .childFirstName
                            }{" "}
                            {
                              currentSession?.serviceSchedule?.bookedClientId
                                .childLastName
                            }
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}
      {selectedTab === "Forms" && <SessionForms />}
      {/* {selectedTab === "Updates" && <SessionUpdates />} */}
    </Box>
  );
};

export default SessionDetail;
