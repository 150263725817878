import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import { ProviderType } from "../../../../constants/provider-status";
import { MenuProps } from "../../../../constants/providers";
import EditConfirmation from "../../../../layouts/edit-confirmation/EditConfirmation";
import { AppDispatch, RootState } from "../../../../redux/store";
import { setListDataChange } from "../../../../redux/features/client-main/client-list-slice";
import { updateProviderType } from "../../../../redux/features/providers-main/provider-slice";
import {
  EmploymentDetails,
  IchecklistPaperWork,
  UpdateValue,
} from "../../../../types/provider";
// import { getAllChecklistPaperWork } from "../../../../redux/features/providers-main/provider-list-slice";

interface SubProviderStatusProps {
  employmentDetails: EmploymentDetails;
  id: string;
}

const SubProviderStatus: React.FC<SubProviderStatusProps> = ({
  employmentDetails,
  id,
}) => {
  // #region variables region

  //onboarding stages
  const onBoardingStages = useSelector<RootState, any>(
    (state) => state.providerOnBoardingStage.stages
  );

  //checklist paper work
  const ChecklistPaperWork = useSelector<RootState, IchecklistPaperWork[]>(
    (state) => state.providerListSlice.checkListPaperWork
  );

  //handles the confirmation model opening/closing for client status/ on track stage and concerned state
  const [editModal, setEditModal] = useState<boolean>(false);

  //stores the name to display while opening confirmation popup for client status/ on track stage and concerned state
  const [editModalName, setEditModalName] = useState<string>("");

  //handles the update redux object for client status/ on track stage and concerned state
  const [editModalValue, setEditModalValue] = useState<UpdateValue>({
    name: "",
    value: "",
  });

  //stores IntakeStage Data String
  const [onboardingStage, setOnboardingStage] = useState<string>("");

  //stores concerned stage data
  const [concernedStage, setConcernedStage] = useState<string[]>([]);
  const [stageCheckList, setStageCheckList] = useState<string[]>([]);

  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();

  // #endregion

  // #region methods region

  //provider status update handler
  const updateProviderStatus = (value: boolean): void => {
    if (value) {
      if (editModalValue.name === "concernedStage")
        setEditModalValue({
          name: "concernedStage",
          value: [...concernedStage],
        });
      if (editModalValue.name === "onBoardingStage") {
        let stageId: string = "";
        onBoardingStages.forEach((item: any) => {
          if (onboardingStage === item.stageName) {
            stageId = item.id;
            return;
          }
        });
        dispatch(
          updateProviderType({
            providerType: editModalValue,
            stageId,
            reqData: { ...employmentDetails },
          })
        );
      } else {
        dispatch(
          updateProviderType({
            providerType: editModalValue,
            reqData: { ...employmentDetails },
            stageId: null,
          })
        );
      }

      dispatch(setListDataChange());
    }

    setEditModal(false);
  };

  // #endregion

  // #region useEffect region

  useEffect(() => {
    if (employmentDetails) {
      setOnboardingStage(employmentDetails.onBoardingStage);
      setConcernedStage(employmentDetails.checklistHistory);
    }
    // dispatch(getAllChecklistPaperWork());
  }, [employmentDetails]);

  useEffect(() => {
    const checklist = onBoardingStages.find(
      (stage: any) => stage.stageName === onboardingStage
    )?.checkList;
    if (checklist && checklist.length) {
      setStageCheckList(checklist);
      const filter = checklist.filter((list: string) =>
        employmentDetails.checklistHistory.includes(list)
      );
      setConcernedStage(filter);
    } else {
      setStageCheckList([]);
      const filter = employmentDetails?.checklistHistory.filter((list) =>
        ChecklistPaperWork.find((checklist) => checklist.conceptValue === list)
      );
      if (filter && filter.length) {
        setConcernedStage(filter);
      }
    }
    // eslint-disable-next-line
  }, [onboardingStage, onBoardingStages]);

  // #endregion
  return (
    <Grid size={{ xs: 12, md: 6 }}>
      <Box className="detailsCard">
        <Box className="detailsCard__header">
          <Typography className="detailsCard__title">
            Provider Status
          </Typography>
        </Box>
        <Box style={{ padding: "20px 20px 0px 20px" }}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 6 }}>
              <div className="detailsCard-part">
                <Typography className="detailsCard-part__title">
                  Status
                </Typography>
                <Box className="fromGroup-chips">
                  {ProviderType.map((item) => (
                    <Card
                      className={
                        item.value === employmentDetails?.providerType
                          ? "fromGroup-chip-active"
                          : "detailsCard-part__chip"
                      }
                      sx={{ display: "flex", flexWrap: "nowrap" }}
                      /*className="detailsCard-part__chip"*/ key={item.key}
                      onClick={(
                        e: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => {
                        e.preventDefault();
                        setEditModal(true);
                        setEditModalName("Provider Status");
                        setEditModalValue({
                          name: "providerType",
                          value: item.value,
                        });
                      }}
                    >
                      {item.value}
                    </Card>
                  ))}
                </Box>
              </div>
            </Grid>
            <Grid size={{ xs: 12, md: 6, lg: 9 }}>
              <Box className="fromGroup" style={{ marginBottom: "22px" }}>
                <Typography className="fromLabel">Onboarding Stage</Typography>
                <Select
                  className="form-control"
                  value={onboardingStage}
                  // placeholder="select"
                  onChange={(e: SelectChangeEvent<string>) => {
                    setEditModal(true);
                    setEditModalName("OnBoarding Stage");
                    setEditModalValue({
                      name: "onBoardingStage",
                      value: e.target.value,
                    });
                    setOnboardingStage(e.target.value);
                  }}
                  MenuProps={MenuProps}
                >
                  {onBoardingStages.map((item: any) => {
                    return (
                      <MenuItem key={item.id} value={item.stageName}>
                        {item.stageName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>

              <Box className="fromGroup">
                <Grid>
                  <InputLabel
                    id="demo-simple-select-label"
                    className="fromLabel"
                  >
                    Checklist
                  </InputLabel>
                  <FormControl>
                    <Select
                      multiple
                      className="form-control"
                      value={concernedStage}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      onChange={(e: SelectChangeEvent<string[]>) => {
                        setConcernedStage(e.target.value as string[]);
                      }}
                    >
                      {stageCheckList.length
                        ? stageCheckList.map((item) => {
                            return (
                              <MenuItem key={item} value={item}>
                                <Checkbox
                                  checked={concernedStage?.includes(item)}
                                />
                                {item}
                              </MenuItem>
                            );
                          })
                        : ChecklistPaperWork.map((item) => {
                            return (
                              <MenuItem key={item.id} value={item.conceptValue}>
                                <Checkbox
                                  checked={
                                    concernedStage?.indexOf(item.conceptValue) >
                                    -1
                                  }
                                />
                                {item.conceptValue}
                              </MenuItem>
                            );
                          })}
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <div className="largeModel__footer" style={{ boxShadow: "none" }}>
            <Button
              className="button"
              type="submit"
              onClick={() => {
                setEditModal(true);
                setEditModalName("Stage Checklist");
                setEditModalValue({
                  name: "checklistHistory",
                  value: concernedStage,
                });
              }}
            >
              Save Changes
            </Button>
          </div>
        </Box>
      </Box>
      {editModal && (
        <EditConfirmation
          name={editModalName}
          setModal={(value) => updateProviderStatus(value)}
        />
      )}
    </Grid>
  );
};

export default SubProviderStatus;
