import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { BorderColor, Close, ContentCopy } from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  addAuthorization,
  CopyAuthorizationById,
  deleteSingleAuthorization,
  removeAuthorization,
  setEditClient,
  setInitialState,
  updateClientAuthorizations,
  createClientAuthorizations,
} from "../../../../redux/features/client-main/client-slice";

import ClientEditAuthorizationForm from "../../client-helper-forms/ClientEditAuthorizationForm";
import ClientAddAuthorizationForm from "../../client-helper-forms/ClientAddAuthorizationForm";

import {
  ClientAuthCode,
  ClientAuthorization,
  ClientAuthPayload,
  InsuranceProviders,
} from "../../../../types/client";

import "./../ClientDetails.scss";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    verticalAlign: "baseline",
    whiteSpace: "nowrap",
  },
  padding: "5px 20px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF6FF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    height: "40px",
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
  height: "50px",
}));
const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
}));

interface ClientSubAuthorizationsProps {
  authorizations: ClientAuthPayload[];
  clientId: string;
  activeTab: string | null;
  setActiveTab: Dispatch<SetStateAction<string | null>>;
  editAuthorizationsInfo: boolean;
  clientInsurance: InsuranceProviders[];
  setEditAuthorizationsInfo: Dispatch<SetStateAction<boolean>>;
}

const ClientSubAuthorizations: React.FC<ClientSubAuthorizationsProps> = ({
  clientInsurance,
  authorizations,
  clientId,
  activeTab,
  setActiveTab,
  editAuthorizationsInfo,
  setEditAuthorizationsInfo,
}) => {
  // #region variables region

  //handles the edit for client authorizations details
  const [addAuthorizationsInfo, setAddAuthorizationsInfo] =
    useState<boolean>(false);

  const [authIndex, setAuthIndex] = useState<number>(0);
  //dispatch for redux
  const dispatch = useAppDispatch();

  //form handler for edit or new
  const { isEditClient } = useAppSelector((state) => state.client);

  const [newAuthorization, setNewAuthorization] = useState<ClientAuthorization>(
    {
      authorizationName: "Authorization",
      authorizationNumber: 0,
      insuranceId: "",
      authPeriod: {
        startDate: "",
        endDate: "",
      },
      files: [],
      auth: [{ authCode: "", units: "", authApprovalUnit: "" }],
    }
  );

  const clientInsuranceProviders = useAppSelector(
    (state) => state.clientInsuranceSlice.clientInsurance
  );

  const insuranceProviders = useAppSelector(
    (state) => state.insuranceProviderSlice.insuranceProviders
  );

  const insuranceId: string = clientInsuranceProviders.find(
    (val) =>
      val.insuranceProviderName ===
      insuranceProviders.find(
        (item) => item.id === authorizations?.[authIndex]?.insuranceId
      )?.insuranceProviderName
  )?.id as string;

  const [editAuthorization, setEditAuthorization] =
    useState<ClientAuthorization>({
      id: authorizations?.[authIndex]?.id,
      authorizationName: authorizations?.[authIndex]?.authorizationName,
      authorizationNumber: authorizations?.[authIndex]?.authorizationNumber,
      insuranceId: insuranceId,
      authPeriod: authorizations?.[authIndex]?.authPeriod,
      files: authorizations?.[authIndex]?.files,
      auth: authorizations?.[authIndex]?.auth,
    });
  // #endregion

  useEffect(() => {
    setEditAuthorization({
      id: authorizations?.[authIndex]?.id,
      authorizationName: authorizations?.[authIndex]?.authorizationName,
      authorizationNumber: authorizations?.[authIndex]?.authorizationNumber,
      insuranceId: insuranceId,
      authPeriod: authorizations?.[authIndex]?.authPeriod,
      files: authorizations?.[authIndex]?.files,
      auth: authorizations?.[authIndex]?.auth,
    });
  }, [authorizations, authIndex, insuranceId]);

  //handles the close logic when save changes or close edit form for client authorizations details
  const handleCloseForAuthorizationsDetails = () => {
    setActiveTab(null);
    setAuthIndex(0);
    if (!isEditClient) {
      dispatch(setInitialState({}));
    }

    setNewAuthorization({
      authorizationName: "Authorization",
      authorizationNumber: 0,
      insuranceId: "",
      authPeriod: {
        startDate: "",
        endDate: "",
      },
      files: [],
      auth: [{ authCode: "", units: "", authApprovalUnit: "" }],
    });

    setEditAuthorization({
      authorizationName: authorizations?.[authIndex]?.authorizationName,
      authorizationNumber: authorizations?.[authIndex]?.authorizationNumber,
      insuranceId: insuranceId,
      authPeriod: authorizations?.[authIndex]?.authPeriod,
      files: authorizations?.[authIndex]?.files,
      auth: authorizations?.[authIndex]?.auth,
    });

    dispatch(setEditClient(false));
    setEditAuthorizationsInfo(false);
    setAddAuthorizationsInfo(false);
  };

  //handles the edit form submission for client authorizations details
  const handleCreateAuthorizationsInfo = () => {
    dispatch(
      createClientAuthorizations({
        clientId: clientId,
        clientAuthorization: newAuthorization,
      })
    );

    setEditAuthorizationsInfo(false);
    setAddAuthorizationsInfo(false);
    dispatch(setEditClient(false));
  };

  const handleUpdateAuthorizationsInfo = () => {
    console.log("handleUpdateAuthorizationsInfo");
    dispatch(
      updateClientAuthorizations({
        clientAuthId: authorizations[authIndex].id,
        clientAuthorization: editAuthorization,
      })
    );
    setEditAuthorizationsInfo(false);
    setAddAuthorizationsInfo(false);
    dispatch(setEditClient(false));
  };

  //handles deleting an authorization
  const handleDeleteAuthorization = (id: string | undefined) => {
    if (!id) {
      return;
    }
    setEditAuthorizationsInfo(false);
    dispatch(deleteSingleAuthorization({ authId: id }));
    setAuthIndex(0);
    setActiveTab("basic");
  };

  // handle copy auth
  const handleCopyAuth = (id: string) => {
    dispatch(CopyAuthorizationById({ id: id }));
  };

  return (
    <Grid size={{ xs: 12 }} mt="22px">
      <div className="listingTable" style={{ marginBottom: 0 }}>
        <div className="listingTable__header">
          <Typography className="listingTable__title" gutterBottom variant="h6">
            Authorizations
          </Typography>
          <div style={{ position: "relative", textAlign: "right" }}>
            <Button
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.preventDefault();
                dispatch(addAuthorization({}));
                dispatch(setEditClient(true));
                setAddAuthorizationsInfo(true);
              }}
              className="addBtn"
            >
              + Add New Authorization
            </Button>
          </div>
        </div>
        <TableContainer className="tableResponsive">
          <StyledTable
            sx={{
              minWidth: 700,
              borderCollapse: "separate",
              cursor: "pointer",
            }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Insurance</StyledTableCell>
                <StyledTableCell>Auth Number</StyledTableCell>
                <StyledTableCell>Auth Period</StyledTableCell>
                <StyledTableCell>Auth Codes</StyledTableCell>
                <StyledTableCell># of Units</StyledTableCell>
                <StyledTableCell>Files</StyledTableCell>
                <StyledTableCell>Update On</StyledTableCell>
                <StyledTableCell align="right"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {authorizations
                ?.filter((item: ClientAuthPayload) => item.id)
                ?.map((authorization: ClientAuthPayload, index: number) => {
                  return (
                    <StyledTableRow
                      style={{ borderRadius: 20 }}
                      // key={`${row.clientBasicDetails + idx}`}
                      key={authorization.id}
                    >
                      <StyledTableCell component="th">
                        {authorization.authorizationName}
                      </StyledTableCell>
                      <StyledTableCell>
                        {authorization.insurance?.insuranceProviderName}
                      </StyledTableCell>
                      <StyledTableCell>
                        {authorization.authorizationNumber}
                      </StyledTableCell>
                      <StyledTableCell scope="row">
                        {authorization?.authPeriod?.startDate}
                        {/* {moment(authorization?.authPeriod?.startDate).format(
                          "DD/MM/YYYY"
                        )} */}{" "}
                        - {authorization?.authPeriod?.endDate}
                        {/* {moment(authorization?.authPeriod?.endDate).format(
                          "DD/MM/YYYY"
                        )} */}
                      </StyledTableCell>
                      <StyledTableCell>
                        {authorization?.auth.map((item: ClientAuthCode) => (
                          <div key={item.authCode}>{item.authCode}</div>
                        ))}
                      </StyledTableCell>
                      <StyledTableCell>
                        {authorization?.auth.map((item: ClientAuthCode) => (
                          <div key={item.authCode}>{item.units}</div>
                        ))}
                      </StyledTableCell>
                      <StyledTableCell>
                        {authorization?.files?.map((item: any) => {
                          if (item?.key) {
                            return <div key={item.key}>{item.fileName}</div>;
                          }
                          return (
                            <div key={item.fileName}>{item?.fileName}</div>
                          );
                        })}
                      </StyledTableCell>
                      <StyledTableCell>
                        {authorization.updatedAt}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <div className="setDate">
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <BorderColor
                              onClick={() => {
                                dispatch(setEditClient(true));
                                setAuthIndex(index);
                                // dispatch(
                                //   setAuthorizationDetails({
                                //     authorization: [...authorizations],
                                //   })
                                // );
                                setEditAuthorizationsInfo(true);
                              }}
                            />
                            <ContentCopy
                              color="primary"
                              onClick={() => handleCopyAuth(authorization.id)}
                            />
                          </span>
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </div>
      {isEditClient && editAuthorizationsInfo && (
        <>
          <Dialog
            open={editAuthorizationsInfo}
            className="largeModel largeModel--edit"
          >
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Edit Client - Authorizations
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={handleCloseForAuthorizationsDetails}
              >
                <Close />
              </Button>
            </Box>
            <ClientEditAuthorizationForm
              clientInsurance={clientInsurance}
              editAuthorization={editAuthorization}
              setEditAuthorization={setEditAuthorization}
              activeTabFromParent={activeTab || "basic"}
              deleteSingleAuthorization={handleDeleteAuthorization}
              onSubmit={handleUpdateAuthorizationsInfo}
            />
          </Dialog>
        </>
      )}
      {isEditClient && addAuthorizationsInfo && (
        <>
          <Dialog
            open={addAuthorizationsInfo}
            className="largeModel largeModel--edit"
          >
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Add Client Authorization
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={() => {
                  handleCloseForAuthorizationsDetails();
                  dispatch(removeAuthorization({}));
                }}
              >
                <Close />
              </Button>
            </Box>
            <ClientAddAuthorizationForm
              clientInsurance={clientInsurance}
              activeTabFromParent={activeTab || "basic"}
              newAuthorization={newAuthorization}
              setNewAuthorization={setNewAuthorization}
              onSubmit={handleCreateAuthorizationsInfo}
            />
          </Dialog>
        </>
      )}
    </Grid>
  );
};

export default ClientSubAuthorizations;
