import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { clientType, onTrackStage } from "../../constants/client";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { InsuranceProvider } from "../../types/response";
import { ScheduleFilters, MatchedProvider } from "../../types/schedule";
import {
  filterAvailabilityTypes,
  EntityScheduleStatus,
  filterProviderStatuses,
  filterOnTrackChangeTypes,
} from "../../constants/providers";
import { ClientType } from "../../constants/client-status";
import "../providers/provider/Providers.scss";
import { OrgBU } from "../../types/company";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getBussinessName } from "../../constants/common";
import { IClientConcernStages } from "../../types/client";
import {
  GetUtilzationClients,
  dataUpdated,
} from "../../redux/features/client-main/client-list-slice";
import { GetUtilizationProviders } from "../../redux/features/providers-main/provider-list-slice";
// import { GetAllClientConcerns } from "../../redux/features/client-main/client-list-slice";
interface ScheduleFiltersProps {
  listData: MatchedProvider[];
  closeModal: (val: boolean, filterObject: ScheduleFilters) => void;
  filterData: boolean;
  isFilterClient: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ScheduleFilter: React.FC<ScheduleFiltersProps> = ({
  closeModal,
  filterData,
  listData,
  isFilterClient,
}) => {
  // #region variable region

  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();

  //insurance providers
  const insuranceProviders = useSelector<RootState, InsuranceProvider[]>(
    (state) => state.clientInsuranceSlice.clientInsurance
  );

  // business units
  const businessUnits = useSelector<RootState, OrgBU[]>(
    (state) => state.businessUnitsSlice.businessUnits
  );

  //filter values from redux
  const [filterValues, setFilterValues] = useState<ScheduleFilters>(
    useSelector<RootState, ScheduleFilters>(
      (state) => state.schedulesFilterSlice
    )
  );

  // Store startDate
  const [startDate, setStartDate] = useState<Dayjs | any | null>(null);

  // store enddate
  const [endDate, setEndDate] = useState<Dayjs | any | null>(null);

  //stores error messages
  const [error, setError] = useState<string>("");

  // #endregion

  // #region methods region

  //
  const handleStartDate = (newValue: Dayjs | null) => {
    setError("");
    setStartDate(newValue);
    setFilterValues((prevState) => {
      return {
        ...prevState,
        // startDate: dayjs(newValue).format("MM/DD/YYYY"),
        startDate: newValue ? dayjs(newValue).format("MM/DD/YYYY") : null,
      };
    });
  };

  const handleEndDate = (newValue: Dayjs | null) => {
    setError("");
    setEndDate(newValue);
    setFilterValues((prevState) => {
      return {
        ...prevState,
        //  startDate: dayjs(startDate).format("MM/DD/YYYY"),
        // endDate: dayjs(newValue).format("MM/DD/YYYY"),
        endDate: newValue ? dayjs(newValue).format("MM/DD/YYYY") : null,
      };
    });
  };

  //filter values updater
  const handleFilterValues = (item: {
    name: string;
    value: string | string[];
  }) => {
    const obj = {
      ...filterValues,
      [item.name]: item.value,
    };
    setFilterValues({ ...obj });
  };

  // #endregion

  const handleChange = (item: {
    name: string;
    value: string[] | string;
  }): void => {
    const obj = {
      ...filterValues,
      [item.name]: item.value,
    };
    setFilterValues({ ...obj });
  };

  ////handle onBoard Stage onChange
  const handleOnBoardStageChange = (e: any) => {
    if (
      !e.target.value.includes("All Stages") &&
      filterValues.onBoardingStage.includes("All Stages")
    ) {
      setFilterValues({ ...filterValues, onBoardingStage: [] });
    } else if (
      e.target.value.includes("All Stages") &&
      !filterValues.onBoardingStage.includes("All Stages")
    ) {
      setFilterValues({
        ...filterValues,
        onBoardingStage: [...filterOnTrackChangeTypes, "All Stages"],
      });
    } else if (
      e.target.value.includes("All Stages") &&
      e.target.value.length === filterOnTrackChangeTypes.length
    ) {
      setFilterValues({
        ...filterValues,
        onBoardingStage: e.target.value.filter((e: any) => e !== "All Stages"),
      });
    } else if (e.target.value.length === filterOnTrackChangeTypes.length) {
      setFilterValues({
        ...filterValues,
        onBoardingStage: [...filterOnTrackChangeTypes, "All Stages"],
      });
    } else {
      handleFilterValues({
        name: "onBoardingStage",
        value: e.target.value,
      });
    }
  };

  //#region useEffect region
  useEffect(() => {
    //fetch all client concerns
    // dispatch(GetAllClientConcerns());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion

  return (
    <div>
      <div className="stepperForm__inner p-30px">
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Business Unit</InputLabel>
              <FormControl>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filterValues.businessUnit}
                  onChange={(e) => {
                    handleChange({
                      name: "businessUnit",
                      value: e.target.value,
                    });
                  }}
                  renderValue={(selected: any) => {
                    selected = selected.map((select: any) => {
                      return getBussinessName(select, businessUnits);
                    });
                    return selected.join(", ");
                  }}
                  MenuProps={MenuProps}
                >
                  {businessUnits.map((unit, index) => (
                    <MenuItem key={index} value={unit.id}>
                      <Checkbox
                        checked={
                          filterValues.businessUnit.indexOf(unit.id) > -1
                        }
                      />
                      <ListItemText primary={unit.businessUnitName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                  filterValues.businessUnit.length > 0 
                    ? filterValues.businessUnit[0]
                    : "All Units"
                }
                label="Age"
                MenuProps={MenuProps}
                onChange={(e: SelectChangeEvent<string>) =>
                  handleFilterValues({
                    name: "businessUnit",
                    value: e.target.value,
                  })
                }
              >
                <MenuItem value={"All Units"}>{"All Units"}</MenuItem>
                {businessUnits.map((unit: OrgBU, index: number) => {
                  return (
                    <MenuItem key={index} value={unit.id}>
                      {unit.businessUnitName}
                    </MenuItem>
                  );
                })}
              </Select> */}
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Insurance Provider</InputLabel>
              <FormControl>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filterValues.insuranceProvider}
                  onChange={(e) => {
                    handleChange({
                      name: "insuranceProvider",
                      value: e.target.value,
                    });
                  }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {insuranceProviders.map((unit, index) => (
                    <MenuItem key={index} value={unit.insuranceProviderName}>
                      <Checkbox
                        checked={
                          filterValues.insuranceProvider.indexOf(
                            unit.insuranceProviderName
                          ) > -1
                        }
                      />
                      <ListItemText primary={unit.insuranceProviderName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <Select
                labelId="business-unit"
                id="businessUnitOption"
                value={
                  filterValues.insuranceProvider
                    ? filterValues.insuranceProvider
                    : "All Employees"
                }
                label=""
                onChange={(e) => {
                  handleFilterValues({
                    name: "insuranceProvider",
                    value: e.target.value,
                  });
                }}
                MenuProps={MenuProps}
              >
                <MenuItem value={"All Employees"}>
                  {"All Employees"}
                </MenuItem>
                {insuranceProviders.map((item, index: number) => {
                  return (
                    <MenuItem key={index} value={item.insuranceProviderName}>
                      {item.insuranceProviderName}
                    </MenuItem>
                  );
                })}
              </Select> */}
            </Box>
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Client Status</InputLabel>
              <FormControl>
                <Select
                  disabled={isFilterClient === "Client"}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filterValues.clientStatus}
                  onChange={(e) => {
                    handleChange({
                      name: "clientStatus",
                      value: e.target.value,
                    });
                  }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {clientType.map((unit, index) => (
                    <MenuItem key={index} value={unit}>
                      <Checkbox
                        checked={filterValues.clientStatus.indexOf(unit) > -1}
                      />
                      <ListItemText primary={unit} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Employee Status</InputLabel>
              <FormControl>
                <Select
                  disabled={isFilterClient === "Provider"}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filterValues.providerStatus}
                  onChange={(e) => {
                    handleFilterValues({
                      name: "providerStatus",
                      value: e.target.value,
                    });
                  }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {filterProviderStatuses.map((unit, index) => (
                    <MenuItem key={index} value={unit}>
                      <Checkbox
                        checked={filterValues.providerStatus.indexOf(unit) > -1}
                      />
                      <ListItemText primary={unit} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Intake Stage</InputLabel>
              <FormControl>
                <Select
                  disabled={isFilterClient === "Client"}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filterValues.intakeStage}
                  onChange={(e) => {
                    handleChange({
                      name: "intakeStage",
                      value: e.target.value,
                    });
                  }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {onTrackStage.map((unit, index) => (
                    <MenuItem key={index} value={unit}>
                      <Checkbox
                        checked={filterValues.intakeStage.indexOf(unit) > -1}
                      />
                      <ListItemText primary={unit} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel id="demo-simple-select-label" className="fromLabel">
                Onboarding Stage
              </InputLabel>
              <FormControl>
                <Select
                  disabled={isFilterClient === "Provider"}
                  multiple
                  className="form-control"
                  value={filterValues.onBoardingStage}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  onChange={handleOnBoardStageChange}
                >
                  <MenuItem value={"All Stages"}>
                    <Checkbox
                      checked={
                        filterValues.onBoardingStage.indexOf("All Stages") > -1
                      }
                    />
                    {"All Stages"}
                  </MenuItem>
                  {filterOnTrackChangeTypes.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        <Checkbox
                          checked={
                            filterValues.onBoardingStage.indexOf(item) > -1
                          }
                        />
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <InputLabel className="fromLabel">Appointment Date</InputLabel>
            <Box
              className="formGroup datePicker"
              style={{ position: "relative" }}
            >
              <Grid size={{ xs: 12 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid container spacing={3}>
                    <Grid size={{ xs: 6 }}>
                      <DatePicker
                        className="singleDatePicker"
                        label="Start Date"
                        value={
                          filterValues.startDate
                            ? dayjs(filterValues.startDate)
                            : null
                        }
                        onChange={handleStartDate}
                        // renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                    <Grid size={{ xs: 6 }}>
                      <DatePicker
                        className="singleDatePicker"
                        label="End Date"
                        value={
                          filterValues.endDate
                            ? dayjs(filterValues.endDate)
                            : null
                        }
                        onChange={handleEndDate}
                        // renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                  </Grid>
                </LocalizationProvider>
              </Grid>
            </Box>
            {error && <p style={{ color: "#d32f2f" }}>{error}</p>}
          </Grid>
          <Grid size={{ xs: 12, md: 10 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Schedule Status</InputLabel>
              <div className="fromGroup-chips">
                {Object.values(EntityScheduleStatus).map(
                  (item: string, index: number) => (
                    <Card
                      className={
                        filterValues.ScheduleStatus === item
                          ? "fromGroup-chip-active"
                          : ""
                      }
                      onClick={(
                        e: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => {
                        if (filterValues.ScheduleStatus === item) {
                          handleFilterValues({
                            name: "ScheduleStatus",
                            value: "",
                          });
                        } else {
                          handleFilterValues({
                            name: "ScheduleStatus",
                            value: item,
                          });
                        }
                      }}
                      key={item}
                    >
                      {item}
                    </Card>
                  )
                )}
              </div>
            </Box>
          </Grid>
          {/* <Grid item xs={12} md={2}>
            <InputLabel className="fromLabel">Age</InputLabel>

            <TextField
              sx={{
                width: { sm: 50, md: 100 },
                "& .MuiInputBase-root": {
                  height: 40,
                },
              }}
              type="number"
              placeholder="client age"
              InputProps={{ inputProps: { min: 0 } }}
              value={filterValues.age}
              onChange={(e) => {
                ["+", "-"].includes(e.target.value) && e.preventDefault();
                handleFilterValues({ name: "age", value: e.target.value });
              }}
              onKeyDown={(event) => {
                if (event.key === "-" || event.key === "+") {
                  event.preventDefault();
                }
              }}
            />
          </Grid> */}
        </Grid>
      </div>
      <Box className="stepperForm__footer b-0">
        <Button
          color="inherit"
          className="border-button"
          onClick={() => {
            setFilterValues({
              businessUnit: [],
              clientStatus: [],
              providerStatus: [],
              insuranceProvider: [],
              concerns: [],
              intakeStage: [],
              onBoardingStage: [],
              availabilityStatus: "",
              startDate: null,
              endDate: null,
              ScheduleStatus: "",
              // ageFrom: null,
              // ageTo: null,
            });
            dispatch(dataUpdated({ dataUpdate: false }));
          }}
        >
          Clear Filter
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            const isStartDateValid =
              !startDate || dayjs(startDate, "MM/DD/YYYY", true).isValid();
            const isEndDateValid =
              !endDate || dayjs(endDate, "MM/DD/YYYY", true).isValid();

            if (
              (!startDate && endDate) ||
              (!endDate && startDate) ||
              (startDate && !isStartDateValid) ||
              (endDate && !isEndDateValid)
            ) {
              e.preventDefault();
              setError("Please enter valid dates.");
            } else {
              e.preventDefault();
              setError("");
              closeModal(false, { ...filterValues });
            }
            // if (filterValues.startDate && filterValues.endDate) {
            //   if (filterData) {
            //     dispatch(
            //       GetUtilizationProviders({
            //         startDate: filterValues.startDate,
            //         endDate: filterValues.endDate,
            //         isList: true,
            //         listData: listData,
            //       })
            //     );
            //   } else {
            //     dispatch(
            //       GetUtilzationClients({
            //         startDate: filterValues.startDate,
            //         endDate: filterValues.endDate,
            //         isClient: true,
            //       })
            //     );
            //   }
            // }
          }}
          className="button"
        >
          Save Filters
        </Button>
      </Box>
    </div>
  );
};

export default ScheduleFilter;
