import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import React, { useEffect, useState } from "react";
import { InsuranceProvider } from "../../types/response";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  MenuProps,
  filterOnTrackChangeTypes,
  filterProviderStatuses,
} from "../../constants/providers";
import { clientType, onTrackStage } from "../../constants/client";
// import { GetAllClientConcerns } from "../../redux/features/client-main/client-list-slice";
import { ProviderType } from "../../types/provider";
import { PlannerFilter } from "../../types/planning";
import {
  GetUtilzationClients,
  dataUpdated,
} from "../../redux/features/client-main/client-list-slice";
import {
  GetProviderSpecialty,
  GetProviderTypes,
} from "../../redux/features/providers-main/provider-list-slice";

interface ProviderFilterProps {
  closeModal: (val: boolean, filterObject: PlannerFilter) => void;
  setIsFilterApplied: React.Dispatch<React.SetStateAction<boolean>>;
}

const PlanningFilter: React.FC<ProviderFilterProps> = ({
  closeModal,
  setIsFilterApplied,
}) => {
  //#region variables region

  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();

  //fetch selected filter values from redux
  const [filterValues, setFilterValues] = useState<PlannerFilter>(
    useSelector<RootState, PlannerFilter>(
      (state) => state.planningFilterSlice.PlanningFilter
    )
  );

  //insurance providers
  const insuranceProviders = useSelector<RootState, InsuranceProvider[]>(
    (state) => state.clientInsuranceSlice.clientInsurance
  );
  //Provider dropdown list
  const ProviderTypeList = useSelector<RootState, ProviderType[]>(
    (state) => state.providerListSlice.ProviderType
  );

  //provider Specialty dropDown list
  const ProviderSpecialtyList = useSelector<RootState, ProviderType[]>(
    (state) => state.providerListSlice.ProviderSpecialty
  );

  //#endregion

  //#region methods region

  //handles filtered values
  const handleFilterValues = (item: {
    name: string;
    value: string | string[];
  }): void => {
    const obj = {
      ...filterValues,
      [item.name]: item.value,
    };
    setFilterValues({ ...obj });
  };

  //#endregion

  //#region useEffect region
  useEffect(() => {
    //fetch all client concerns
    dispatch(GetProviderSpecialty());
    //fetch client ChecklistTraining
    dispatch(GetProviderTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion

  return (
    <React.Fragment>
      <div className="stepperForm__inner p-30px">
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Insurance Providers</InputLabel>
              <FormControl>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filterValues.insuranceProvider}
                  onChange={(e) => {
                    handleFilterValues({
                      name: "insuranceProvider",
                      value: e.target.value,
                    });
                  }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {insuranceProviders.map((unit, index) => (
                    <MenuItem key={index} value={unit.insuranceProviderName}>
                      <Checkbox
                        checked={
                          filterValues.insuranceProvider.indexOf(
                            unit.insuranceProviderName
                          ) > -1
                        }
                      />
                      <ListItemText primary={unit.insuranceProviderName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Client Status</InputLabel>
              <FormControl>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filterValues.clientStatus}
                  onChange={(e) => {
                    handleFilterValues({
                      name: "clientStatus",
                      value: e.target.value,
                    });
                  }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {clientType.map((unit, index) => (
                    <MenuItem key={index} value={unit}>
                      <Checkbox
                        checked={filterValues.clientStatus.indexOf(unit) > -1}
                      />
                      <ListItemText primary={unit} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Employee Status</InputLabel>
              <FormControl>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filterValues.providerStatus}
                  onChange={(e) => {
                    handleFilterValues({
                      name: "providerStatus",
                      value: e.target.value,
                    });
                  }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {filterProviderStatuses.map((unit, index) => (
                    <MenuItem key={index} value={unit}>
                      <Checkbox
                        checked={filterValues.providerStatus.indexOf(unit) > -1}
                      />
                      <ListItemText primary={unit} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Intake Stage</InputLabel>
              <FormControl>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filterValues.intakeStage}
                  onChange={(e) => {
                    handleFilterValues({
                      name: "intakeStage",
                      value: e.target.value,
                    });
                  }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {onTrackStage.map((unit, index) => (
                    <MenuItem key={index} value={unit}>
                      <Checkbox
                        checked={filterValues.intakeStage.indexOf(unit) > -1}
                      />
                      <ListItemText primary={unit} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel id="demo-simple-select-label" className="fromLabel">
                Onboarding Stage
              </InputLabel>
              <FormControl>
                <Select
                  multiple
                  className="form-control"
                  value={filterValues.onBoardingStage}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  onChange={(e: SelectChangeEvent<string[]>) => {
                    handleFilterValues({
                      name: "onBoardingStage",
                      value: e.target.value,
                    });
                  }}
                >
                  <MenuItem value={"All Stages"}>
                    <Checkbox
                      checked={
                        filterValues.onBoardingStage.indexOf("All Stages") > -1
                      }
                    />
                    {"All Stages"}
                  </MenuItem>
                  {filterOnTrackChangeTypes.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        <Checkbox
                          checked={
                            filterValues.onBoardingStage.indexOf(item) > -1
                          }
                        />
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Provider Type</InputLabel>
              <FormControl>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  multiple
                  id="demo-multiple-checkbox"
                  value={filterValues.providerType}
                  renderValue={(selected) => selected.join(", ")}
                  onChange={(e) => {
                    handleFilterValues({
                      name: "providerType",
                      value: e.target.value,
                    });
                  }}
                  MenuProps={MenuProps}
                >
                  {ProviderTypeList.map((unit, index) => (
                    <MenuItem key={index} value={unit.conceptValue}>
                      <Checkbox
                        checked={
                          filterValues.providerType.indexOf(unit.conceptValue) >
                          -1
                        }
                      />
                      <ListItemText primary={unit.conceptValue} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Provider Speciality</InputLabel>
              <FormControl>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filterValues.providerSpeciality}
                  onChange={(e) => {
                    handleFilterValues({
                      name: "providerSpeciality",
                      value: e.target.value,
                    });
                  }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {ProviderSpecialtyList.map((unit, index) => (
                    <MenuItem key={index} value={unit.conceptValue}>
                      <Checkbox
                        checked={
                          filterValues.providerSpeciality.indexOf(
                            unit.conceptValue
                          ) > -1
                        }
                      />
                      <ListItemText primary={unit.conceptValue} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Box className="stepperForm__footer b-0">
        <Button
          color="inherit"
          className="border-button"
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            setFilterValues({
              businessUnit: [],
              insuranceProvider: [],
              clientStatus: [],
              providerStatus: [],
              ScheduleStatus: "",
              intakeStage: [],
              onBoardingStage: [],
              clientConcerns: [],
              providerTasks: [],
              startDate: null,
              endDate: null,
              providerSpeciality: [],
              providerType: [],
              months: [],
              authCode: [],
            });
            dispatch(dataUpdated({ dataUpdate: false }));
          }}
        >
          Clear Filter
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            closeModal(false, { ...filterValues });
            if (filterValues.endDate || filterValues.startDate) {
              dispatch(
                GetUtilzationClients({
                  startDate: filterValues.startDate,
                  endDate: filterValues.endDate,
                  isClient: null,
                })
              );
            }
            setIsFilterApplied(true);
          }}
          className="button"
        >
          Apply Filter
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default PlanningFilter;
