import React, { useEffect, useState, useRef } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import { Box, Dialog, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Close } from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  setEditClient,
  setInitialState,
  updateClientAvailabilityDetailById,
  updateClientBasicDetailById,
  updateClientContactDetailById,
  updateClientInsuranceInfoById,
} from "../../../redux/features/client-main/client-slice";
import { setClientInsuranceUpdated } from "../../../redux/features/client-main/client-insurance-slice";
import {
  GetAllClientData,
  GetInactiveClients,
} from "../../../redux/features/client-main/client-list-slice";

import ClientCards from "../client-cards/ClientsCards";
import ClientList from "../client-list/ClientList";
import ClientModal from "../add-client/ClientModal";
import ClientBasicDetailsForm from "../client-stepper/ClientBasicDetails";
import ClientContactDetails from "../client-stepper/ClientContactDetails";
import AdminDetails from "../client-stepper/AdminDetails";
import InsuranceDetails from "../client-stepper/InsuranceDetails";
import ClientAvailabilityDetailsNew from "../client-stepper/ClientAvailabilityDetailsNew";

const Clients: React.FC = () => {
  // #region variables region

  //dispatch for redux
  const dispatch = useAppDispatch();

  const data = useAppSelector((state) => state.clientListSlice.listData);

  //stores client updated data according to utilization data range
  const updatedUtilzationData = useAppSelector(
    (state) => state.clientListSlice.utilizationListData.Clients
  );

  //handle changes in client data
  const updatedData = useAppSelector(
    (state) => state.clientListSlice.dataUpdate
  );
  //in-active list from redux
  const inactiveData = useAppSelector(
    (state) => state.clientListSlice.inactiveListData
  );

  //variable to check for data change
  const dataChange: boolean = useAppSelector(
    (state) => state.clientListSlice.listDataChange
  );

  //handles client basic details modal
  const [isEditBasicDetailsModal, setIsEditBasicDetailsModal] =
    useState<boolean>(false);

  //handles client contact details modal
  const [isEditContactDetailsModal, setIsEditContactDetailsModal] =
    useState<boolean>(false);

  //handles admin details modal
  const [isEditAdminDetailsModal, setIsEditAdminDetailsModal] =
    useState<boolean>(false);

  //handles client availability details modal
  const [isEditAvailabilityDetailsModal, setIsEditAvailabilityDetailsModal] =
    useState<boolean>(false);

  //handles insurance details modal
  const [isEditInsuranceDetailsModal, setIsEditInsuranceDetailsModal] =
    useState<boolean>(false);

  //handles client modal for forms
  const [isOpenClientModal, setIsOpenClientModal] = useState<boolean>(false);

  //client basic details from redux
  const clientBasicDetails = useAppSelector(
    (state) => state.client.clientBasicDetails
  );

  //binding action creators and dispatch
  const dispatchAction = bindActionCreators(
    {
      GetAllClientData,
      GetInactiveClients,
      setInitialState,
    },
    dispatch
  );

  //same dispatch ref between multiple re-renders
  const dispatchActionRef = useRef(dispatchAction);

  // #endregion

  // #region methods region
  //handles the closing of basic details form
  const handleCloseForBasicDetails = () => {
    dispatch(setInitialState({}));
    dispatch(setEditClient(false));
    setIsEditBasicDetailsModal(false);
    //setIsOpenMaxDialog(false);
  };

  //handles the edit form submission for client basic details
  const onSubmitBasicInfo = () => {
    dispatch(updateClientBasicDetailById({}));
    dispatch(setEditClient(false));
    setIsEditBasicDetailsModal(false);
    setIsEditAdminDetailsModal(false);
  };

  //handles the close logic when save changes or close edit form for client contact details
  const handleCloseForContactDetails = () => {
    dispatch(setInitialState({}));
    dispatch(setEditClient(false));
    setIsEditContactDetailsModal(false);
  };

  //handles the edit form submission for client contact details
  const onSubmitContactInfo = () => {
    dispatch(updateClientContactDetailById());
    dispatch(setEditClient(false));
    setIsEditContactDetailsModal(false);
  };

  //handles the close logic when save changes or close edit form for client contact details
  const handleCloseForAdministrativeDetails = () => {
    dispatch(setInitialState({}));
    dispatch(setEditClient(false));
    setIsEditAdminDetailsModal(false);
  };

  //handles the edit form submission for client availability details
  const onSubmitAvailabilityInfo = () => {
    // dispatch(setClientId({ id: clientId }));
    dispatch(updateClientAvailabilityDetailById());
    dispatch(setEditClient(false));
    setIsEditAvailabilityDetailsModal(false);
  };

  //handles the close logic when save changes or close edit form for client availability details
  const handleCloseForAvailabilityDetails = () => {
    dispatch(setInitialState({}));
    dispatch(setEditClient(false));
    setIsEditAvailabilityDetailsModal(false);
  };

  //handles the close logic when save changes or close edit form for client contact details
  const handleCloseForInsuranceDetails = () => {
    dispatch(setInitialState({}));
    dispatch(setEditClient(false));
    setIsEditInsuranceDetailsModal(false);
  };

  //handles the edit form submission for client contact details
  const onSubmitInsuranceInfo = () => {
    dispatch(updateClientInsuranceInfoById());
    dispatch(setClientInsuranceUpdated(true));
    setIsEditInsuranceDetailsModal(false);
    dispatch(setEditClient(false));
  };
  // #endregion

  //#region useEffect region
  //handle planname

  //clears search value when landing on this page
  useEffect(() => {
    dispatchActionRef.current.setInitialState({});
    dispatchActionRef.current.GetAllClientData();
    dispatchActionRef.current.GetInactiveClients();
  }, [dataChange]);

  //#endregion

  return (
    <>
      {/* {isLoading && <Loader isShow={isLoading} />} */}
      <div className="pageTemplate">
        <Grid size={{ xs: 12, sm: 9 }}>
          <Box className="detailsPageBody">
            <Grid container spacing={0}>
              <Grid size={{ xs: 12 }} mb={"48px"}>
                <ClientCards
                  mergedData={
                    updatedData
                      ? updatedUtilzationData
                      : [...data, ...inactiveData]
                  }
                  setIsOpenClientModal={setIsOpenClientModal}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                {
                  <ClientList
                    mergedData={
                      updatedData
                        ? updatedUtilzationData
                        : [...data, ...inactiveData]
                    }
                  />
                }
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {isOpenClientModal && (
          <ClientModal
            selectedTab={"All Clients"}
            isOpenClientModal={isOpenClientModal}
            setIsOpenClientModal={setIsOpenClientModal}
            clientBasicDetails={clientBasicDetails}
          />
        )}
        {isEditBasicDetailsModal && (
          <Dialog
            open={isEditBasicDetailsModal}
            className="largeModel largeModel--edit"
          >
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Edit Client
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={handleCloseForBasicDetails}
              >
                <Close />
              </Button>
            </Box>
            <ClientBasicDetailsForm onSubmit={onSubmitBasicInfo} />
          </Dialog>
        )}
        {isEditContactDetailsModal && (
          <Dialog
            open={isEditContactDetailsModal}
            className="largeModel largeModel--edit"
          >
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Edit Client
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={handleCloseForContactDetails}
              >
                <Close />
              </Button>
            </Box>
            <ClientContactDetails onSubmit={onSubmitContactInfo} />
          </Dialog>
        )}
        {isEditAdminDetailsModal && (
          <Dialog
            open={isEditAdminDetailsModal}
            className="largeModel largeModel--edit"
          >
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Edit Client
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={handleCloseForAdministrativeDetails}
              >
                <Close />
              </Button>
            </Box>
            <AdminDetails onSubmit={onSubmitBasicInfo} />
          </Dialog>
        )}
        {isEditAvailabilityDetailsModal && (
          <Dialog
            open={isEditAvailabilityDetailsModal}
            className="largeModel largeModel--edit"
          >
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Edit Client
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={handleCloseForAvailabilityDetails}
              >
                <Close />
              </Button>
            </Box>
            {/* <ClientAvailabilityDetails
              isBoardPage={true}
              onSubmit={onSubmitAvailabilityInfo}
            /> */}
            <ClientAvailabilityDetailsNew
              isBoardPage={true}
              onSubmit={onSubmitAvailabilityInfo}
            />
          </Dialog>
        )}
        {isEditInsuranceDetailsModal && (
          <Dialog
            open={isEditInsuranceDetailsModal}
            className="largeModel largeModel--edit"
          >
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Edit Client
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={handleCloseForInsuranceDetails}
              >
                <Close />
              </Button>
            </Box>
            <InsuranceDetails onSubmit={onSubmitInsuranceInfo} />
          </Dialog>
        )}
      </div>
    </>
  );
};

export default Clients;
