import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import ProviderImg from "../../assets/images/images-svg/provider-large-vertical.svg";
import ClientImg from "../../assets/images/images-svg/client-large.svg";
import { useSelector, useDispatch } from "react-redux";
import { RootState, useAppSelector } from "../../redux/store";
import { setDraggingAtomState } from "../../redux/features/trello-board/dragging-state-slice";
import { Popover } from "@mui/material";
import { CardData } from "../../types/board";
import { ReactComponent as Dots } from "../../assets/images/images-svg/dots.svg";
import PopoverOptions from "./PopoverOptions";
import { clientPopoverOptions } from "../../constants/client";
import { providerPopoverOptions } from "../../constants/providers";
import {
  getBoardAgingDays,
  getBoardsUpdatedDays,
  getTaskOverallDays,
} from "../../helpers/reverse-list/reverse-list";
import { useNavigate } from "react-router-dom";
import {
  setBoardScrollPosition,
  setSelectedBoardTab,
  setSelectedCard,
} from "../../redux/features/custom-board/custom-board-slice";

interface IIndexedTask {
  stageName: string; //column name;
  id: string;
  index: number;
  task: CardData | CardData;
  callbacks: any;
  chatCommand: (clientId: string) => void;
  intakeOrOnboarding?: boolean;
}

const Wrapper = styled.div<{ isDragging: boolean }>`
  width: 100%;
  padding: 10px 22px 10px 22px;
  background-color: ${(props) =>
    props.isDragging
      ? (props) => props.theme.taskDraggingColor
      : (props) => props.theme.taskColor};
  border-radius: 5px;
  align-items: center;

  &:hover {
    background-color: ${(props) => props.theme.taskDraggingColor};
  }
`;

const Task: React.FC<IIndexedTask> = ({
  stageName,
  id,
  index,
  task,
  callbacks,
  chatCommand,
  intakeOrOnboarding,
}) => {
  // #region variables region

  const navigate = useNavigate();

  //
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  //
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  //
  const handleClose = () => {
    setAnchorEl(null);
  };

  //
  const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;
  const dispatch = useDispatch();

  const [isDragging, setIsDragging] = useState(false);
  // const [draggingState, setDraggingAtomState] =
  //   useRecoilState(draggingAtomState);
  const draggingState = useAppSelector<boolean>(
    (state) => state.draggingStateSlice.dragging
  );
  // #endregion
  const selectedCard = useAppSelector<string[]>(
    (state) => state.boardSlice.selectedCard
  );
  const { selectedTab, scrollPosition } = useAppSelector(
    (state) => state.boardSlice
  );
  //#region methods region

  //redirect board to details page
  const handleRedirect = (task: CardData | CardData) => {
    if (selectedTab) {

      const [boardTemplate] = document.getElementsByClassName("boardTemplate");
      const data = {
        boardId: selectedTab.id,
        value: boardTemplate?.scrollLeft,
      };
      const updatedScrollPosition = scrollPosition.map((item) =>
        item.boardId === selectedTab.id
          ? { ...item, value: boardTemplate?.scrollLeft }
          : item
      );

      if (!scrollPosition.some((item) => item.boardId === selectedTab.id)) {
        updatedScrollPosition.push(data);
      }
      dispatch(setBoardScrollPosition(updatedScrollPosition));
    }
    if (task.entityType === "Provider") {
      navigate(`/provider/${task.entityId}`);
    } else {
      navigate(`/client/${task.entityId}`);
    }
  };

  //#endregion

  const selectHandler = (e: any) => {
    e.stopPropagation();
    if (selectedCard.includes(id)) {
      dispatch(setSelectedCard(selectedCard.filter((card) => card !== id)));
    } else {
      dispatch(setSelectedCard([...selectedCard, id]));
    }
  };

  //#region useEffect region
  useEffect(() => {
    // prevent unlimited re-rendering
    dispatch(setDraggingAtomState(isDragging));
  }, [isDragging, draggingState, dispatch]);

  //#endregion
  return (
    <Draggable draggableId={id} index={index}>
      {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => {
        setIsDragging(isDragging);
        return (
          <Wrapper
            isDragging={isDragging}
            ref={innerRef}
            {...draggableProps}
            {...dragHandleProps}
            className={
              selectedCard.includes(id)
                ? "StageInnerCard selected"
                : "StageInnerCard"
            }
            onClick={selectHandler}
          >
            <div className="StageInnerCard__user">
              <div className="StageInnerCard__userInner">
                <span className="StageInnerCard__userImage">
                  <img
                    src={
                      task.entityType === "Client"
                        ? task.cardProfile.url
                          ? task.cardProfile.url
                          : ClientImg
                        : task.cardProfile.url
                        ? task.cardProfile.url
                        : ProviderImg
                    } //image has to be sent from backend;
                    alt="client"
                    className="img-fluid"
                    onClick={() => {
                      chatCommand(task.entityId);
                    }}
                  />
                </span>
                <div className="StageInnerCard__userInfo">
                  <div>
                    <h6
                      className="StageInnerCard__userName activeLink"
                      onClick={() => handleRedirect(task)}
                    >
                      {task.cardTitle}
                    </h6>
                  </div>
                  {task.boardColumnUpdatedAt && (
                    <p>{getBoardsUpdatedDays(task.boardColumnUpdatedAt)}</p>
                  )}
                </div>
              </div>
              <div style={{ flex: 1 }} className="StageInnerCard__info">
                {task.createdAt && (
                  <h5 className="StageInnerCard__time">
                    {
                      //TODO: to have a better logic using isLastStage
                      stageName?.toUpperCase() === "INTAKE COMPLETE" ||
                      stageName?.toUpperCase() === "CREDENTIALING: COMPLETE"
                        ? getTaskOverallDays(
                            task.createdAt,
                            task.boardColumnUpdatedAt
                          )
                        : getBoardAgingDays(task.createdAt)
                    }
                  </h5>
                )}
                {intakeOrOnboarding && (
                  <div>
                    <button
                      aria-describedby={id}
                      onClick={handleClick}
                      style={{ cursor: "pointer" }}
                    >
                      <Dots />
                    </button>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <ul className="popoverLinkList">
                        {task.entityType === "Client"
                          ? clientPopoverOptions.map(
                              (item: string, index: number) => (
                                <PopoverOptions
                                  optionName={item}
                                  key={index}
                                  callback={(id: string) =>
                                    callbacks[0][index](id)
                                  }
                                  entityId={task.entityId}
                                  taskId={task.id}
                                  taskType={task.entityType}
                                  closeAnchor={() => handleClose()}
                                />
                              )
                            )
                          : providerPopoverOptions.map(
                              (item: string, index: number) => (
                                <PopoverOptions
                                  optionName={item}
                                  key={index}
                                  callback={(id: string) =>
                                    callbacks[1][index](id)
                                  }
                                  taskId={task.id}
                                  taskType={task.entityType}
                                  entityId={task.entityId}
                                  closeAnchor={() => handleClose()}
                                />
                              )
                            )}
                      </ul>
                    </Popover>
                  </div>
                )}
              </div>
            </div>

            {/* </div> */}
          </Wrapper>
          // <Wrapper
          //   isDragging={isDragging}
          //   ref={innerRef}
          //   {...draggableProps}
          //   {...dragHandleProps}
          // >
          //   <Text>{text}</Text>
          // </Wrapper>
        );
      }}
    </Draggable>
  );
};

export default React.memo(Task);
