import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import ResourceHubForms from "./ResourceHubForms";
import ResourceMessage from "./ResourceMessage";
import { GetAllResource } from "../../redux/features/resourceHub/resourceHub-slice";
import { useAppDispatch } from "../../redux/store";
import { KeyboardBackspace } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router";
import { AppUrls } from "../../AppUrls";

const ResourceHub = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState<string>(state?.tab || "Forms");

  useEffect(() => {
    dispatch(GetAllResource());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="pageTemplate resourceHub">
      <div className="resourceHeader">
        <Button
          onClick={() => {
            navigate(AppUrls.Client.BusinessSettings);
          }}
          className="backBtn"
          startIcon={<KeyboardBackspace />}
          style={{ marginBottom: 0 }}
        >
          Back
        </Button>
        <h3 className="settingPage__title">Resource Center</h3>
      </div>

      <div className="pageTemplate-top">
        <ul className="innerTabs mb-tab">
          <li className="innerTabs__item form-tabs">
            <button
              onClick={() => setSelectedTab("Forms")}
              className={selectedTab === "Forms" ? "active" : ""}
            >
              Forms
            </button>
          </li>
          <li className="innerTabs__item form-tabs">
            <button
              onClick={() => setSelectedTab("MessageTemplates")}
              className={selectedTab === "MessageTemplates" ? "active" : ""}
            >
              Templates
            </button>
          </li>
          <li className="innerTabs__item form-tabs">
            <button
              onClick={() => setSelectedTab("Automation")}
              className={selectedTab === "Automation" ? "active" : ""}
            >
              Automation
            </button>
          </li>
        </ul>

        {/* <Button variant="contained">+ Submit Idea</Button> */}
      </div>
      <div>{selectedTab === "Forms" && <ResourceHubForms />}</div>
      <div>{selectedTab === "MessageTemplates" && <ResourceMessage />}</div>
    </div>
  );
};

export default ResourceHub;
