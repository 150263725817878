import {
  useEffect,
  useState,
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction,
} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Menu,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  Close,
  DeleteOutlineOutlined,
  SettingsOutlined,
} from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  DeleteBoardcard,
  deleteCustomBoard,
  resetNewBoard,
  saveAutomationChanges,
  setEditBoard,
  setSaveAutomation,
  setSelectedCard,
  getAllCustomBoard,
} from "../../redux/features/custom-board/custom-board-slice";
import { setResponseValue } from "../../redux/features/api-response/api-response";
import { getClientBoards } from "../../redux/features/client-main/client-board-slice";
import { getProviderBoards } from "../../redux/features/providers-main/provider-board-slice";
import { ClientForms } from "../../types/client";
import { ClientInitialState } from "../../constants/client";
import {
  setClientData,
  setClientFilesFromResponse,
  setClientMessages,
  setEditClient,
  setInitialState,
  updateClientAvailabilityDetailById,
  updateClientBasicDetailById,
  updateClientContactDetailById,
  updateClientInsuranceInfoById,
} from "../../redux/features/client-main/client-slice";
import {
  setProviderData,
  setProviderFilesFromResponse,
  setProviderMessages,
  updateProviderAvailabilityById,
  updateProviderContactById,
  updateProviderEmploymentById,
} from "../../redux/features/providers-main/provider-slice";
import { setClientInsuranceUpdated } from "../../redux/features/client-main/client-insurance-slice";
import { DeleteProjectByID } from "../../redux/features/project/taskManager-slice";

import ClientService from "../../service/client.service";
import CommonService from "../../service/common.service";
import ProviderService from "../../service/provider.service";
import { postMoveCard } from "../../service/customBoard.service";

import RenderSelectGroup from "./MoveCard/RenderSelectGroup";
import Intake from "./BoardComponents/Intake";
import Onboarding from "./BoardComponents/Onboarding";
import NewBoardModal from "./BoardComponents/addBoard/NewBoardModal";
import AutomationCard from "./BoardComponents/automation/AutomationCard";
import ClientContactDetails from "../clients/client-stepper/ClientContactDetails";
import Runs from "./BoardComponents/runs/Runs";
import CustomBoard from "./BoardComponents/CustomBoard/CustomBoard";
import UserStateCards from "./BoardComponents/UserStateCards";
import AdminDetails from "../clients/client-stepper/AdminDetails";
import ClientAvailabilityDetailsNew from "../clients/client-stepper/ClientAvailabilityDetailsNew";
import InsuranceDetails from "../clients/client-stepper/InsuranceDetails";
import BoardTask from "./BoardComponents/board-task/BoardTask";
import AddTaskModal from "./BoardComponents/board-task/AddTaskModal";
import DeleteConfirmation from "../../layouts/delete-confirmation/DeleteConfirmation";
import NewProjectModal from "./BoardComponents/board-task/NewProjectModal";
import Dashboard from "../dashboard/Dashboard";
import AvailabilityDetailsNew from "../providers/providers-forms/AvailabilityDetailsFormNew";
import EmploymentDetailsForm from "../providers/providers-forms/EmploymentDetailsForm";
import ContactDetailsForm from "../providers/providers-forms/ContactDetailsForm";
import ClientBasicDetailsForm from "../clients/client-stepper/ClientBasicDetails";
import { Board, IBoardMenu } from "../../types/board";
import { DashboardType } from "../../types/dashboard";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
interface BoardPanelProps {
  setSelectedTab: Dispatch<
    SetStateAction<{
      id: string;
      type: string;
      index?: number;
    }>
  >;
  selectedTab: {
    id: string;
    type: string;
    index?: number;
  };
  drawerOpen: boolean;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

const BoardPanel: React.FC<BoardPanelProps> = ({
  selectedTab,
  setSelectedTab,
  drawerOpen,
  setDrawerOpen,
}) => {
  const [saveAutomationDialog, setsaveAutomationDialog] = useState(false);
  const [moveEl, setMoveEl] = useState<null | HTMLElement>(null);
  const [boardmenu, setBoardMenu] = useState<IBoardMenu[]>([]);
  const [copyEl, setCopyEl] = useState<null | HTMLElement>(null);
  const [isEditBasicDetailsModal, setIsEditBasicDetailsModal] =
    useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteCardId, setDeleteCardId] = useState<string>("");
  const [isEditContactDetailsModal, setIsEditContactDetailsModal] =
    useState<boolean>(false);
  const [isEditAdminDetailsModal, setIsEditAdminDetailsModal] =
    useState<boolean>(false);
  const [isEditAvailabilityDetailsModal, setIsEditAvailabilityDetailsModal] =
    useState<boolean>(false);
  const [isEditInsuranceDetailsModal, setIsEditInsuranceDetailsModal] =
    useState<boolean>(false);
  const [isEditEmploymentModal, setIsEditEmploymentModal] =
    useState<boolean>(false);
  const [isEditContactModal, setIsEditContactModal] = useState<boolean>(false);
  const [isEditAvailabilityModal, setIsEditAvailabilityModal] =
    useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userCardData, setUserCardData] = useState<{
    boardName: string;
    card: any;
    stages: any[];
  }>({
    boardName: "",
    card: {},
    stages: [],
  });
  const [iscurrentRuleValidated, setIsCurrentRuleValidated] =
    useState<boolean>(false);
  const [intakeModal, setIntakeModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState(false);
  const [addTaskModal, setAddTaskModal] = useState<boolean>(false);
  const [isEditProject, setIsEditProject] = useState<boolean>(false);
  const [isDeleteProject, setIsDeleteProject] = useState<boolean>(false);
  const [taskValidation, setTaskValidation] = useState<boolean>(true);

  const copy = Boolean(copyEl);
  const move = Boolean(moveEl);

  const dispatch = useAppDispatch();

  const selectedCards = useAppSelector(
    (state) => state.boardSlice.selectedCard
  );
  const intakeBoardInfo = useAppSelector((state) => state.clientIntakeStage);
  const onBoardingInfo = useAppSelector(
    (state) => state.providerOnBoardingStage
  );
  const customBoard = useAppSelector((state) => state.boardSlice.allBoard);
  const projectList = useAppSelector((state) => state.taskManagerSlice.project);

  //handle move card handler
  const moveHandler = async (selectedOption: string, stageId: string) => {
    try {
      if (selectedCards.length <= 0) {
        dispatch(setResponseValue({ name: "error", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: "Please Select the card!",
          })
        );
        dispatch(setResponseValue({ name: "pending", value: false }));
        return;
      }
      dispatch(setResponseValue({ name: "pending", value: true }));
      const response = await postMoveCard({
        selectedCards,
        selectedStage: stageId,
        selectedOption,
      });
      if (response.status === 1) {
        dispatch(getClientBoards({ useLoader: false }));
        dispatch(getProviderBoards({ useLoader: false }));
        dispatch(getAllCustomBoard());
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: response.message,
          })
        );
      } else {
        dispatch(setResponseValue({ name: "error", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: response.message,
          })
        );
      }
      dispatch(setResponseValue({ name: "pending", value: false }));
      dispatch(setSelectedCard([]));
      setCopyEl(null);
      setMoveEl(null);
    } catch (e) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: "Error Occured",
        })
      );
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };

  // handle update handler
  const updateHandler = (board: Board) => {
    dispatch(
      setEditBoard({
        ...board,
        stages: [...board.stages].sort((a, b) => a.index - b.index),
        automation: board.automation,
      })
    );
    setEditModal(true);
  };

  //TODO: refactor, can be merged
  const getClientById = async (clientId: string) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const data = await ClientService.getClientById(clientId);
      if (data.status) {
        const newData: ClientForms = {
          isEditClient: false,
          clientBasicDetails: {
            ...data.data.clientBasicDetails,
            files: data.data.clientBasicDetails.files || [],
          },
          clientContactDetails: data.data.clientContactDetails
            ? { ...data.data.clientContactDetails }
            : { ...ClientInitialState.clientContactDetails },
          // availabilityDetails: data.data.availabilityDetails
          //   ? { ...data.data.availabilityDetails }
          //   : { ...ClientInitialState.availabilityDetails },
          availabilityDetails:
            data.data.availabilityDetails.length > 0
              ? data.data.availabilityDetails
              : ClientInitialState.availabilityDetails,
          clientInsurance:
            data.data.clientInsurance.length > 0
              ? data.data.clientInsurance
              : ClientInitialState.clientInsurance,
          authorizations:
            data.data.authorizations.length > 0
              ? data.data.authorizations
              : ClientInitialState.authorizations,
          clientFiles: [],
          clientMessages: [],
          bookedServices: [],
          schedules: [],
          clientUtilization:
            data.data.clientUtilization.length > 0
              ? data.data.clientUtilization
              : ClientInitialState.clientUtilization,
          clientAuthUtilization:
            data.data.clientAuthUtilization.length > 0
              ? data.data.clientAuthUtilization
              : ClientInitialState.clientAuthUtilization,
        };
        dispatch(setClientData(newData));
        return data.status;
      }
    } catch (e: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };
  //fetch comment by Id
  const getCommentById = async (clientId: string) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const data = await ClientService.getClientMessagesById(clientId);
      if (data.status) {
        dispatch(setClientMessages(data.data));
      }
      return data.status;
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: true }));
    }
  };

  //get Files By ID
  const getFilesById = async (clientId: string) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const data = await CommonService.getFilesByEntityId(clientId);
      if (data.status) {
        dispatch(setClientFilesFromResponse(data.data));
      }
      return data.status;
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: true }));
    }
  };

  //handles fetching data and files
  const fetchClientAndClientFilesById = async (
    clientId: string
  ): Promise<boolean> => {
    const clientData = getClientById(clientId);
    const filesData = getFilesById(clientId);
    const MessageData = getCommentById(clientId);
    const clientDataStatus = await clientData;
    const filesDataStatus = await filesData;
    const MessageDataStatus = await MessageData;
    if (clientDataStatus && filesDataStatus && MessageDataStatus) {
      return true;
    } else {
      return false;
    }
  };

  //handles basic details modal
  const clientBasicDetailsModal = async (clientId: string): Promise<void> => {
    const modalValue = await fetchClientAndClientFilesById(clientId);

    if (modalValue) {
      dispatch(setEditClient(true));
      setIsEditBasicDetailsModal(modalValue);
    }
  };

  const DeleteModal = async (cardId: string): Promise<void> => {
    setShowDeleteModal(true);
    setDeleteCardId(cardId);
  };

  const clientContactDetailsModal = async (clientId: string): Promise<void> => {
    const modalValue = await fetchClientAndClientFilesById(clientId);
    if (modalValue) {
      dispatch(setEditClient(true));
      setIsEditContactDetailsModal(modalValue);
    }
  };

  //handles admin details modal
  const clientAdminDetailsModal = async (clientId: string): Promise<void> => {
    const modalValue = await fetchClientAndClientFilesById(clientId);
    if (modalValue) {
      dispatch(setEditClient(true));
      setIsEditAdminDetailsModal(modalValue);
    }
  };

  //handles insurance details modal
  const clientInsuranceDetailsModal = async (
    clientId: string
  ): Promise<void> => {
    const modalValue = await fetchClientAndClientFilesById(clientId);
    if (modalValue) {
      dispatch(setEditClient(true));
      setIsEditInsuranceDetailsModal(modalValue);
    }
  };

  //handles availability details modal
  const clientAvailabilityDetailsModal = async (
    clientId: string
  ): Promise<void> => {
    const modalValue = await fetchClientAndClientFilesById(clientId);
    if (modalValue) {
      dispatch(setEditClient(true));
      setIsEditAvailabilityDetailsModal(modalValue);
    }
  };

  //handles the closing of basic details form
  const handleCloseForBasicDetails = () => {
    dispatch(setInitialState({}));
    dispatch(setEditClient(false));
    setIsEditBasicDetailsModal(false);
  };

  //handles the edit form submission for client basic details
  const onSubmitBasicInfo = () => {
    dispatch(updateClientBasicDetailById({}));
    dispatch(setEditClient(false));
    setIsEditBasicDetailsModal(false);
    setIsEditAdminDetailsModal(false);
  };

  //handles the close logic when save changes or close edit form for client contact details
  const handleCloseForContactDetails = () => {
    dispatch(setInitialState({}));
    dispatch(setEditClient(false));
    setIsEditContactDetailsModal(false);
  };

  //handles the edit form submission for client contact details
  const onSubmitContactInfo = () => {
    dispatch(updateClientContactDetailById());
    dispatch(setEditClient(false));
    setIsEditContactDetailsModal(false);
  };

  //handles the close logic when save changes or close edit form for client contact details
  const handleCloseForAdministrativeDetails = () => {
    dispatch(setInitialState({}));
    dispatch(setEditClient(false));
    setIsEditAdminDetailsModal(false);
  };

  //handles the edit form submission for client availability details
  const onSubmitAvailabilityInfo = () => {
    // dispatch(setClientId({ id: clientId }));
    dispatch(updateClientAvailabilityDetailById());
    dispatch(setEditClient(false));
    setIsEditAvailabilityDetailsModal(false);
  };

  //handles the close logic when save changes or close edit form for client availability details
  const handleCloseForAvailabilityDetails = () => {
    dispatch(setInitialState({}));
    dispatch(setEditClient(false));
    setIsEditAvailabilityDetailsModal(false);
  };

  //handles the close logic when save changes or close edit form for client contact details
  const handleCloseForInsuranceDetails = () => {
    dispatch(setInitialState({}));
    dispatch(setEditClient(false));
    setIsEditInsuranceDetailsModal(false);
  };

  //employment details update handler
  const editEmploymentDetailsUpdater = () => {
    dispatch(updateProviderEmploymentById({}));
    setIsEditEmploymentModal(false);
  };

  //contact details update handler
  const editContactDetailsUpdater = () => {
    dispatch(updateProviderContactById());
    setIsEditContactModal(false);
  };

  //availability details update handler
  const editAvailabilityDetailsUpdater = () => {
    dispatch(updateProviderAvailabilityById());
    setIsEditAvailabilityModal(false);
  };

  //handles the edit form submission for client contact details
  const onSubmitInsuranceInfo = () => {
    dispatch(updateClientInsuranceInfoById());
    dispatch(setClientInsuranceUpdated(true));
    setIsEditInsuranceDetailsModal(false);
    dispatch(setEditClient(false));
  };

  //fetch get provider by Id
  const getProviderById = async (providerId: string) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { data, status, isError } = await ProviderService.GetProviderById(
        providerId
      );
      if (status) {
        dispatch(setProviderData(data));
        return !isError;
      }
    } catch (e: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };

  //fetches the client files
  const getFilesByIdProvider = async (providerId: string) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const data = await CommonService.getFilesByEntityId(providerId);

      if (data.status) {
        dispatch(setProviderFilesFromResponse(data.data));
        return data.status;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };

  //fetches the Provider Comments
  const getCommentsById = async (providerId: string) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const data = await ProviderService.getProviderMessagesById(providerId);
      if (data.status) {
        dispatch(setProviderMessages(data.data));
        return data.status;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };

  //handles deletion of card
  const handleDeleteCard = () => {
    if (deleteCardId) dispatch(DeleteBoardcard({ id: deleteCardId }));
    setShowDeleteModal(false);
  };

  //fetch Data by Id
  const fetchDataById = async (providerId: string): Promise<boolean> => {
    const providerData = getProviderById(providerId);
    const filesData = getFilesByIdProvider(providerId);
    const messageData = getCommentsById(providerId);
    const providerDataStatus = await providerData;
    const filesDataStatus = await filesData;
    const MessageDataStatus = await messageData;
    if (providerDataStatus && filesDataStatus && MessageDataStatus) {
      return true;
    } else {
      return false;
    }
  };

  //Edit employement Details Modal
  const editEmploymentDetailsModal = async (id: string): Promise<void> => {
    const modalValue = await fetchDataById(id);
    if (modalValue) {
      setIsEditEmploymentModal(modalValue);
    }
  };

  //Edit Contact Details Modal
  const editEditContactModal = async (id: string): Promise<void> => {
    const modalValue = await fetchDataById(id);
    if (modalValue) {
      setIsEditContactModal(modalValue);
    }
  };

  //Edit Availability Details Modal
  const editAvailabilityModal = async (id: string): Promise<void> => {
    const modalValue = await fetchDataById(id);
    if (modalValue) {
      setIsEditAvailabilityModal(modalValue);
    }
  };

  //handle delete handler
  const deleteHandler = (board: Board) => {
    dispatch(setEditBoard(board));
    setDeleteModal(true);
  };

  //handle deletion Project
  const handleDeleteProject = () => {
    dispatch(
      DeleteProjectByID({
        id: selectedTab.id,
      })
    );
    dispatch(setResponseValue({ name: "pending", value: true }));
    if (selectedTab.index) {
      setSelectedTab({
        id: projectList[selectedTab.index - 1].id,
        type: "Projects",
      });
    } else if (selectedTab.index === 0) {
      setSelectedTab({
        id: "My Tasks",
        type: "Focus",
      });
    }

    setTimeout(() => {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }, 1000);
  };

  const intakeModalFunction = (boardName: string, clientId: string): void => {
    if (boardName === "intake") {
      let breakPoint = false;
      for (let stage of intakeBoardInfo.stages) {
        for (let card of stage.boardColumnNames) {
          if (card.entityId === clientId) {
            setUserCardData({
              boardName: intakeBoardInfo.boardName,
              card: { ...card, stageId: stage.id },
              stages: intakeBoardInfo.stages,
            });
            breakPoint = true;
          }
          if (breakPoint) break;
        }
        if (breakPoint) break;
      }
    } else if (boardName === "onboarding") {
      let breakPoint = false;
      for (let stage of onBoardingInfo.stages) {
        for (let card of stage.boardColumnNames) {
          if (card.entityId === clientId) {
            setUserCardData({
              boardName: onBoardingInfo.boardName,
              card: { ...card, stageId: stage.id },
              stages: onBoardingInfo.stages,
            });
            breakPoint = true;
          }
          if (breakPoint) break;
        }
        if (breakPoint) break;
      }
    } else {
      const board = customBoard.find((board) => board.boardName === boardName);
      if (board) {
        let breakPoint = false;
        for (let stage of board.stages) {
          for (let card of stage.boardColumnNames) {
            if (card.entityId === clientId) {
              setUserCardData({
                boardName: board.boardName,
                card: { ...card, stageId: stage.id },
                stages: board.stages,
              });
              breakPoint = true;
            }
            if (breakPoint) break;
          }
          if (breakPoint) break;
        }
      }
    }
    setIntakeModal(true);
  };

  const callbacks = [
    [
      clientBasicDetailsModal,
      clientContactDetailsModal,
      clientAdminDetailsModal,
      clientInsuranceDetailsModal,
      clientAvailabilityDetailsModal,
      DeleteModal,
    ],
    [
      editEmploymentDetailsModal,
      editEditContactModal,
      editAvailabilityModal,
      DeleteModal,
    ],
  ];

  const [panelData, setPanelData] = useState<{ [key in string]: JSX.Element }>({
    Intake: (
      <Intake
        updateHandler={updateHandler.bind(null, {
          ...intakeBoardInfo,
          primitive: true,
        })}
        clientIntakeModal={intakeModalFunction.bind(null, "intake")}
        callbacks={callbacks}
      />
    ),
    Onboarding: (
      <Onboarding
        callbacks={callbacks}
        updateHandler={updateHandler.bind(null, {
          ...onBoardingInfo,
          primitive: true,
        })}
        providerIntakeModal={intakeModalFunction.bind(null, "onboarding")}
      />
    ),
    Automation: (
      <AutomationCard
        setSaveAutomation={setsaveAutomationDialog}
        saveAutomationDialog={saveAutomationDialog}
        setSelectedTab={setSelectedTab}
        taskValidation={taskValidation}
        validation={(value) => {
          setIsCurrentRuleValidated(value);
        }}
      />
    ),
    "My Tasks": <BoardTask projectId="My Tasks" />,
    "Client Hours": <Dashboard type={DashboardType.ClientHours} />,
    "Provider Hours": <Dashboard type={DashboardType.ProviderHours} />,
    "Intake Dashboard": <Dashboard type={DashboardType.IntakeSummary} />,
    "Onboarding Dashboard": (
      <Dashboard type={DashboardType.OnboardingSummary} />
    ),
    Logs: <Runs />,
  });
  //handle functions form step

  const boardName = useMemo(() => {
    if (["Clients", "Providers"].includes(selectedTab.type)) {
      if (selectedTab.id === "Intake") return `${selectedTab.type} / Intake`;
      if (selectedTab.id === "Onboarding")
        return `${selectedTab.type} / Onboarding`;
      return `${selectedTab.type} / ${
        customBoard?.find(({ id }) => id === selectedTab.id)?.boardName
      }`;
    }
    if (selectedTab.type === "Projects") {
      return `${selectedTab.type} / ${
        projectList?.find(({ id }) => id === selectedTab.id)?.name
      }`;
    }
    if (selectedTab.type === "Automation" || selectedTab.type === "Logs") {
      return selectedTab.type;
    }

    return `${selectedTab.type} / ${selectedTab.id}`;
  }, [selectedTab, customBoard, projectList]);

  const boardExists = useCallback(
    (name: string) => customBoard.some(({ boardName }) => boardName === name),
    [customBoard]
  );

  useEffect(() => {
    if (customBoard) {
      const paneldata: { [key in string]: JSX.Element } = {};
      customBoard.forEach((board) => {
        paneldata[board.id] = (
          <CustomBoard
            board={board}
            customIntakeModal={intakeModalFunction.bind(null, board.boardName)}
            callbacks={callbacks}
            updateHandler={updateHandler.bind(null, board)}
            deleteHandler={deleteHandler.bind(null, board)}
          />
        );
      });
      projectList.forEach((project) => {
        paneldata[project.id] = <BoardTask projectId={project.id} />;
      });
      setPanelData((pre) => ({ ...pre, ...paneldata }));
    }
  }, [customBoard, onBoardingInfo, intakeBoardInfo, projectList]);

  /**
   * populate board menu
   */
  useEffect(() => {
    if (customBoard) {
      let allNewBoard = [intakeBoardInfo, onBoardingInfo, ...customBoard];
      const newData = allNewBoard
        .map((board) => {
          if (board.boardType === "client" && selectedTab.type === "Clients") {
            return {
              id: board.id,
              name: board.name ? board.name : board.boardName,
              boardType: board.boardType,
              stages: board.stages.map((stage) => {
                return { id: stage.id, name: stage.stageName };
              }),
            };
          } else if (
            board.boardType === "provider" &&
            selectedTab.type === "Providers"
          ) {
            return {
              id: board.id,
              name: board.name ? board.name : board.boardName,
              boardType: board.boardType,
              stages: board.stages.map((stage) => {
                return { id: stage.id, name: stage.stageName };
              }),
            };
          }
        })
        .filter(Boolean);
      // filter out undefined values
      if (newData.length) {
        setBoardMenu(newData as IBoardMenu[]); // cast to IBoardMenu[] to ensure type compatibility
      }
    }
  }, [selectedTab.type, customBoard]);

  useEffect(() => {
    setPanelData((pre) => ({
      ...pre,
      Intake: (
        <Intake
          updateHandler={updateHandler.bind(null, {
            ...intakeBoardInfo,
            primitive: true,
          })}
          clientIntakeModal={intakeModalFunction.bind(null, "intake")}
          callbacks={callbacks}
        />
      ),
    }));
  }, [intakeBoardInfo]);

  useEffect(() => {
    setPanelData((pre) => ({
      ...pre,
      Onboarding: (
        <Onboarding
          callbacks={callbacks}
          updateHandler={updateHandler.bind(null, {
            ...onBoardingInfo,
            primitive: true,
          })}
          providerIntakeModal={intakeModalFunction.bind(null, "onboarding")}
        />
      ),
    }));
  }, [onBoardingInfo]);

  useEffect(() => {
    setPanelData((pre) => ({
      ...pre,
      Automation: (
        <AutomationCard
          setSaveAutomation={setsaveAutomationDialog}
          saveAutomationDialog={saveAutomationDialog}
          setSelectedTab={setSelectedTab}
          taskValidation={taskValidation}
          validation={(value) => {
            setIsCurrentRuleValidated(value);
          }}
        />
      ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskValidation, saveAutomationDialog]);

  return (
    <div
      className={
        Object.values(DashboardType).some((item) => item === selectedTab.id)
          ? "boardTemplate dashboardTemplateSide"
          : "boardTemplate"
      }
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: selectedTab?.id?.includes("Hours") ? "0px" : "20px",
          flexWrap: "wrap",
          position: "sticky",
          left: "0",
          bottom: "0",
          right: "0",
        }}
      >
        <ul className="innerTabs m-0" style={{ overflow: "hidden" }}>
          <li>
            <Button
              onClick={() => setDrawerOpen(!drawerOpen)}
              sx={{ minWidth: "25px" }}
            >
              {drawerOpen ? (
                <ArrowBackIosIcon color="primary" />
              ) : (
                <ArrowForwardIosIcon color="primary" />
              )}
            </Button>
          </li>
          <li>
            <Typography mr={1} variant="h5" fontWeight={"bold"}>
              {boardName}
            </Typography>
          </li>
        </ul>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
          {["Automation"].includes(selectedTab.type) && (
            <Button
              variant="outlined"
              sx={{ width: "200px", height: "40px", lineHeight: "18px" }}
              onClick={() => {
                setTaskValidation(iscurrentRuleValidated);
                if (!iscurrentRuleValidated) return;
                setsaveAutomationDialog(false);
                dispatch(setSaveAutomation(false));
              }}
            >
              Save Rules
            </Button>
          )}
          {["Clients", "Providers"].includes(selectedTab.type) && (
            <>
              <Button
                variant="outlined"
                onClick={(e) => setMoveEl(e.currentTarget)}
                sx={{ margin: "0px 5px", borderRadius: "8px" }}
              >
                Move
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={moveEl}
                open={move}
                onClose={() => setMoveEl(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {boardmenu.map((board) => (
                  <RenderSelectGroup
                    key={board?.id}
                    moveHandler={moveHandler.bind(null, "move")}
                    board={board}
                  />
                ))}
              </Menu>
              <Button
                id="demo-customized-button"
                aria-controls={copy ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={copy ? "true" : undefined}
                variant="outlined"
                disableElevation
                onClick={(e) => setCopyEl(e.currentTarget)}
                sx={{ margin: "0px 5px", borderRadius: "8px" }}
              >
                Copy
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={copyEl}
                open={copy}
                onClose={() => setCopyEl(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {boardmenu.map((board) => (
                  <RenderSelectGroup
                    key={board.id}
                    moveHandler={moveHandler.bind(null, "copy")}
                    board={board}
                  />
                ))}
              </Menu>
            </>
          )}
          {["Projects"].includes(selectedTab.type) && (
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Button
                variant="contained"
                onClick={(e) => setAddTaskModal(true)}
                sx={{ margin: "0px 5px" }}
              >
                + Add Task
              </Button>
              <SettingsOutlined
                sx={{ color: "rgb(25, 118, 210)", cursor: "pointer" }}
                onClick={() => setIsEditProject(true)}
              />
              <DeleteOutlineOutlined
                sx={{ color: "rgb(25, 118, 210)", cursor: "pointer" }}
                onClick={() => setIsDeleteProject(true)}
              />
            </div>
          )}
        </div>
      </div>
      <div>{panelData[selectedTab.id]}</div>
      <Dialog open={editModal} className="boardLargeModel">
        <Grid>
          <Box className="largeModel__head">
            <Typography variant="h5" className="largeModel__title">
              Edit Board
            </Typography>
            <Button
              className="largeModel__closeBtn"
              onClick={() => {
                setEditModal(false);
                dispatch(resetNewBoard());
              }}
            >
              <Close />
            </Button>
          </Box>
        </Grid>
        <NewBoardModal
          boardExists={boardExists}
          addedNew={false}
          setModal={setEditModal}
        />
      </Dialog>
      <Dialog open={deleteModal} className="smallModel">
        <div>
          <DialogContent>Do you want to delete this board?</DialogContent>
          <DialogActions>
            <Button
              className="border-button"
              variant="contained"
              onClick={() => {
                setDeleteModal(false);
                dispatch(resetNewBoard());
              }}
              color="secondary"
            >
              No
            </Button>
            <Button
              className="button"
              variant="contained"
              onClick={() => {
                dispatch(deleteCustomBoard());
                setDeleteModal(false);
                dispatch(resetNewBoard());
                setSelectedTab({ type: "Clients", id: "Intake" });
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      {intakeModal && (
        <UserStateCards cardData={userCardData} closeCommand={setIntakeModal} />
      )}
      {isEditBasicDetailsModal && (
        <Dialog
          open={isEditBasicDetailsModal}
          className="largeModel largeModel--edit"
        >
          <Box className="largeModel__head">
            <Typography variant="h5" className="largeModel__title">
              Edit Client - Basic Information
            </Typography>
            <Button
              className="largeModel__closeBtn"
              onClick={handleCloseForBasicDetails}
            >
              <Close />
            </Button>
          </Box>
          <ClientBasicDetailsForm onSubmit={onSubmitBasicInfo} />
        </Dialog>
      )}
      {isEditContactDetailsModal && (
        <Dialog
          open={isEditContactDetailsModal}
          className="largeModel largeModel--edit"
        >
          <Box className="largeModel__head">
            <Typography variant="h5" className="largeModel__title">
              Edit Client - Contact Information
            </Typography>
            <Button
              className="largeModel__closeBtn"
              onClick={handleCloseForContactDetails}
            >
              <Close />
            </Button>
          </Box>
          <ClientContactDetails onSubmit={onSubmitContactInfo} />
        </Dialog>
      )}
      {isEditAdminDetailsModal && (
        <Dialog
          open={isEditAdminDetailsModal}
          className="largeModel largeModel--edit"
        >
          <Box className="largeModel__head">
            <Typography variant="h5" className="largeModel__title">
              Edit Client - Admin Details
            </Typography>
            <Button
              className="largeModel__closeBtn"
              onClick={handleCloseForAdministrativeDetails}
            >
              <Close />
            </Button>
          </Box>
          <AdminDetails onSubmit={onSubmitBasicInfo} />
        </Dialog>
      )}
      {isEditAvailabilityDetailsModal && (
        <Dialog
          open={isEditAvailabilityDetailsModal}
          className="largeModel largeModel--edit"
        >
          <Box className="largeModel__head">
            <Typography variant="h5" className="largeModel__title">
              Edit Client - Availability
            </Typography>
            <Button
              className="largeModel__closeBtn"
              onClick={handleCloseForAvailabilityDetails}
            >
              <Close />
            </Button>
          </Box>
          <ClientAvailabilityDetailsNew
            isBoardPage={true}
            onSubmit={onSubmitAvailabilityInfo}
          />
        </Dialog>
      )}
      {isEditInsuranceDetailsModal && (
        <Dialog
          open={isEditInsuranceDetailsModal}
          className="largeModel largeModel--edit"
        >
          <Box className="largeModel__head">
            <Typography variant="h5" className="largeModel__title">
              Edit Client - Insurance Providers
            </Typography>
            <Button
              className="largeModel__closeBtn"
              onClick={handleCloseForInsuranceDetails}
            >
              <Close />
            </Button>
          </Box>
          <InsuranceDetails onSubmit={onSubmitInsuranceInfo} />
        </Dialog>
      )}
      {isEditEmploymentModal && (
        <Dialog
          className="largeModel"
          open={isEditEmploymentModal}
          onClose={() => {
            setIsEditEmploymentModal(false);
          }}
        >
          <Box className="stepperForm">
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Edit Employee - Basic Information
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={() => {
                  setIsEditEmploymentModal(false);
                }}
              >
                <Close />
              </Button>
            </Box>
          </Box>
          <EmploymentDetailsForm
            isNew={false}
            updateHandler={() => editEmploymentDetailsUpdater()}
          />
        </Dialog>
      )}
      {isEditContactModal && (
        <Dialog
          className="largeModel"
          open={isEditContactModal}
          onClose={() => {
            setIsEditContactModal(false);
            dispatch(setInitialState(null));
          }}
        >
          <Box className="stepperForm">
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Edit Employee - Contact Information
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={() => {
                  setIsEditContactModal(false);
                  dispatch(setInitialState(null));
                }}
              >
                <Close />
              </Button>
            </Box>
          </Box>
          <ContactDetailsForm
            isNew={false}
            updateHandler={() => {
              editContactDetailsUpdater();
            }}
          />
        </Dialog>
      )}
      {isEditAvailabilityModal && (
        <Dialog
          className="largeModel"
          open={isEditAvailabilityModal}
          onClose={() => {
            setIsEditAvailabilityModal(false);
            dispatch(setInitialState(null));
          }}
        >
          <Box className="stepperForm">
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Edit Employee - Availability
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={() => {
                  setIsEditAvailabilityModal(false);
                  dispatch(setInitialState(null));
                }}
              >
                <Close />
              </Button>
            </Box>
          </Box>
          <AvailabilityDetailsNew
            isNew={false}
            updateHandler={() => editAvailabilityDetailsUpdater()}
            isBoardPage={true}
          />
        </Dialog>
      )}
      {iscurrentRuleValidated && saveAutomationDialog && (
        <Dialog open={saveAutomationDialog} className="smallModel">
          <div>
            <DialogContent>
              Do you want to save current Automation?
            </DialogContent>
            <DialogActions>
              <Button
                className="border-button"
                variant="contained"
                onClick={() => {
                  setsaveAutomationDialog(false);
                  setIsCurrentRuleValidated(false);
                  dispatch(setSaveAutomation(false));
                }}
                color="secondary"
              >
                No
              </Button>
              <Button
                className="button"
                variant="contained"
                onClick={() => {
                  dispatch(setSaveAutomation(false));
                  setsaveAutomationDialog(false);
                  setIsCurrentRuleValidated(false);
                  dispatch(saveAutomationChanges());
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      )}
      {showDeleteModal && (
        <Dialog open={showDeleteModal} className="smallModel">
          <div>
            <DialogContent>Do you want to delete this card?</DialogContent>
            <DialogActions>
              <Button
                className="border-button"
                variant="contained"
                color="secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                No
              </Button>
              <Button
                className="button"
                variant="contained"
                onClick={handleDeleteCard}
              >
                Yes
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      )}
      {addTaskModal && (
        <Dialog open={addTaskModal} className="boardLargeModel">
          <AddTaskModal setAddTaskModal={setAddTaskModal} isEdit={false} />
        </Dialog>
      )}
      {isDeleteProject && (
        <DeleteConfirmation
          name={"Project"}
          setModal={(value) => {
            if (value) {
              handleDeleteProject();
            }
            setIsDeleteProject(false);
          }}
        />
      )}
      {isEditProject && (
        <Dialog open={isEditProject} className="boardLargeModel">
          <NewProjectModal setBoardModal={setIsEditProject} isEdit={true} />
        </Dialog>
      )}
    </div>
  );
};

export default BoardPanel;
