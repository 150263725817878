import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "@reduxjs/toolkit";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  setActiveSteps,
  setSearchValue,
} from "../../../redux/features/active-steps/active-slice";
import { setInitialState } from "../../../redux/features/client-main/client-slice";

import InLoader from "../../../layouts/loader/InLoader";

import { filterClients } from "../../../helpers/client-filter-helper/client-filter-helper";
import { getAge } from "../../../utils/DateUtils";

import { ClientForms } from "../../../types/client";

import ClientImg from "../../../assets/images/images-svg/client-large.svg";
import { ReactComponent as LeftArrow } from "../../../assets/images/images-svg/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../../assets/images/images-svg/rightArrow.svg";

import "pure-react-carousel/dist/react-carousel.es.css";
import "react-multi-carousel/lib/styles.css";
import "../client/Client.scss";
import "../../providers/provider/Providers.scss";

interface ClientCardsProps {
  mergedData: ClientForms[];
  setIsOpenClientModal: Dispatch<SetStateAction<boolean>>;
}

const ClientsCards: React.FC<ClientCardsProps> = ({
  mergedData,
  setIsOpenClientModal,
}) => {
  // #region variables region

  //used to display number cards based on screen size
  const [isDesktop, setDesktop] = useState(4);

  //routing object
  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState<ClientForms[]>([]);

  //list data from redux
  const loader = useAppSelector((state) => state.clientListSlice.loader);

  //set active Index for show and hide scroll buttons
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  //dispatch for redux
  const dispatch = useAppDispatch();

  //binding action creators and dispatch
  const dispatchAction = bindActionCreators(
    {
      setSearchValue,
      setInitialState,
    },
    dispatch
  );

  //same dispatch ref between multiple re-renders
  const dispatchActionRef = useRef(dispatchAction);

  //active step for the form
  const { activeStep } = useAppSelector((state) => state.activeStep);

  //search value from redux
  const { searchValue } = useAppSelector((state) => state.activeStep);

  //filter values from redux
  const filterValues = useAppSelector((state) => state.clientFilterSlice);

  // #endregion

  // #region methods region

  //Left scroll button popup handle
  const funcLeft = () => {
    if (activeIndex === null) {
      return false;
    }
    if (activeIndex < isDesktop) {
      return true;
    } else {
      return true;
    }
  };

  // Right scroll button popup handle
  const funcRight = () => {
    if (activeIndex === null) {
      return false;
    }
    if (activeIndex < filteredData?.length) {
      return true;
    }
    return true;
  };

  //Right scroll button Click handle
  const handleRight = () => {
    if (activeIndex) {
      if (activeIndex + isDesktop < filteredData?.length) {
        setActiveIndex(activeIndex + isDesktop);
      } else {
        setActiveIndex(filteredData?.length - 1);
      }
    }
  };

  //Left scroll button Click handle
  const handleLeft = () => {
    if (activeIndex) {
      if (activeIndex - isDesktop + 1 >= isDesktop) {
        setActiveIndex(activeIndex - isDesktop);
      } else {
        setActiveIndex(isDesktop - 1);
      }
    }
  };

  // #region useEffect region

  //ensure to repopulate filteredClient after filter or search.
  useEffect(() => {
    const sortedData = mergedData.sort(
      (a, b) =>
        new Date(b.clientBasicDetails.updatedAt).getTime() -
        new Date(a.clientBasicDetails.updatedAt).getTime()
    );
    setFilteredData(filterClients(filterValues, searchValue, sortedData));
  }, [filterValues, searchValue, mergedData]);

  useEffect(() => {
    if (mergedData && mergedData.length > isDesktop) {
      setActiveIndex(isDesktop - 1);
    }
  }, [mergedData?.length, isDesktop]);

  useEffect(() => {
    if (window.innerWidth > 1240) {
      setDesktop(4);
    } else if (window.innerWidth > 991) {
      setDesktop(3);
    } else if (window.innerWidth > 650) {
      setDesktop(2);
    } else {
      setDesktop(1);
    }

    const updateMedia = () => {
      if (window.innerWidth > 1240) {
        setDesktop(4);
      } else if (window.innerWidth > 991) {
        setDesktop(3);
      } else if (window.innerWidth > 650) {
        setDesktop(2);
      } else {
        setDesktop(1);
      }
    };
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  /* Stripe Integration, review later!! 
  //handle planname
  useEffect(() => {
    if (customerSubscriptions.data.length > 0) {
      if (
        customerSubscriptions &&
        products !== undefined &&
        products.data[0].id !== ""
      ) {
        let name: string | undefined = products.data.find(
          (i) =>
            //     i.name === customerSubscriptions?.data[0].items?.data[0]?.plan?.product
            // )?.name;
            i.name ===
            customerSubscriptions?.data[0].items?.data[0]?.plan?.product
        )?.name;
        // if (name !== undefined) {
        //   setPlanName(name);
        // }
      }
    }
  }, [customerSubscriptions, products]);
  */

  // function setIsOpenMaxDialog(arg0: boolean) {
  //   throw new Error("Function not implemented.");
  // }

  // #endregion
  // console.log(filteredData, "filteredData");

  return (
    <div className="cardBlock">
      <div className="cardBlock__header">
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          className="cardBlock__title"
        >
          Recent Added Clients
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              if (activeStep > 5) {
                dispatch(setActiveSteps(0));
              }
              setIsOpenClientModal(true);
            }}
            className="button"
          >
            Add New
          </Button>
        </Box>
      </div>
      <div style={{ position: "relative" }}>
        {loader ? (
          <InLoader isShow={loader} />
        ) : filteredData && filteredData.length > 0 ? (
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={125}
            totalSlides={filteredData?.length}
            visibleSlides={isDesktop}
            step={isDesktop}
            // infinite={true}
            orientation={"horizontal"}
            className="cardSlider"
          >
            <Slider>
              {filteredData?.map((item: ClientForms, index: number) => {
                return (
                  <Slide index={index} key={index}>
                    <Card
                      className="cardBox"
                      onClick={() =>
                        navigate("/client/" + item.clientBasicDetails.id)
                      }
                    >
                      <CardMedia
                        component="img"
                        alt="green iguana"
                        image={
                          item?.clientBasicDetails?.clientProfile?.url ||
                          ClientImg
                        }
                        className="cardBox__image"
                      />
                      <Typography
                        gutterBottom
                        variant="h5"
                        className="cardBox__title"
                      >
                        {item?.clientBasicDetails?.childFirstName +
                          "   " +
                          item?.clientBasicDetails?.childLastName}
                      </Typography>
                      <CardContent className="cardBox__info">
                        <div className="cardBox__infoList">
                          <div className="cardBox__infoList-item">
                            <Typography className="cardBox__info-title">
                              Age
                            </Typography>
                            <Typography className="cardBox__info-text">
                              {item?.clientBasicDetails?.childDateOfBirth
                                ? getAge(
                                    item?.clientBasicDetails?.childDateOfBirth
                                  )
                                : null}
                            </Typography>
                          </div>
                          <div className="cardBox__infoList-item">
                            <Typography className="cardBox__info-title">
                              Location
                            </Typography>
                            <Typography className="cardBox__info-text">
                              {item?.clientContactDetails?.city}
                            </Typography>
                          </div>
                        </div>
                        <div className="cardBox__infoList">
                          <div className="cardBox__infoList-item">
                            <Typography className="cardBox__info-title">
                              Insurance
                            </Typography>
                            <Typography className="cardBox__info-text">
                              {item?.clientInsurance
                                ? item?.clientInsurance[0]
                                    ?.insuranceProviderName
                                : ""}
                            </Typography>
                          </div>
                          <div className="cardBox__infoList-item">
                            <Typography className="cardBox__info-title">
                              Status
                            </Typography>
                            <Typography className="cardBox__info-text">
                              {item?.clientBasicDetails?.clientType}
                            </Typography>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Slide>
                );
              })}
            </Slider>
            {funcLeft() && (
              <ButtonBack className="sliderNavBtn" onClick={handleLeft}>
                <LeftArrow />
              </ButtonBack>
            )}
            {funcRight() && (
              <ButtonNext className="sliderNavBtn" onClick={handleRight}>
                <RightArrow />
              </ButtonNext>
            )}
          </CarouselProvider>
        ) : (
          <div className="notFound-Block">No Clients Found</div>
        )}
      </div>
    </div>
  );
};

export default ClientsCards;
