import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSession, useUser, useDescope } from "@descope/react-sdk";

import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  alpha,
  InputBase,
  styled,
  Avatar,
  Tooltip,
} from "@mui/material";
import { Menu, Logout, Search as SearchIcon } from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setSearchValue } from "../../redux/features/active-steps/active-slice";
import {
  setName,
  setPicture,
  setEmail,
  setEmail_verified,
  setSideMenuSlider,
} from "../../redux/features/auth-slice";
import { getAllUserProfiles } from "../../redux/features/user-slice";
import { clearSchedulesFilterValues } from "../../redux/features/schedules-main/schedules-filter-slice";

import HeaderTab from "../../components/header-tab/HeaderTab";

import { HeadersTabs, Tabs } from "../../constants/tabs";

import ABAEngineLogo from "../../assets/images/logos/abaengine.png";

import "./header.scss";
import { setSelectedBoardTab } from "../../redux/features/custom-board/custom-board-slice";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: "100%",
  },
}));

export const Header: React.FC = () => {
  // #region variables region

  //new selected tab on re-render
  const [newSelectedTab] = useState<string>("");

  //used to track location
  const location = useLocation();

  //ref for newSelectedTab
  const activeTabRef = useRef(newSelectedTab);

  //search value
  const searchValue = useAppSelector((state) => state.activeStep.searchValue);

  //side menu slide controller
  const sideMenuSlider = useAppSelector((state) => state.auth.sideMenuSlider);

  //selected tab
  const [selectedTab, setSelectedTab] = useState<string>("Map");

  //
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isAuthenticated } = useSession();
  const { user } = useUser();

  const orgLogo = useAppSelector(
    (state) => state.organizationSlice.orgData.orgLogo.url
  );

  const currentTenantId = user?.customAttributes?.currentTenantId;

  const enabledTabs = HeadersTabs.filter((tab) => {
    // if currentTenant is not A, exclude Session Tab
    if (tab.tabName !== "Sessions") {
      return true;
    }

    if (tab.tabName === "Sessions") {
      if (process.env.REACT_APP_ENVIRONMENT === "DEV" ||
        process.env.REACT_APP_ENVIRONMENT === "QA" ||
        process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
        (process.env.REACT_APP_ENVIRONMENT === "PROD" && 
        currentTenantId === "T2YQRMXYeyzIFEvqRX0oYTrn0b4B")) {
        return true;
      }
    } else {
      return false;
    }
  });
  // console.log("enabledTab:", enabledTabs);

  // #endregion

  // We need this in case user goes to message templates without visiting user management page, or tenantUsers will be empty array.
  useEffect(() => {
    if (user) {
      dispatch(
        getAllUserProfiles({
          authTenantId: user?.customAttributes?.currentTenantId,
          callerEmail: user?.email as string,
          callerName: user?.name as string,
        })
      );
    }
  }, [user, dispatch]);

  //----------------------------------------------------------------
  //Sync Current User in Auth Slice
  useEffect(() => {
    async function fetchData() {
      if (user && isAuthenticated) {
        //dispatch(setNickname(user?.nickname));
        dispatch(setName(user?.name));
        dispatch(setPicture(user?.picture));
        dispatch(setEmail(user?.email));
        dispatch(setEmail_verified(user?.verifiedEmail));
        //dispatch(setSub(user?.sub));
        //dispatch(setUpdated_at(user?.updated_at));
      }
    }
    fetchData();
  }, [user, dispatch, isAuthenticated]);

  const setUpdatedActiveTab = useCallback(() => {
    if (activeTabRef.current) {
      if (
        selectedTab.toLowerCase().includes(activeTabRef.current.toLowerCase())
      ) {
        setSelectedTab(selectedTab);
      } else {
        setSelectedTab(activeTabRef.current);
      }
    }
  }, [selectedTab]);

  useEffect(() => {
    let newTab = "";
    let count = 0;
    let i = 1;

    while (count < 1) {
      if (location.pathname[i] !== "/" && location.pathname[i]) {
        newTab += location.pathname[i];
        i++;
      } else {
        count = 1;
      }
    }

    if (newTab !== activeTabRef.current) {
      if (newTab !== activeTabRef.current) {
        if (newTab === "map") {
          activeTabRef.current = "Map";
        } else if (newTab === "planning") {
          activeTabRef.current = "Planning";
        } else if (newTab === "assessment") {
          activeTabRef.current = "Assessments";
        } else {
          activeTabRef.current = newTab;
        }
      }
    }
    setUpdatedActiveTab();
  }, [location.pathname, setUpdatedActiveTab]);

  const { logout } = useDescope();

  const handleUserLogout = useCallback(() => {
    localStorage.removeItem("selectedTab");
    localStorage.removeItem("setCounter");
    logout();
    // dispatch(resetCurrentTenant(""));
    // navigate("/sign-in");
  }, [logout]);

  const handleRedirectProfile = () => {
    //alert("hey Hui this is profile section ");
  };

  const handleSearchChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(setSearchValue(e.target.value));
  };

  return (
    <Box>
      <AppBar position="relative">
        <Toolbar
          className="mainHeader"
          style={{
            color: "black",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box display={"flex"}>
            <IconButton
              className="mainHeader__toggle"
              size="large"
              edge="start"
              sx={{ mr: 2 }}
              style={{ background: "transform", color: "#000" }}
              onClick={() => dispatch(setSideMenuSlider(!sideMenuSlider))}
            >
              <Menu />
            </IconButton>
            {/*TODO: later need to read logo file from Org.Files */}
            <img
              alt="Loading"
              src={orgLogo ? orgLogo : ABAEngineLogo}
              className="mainHeader__logo"
            />
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <Box
              className="mobileSearch"
              style={{ cursor: "pointer" }}
              onClick={() => setOpenSearch(!openSearch)}
            >
              <SearchIcon color="primary" />
            </Box>
            <Search
              className={
                openSearch === true
                  ? "search-block active-search"
                  : "search-block"
              }
            >
              <SearchIconWrapper
                className="search-block__icon"
                // onClick={() => handleSearch()}
              >
                <SearchIcon color="primary" />
              </SearchIconWrapper>
              <StyledInputBase
                onChange={(
                  e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => handleSearchChange(e)}
                value={searchValue}
                style={{ color: "black" }}
                placeholder="Search name"
              />
            </Search>
            <div className="nav-header">
              <HeaderTab
                onTabClick={(tab: Tabs) => {
                  //dispatch(setPlan(false))
                  if (selectedTab === "Schedules") {
                    dispatch(clearSchedulesFilterValues({}));
                  }
                  if (selectedTab === "Board") {
                    dispatch(
                      setSelectedBoardTab({ id: "Intake", type: "Clients" })
                    );
                  }
                  dispatch(setSearchValue(""));
                  setSelectedTab(tab.tabName);
                  // localStorage.setItem("selectedTab", tab.tabName); //turn it off
                  navigate(tab.url);
                  if (location.pathname !== "/schedule") {
                    localStorage.removeItem("setCounter");
                  }
                }}
                selectedTab={selectedTab}
                // tabs={HeadersTabs}
                tabs={enabledTabs}
              />
            </div>

            <Box>
              {isAuthenticated && (
                <Box display={"flex"} alignItems={"center"}>
                  <Box
                    sx={{ flexGrow: 0 }}
                    className="userBox"
                    alignItems={"center"}
                  >
                    <Tooltip title="" className="userBox__image">
                      <Avatar
                        onClick={handleRedirectProfile}
                        alt="userProfile"
                        src={user?.picture}
                      />
                    </Tooltip>
                    <Typography>
                      {user?.name?.split(" ")[0]
                        ? user?.name?.split(" ")[0]
                        : ""}
                    </Typography>
                  </Box>
                  <Logout onClick={handleUserLogout} className="logout-icon" />
                </Box>
              )}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
