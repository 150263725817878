//dependencies
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  Card,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";

//files
import { RootState } from "../../../redux/store";
import { setActiveSteps } from "../../../redux/features/active-steps/active-slice";
import { language } from "../../../constants/client";
import { setClientBasicDetails } from "../../../redux/features/client-main/client-slice";

//css
import "../../providers/provider/Providers.scss";
import { getSupervisor } from "../../../redux/features/providers-main/provider-supervisor-slice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IClientBasicDetails {
  onSubmit?: () => void;
}

const ClientBasicDetails: React.FC<IClientBasicDetails> = ({ onSubmit }) => {
  // #region variables region

  const dispatch = useDispatch();
  const { activeStep } = useSelector((state: RootState) => state.activeStep);
  const clientBasicDetails = useSelector(
    (state: RootState) => state.client.clientBasicDetails
  );

  const { isEditClient } = useSelector((state: RootState) => state.client);
  const [hasError, setHasError] = useState(false);

  // #endregion

  // #region methods region

  /**
   * handle form change
   * @param e
   */

  // eslint-disable-next-line
  const [caseSupervisor, setCaseSupervisor] = useState<string>(
    clientBasicDetails?.supervisor?.supervisorId || ""
  );

  // handle feild data
  const handleChange = (e: any) => {
    const obj = {
      name: e.target.name,
      value: e.target.value,
    };
    setCaseSupervisor(e.target.value);
    dispatch(setClientBasicDetails(obj));
  };

  // handle form submit

  const handleSubmit = async () => {
    if (clientBasicDetails.childLanguages.length === 0) {
      setHasError(true);
    } else if (clientBasicDetails.childGender === "") {
      setHasError(true);
    } else if (clientBasicDetails.childDateOfBirth === "") {
      setHasError(true);
    } else {
      if (isEditClient) {
        if (onSubmit) {
          onSubmit();
        }
      } else {
        dispatch(setActiveSteps(activeStep + 1));
      }
    }
  };

  // handle focus
  const removeFocus = (id: string) => {
    document.getElementById(id)?.blur();
  };

  // #endregion

  //#region useEffect region

  useEffect(() => {
    dispatch(getSupervisor());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#endregion

  return (
    <>
      <Box className="stepperForm">
        <ValidatorForm onSubmit={handleSubmit}>
          {/* <h1 className="stepperForm__title"> Basic Information</h1> */}
          <div className="stepperForm__inner">
            <Grid container spacing={3}>
              <Grid size={{ xs: 12, md: 6 }}>
                <Box className="fromGroup">
                  <InputLabel className="fromLabel">
                    Child's First Name
                  </InputLabel>
                  <TextValidator
                    className="form-control"
                    onChange={handleChange}
                    name="childFirstName"
                    id="childFirstName"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        removeFocus("childFirstName");
                      }
                    }}
                    value={clientBasicDetails.childFirstName.trimStart()}
                    validators={["required", "matchRegexp:^[a-zA-Z- ]*$"]}
                    errorMessages={[
                      "Please Enter First Name",
                      "Please enter valid first name",
                    ]}
                  />
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Box className="fromGroup">
                  <InputLabel className="fromLabel">
                    Child's Last Name
                  </InputLabel>
                  <TextValidator
                    className="form-control"
                    onChange={handleChange}
                    name="childLastName"
                    id="childLastName"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        removeFocus("childLastName");
                      }
                    }}
                    value={clientBasicDetails.childLastName.trimStart()}
                    validators={["required", "matchRegexp:^[a-zA-Z- ]*$"]}
                    errorMessages={[
                      "Please Enter Last Name",
                      "name can only has letters",
                    ]}
                  />
                </Box>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                <Box className="fromGroup">
                  <InputLabel className="fromLabel">
                    Child's Date of Birth
                  </InputLabel>
                  <TextValidator
                    id="date"
                    TextValidator
                    inputProps={{ max: moment().format("YYYY-MM-DD") }}
                    onChange={handleChange}
                    type="date"
                    name="childDateOfBirth"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        removeFocus("date");
                      }
                    }}
                    value={moment(clientBasicDetails.childDateOfBirth).format(
                      "YYYY-MM-DD"
                    )}
                    sx={{ width: 220 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validators={["required"]}
                    errorMessages={["Please enter child's date of birth"]}
                  />
                  {hasError && clientBasicDetails.childDateOfBirth === "" ? (
                    <FormHelperText style={{ color: "#d32f2f" }}>
                      Please select date of birth.
                    </FormHelperText>
                  ) : null}
                </Box>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                <Box className="fromGroup">
                  <InputLabel
                    id="demo-simple-select-label"
                    className="fromLabel"
                  >
                    Child's Gender
                  </InputLabel>
                  <Select
                    className="form-control"
                    labelId="demo-simple-select-label"
                    value={clientBasicDetails.childGender}
                    name="childGender"
                    onChange={handleChange}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </Select>
                  {hasError && clientBasicDetails.childGender === "" ? (
                    <FormHelperText style={{ color: "#d32f2f" }}>
                      Please select gender
                    </FormHelperText>
                  ) : null}
                </Box>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                <Box className="fromGroup">
                  <InputLabel className="fromLabel">
                    Parent/Guardian 1 First Name
                  </InputLabel>
                  <TextValidator
                    id="parentfirstname"
                    onChange={handleChange}
                    name="parentFirstName"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        removeFocus("parentfirstname");
                      }
                    }}
                    value={clientBasicDetails.parentFirstName}
                  />
                </Box>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                <Box className="fromGroup">
                  <InputLabel className="fromLabel">
                    Parent/Guardian 1 Last Name
                  </InputLabel>
                  <TextValidator
                    className="form-control"
                    onChange={handleChange}
                    name="parentLastName"
                    id="parentLastName"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        removeFocus("parentLastName");
                      }
                    }}
                    value={clientBasicDetails.parentLastName}
                  />
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Box className="fromGroup">
                  <InputLabel className="fromLabel">
                    Parent/Guardian 2 First Name (Optional)
                  </InputLabel>
                  <TextValidator
                    id="parent2FirstName"
                    onChange={handleChange}
                    name="parent2FirstName"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        removeFocus("parentfirstname");
                      }
                    }}
                    value={clientBasicDetails.parent2FirstName}
                  />
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Box className="fromGroup">
                  <InputLabel className="fromLabel">
                    Parent/Guardian 2 Last Name (Optional)
                  </InputLabel>
                  <TextValidator
                    className="form-control"
                    onChange={handleChange}
                    name="parent2LastName"
                    id="parent2LastName"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        removeFocus("parentLastName");
                      }
                    }}
                    value={clientBasicDetails.parent2LastName}
                  />
                </Box>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                <Box className="fromGroup">
                  <InputLabel className="fromLabel">
                    Child's Language
                  </InputLabel>
                  <div className="fromGroup-chips">
                    {language.map((item, index) => (
                      <Card
                        className={
                          clientBasicDetails.childLanguages.includes(item.value)
                            ? "fromGroup-chip-active"
                            : ""
                        }
                        onClick={() => {
                          if (
                            clientBasicDetails.childLanguages.includes(
                              item.value
                            )
                          ) {
                            let removeLanguage =
                              clientBasicDetails.childLanguages.filter(
                                (x: string) => x !== item.value
                              );
                            const language = {
                              name: "childLanguages",
                              value: removeLanguage,
                            };
                            dispatch(setClientBasicDetails(language));
                          } else {
                            const language = {
                              name: "childLanguages",
                              value: [
                                ...clientBasicDetails.childLanguages,
                                item.value,
                              ],
                            };
                            dispatch(setClientBasicDetails(language));
                          }
                        }}
                        key={item.key}
                      >
                        {item.value}
                      </Card>
                    ))}
                    <div style={{ width: "100%" }} />
                    {hasError &&
                    clientBasicDetails.childLanguages.length === 0 ? (
                      <FormHelperText style={{ color: "#d32f2f" }}>
                        Please select any language
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </div>
                </Box>
              </Grid>
            </Grid>
          </div>

          <Box className="stepperForm__footer">
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              type="submit"
              className="button"
              onClick={() => setHasError(true)}
            >
              {isEditClient ? "Save Changes" : "Next"}
            </Button>
          </Box>
        </ValidatorForm>
      </Box>
    </>
  );
};

export default ClientBasicDetails;
