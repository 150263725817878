import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Delete } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import FileUpload from "react-material-file-upload";
import { MuiFileInput } from "mui-file-input";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { setActiveSteps } from "../../../redux/features/active-steps/active-slice";
import {
  addAuthCodeInAuthorization,
  addAuthorization,
  deleteAuthCodeAuthorization,
  deleteAuthorization,
  deleteAuthorizationFiles,
  setAuthorizationAuthCodes,
  setAuthorizationAuthCodeUnits,
  setAuthorizationDateRange,
  setAuthorizationFiles,
  setAuthorizationInsuranceProvider,
  setAuthorizationName,
  setAuthorizationNumber,
} from "../../../redux/features/client-main/client-slice";
import {
  GetAuthcode,
  getClientInsuranceProviders,
} from "../../../redux/features/client-main/client-insurance-slice";

import {
  AuthCode,
  ClientAuthCode,
  ClientAuthPayload,
} from "../../../types/client";
import { InsuranceProvider } from "../../../types/response";
import { IHashObj } from "../../../types/Common";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "../../providers/provider/Providers.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

//Define Props Types
interface ClientAuthorizationProps {
  onSubmit?: () => void;
  authIndex?: number | null;
  activeTabForAuthorization?: string | null;
  deleteSingleAuthorization?(id: string): void;
  setAuthId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  deleteAuthorizationFile?(
    authId: string,
    key: string,
    authIndex: number,
    activeTab: string
  ): Promise<void>;
  isEdit: boolean;
  headerTitle: string;
}

const AuthorizationsDetails: React.FC<ClientAuthorizationProps> = ({
  onSubmit,
  deleteSingleAuthorization,
  authIndex,
  activeTabForAuthorization,
  isEdit,
  headerTitle,
  setAuthId,
}) => {
  // #region variable region

  //auth code error handler
  const [duplicateAuthCodeError, setDuplicateAuthCodeError] =
    useState<boolean>(false);

  //duplicate error image
  const [duplicateErrorImage, setDuplicateErrorImage] =
    useState<boolean>(false);

  //duplicate error image
  const [duplicateErrorImageMessage, setDuplicateErrorImageMessage] =
    useState<string>("");

  //insurance Providers Data
  const insuranceProviders = useAppSelector(
    (state) => state.clientInsuranceSlice.clientInsurance
  );

  //
  const [authorizationIndex, setAuthorizationIndex] = useState<number>(
    authIndex || 0
  );

  //variable to get authorizations data from redux
  const authorizations = useAppSelector((state) => state.client.authorizations);

  //Store variable startdate of authperiod
  const [startDate, setStartDate] = useState<Dayjs | null | any>(
    authorizations[authorizationIndex]?.authPeriod?.startDate
  );

  //Store variable enddate of authperiod
  const [endDate, setEndDate] = useState<Dayjs | null | any>(
    authorizations[authorizationIndex]?.authPeriod?.endDate
  );

  //active step for the stepper
  const { activeStep } = useAppSelector((state) => state.activeStep);

  //Edit Client to set boolean for popups and change Buttons Featurs
  const { isEditClient } = useAppSelector((state) => state.client);

  //handle the Error to show the warnings
  const [hasError, setHasError] = useState<boolean>(false);

  //show for warning message
  const [errorMessage, setErrorMessage] = useState<string>("");

  //keeps track of activeTab for particular authorization
  const [activeTab, setActiveTab] = useState<string>(
    activeTabForAuthorization || "basic"
  );

  const ClientInsurance = useAppSelector(
    (state) => state.client.clientInsurance
  );

  const [selectedInsuranceName, setSelectedInsuranceName] = useState<string[]>(
    isEdit && authorizations.length > 0
      ? authorizations
          .map((item) => {
            return item.insurance.insuranceProviderName;
          })
          .filter((id) => id !== "")
      : []
  );
  const [isInsuranceError, setIsInsuranceError] = useState<boolean>(false);

  //defined dispatch
  const dispatch = useAppDispatch();

  // reading authcodes from data catalog
  const authcode = useAppSelector(
    (state) => state.clientInsuranceSlice.clientAuthcode
  );

  // #endregion

  // #region methods region
  //SAME as the backend
  const getWeeksBetween = (startDate: any, endDate: any): number => {
    if (startDate && endDate) {
      const dt1 = new Date(startDate);
      const dt2 = new Date(endDate);
      let diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60 * 60 * 24 * 7;

      return Math.abs(parseFloat(diff.toFixed(2)));
    } else return 0;
  };

  // handle start date of authperiod
  const handleStartDate = (newValue: Dayjs | null) => {
    setStartDate(newValue);
    const obj = {
      startDate: dayjs(newValue).format("MM/DD/YYYY"),
    };

    if (obj.startDate !== "Invalid Date") {
      dispatch(
        setAuthorizationDateRange({
          index: authorizationIndex,
          startDate: obj.startDate,
          endDate: null,
        })
      );
    }
  };

  //handle enddate of authperiod
  const handleEndDate = (newValue: Dayjs | null) => {
    setEndDate(newValue);

    const obj = {
      endDate: dayjs(newValue).format("MM/DD/YYYY"),
      startDate: dayjs(startDate).format("MM/DD/YYYY"),
    };

    if (obj.endDate !== "Invalid Date") {
      dispatch(
        setAuthorizationDateRange({
          index: authorizationIndex,
          startDate: obj.startDate,
          endDate: obj.endDate,
        })
      );
    }
  };

  //handle submiting the data
  const handleSubmit = (e: any) => {
    e.preventDefault();
    let error = false;

    if (error) {
      setHasError(true);
    } else {
      if (isEditClient || isEdit) {
        if (onSubmit) {
          onSubmit();
        }
      } else {
        dispatch(setActiveSteps(activeStep + 1));
      }
    }
  };

  //remove focus on input field
  const removeFocus = (id: string) => {
    document.getElementById(id)?.blur();
  };

  // #endregion
  // store insurance temporary

  const validateInsurance = (e: any) => {
    const currentInsurance = insuranceProviders.find(
      (insurance) => insurance.insuranceProviderName === e.target.value
    );
    const found = ClientInsurance.find(
      (item: any) =>
        item.insuranceProviderName === currentInsurance?.insuranceProviderName
    );

    if (isEdit) {
      if (!currentInsurance || found === undefined) {
        setIsInsuranceError(true);
        return;
      }
    }
    setIsInsuranceError(false);
    if (currentInsurance) {
      if (selectedInsuranceName[authorizationIndex] !== undefined) {
        const data = selectedInsuranceName.map((item, index) => {
          if (index === authorizationIndex) {
            return e.target.value;
          } else {
            return item;
          }
        });
        setSelectedInsuranceName(data);
      } else {
        setSelectedInsuranceName([...selectedInsuranceName, e.target.value]);
      }

      dispatch(
        setAuthorizationInsuranceProvider({
          index: authorizationIndex,
          value: currentInsurance.id,
        })
      );
    }
  };

  const handleDeleteAuthorization = (
    item: ClientAuthPayload,
    index: number
  ) => {
    if (setAuthId) {
      setAuthId(item.id);
    }
    if (isEditClient) {
      if (authorizations.length > 0) {
        if (deleteSingleAuthorization) {
          deleteSingleAuthorization(item.id);
        }
      }
    } else {
      if (authorizations.length > 0) {
        dispatch(deleteAuthorization({ index: index }));
        setAuthorizationIndex(0);
      }
    }
  };

  useEffect(() => {
    dispatch(getClientInsuranceProviders());
    dispatch(GetAuthcode());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // #endregion

  return (
    <>
      <Box className="stepperForm">
        <ValidatorForm onSubmit={handleSubmit}>
          <div className="stepperForm__inner" style={{ marginBottom: 0 }}>
            <Grid container spacing={2} className="InsuranceHeader">
              <Grid size={{ xs: 12, lg: 6 }}>
                <h1 className="stepperForm__title">{headerTitle}</h1>
              </Grid>
              <Grid size={{ xs: 12, lg: 6 }} className="InsuranceHeader__btn">
                <Button
                  onClick={() => {
                    setAuthorizationIndex(authorizationIndex + 1);
                    dispatch(addAuthorization({}));
                    setActiveTab("basic");
                    setHasError(false);
                    setIsInsuranceError(false);
                  }}
                >
                  + Add Authorization
                </Button>
              </Grid>
            </Grid>
            {errorMessage && hasError && (
              <FormHelperText style={{ color: "#d32f2f" }}>
                {errorMessage}
              </FormHelperText>
            )}
          </div>

          <div className="stepperForm__inner">
            <Grid container spacing={3} sx={{ position: "relative" }}>
              <Grid size={{ xs: 12, lg: 4 }}>
                <div className="authListBox">
                  {authorizations.map(
                    (item: ClientAuthPayload, index: number) => {
                      return (
                        <div className="authListBox__item" key={index}>
                          <div className="authListBox__head">
                            <p
                              className="authListBox__title"
                              onClick={() => {
                                setHasError(false);
                                setIsInsuranceError(false);
                                setAuthorizationIndex(index);
                              }}
                            >
                              {item?.authorizationName}
                            </p>
                            {authorizations.length <= 1 ? null : (
                              <span className="authListBox__removeBtn">
                                <Delete
                                  onClick={() => {
                                    handleDeleteAuthorization(item, index);
                                  }}
                                />
                              </span>
                            )}
                          </div>
                          <div className="authListBox__body">
                            <span
                              className={
                                authorizationIndex === index &&
                                activeTab === "basic"
                                  ? "authListBox__link activeLink"
                                  : "authListBox__link"
                              }
                              onClick={() => {
                                setHasError(false);
                                setIsInsuranceError(false);
                                setAuthorizationIndex(index);
                                setActiveTab("basic");
                              }}
                            >
                              Basic
                            </span>
                            <span
                              className={
                                authorizationIndex === index &&
                                activeTab === "files"
                                  ? "authListBox__link activeLink"
                                  : "authListBox__link"
                              }
                              onClick={() => {
                                setAuthorizationIndex(index);
                                setActiveTab("files");
                              }}
                            >
                              Files
                            </span>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </Grid>

              {activeTab === "basic" && (
                <Grid size={{ xs: 12, lg: 8 }}>
                  <Grid container spacing={3}>
                    <Grid size={{ xs: 12 }}>
                      <Box className="fromGroup">
                        <InputLabel className="fromLabel">
                          Authorization Name
                        </InputLabel>
                        <TextValidator
                          onChange={(e: any) => {
                            dispatch(
                              setAuthorizationName({
                                index: authorizationIndex,
                                value: e.target.value,
                              })
                            );
                          }}
                          name="authorizationName"
                          value={
                            authorizations[authorizationIndex]
                              ?.authorizationName
                          }
                          validators={["required"]}
                          errorMessages={["Please enter authorization name"]}
                          id="authorizationName"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              removeFocus("authorizationName");
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                      <Box className="fromGroup">
                        <InputLabel
                          id="demo-simple-select-label"
                          className="fromLabel"
                        >
                          Insurance Provider
                        </InputLabel>
                        <Select
                          className="form-control"
                          value={
                            selectedInsuranceName[authorizationIndex] !==
                            undefined
                              ? selectedInsuranceName[authorizationIndex]
                              : null
                          }
                          // placeholder="Select"
                          id="authInsuranceProvider"
                          onChange={(e: any) => validateInsurance(e)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              removeFocus("authInsuranceProvider");
                            }
                          }}
                          MenuProps={MenuProps}
                        >
                          {insuranceProviders.map((item: InsuranceProvider) => {
                            return (
                              <MenuItem
                                key={item.id}
                                value={item.insuranceProviderName}
                              >
                                {item.insuranceProviderName}
                              </MenuItem>
                            );
                          })}
                        </Select>

                        {isInsuranceError ? (
                          <FormHelperText style={{ color: "#d32f2f" }}>
                            The client didn't provide this insurance provider.
                            Please verify client's insurance first.
                          </FormHelperText>
                        ) : null}
                      </Box>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                      <Box className="fromGroup">
                        <InputLabel className="fromLabel">
                          Auth Number
                        </InputLabel>
                        <TextValidator
                          onChange={(e: any) => {
                            dispatch(
                              setAuthorizationNumber({
                                index: authorizationIndex,
                                value: e.target.value,
                              })
                            );
                          }}
                          name="authorizationNumber"
                          value={
                            authorizations[authorizationIndex]
                              ?.authorizationNumber
                          }
                          id="authorizationNumber"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              removeFocus("authorizationNumber");
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                      <InputLabel className="fromLabel">
                        Auth Period (Weeks:{" "}
                        {getWeeksBetween(
                          authorizations[authorizationIndex]?.authPeriod
                            .startDate,
                          authorizations[authorizationIndex]?.authPeriod.endDate
                        )}
                        )
                      </InputLabel>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid container spacing={3}>
                          <Grid size={{ xs: 12, md: 6 }}>
                            <DesktopDatePicker
                              className="singleDatePicker"
                              label="Start Date"
                              // inputFormat="MM/DD/YYYY"
                              format="MM/DD/YYYY"
                              value={
                                authorizations[authorizationIndex]?.authPeriod
                                  .startDate
                                  ? dayjs(
                                      authorizations[authorizationIndex]
                                        ?.authPeriod.startDate
                                    )
                                  : null
                              }
                              onChange={handleStartDate}
                              // renderInput={(params) => (
                              //   <TextField {...params} />
                              // )}
                            />
                          </Grid>
                          <Grid size={{ xs: 12, md: 6 }}>
                            <DesktopDatePicker
                              className="singleDatePicker"
                              label="End Date"
                              // inputFormat="MM/DD/YYYY"
                              format="MM/DD/YYYY"
                              value={
                                authorizations[authorizationIndex]?.authPeriod
                                  .endDate
                                  ? dayjs(
                                      authorizations[authorizationIndex]
                                        ?.authPeriod.endDate
                                    )
                                  : null
                              }
                              onChange={handleEndDate}
                              // renderInput={(params) => (
                              //   <TextField {...params} />
                              // )}
                            />
                          </Grid>
                        </Grid>
                      </LocalizationProvider>
                    </Grid>
                    <Grid
                      size={{ xs: 12 }}
                      sx={{ position: "relative", textAlign: "right" }}
                    >
                      <Button
                        className="addBtn"
                        onClick={() => {
                          dispatch(
                            addAuthCodeInAuthorization({
                              index: authorizationIndex,
                            })
                          );
                        }}
                      >
                        + Add Auth Code
                      </Button>
                    </Grid>
                    <Grid size={{ xs: 6 }}>
                      {duplicateAuthCodeError && (
                        <FormHelperText style={{ color: "#d32f2f" }}>
                          Please select different auth code
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid size={{ xs: 6 }}></Grid>
                    <Grid size={{ xs: 12 }} sx={{ mt: "-20px" }}>
                      {authorizations[authorizationIndex]?.auth?.map(
                        (
                          authCodeItem: ClientAuthCode,
                          authCodeIndex: number
                        ) => {
                          let maxUnits = 0;
                          if (startDate && endDate && authCodeItem.authCode) {
                            const weeks =
                              moment(endDate["$d"], "DD-MM-YYYY").diff(
                                moment(startDate["$d"], "DD-MM-YYYY"),
                                "week"
                              ) * 5;
                            maxUnits =
                              (authcode.find(
                                (item) =>
                                  item.conceptValue === authCodeItem.authCode
                              )?.data?.unitsPerDay || 0) * weeks;
                          }
                          return (
                            <div key={authCodeIndex}>
                              <Grid container spacing={3}>
                                <Grid size={{ xs: 4 }}>
                                  <InputLabel className="fromLabel">
                                    Auth Code
                                  </InputLabel>
                                  <Box className="fromGroup">
                                    <Select
                                      labelId="multiple-select-label"
                                      name="authCode"
                                      id="authCode"
                                      value={authCodeItem.authCode}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                          removeFocus("authCode");
                                        }
                                      }}
                                      onChange={(e: any) => {
                                        const selectedAuthorization =
                                          authorizations[authorizationIndex]
                                            .auth;
                                        const authorizationAuthCodes: IHashObj =
                                          {};
                                        selectedAuthorization.forEach(
                                          (element) => {
                                            if (element.authCode) {
                                              authorizationAuthCodes[
                                                element.authCode.toString()
                                              ] = 1;
                                            }
                                          }
                                        );
                                        if (
                                          authorizationAuthCodes[e.target.value]
                                        ) {
                                          setDuplicateAuthCodeError(true);
                                          return;
                                        }
                                        if (duplicateAuthCodeError) {
                                          setDuplicateAuthCodeError(false);
                                        }
                                        dispatch(
                                          setAuthorizationAuthCodes({
                                            index: authorizationIndex,
                                            authCodeIndex: authCodeIndex,
                                            value: e.target.value,
                                          })
                                        );
                                      }}
                                      MenuProps={MenuProps}
                                    >
                                      {authcode.map((item: AuthCode) => {
                                        return (
                                          <MenuItem
                                            key={item.id}
                                            value={item.conceptValue}
                                          >
                                            {item.conceptValue}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </Box>
                                </Grid>
                                <Grid size={{ xs: 4 }}>
                                  {maxUnits > 0 ? (
                                    <InputLabel className="fromLabel">
                                      # of Units (max: {maxUnits})
                                    </InputLabel>
                                  ) : (
                                    <InputLabel className="fromLabel">
                                      # of Units
                                    </InputLabel>
                                  )}
                                  <Box className="fromGroup">
                                    <TextValidator
                                      placeholder="##"
                                      className="form-control"
                                      name="units"
                                      id="units"
                                      value={
                                        authorizations[authorizationIndex]
                                          ?.auth[authCodeIndex].units
                                      }
                                      type="number"
                                      max={maxUnits}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                          removeFocus("units");
                                        }
                                      }}
                                      onChange={(e: any) => {
                                        const objChange = {
                                          index: authorizationIndex,
                                          authCodeIndex: authCodeIndex,
                                          value: e.target.value,
                                        };
                                        if (objChange.value !== 0) {
                                          dispatch(
                                            setAuthorizationAuthCodeUnits(
                                              objChange
                                            )
                                          );
                                        }
                                      }}
                                      validators={[
                                        // "required",
                                        "matchRegexp:^[1-9][0-9]*$",
                                      ]}
                                      errorMessages={[
                                        // "Please enter unit",
                                        "unit must be a positive number",
                                      ]}
                                    />
                                    {!errorMessage &&
                                    hasError &&
                                    !authorizations[authorizationIndex]?.auth[
                                      authCodeIndex
                                    ].units ? (
                                      <FormHelperText
                                        style={{ color: "#d32f2f" }}
                                      >
                                        Please enter unit
                                      </FormHelperText>
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                </Grid>
                                <Grid size={{ xs: 3 }}>
                                  <InputLabel className="fromLabel">
                                    Auth Hours
                                  </InputLabel>
                                  <Box className="fromGroup">
                                    {startDate &&
                                      endDate &&
                                      authcode &&
                                      authCodeItem.authCode &&
                                      //if cannot find authcode, then set to "-"
                                      // if find authcode, calculate authHours
                                      (authcode.find(
                                        (item) =>
                                          item.conceptValue ===
                                          authCodeItem.authCode
                                      )?.data?.minsPerUnit ? (
                                        <p>
                                          {((authcode.find(
                                            (item) =>
                                              item.conceptValue ===
                                              authCodeItem.authCode
                                          )?.data?.minsPerUnit || 0) *
                                            Number(authCodeItem.units)) /
                                            60}
                                        </p>
                                      ) : (
                                        <p>{"-"}</p>
                                      ))}
                                  </Box>
                                </Grid>
                                <Grid
                                  sx={{ m: 2 }}
                                  className="time-group__space"
                                >
                                  <Delete
                                    className="removeTimeBtn"
                                    onClick={() => {
                                      dispatch(
                                        deleteAuthCodeAuthorization({
                                          index: authorizationIndex,
                                          authindex: authCodeIndex,
                                        })
                                      );
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          );
                        }
                      )}
                    </Grid>
                    <Grid size={{ xs: 12 }} sx={{ textAlign: "center" }}>
                      <Button onClick={() => setActiveTab("files")}>
                        Upload Files
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {activeTab === "files" && (
                <Grid size={{ xs: 12, lg: 8 }}>
                  <Box className="fromGroup">
                    <InputLabel
                      id="demo-simple-select-label"
                      className="fromLabel"
                    >
                      {duplicateErrorImage && (
                        <FormHelperText style={{ color: "#d32f2f" }}>
                          {duplicateErrorImageMessage}
                        </FormHelperText>
                      )}
                    </InputLabel>
                    <Grid container spacing={3}>
                      <Grid
                        size={{ xs: 12, md: 6 }}
                        className="fileUploadBlock"
                      >
                        <MuiFileInput
                          multiple
                          value={authorizations[authorizationIndex]?.files}
                          onChange={(e: File[]) => {
                            let temp: any = [
                              ...authorizations[authorizationIndex]?.files,
                            ];
                            let hashObj: IHashObj = {};
                            temp.forEach((item: any) => {
                              if (item.key) {
                                if (hashObj[item.key.split("*$SPLIT$*")[1]]) {
                                  hashObj[item.key.split("*$SPLIT$*")[1]] += 1;
                                } else {
                                  hashObj[item.key.split("*$SPLIT$*")[1]] = 1;
                                }
                              } else {
                                if (hashObj[item.name]) {
                                  hashObj[item.name] += 1;
                                } else {
                                  hashObj[item.name] = 1;
                                }
                              }
                            });

                            let duplicateFileNames: IHashObj = {};
                            e.forEach((item: File) => {
                              if (!hashObj[item.name]) {
                                temp.push(item);
                                hashObj[item.name] = 1;
                              } else {
                                if (duplicateFileNames[item.name]) {
                                  duplicateFileNames[item.name] += 1;
                                } else {
                                  duplicateFileNames[item.name] = 1;
                                }
                              }
                            });
                            let fileNameString = "";
                            if (Object.keys(duplicateFileNames).length > 0) {
                              Object.keys(duplicateFileNames).forEach(
                                (item: string) => {
                                  fileNameString += item + ", ";
                                }
                              );
                            }
                            if (fileNameString) {
                              fileNameString +=
                                " found duplicate names for the particular files";
                              setDuplicateErrorImage(true);
                              setDuplicateErrorImageMessage(fileNameString);
                              setTimeout(() => {
                                setDuplicateErrorImage(false);
                                setDuplicateErrorImageMessage("");
                              }, 3000);
                            }
                            dispatch(
                              setAuthorizationFiles({
                                index: authorizationIndex,
                                files: temp,
                              })
                            );
                          }}
                        />
                      </Grid>
                      <Grid size={{ xs: 12, md: 6 }}>
                        <div className="uploadProfile__img selectedIn-fileBox">
                          {authorizations[authorizationIndex]?.files.length >
                          0 ? (
                            authorizations[authorizationIndex]?.files.map(
                              (item: any, index: number) => {
                                return (
                                  <div className="selectedIn-file" key={index}>
                                    <h1>
                                      {item.path
                                        ? item.path
                                        : item.key.split("*$SPLIT$*")[1]}
                                    </h1>
                                    <span>
                                      <Delete
                                        onClick={() => {
                                          dispatch(
                                            deleteAuthorizationFiles({
                                              index: authorizationIndex,
                                              fileIndex: index,
                                            })
                                          );
                                        }}
                                      />
                                    </span>
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <p className="selectedIn-fileBlank">
                              Upload insurance and assessment documents here.
                            </p>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )}
            </Grid>
          </div>
          <Box className="stepperForm__footer">
            {!isEditClient && !isEdit ? (
              <Button
                type="submit"
                color="inherit"
                disabled={activeStep === 0 ? true : false}
                className="border-button"
                onClick={() => dispatch(setActiveSteps(activeStep - 1))}
              >
                Previous
              </Button>
            ) : null}

            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              type="submit"
              className="button"
              onClick={() => setHasError(true)} //relax the logic to allow partial save
            >
              {isEdit ? "Save Changes" : "Next"}
            </Button>
          </Box>
        </ValidatorForm>
      </Box>
    </>
  );
};

export default AuthorizationsDetails;
