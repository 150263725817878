import React, { Dispatch, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Close } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import ProviderAddNewInsuranceProviderForm from "../../provider-helper-forms/ProviderAddNewInsuranceProviderForm";
import { AppDispatch } from "../../../../redux/store";
import {
  addCredential,
  removeCredential,
  setCredentialDetails,
  updateProviderCredentialById,
} from "../../../../redux/features/providers-main/provider-slice";
import { setResponseValue } from "../../../../redux/features/api-response/api-response";
import { setListDataChange } from "../../../../redux/features/providers-main/provider-list-slice";
import ProviderService from "../../../../service/provider.service";
import { Credentials } from "../../../../types/provider";

// handle styling of Tablecell
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    verticalAlign: "baseline",
    whiteSpace: "nowrap",
  },
  padding: "5px 20px",
}));

// handle styling of TableRow
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF6FF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    height: "40px",
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
  height: "50px",
}));

//handling Table componant and their styling
const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
}));

interface SubProviderInsuranceDetailsProps {
  providerInsurance: Credentials[];
  id: string;
  authIndex: number | null;
  setAuthIndex: Dispatch<SetStateAction<number | null>>;
  setActiveTab: Dispatch<SetStateAction<string | null>>;
}

const SubProviderInsuranceDetails: React.FC<
  SubProviderInsuranceDetailsProps
> = ({ providerInsurance, id, authIndex, setAuthIndex, setActiveTab }) => {
  // #region variables region

  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();

  // Pop up Set for save Files
  const [editDialog, setEditDialog] = useState<boolean>(false);

  //handles the popup for editing Credential details
  const [editCredentialDetails, setEditCredentialDetails] =
    useState<boolean>(false);

  //handles the popup for editing Credential details
  const [addCredentialDetails, setAddCredentialDetails] =
    useState<boolean>(false);

  //delete popup handler
  const [deletePopup, setDeletePopup] = useState<boolean>(false);

  //store credential Id
  const [credentialId, setCredentialId] = useState<string>("");
  // #endregion

  // #region methods region

  //handles deleting provider
  const deleteCredential = (credentialId: string) => {
    if (providerInsurance.length > 0) {
      deleteSingleProviderInsurance(credentialId);
    }
    setDeletePopup(false);
  };

  //handles the edit form submission for client authorizations details
  const onSubmitCredentialsInfo = () => {
    dispatch(updateProviderCredentialById({ providerId: id as string }));
    setEditCredentialDetails(false);
    setAddCredentialDetails(false);
    setEditDialog(false);
    setActiveTab(null);
    setAuthIndex(null);
  };

  //handles deleting an authorization
  const deleteSingleProviderInsurance = async (id: string) => {
    try {
      setEditCredentialDetails(false);
      dispatch(setResponseValue({ name: "pending", value: true }));

      const { status, message } = await ProviderService.deleteCredentialById(
        id
      );

      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
      dispatch(setListDataChange(null));
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
      setActiveTab(null);
      setAuthIndex(0);
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };

  // #endregion

  return (
    <Grid size={{ xs: 12 }} sx={{ mt: "22px" }}>
      <div className="listingTable" style={{ marginBottom: 0 }}>
        <div className="listingTable__header">
          <Typography className="listingTable__title" gutterBottom variant="h6">
            Credentials
          </Typography>
          <div style={{ position: "relative", textAlign: "right" }}>
            <Button
              onClick={() => {
                setEditDialog(true);
                setAddCredentialDetails(true);
                setAuthIndex(null);
                dispatch(addCredential());
              }}
              className="addBtn"
            >
              + Add Credential
            </Button>
          </div>
        </div>
        <TableContainer component={Paper} className="tableResponsive">
          <StyledTable
            sx={{
              minWidth: 700,
              borderCollapse: "separate",
              cursor: "pointer",
            }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Insurance</StyledTableCell>
                <StyledTableCell>Complete</StyledTableCell>
                <StyledTableCell>Expiration Date</StyledTableCell>
                <StyledTableCell>Files</StyledTableCell>
                <StyledTableCell align="right"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {providerInsurance
                ?.filter((item: Credentials) => item.id)
                ?.map((credential: Credentials, index: number) => {
                  return (
                    <StyledTableRow
                      style={{ borderRadius: 20 }}
                      key={credential.id}
                    >
                      <StyledTableCell>{credential?.name}</StyledTableCell>
                      <StyledTableCell>
                        {credential.complete ? <>Yes</> : <>No</>}
                      </StyledTableCell>
                      <StyledTableCell scope="row">
                        {credential?.expirationDate
                          ? moment(credential?.expirationDate).format(
                              "MM-DD-YYYY"
                            )
                          : null}
                      </StyledTableCell>

                      <StyledTableCell>
                        {credential?.files?.map((item: any, index: number) => {
                          if (item?.key) {
                            return <div key={item.key}>{item.fileName}</div>;
                          }
                          return (
                            <div key={item.fileName}>{item?.fileName}</div>
                          );
                        })}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <div className="setDate">
                          <span>
                            <BorderColorIcon
                              onClick={() => {
                                setAuthIndex(index);
                                setEditDialog(true);
                                setEditCredentialDetails(true);
                                dispatch(
                                  setCredentialDetails({
                                    providerInsurance: [...providerInsurance],
                                  })
                                );
                              }}
                            />
                          </span>
                          <span>
                            <DeleteIcon
                              onClick={() => {
                                setCredentialId(credential.id);
                                setDeletePopup(true);
                              }}
                            />
                          </span>
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </div>
      {editDialog && addCredentialDetails && (
        <Dialog open={addCredentialDetails} className="largeModel">
          <Grid>
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Add Credential
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={() => {
                  setAddCredentialDetails(false);
                  dispatch(removeCredential({}));
                }}
              >
                <Close />
              </Button>
            </Box>
          </Grid>

          <ProviderAddNewInsuranceProviderForm
            onSubmit={onSubmitCredentialsInfo}
            authIndex={
              providerInsurance.length - 1 > -1
                ? providerInsurance.length - 1
                : 0
            }
            activeTabForAuthorization={"basic"}
            deleteSingleProviderInsurance={deleteSingleProviderInsurance}
            providerInsurance={providerInsurance}
            title={"Add Credential"}
          />
        </Dialog>
      )}
      {editDialog && editCredentialDetails && (
        <Dialog open={editCredentialDetails} className="largeModel">
          <Grid>
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Edit Credential
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={() => {
                  setEditCredentialDetails(false);
                }}
              >
                <Close />
              </Button>
            </Box>
          </Grid>

          <ProviderAddNewInsuranceProviderForm
            onSubmit={onSubmitCredentialsInfo}
            authIndex={authIndex || 0}
            activeTabForAuthorization={"basic"}
            deleteSingleProviderInsurance={deleteSingleProviderInsurance}
            providerInsurance={providerInsurance}
            title={"Edit Credential"}
          />
        </Dialog>
      )}

      <Dialog open={deletePopup} className="smallModel">
        <div>
          <DialogContent>
            {/* Are you sure want to delete this client ? */}
            Do you want to delete this credential record ?
          </DialogContent>
          <DialogActions>
            <Button
              className="border-button"
              variant="contained"
              onClick={() => setDeletePopup(false)}
              color="secondary"
            >
              No
            </Button>
            <Button
              className="button"
              variant="contained"
              onClick={() => {
                deleteCredential(credentialId as string);
                setDeletePopup(false);
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </Grid>
  );
};

export default SubProviderInsuranceDetails;
