import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  tableCellClasses,
  TableCell,
  styled,
  Typography,
  Button,
  ButtonGroup,
  Dialog,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Close } from "@mui/icons-material";
import moment from "moment";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { setClientFilterValues } from "../../../redux/features/client-main/client-filter-slice";
import { dataUpdated } from "../../../redux/features/client-main/client-list-slice";

import ClientFilter from "../../filter-main/ClientFilter";
import Pagination from "../../../components/common/Pagination";

import usePaginatedData from "../../../custom-hooks/pagination-hook/usePaginatedData";

import { filterClients } from "../../../helpers/client-filter-helper/client-filter-helper";
import { getAge } from "../../../utils/DateUtils";
import { getBusinessUnitName } from "../../../utils/EntityUtils";

import { ClientForms, InsuranceProviders } from "../../../types/client";
import { getBussinessName } from "../../../constants/common";

import ClientImg from "../../../assets/images/images-svg/client-large.svg";
import { ReactComponent as Filter } from "../../../assets/images/images-svg/filterIcon.svg";
import VerifiedAddressImg from "../../../assets/images/images-svg/verifiedAddress.svg";

import "../client/Client.scss";
import "../../providers/provider/Providers.scss";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  padding: "5px 20px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF6FF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    height: "40px",
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
  height: "50px",
}));
const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
}));

interface ClientListProps {
  mergedData: ClientForms[];
}

const ClientList: React.FC<ClientListProps> = ({ mergedData }) => {
  // #region variable region

  //business units
  const businessUnits = useAppSelector(
    (state) => state.businessUnitsSlice.businessUnits
  );

  //client list data
  const [filteredData, setFilteredData] = useState<ClientForms[]>([]);

  //number of posts per page
  const [postPerPage] = useState<number>(12);

  //page number
  const [pageNumber, setPageNumber] = useState<number>(1);

  //routing object
  const navigate = useNavigate();

  //dispatch for redux
  const dispatch = useAppDispatch();

  const [isFilterDialogOpen, setFilterDialogOpen] = useState<boolean>(false);

  //filter values
  const filterValues = useAppSelector((state) => state.clientFilterSlice);

  //search value
  const { searchValue } = useAppSelector((state) => state.activeStep);

  // paginated & filtered data
  const pageFilteredData = usePaginatedData<ClientForms>(
    filteredData, //after filter
    pageNumber || 1,
    postPerPage
  );

  // const dispatchAction = bindActionCreators(
  //   {
  //     setFilterValues,
  //     clearFilterValues,
  //     setSearchValue,
  //     setInitialState,
  //   },
  //   dispatch
  // );
  // #endregion

  // #region methods region

  //handles pagination
  const paginate = (page: number) => {
    setPageNumber(page);
  };

  //handles closing of single filter
  const handleCloseSingleFilter = (key: string, item: string) => {
    if (key === "insuranceProvider") {
      let value = filterValues.insuranceProvider.filter((i) => i !== item);
      dispatch(
        setClientFilterValues({ ...filterValues, insuranceProvider: value })
      );
    } else if (key === "businessUnit") {
      let value = filterValues.businessUnit.filter((i) => i !== item);
      dispatch(setClientFilterValues({ ...filterValues, businessUnit: value }));
    } else if (key === "clientStatus") {
      let value = filterValues.clientStatus.filter((i) => i !== item);
      dispatch(setClientFilterValues({ ...filterValues, clientStatus: value }));
    } else if (key === "intakeStage") {
      let value = filterValues.intakeStage.filter((i) => i !== item);
      dispatch(setClientFilterValues({ ...filterValues, intakeStage: value }));
    } else if (key === "clientConcerns") {
      let value = filterValues.concerns.filter((i) => i !== item);
      dispatch(setClientFilterValues({ ...filterValues, concerns: value }));
    } else if (key === "ScheduleStatus") {
      dispatch(setClientFilterValues({ ...filterValues, ScheduleStatus: "" }));
    } else if (key === "startDate") {
      dispatch(setClientFilterValues({ ...filterValues, startDate: null }));
      dispatch(dataUpdated({ dataUpdate: false }));
    } else if (key === "endDate") {
      dispatch(setClientFilterValues({ ...filterValues, endDate: null }));
      dispatch(dataUpdated({ dataUpdate: false }));
    } else if (key === "availabilityStatus") {
      dispatch(
        setClientFilterValues({ ...filterValues, availabilityStatus: "" })
      );
    } else if (key === "age") {
      dispatch(setClientFilterValues({ ...filterValues, age: null }));
    }
  };

  // #endregion

  // #region methods region
  //ensure to repopulate filteredClient after filter or search.
  useEffect(() => {
    const sortedData = mergedData.sort(
      (a, b) =>
        new Date(b.clientBasicDetails.updatedAt).getTime() -
        new Date(a.clientBasicDetails.updatedAt).getTime()
    );
    setFilteredData(filterClients(filterValues, searchValue, sortedData));
    paginate(1);
  }, [filterValues, searchValue, mergedData]);

  // #endregion

  return (
    <>
      <div className="listingTable">
        <div className="listingTable__header">
          <Typography className="listingTable__title" gutterBottom variant="h6">
            All Clients
          </Typography>
          <ButtonGroup
            disableElevation
            variant="contained"
            className="filterGroup-btn"
          >
            <Button onClick={() => setFilterDialogOpen(true)}>
              <Filter />
            </Button>
          </ButtonGroup>
        </div>
        <div className="selected-filtersButtons">
          {Object.entries(filterValues)
            .filter(([_, value], idx) => {
              if (value && typeof value === "string") return true;
              if (value?.length > 0 && typeof value === "object") return true;
              return false;
            })
            .map(([key, value], index) => {
              if (typeof value === "object") {
                return value.map((subItem: string, subIndex: number) => {
                  if (
                    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
                      subItem
                    )
                  ) {
                    return (
                      <Button className="border-button" key={subIndex}>
                        {getBussinessName(subItem, businessUnits)}
                        <span className="closeBtn">
                          <Close
                            fontSize="small"
                            onClick={() => {
                              handleCloseSingleFilter(key, subItem);
                            }}
                          />
                        </span>
                      </Button>
                    );
                  }
                  return (
                    <Button className="border-button" key={subIndex}>
                      {subItem}
                      <span className="closeBtn">
                        <Close
                          fontSize="small"
                          onClick={() => {
                            handleCloseSingleFilter(key, subItem);
                          }}
                        />
                      </span>
                    </Button>
                  );
                });
              } else {
                return (
                  <Button className="border-button" key={index}>
                    {key === "businessUnit"
                      ? getBusinessUnitName(businessUnits, value)
                      : key === "age"
                      ? `Age:${value}`
                      : value}
                    <span className="closeBtn">
                      <Close
                        fontSize="small"
                        onClick={() => {
                          handleCloseSingleFilter(key, value);
                        }}
                      />
                    </span>
                  </Button>
                );
              }
            })}
        </div>

        <TableContainer component={Paper} className="tableResponsive">
          <StyledTable
            sx={{
              minWidth: 700,
              borderCollapse: "separate",
              cursor: "pointer",
            }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">BU</StyledTableCell>
                <StyledTableCell>Client</StyledTableCell>
                <StyledTableCell align="center">Age</StyledTableCell>
                <StyledTableCell align="center">Location</StyledTableCell>
                <StyledTableCell align="center">Insurance</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Intake Stage</StyledTableCell>
                {/* <StyledTableCell align="center">Concern Stages</StyledTableCell> */}
                <StyledTableCell align="center">Updated On</StyledTableCell>
              </TableRow>
            </TableHead>

            {mergedData?.length > 0 ? (
              pageFilteredData?.length > 0 ? (
                <TableBody>
                  {pageFilteredData.map((row, idx) => (
                    <StyledTableRow
                      key={`${row.clientBasicDetails.id}-${idx}`}
                      onClick={() => {
                        navigate("/client/" + row.clientBasicDetails?.id);
                      }}
                      style={{ borderRadius: 20 }}
                    >
                      <StyledTableCell scope="row" align="center">
                        {/* {getBusinessUnitName(
                          row?.clientBasicDetails?.businessUnit
                        )} */}
                        {getBusinessUnitName(
                          businessUnits,
                          row?.clientBasicDetails?.businessUnit
                        )?.map((res: string, index: number) => {
                          return (
                            <span key={index}>
                              {`${index ? "," : ""} ${res}`}{" "}
                            </span>
                          );
                        })}
                      </StyledTableCell>
                      <StyledTableCell component="th">
                        <div className="userList-name">
                          <img
                            src={
                              row?.clientBasicDetails?.clientProfile?.url ||
                              ClientImg
                            }
                            className="userSmallCircle"
                            alt=""
                          />
                          <span style={{ marginLeft: 10 }}>
                            {row?.clientBasicDetails?.childFirstName +
                              "  " +
                              row?.clientBasicDetails?.childLastName}
                          </span>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.clientBasicDetails?.childDateOfBirth
                          ? getAge(row?.clientBasicDetails?.childDateOfBirth)
                          : null}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div>
                          {row?.clientContactDetails?.address?.fullAddress?.trim()
                            .length > 0 &&
                          row?.clientContactDetails?.address?.lat !== 0 ? (
                            <>
                              <img
                                src={VerifiedAddressImg}
                                className="verifiedAddressInList"
                                alt=""
                              />
                            </>
                          ) : null}
                          {row?.clientContactDetails?.address?.fullAddress}
                        </div>
                        <div>
                          {row?.clientContactDetails?.city}{" "}
                          {row?.clientContactDetails?.state}{" "}
                          {row?.clientContactDetails?.zipCode}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.clientInsurance?.map(
                          (item: InsuranceProviders, index: number) => (
                            <div key={index}>
                              {item.insuranceProviderName}{" "}
                              {item.isPrimary ? "(P)" : ""}
                            </div>
                          )
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.clientBasicDetails?.clientType}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.clientBasicDetails?.intakeStage}
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                          {row?.clientBasicDetails?.concernedStage.map(
                            (item: string) => (
                              <div key={item}>{item}</div>
                            )
                          )}
                        </StyledTableCell> */}
                      <StyledTableCell align="center">
                        {moment
                          .utc(row?.clientBasicDetails?.updatedAt)
                          .format("MM/DD/YYYY")}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              ) : (
                <TableHead>
                  <TableRow>
                    <StyledTableCell colSpan={12}>
                      <div className="notFound-Block">
                        No Clients Found That Match The Filters
                      </div>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
              )
            ) : (
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan={12}>
                    <div className="notFound-Block">No Clients Found</div>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
            )}
          </StyledTable>
        </TableContainer>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Pagination
            postPerPage={postPerPage}
            totalData={filteredData?.length}
            paginate={setPageNumber}
          />
        </Box>
        <Dialog open={isFilterDialogOpen} className="largeModel">
          <Grid>
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Filter Clients
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={() => setFilterDialogOpen(false)}
              >
                <Close />
              </Button>
            </Box>
          </Grid>
          <ClientFilter
            closeModal={(val, filterObject) => {
              dispatch(setClientFilterValues({ ...filterObject }));
              setFilterDialogOpen(val);
            }}
          />
        </Dialog>
      </div>
    </>
  );
};

export default ClientList;
