import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SchedulesService from "../../../service/schedules.service";
import { InsuranceProvider } from "../../../types/response";
import { setResponseValue } from "../api-response/api-response";
import { AuthCode } from "../../../types/client";
import DataCatalogService from "../../../service/dataCatalog.service";

interface ClientInsurance {
  clientInsurance: InsuranceProvider[]; //TODO: rename to catalogInsurance
  clientInsuranceUpdated: boolean;
  clientAuthcode: AuthCode[];
}

const initialState: ClientInsurance = {
  clientInsurance: [],
  clientInsuranceUpdated: false,
  clientAuthcode: [],
};

const clientInsuranceSlice = createSlice({
  name: "client/clientInsurance",
  initialState: initialState,
  reducers: {
    setUpdatedInsuranceProviders: (state, action) => {
      state.clientInsurance = action.payload;
      return state;
    },
    setClientInsuranceUpdated: (state, action) => {
      return {
        ...state,
        clientInsuranceUpdated: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientInsuranceProviders.fulfilled, (state, action) => {
      let newClientInsurances: InsuranceProvider[] = [];
      if (action.payload) {
        newClientInsurances = [...action.payload];
        if (
          newClientInsurances !== state.clientInsurance ||
          state.clientInsuranceUpdated
        ) {
          state.clientInsurance = newClientInsurances;
          state.clientInsuranceUpdated = false;
        }
      }
    });
    builder.addCase(GetAuthcode.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          clientAuthcode: [...action.payload],
        };
      }
    });
  },
});

export const getClientInsuranceProviders = createAsyncThunk(
  "catalog/insuranceProviders",
  async (_, { dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, data } =
        await DataCatalogService.GetAllInsuranceProviders();
      console.log(data);
      if (status) {
        return data.map((e: any) => {
          return { ...e, insuranceProviderName: e.conceptValue };
        });
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const GetAuthcode = createAsyncThunk(
  "catalog/getAuthcode",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));

      const { data } = await DataCatalogService.getAllAuthCodes();
      return data;
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const { setClientInsuranceUpdated, setUpdatedInsuranceProviders } =
  clientInsuranceSlice.actions;

export default clientInsuranceSlice;
