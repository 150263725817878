import { useState, memo } from "react";
import { Box, Button, CircularProgress, TextareaAutosize } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useUser } from "@descope/react-sdk";
import { AllUserMessage } from "../../../types/message";
import system from "../../../assets/images/images-png/setting.png";
import ProviderImg from "../../../assets/images/images-svg/provider-large-vertical.svg";
import { useAppDispatch } from "../../../redux/store";
import { PostCreateMessage } from "../../../redux/features/messages/message-slice";
import { sortData } from "../../../constants/message";
import { dateToFromNowDaily } from "../../../helpers/reverse-list/reverse-list";

interface IInternalMessage {
  selectedUser: {
    id: string;
    name: string;
    entityType: string;
  };
  internalComments: AllUserMessage[];
}

interface IInternalMessageComponent {
  userId: string;
  message: AllUserMessage;
}

const InternalMessageComponent: React.FC<IInternalMessageComponent> = memo(
  ({ userId, message }) => {
    return (
      <Box
        className={
          !message.seenUserId.includes(userId)
            ? "commentListItem generated light-blue-bg"
            : "commentListItem generated"
        }
      >
        <Box className="compose-block">
          <span className="commentListItem__user-img">
            <img
              src={
                message.fromUser === null
                  ? system
                  : message?.fromUser?.profilePictureUrl || ProviderImg
              }
              className="userSmallCircle"
              alt=""
            />
          </span>
          <div className="commentListItem__user-info">
            <div className="compose-user-heading">
              <h5 className="compose-user-name">
                {message.fromUser === null
                  ? "System Generated "
                  : message.fromUser?.firstName?.length > 0
                  ? message.fromUser?.firstName +
                    " " +
                    message.fromUser?.lastName
                  : message.fromUser?.email}
              </h5>
              <h6 className="compose-user-time">
                <span>{dateToFromNowDaily(message?.createdOn)}</span>
              </h6>
            </div>

            <div
              className="compose-user-comment"
              style={{ whiteSpace: "pre-line" }}
            >
              {message?.message}
            </div>
          </div>
        </Box>
      </Box>
    );
  }
);

const InternalMessage: React.FC<IInternalMessage> = ({
  selectedUser,
  internalComments,
}) => {
  //user from Descope
  const { user } = useUser();

  const dispatch = useAppDispatch();

  const [internalComment, setInternalComment] = useState<string>("");
  const [CommentError, setCommentError] = useState<string>("");
  const [loading, setLoading] = useState(false);
  //Submit Internal comments
  const handleAddNotice = async () => {
    try {
      setLoading(true);
      setCommentError("");
      if (!internalComment.trim()) {
        setCommentError("Please enter notice text first");
        return;
      }
      await dispatch(
        PostCreateMessage({
          entityId: selectedUser.id,
          subject: "User Notice",
          message: internalComment,
          whoCanView: ["admin", "parent"],
          type: "internal",
          from: "",
          entityType: selectedUser.entityType,
          messagetype: "internalCount",
        })
      );
    } catch (e) {
    } finally {
      setInternalComment("");
      setLoading(false);
    }
  };

  return (
    <>
      <span style={{ color: "#d32f2f" }}>{CommentError}</span>
      <Grid size={{ xs: 16, md: 16 }}>
        <Box className="commentForm">
          <TextareaAutosize
            className="form-control"
            value={loading ? "Sending..." : internalComment}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              setInternalComment(e.target.value);
              setCommentError("");
            }}
            placeholder="Leave an internal notice..."
            minRows={2}
          />
          <Button
            variant="outlined"
            disabled={loading}
            onClick={handleAddNotice}
          >
            {loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              "Add Notice"
            )}
          </Button>
        </Box>
      </Grid>
      <Grid className="scroll-bar" id="load-more">
        {selectedUser.id &&
          (internalComments.length > 0 ? (
            sortData(internalComments).map((item: AllUserMessage) => {
              return (
                <InternalMessageComponent
                  userId={user.userId}
                  message={item}
                  key={item.id}
                />
              );
            })
          ) : (
            <Box>No Notice Found</Box>
          ))}
      </Grid>
      {sortData(internalComments)?.length > 5 && (
        <Box className="commentList-btn">
          <button
            onClick={() => {
              document.getElementById("load-more")?.scrollBy(0, 100);
            }}
          >
            Load More
          </button>
        </Box>
      )}
    </>
  );
};

export default memo(InternalMessage);
