import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Close } from "@mui/icons-material";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  GroupSettingsModel,
  Inject,
  Page,
  Group,
  Sort,
  Filter,
  Edit,
  TextWrapSettingsModel,
  Selection,
  SelectionSettingsModel,
  ContextMenu,
  Toolbar,
  ContextMenuItemModel,
  Resize,
  DetailRow,
} from "@syncfusion/ej2-react-grids";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  GetFormRequests,
  ReprocessFormRequest,
} from "../../../redux/features/forms/form-slice";

import DetailTemplate from "../subComponent/DetailTemplate";

import { IFormRequestDetail } from "../../../types/forms";

import { dateToFromNowDaily } from "../../../helpers/reverse-list/reverse-list";

import ClientImg from "../../../assets/images/images-svg/client-large.svg";
import Reset from "../../../assets/images/images-png/reset2.png";
import { BusinessFormType } from "../../../constants/forms";

function RowTemplate(this: any) {
  // #region Variable region

  //used to dispatch
  const dispatch = useAppDispatch();

  //stores Assessment List
  const assessmentList = useAppSelector(
    (state) => state.formSlice.AssessmentRequests
  );
  //stores sorted formRequests
  const [formRequests, setFormRequests] = useState<IFormRequestDetail[]>([]);

  //handles  critical answers Modal
  const [openCreticalAssessment, setOpenCreticalAssessment] =
    React.useState<boolean>(false);

  //gets assessment ID
  const [CritcalId, setCriticalId] = React.useState("");

  //#region datagrid region

  //groupings for grid
  const groupOptions: GroupSettingsModel = { showGroupedColumn: true };

  let gridInstance: GridComponent;

  //text-wrap for grid
  const textWrapSettings: TextWrapSettingsModel = { wrapMode: "Content" };

  //select format for grid
  const selectionSettings: SelectionSettingsModel = {
    type: "Multiple",
    mode: "Both",
  };

  const contextMenuItems: ContextMenuItemModel[] = [
    { text: "Add Into Calendar", target: ".e-content", id: "addintocalendar" },
  ];

  let refresh: boolean;
  function load(this: any) {
    refresh = this?.refreshing;
  }

  function dataBound() {
    if (refresh) {
      gridInstance.groupColumn("name");
      refresh = false;
    }
  }

  const rowSelected = () => {
    if (gridInstance) {
      // eslint-disable-next-line
      const selectedrowindex: number[] = gridInstance.getSelectedRowIndexes();
      // eslint-disable-next-line
      const selectedrecords: object[] = gridInstance.getSelectedRecords();
    }
  };

  //handle formatting of symtoms
  const handleSymptoms = (item: string) => {
    let result = item.charAt(0).toUpperCase() + item.slice(1);

    let data = result.match(/([A-Z]?[^A-Z]*)/g);
    if (data !== null) {
      return data.slice(0, -1).join(" ");
    }
  };

  const entityTypeTemplate = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>
          {props.entityType === "Client"
            ? "Client"
            : props.entityType === "Provider"
            ? "Employee"
            : props.entityType}
        </span>
      </div>
    );
  };

  //template for completed date in grid
  const submitDtmTemplate = (props: any) => {
    return (
      <div>
        <p>
          {props.submitDtm
            ? new Date(`${props.submitDtm}`).toLocaleDateString("en-US")
            : "--------"}
        </p>
      </div>
    );
  };

  //template for profile Picture in grid
  const profileTemplate = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={props.profileUrl ? props.profileUrl : ClientImg}
          style={{
            height: "40px",
            borderRadius: "50%",
            padding: "5px",
            width: "40px",
          }}
          alt="download"
        />
        <span>{props.entityName}</span>
      </div>
    );
  };

  const sentDtmTemplate = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>
          {props.sentDtm
            ? new Date(`${props.sentDtm}`).toLocaleDateString("en-US")
            : "--------"}
        </span>
      </div>
    );
  };

  const updatedDtmTemplate = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>
          {props.updatedAt
            ? dateToFromNowDaily(new Date(`${props.updatedAt}`))
            : "--------"}
        </span>
      </div>
    );
  };

  //template for icons in data grid
  function materialTemplate(props: any) {
    return (
      <div style={{ display: "flex", gap: "23px" }}>
        {props.status === "Error" || props.status === "Evaluated" ? (
          <div
            aria-label="reprocess"
            style={{
              cursor: "pointer",
              textAlign: "center",
              alignItems: "center",
            }}
            onClick={() => {
              dispatch(
                ReprocessFormRequest({
                  id: props.id,
                  formType: BusinessFormType.AssessmentForm,
                })
              );
            }}
          >
            <img src={Reset} height={30} width={30} />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }

  const errorMsgTemplate = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>{props.errorMsg}</span>
      </div>
    );
  };

  //handle style of pdf button
  const btnStyle = {
    marginLeft: "20px",
    marginTop: "20px",
    marginBottom: "20px",
    height: "30px",
    cursor: "pointer",
  };

  //#endregion

  //#region dataTable region
  //data grid table
  const dataGridTable = React.useMemo(
    () =>
      formRequests &&
      formRequests[0]?.id !== "" && (
        <GridComponent
          id="grid" //must have!!
          className="gridTable"
          dataSource={formRequests}
          dataBound={dataBound.bind(this)}
          allowPaging={true}
          ref={(grid) => (gridInstance = grid as GridComponent)}
          load={load}
          allowGrouping={true}
          groupSettings={groupOptions}
          allowFiltering={true}
          allowSorting={true}
          allowResizing={true}
          width="auto"
          height="650"
          filterSettings={{ type: "Menu" }}
          allowTextWrap={true}
          textWrapSettings={textWrapSettings}
          selectionSettings={selectionSettings}
          rowSelected={rowSelected}
          contextMenuItems={contextMenuItems}
          detailTemplate={(prop: any) => (
            <DetailTemplate
              {...prop}
              setOpenCreticalAssessment={setOpenCreticalAssessment}
              setCriticalId={setCriticalId}
            />
          )}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="id"
              visible={false}
              headerText="ID"
              isPrimaryKey={true}
              width="130"
            />
            {/* <ColumnDirective
              headerText="Type"
              width="80"
              textAlign="Left"
              field="entityType"
              template={entityTypeTemplate}
            /> */}
            <ColumnDirective
              headerText="Person"
              width="180"
              textAlign="Left"
              field="entityName"
              template={profileTemplate}
            />
            <ColumnDirective
              headerText="Assessment"
              width="180"
              textAlign="Left"
              field="formName"
            />
            <ColumnDirective
              headerText="Send Date"
              width="100"
              textAlign="Left"
              field="sentDtm"
              template={sentDtmTemplate}
            />
            <ColumnDirective
              headerText="Submit Date"
              width="100"
              textAlign="Left"
              field="submitDtm"
              template={submitDtmTemplate}
            />
            <ColumnDirective
              headerText="Status"
              width="100"
              textAlign="Left"
              field="status"
            />
            <ColumnDirective
              headerText="Result"
              width="100"
              textAlign="Left"
              field="Result"
              disableHtmlEncode={false}
              template={materialTemplate}
            />
            <ColumnDirective
              headerText="Error"
              width="150"
              textAlign="Left"
              field="errorMsg"
              template={errorMsgTemplate}
            />
            <ColumnDirective
              headerText="Updated On"
              width="100"
              textAlign="Left"
              field="updatedOn"
              template={updatedDtmTemplate}
            />
          </ColumnsDirective>
          <Inject
            services={[
              Filter,
              Page,
              Group,
              Sort,
              Edit,
              Selection,
              ContextMenu,
              Toolbar,
              Toolbar,
              Resize,
              DetailRow,
            ]}
          />
        </GridComponent>
      ),
    [formRequests]
  );

  //#endregion

  //#region useEffect region

  //fetch Assessments
  useEffect(() => {
    dispatch(GetFormRequests({ formType: "All Assessment" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (assessmentList) {
      const data = [...assessmentList].sort((a, b) => {
        return (new Date(b.updatedAt) as any) - (new Date(a.updatedAt) as any);
      });
      setFormRequests(data);
    }
  }, [assessmentList]);
  //#endregion

  //#endregion
  return (
    <>
      <div>
        <Grid sx={{ marginTop: "30px" }}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            fontWeight="bold"
            className="listingTable__title"
            style={{ marginBottom: "10px" }}
          >
            Requests & Submissions
          </Typography>

          {dataGridTable}
        </Grid>
        {openCreticalAssessment && (
          <Dialog
            open={openCreticalAssessment}
            className="smallModel--assessment"
          >
            <Box className="smallModel__head" style={{ padding: "12px 16px" }}>
              <Typography variant="h3" className="smallModel__title">
                {"Critical Answers"}
              </Typography>
              <Button
                className="smallModel__closeBtn"
                onClick={() => {
                  setOpenCreticalAssessment(false);
                }}
              >
                <Close />
              </Button>
            </Box>
            <Box style={{ minHeight: "450px", overflowY: "auto" }}>
              <Box className="documentsBlock">
                {formRequests?.map((item: any, index: number) => (
                  <>
                    {item.id === CritcalId ? (
                      <>
                        {Object.keys(item.evaluation).map((key: string) => (
                          <Box className="documentsBlock__item" key={index}>
                            <Box className="documentsBlock__item-head">
                              <Typography className="documentsBlock__item-title">
                                <span>Symptom:</span> {handleSymptoms(key)}
                              </Typography>
                            </Box>
                            {item.evaluation[key]?.map(
                              (ele: any, index: number) => (
                                <Box
                                  className="documentsBlock__item-body"
                                  key={index}
                                >
                                  <p className="documentsBlock__item-que">
                                    {ele.questionName ? (
                                      <span>{ele.questionName}</span>
                                    ) : (
                                      <span>Q:</span>
                                    )}
                                    {ele.question}
                                  </p>
                                  <p className="documentsBlock__item-ans">
                                    <span>A:</span>
                                    <div>
                                      {Array.isArray(ele.answer)
                                        ? ele.answer.map(
                                            (ans: string, i: number) => (
                                              <div key={i}>{`${
                                                i + 1
                                              }.${ans}`}</div>
                                            )
                                          )
                                        : ele.answer.toString()}
                                    </div>
                                  </p>
                                </Box>
                              )
                            )}
                          </Box>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
              </Box>
            </Box>
          </Dialog>
        )}
      </div>
    </>
  );
}
export default RowTemplate;
