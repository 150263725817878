import { Box, Button, Card, Dialog, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useState } from "react";
import { ResourceHubMessage } from "../../constants/forms";
import Eye from "../../assets/images/images-svg/Eye.svg";
import Reload from "../../assets/images/images-svg/Reload.svg";
import CloseIcon from "@mui/icons-material/Close";
import "./resourceHub.scss";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import moment from "moment";
import { InstallOrUpdateResourceById } from "../../redux/features/resourceHub/resourceHub-slice";
import Download from "../../assets/images/images-svg/Download.svg";
import { NewMessageTemplate } from "../../types/businessSettings";
import { IResource } from "../../types/resourceHub";

const ResourceMessage = () => {
  //#region variable
  const dispatch = useAppDispatch();
  const [filterMessageGroup, setFilterMessageGroup] =
    useState<string>("Client");
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [messageTemplate, setMessageTemplate] =
    useState<NewMessageTemplate | null>(null);
  const resourceTemplates = useAppSelector((state) =>
    state.resourceHubSlice.resourceMessageTemplate
  );

  const ClientGroup = ["Client", "Intake"]
  //#endregion

  //#region method
  const filterResourceMessages = (): IResource[] => {
    return resourceTemplates.filter((item) => {
      if (filterMessageGroup) {
        return item.resourceObject.audience === filterMessageGroup;
      } else {
        return item;
      }
    });
  };

  //#endregion
  return (
    <div>
      <div className="listingTable__header" style={{ paddingBottom: "20px" }}>
        <Box
          sx={{
            display: { xs: "block", md: "flex" },
            gap: "15px",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Typography
            className="listingTable__title"
            gutterBottom
            style={{ fontWeight: 500, fontSize: "20px" }}
          >
            Select Group:
          </Typography>
          <Box sx={{ display: "flex", gap: "15px" }} className="form-tabs">
            {Object.values(ResourceHubMessage)?.map((item, index) => (
              <Box className="fromGroup-chips" key={item}>
                <Card
                  key={index}
                  className={
                    filterMessageGroup === item ? "fromGroup-chip-active" : ""
                  }
                  onClick={() => {
                    filterMessageGroup === item
                      ? setFilterMessageGroup("")
                      : setFilterMessageGroup(item);
                  }}
                >
                  {item}
                </Card>
              </Box>
            ))}
          </Box>
        </Box>
      </div>
      <div className="resource">
        <Grid container>
          {resourceTemplates?.length > 0 && filterResourceMessages()?.map((item) => (
            <Grid
              size={{ xs: 6, md: 4, lg: 3 }}
              className="resourceMessage_cards"
            >
              <div className="documentCard">
                <div className={ClientGroup.includes(filterMessageGroup) ? "documentCard__img client" : "documentCard__img provider"}>
                  <div className="documentCard__img-card">
                    <p>{item.name}</p>
                    <p className="version">Version: {item.version}</p>
                    <p className="updateAt">
                      Update: {moment(item.updatedAt).format("MM/DD/YYYY")}
                    </p>
                  </div>
                </div>
                <div className="documentCard__btn">
                  <span
                    onClick={() => {
                      setOpenPreview(true);
                      setMessageTemplate(item.resourceObject);
                    }}
                  >
                    <img src={Eye} alt="" />
                    Preview
                  </span>
                  {item?.isUpdateAvailable && (
                    <span onClick={() => {
                      dispatch(
                        InstallOrUpdateResourceById({
                          formId: item.id,
                          resourceType: "Message",
                        })
                      );
                    }}>
                      <img src={Reload} alt="" />
                      Update
                    </span>
                  )}
                  {!item?.isUpdateAvailable && item.messageTemplates?.length === 0 && (
                    <span
                      onClick={() => {
                        dispatch(
                          InstallOrUpdateResourceById({
                            formId: item.id,
                            resourceType: "Message",
                          })
                        );
                      }}
                    >
                      <img src={Download} alt="" />
                      Install
                    </span>
                  )}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
      <Dialog
        open={openPreview}
        className="smallModel--intakeforms resourceHub-preview"
      >
        <Box className="smallModel__head" style={{ padding: "12px 16px" }}>
          <Typography variant="h3" className="smallModel__title">
            preview
          </Typography>

          <Button
            className="smallModel__closeBtn"
            onClick={() => {
              setOpenPreview(false);
            }}
          >
            <CloseIcon />
          </Button>
        </Box>
        {messageTemplate !== null && (
          <div className="template-preview">
            <Box className="content">
              <Typography className="label">Method:</Typography>{" "}
              <Box className="card centeredText">{messageTemplate.methods}</Box>
            </Box>
            <Box className="content">
              <Typography className="label">From:</Typography>{" "}
              <Box className="card centeredText">{messageTemplate.from}</Box>
            </Box>

            <Box className="content">
              <Typography className="label">Subject:</Typography>{" "}
              <Box className="card centeredText">{messageTemplate.subject}</Box>
            </Box>

            <Typography className="label">Message :</Typography>
            <div className="message card">
              <Typography>{messageTemplate.message}</Typography>
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            borderTop: "1px solid #dee2e6",
            padding: "7px 12px",
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setOpenPreview(false);
            }}
          >
            Close
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default ResourceMessage;
