import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  MenuProps,
  filterProviderStatuses,
  filterOnTrackChangeTypes,
  EntityScheduleStatus,
} from "../../constants/providers";

import { InsuranceProvider } from "../../types/response";
import { clientType, onTrackStage } from "../../constants/client";
import { OrgBU } from "../../types/company";
import { getBussinessName } from "../../constants/common";
import dayjs, { Dayjs } from "dayjs";
import { MapsFilter } from "../../types/map";
import { dataUpdated } from "../../redux/features/providers-main/provider-list-slice";
import { GetUtilzationClients } from "../../redux/features/client-main/client-list-slice";
import { Label } from "@mui/icons-material";

interface ProviderFilterProps {
  closeModal: (val: boolean, filterObject: MapsFilter) => void;
}

const MapFilter: React.FC<ProviderFilterProps> = ({ closeModal }) => {
  // #region variables region

  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();

  //business units
  const businessUnits = useSelector<RootState, OrgBU[]>(
    (state) => state.businessUnitsSlice.businessUnits
  );

  //gets the selected filter values from redux
  const [filterValues, setFilterValues] = useState<MapsFilter>(
    useSelector<RootState, MapsFilter>((state) => state.mapsFilterSlice)
  );

  //insurance providers
  const insuranceProviders = useSelector<RootState, InsuranceProvider[]>(
    (state) => state.clientInsuranceSlice.clientInsurance
  );

  // Store startDate
  const [, setStartDate] = useState<Dayjs | any | null>(null);

  // store enddate
  const [, setEndDate] = useState<Dayjs | any | null>(null);

  // #endregion

  // #region methods region

  //handles closing the filter Modal and calling the filter method
  const handleFilterValues = (item: {
    name: string;
    value: string | string[];
  }): void => {
    const obj = {
      ...filterValues,
      [item.name]: item.value,
    };
    setFilterValues({ ...obj });
  };

  ////handle onBoard Stage onChange
  const handleOnBoardStageChange = (e: any) => {
    if (
      !e.target.value.includes("All Stages") &&
      filterValues.onBoardingStage.includes("All Stages")
    ) {
      setFilterValues({ ...filterValues, onBoardingStage: [] });
    } else if (
      e.target.value.includes("All Stages") &&
      !filterValues.onBoardingStage.includes("All Stages")
    ) {
      setFilterValues({
        ...filterValues,
        onBoardingStage: [...filterOnTrackChangeTypes, "All Stages"],
      });
    } else if (
      e.target.value.includes("All Stages") &&
      e.target.value.length === filterOnTrackChangeTypes.length
    ) {
      setFilterValues({
        ...filterValues,
        onBoardingStage: e.target.value.filter((e: any) => e !== "All Stages"),
      });
    } else if (e.target.value.length === filterOnTrackChangeTypes.length) {
      setFilterValues({
        ...filterValues,
        onBoardingStage: [...filterOnTrackChangeTypes, "All Stages"],
      });
    } else {
      handleFilterValues({
        name: "onBoardingStage",
        value: e.target.value,
      });
    }
  };

  //handle start date for appointment date
  const handleStartDate = (newValue: Dayjs | null) => {
    setStartDate(newValue);
    setFilterValues((prevState) => {
      return {
        ...prevState,
        startDate: dayjs(newValue).format("MM/DD/YYYY"),
      };
    });
  };

  //handle end date for appointment date
  const handleEndDate = (newValue: Dayjs | null) => {
    setEndDate(newValue);
    setFilterValues((prevState) => {
      return {
        ...prevState,
        //  startDate: dayjs(startDate).format("MM/DD/YYYY"),
        endDate: dayjs(newValue).format("MM/DD/YYYY"),
      };
    });
  };

  //#endregion

  return (
    <React.Fragment>
      <div className="stepperForm__inner p-30px">
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel id="demo-simple-select-label" className="fromLabel">
                Business Unit
              </InputLabel>
              <FormControl>
                <Select
                  multiple
                  className="form-control"
                  value={filterValues?.businessUnit}
                  renderValue={(selected: any) => {
                    selected = selected.map((select: any) => {
                      return getBussinessName(select, businessUnits);
                    });
                    return selected.join(", ");
                  }}
                  MenuProps={MenuProps}
                  onChange={(e: SelectChangeEvent<string[]>) => {
                    handleFilterValues({
                      name: "businessUnit",
                      value: e.target.value,
                    });
                  }}
                >
                  {businessUnits.map((unit: OrgBU, index: number) => {
                    return (
                      <MenuItem key={index} value={unit.id}>
                        <Checkbox
                          checked={
                            filterValues.businessUnit.indexOf(unit.id) > -1
                          }
                        />
                        {unit.businessUnitName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Insurance Providers</InputLabel>
              <FormControl>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filterValues.insuranceProvider}
                  onChange={(e) => {
                    handleFilterValues({
                      name: "insuranceProvider",
                      value: e.target.value,
                    });
                  }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {insuranceProviders.map((unit, index) => (
                    <MenuItem key={index} value={unit.insuranceProviderName}>
                      <Checkbox
                        checked={
                          filterValues.insuranceProvider.indexOf(
                            unit.insuranceProviderName
                          ) > -1
                        }
                      />
                      <ListItemText primary={unit.insuranceProviderName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Client Status</InputLabel>
              <FormControl>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filterValues.clientStatus}
                  onChange={(e) => {
                    handleFilterValues({
                      name: "clientStatus",
                      value: e.target.value,
                    });
                  }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {clientType.map((unit, index) => (
                    <MenuItem key={index} value={unit}>
                      <Checkbox
                        checked={filterValues.clientStatus.indexOf(unit) > -1}
                      />
                      <ListItemText primary={unit} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Client Intake Stage</InputLabel>
              <FormControl>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filterValues.intakeStage}
                  onChange={(e) => {
                    handleFilterValues({
                      name: "intakeStage",
                      value: e.target.value,
                    });
                  }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {onTrackStage.map((unit, index) => (
                    <MenuItem key={index} value={unit}>
                      <Checkbox
                        checked={filterValues.intakeStage.indexOf(unit) > -1}
                      />
                      <ListItemText primary={unit} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Employee Status</InputLabel>
              <FormControl>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filterValues.providerStatus}
                  onChange={(e) => {
                    handleFilterValues({
                      name: "providerStatus",
                      value: e.target.value,
                    });
                  }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {filterProviderStatuses.map((unit, index) => (
                    <MenuItem key={index} value={unit}>
                      <Checkbox
                        checked={filterValues.providerStatus.indexOf(unit) > -1}
                      />
                      <ListItemText primary={unit} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup">
              <InputLabel id="demo-simple-select-label" className="fromLabel">
                Employee Onboarding Stage
              </InputLabel>
              <FormControl>
                <Select
                  multiple
                  className="form-control"
                  value={filterValues.onBoardingStage}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  onChange={handleOnBoardStageChange}
                >
                  <MenuItem value={"All Stages"}>
                    <Checkbox
                      checked={
                        filterValues.onBoardingStage.indexOf("All Stages") > -1
                      }
                    />
                    {"All Stages"}
                  </MenuItem>
                  {filterOnTrackChangeTypes.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        <Checkbox
                          checked={
                            filterValues.onBoardingStage.indexOf(item) > -1
                          }
                        />
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <InputLabel className="fromLabel">Appointment Date</InputLabel>
            <Box
              className="formGroup datePicker"
              style={{ position: "relative" }}
            >
              <Grid size={{ xs: 12 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid container spacing={3}>
                    <Grid size={{ xs: 6 }}>
                      <DatePicker
                        className="singleDatePicker"
                        label="Start Date"
                        value={
                          filterValues.startDate
                            ? dayjs(filterValues.startDate)
                            : null
                        }
                        onChange={handleStartDate}
                        // renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                    <Grid size={{ xs: 6 }}>
                      <DatePicker
                        className="singleDatePicker"
                        label="end Date"
                        value={
                          filterValues.endDate
                            ? dayjs(filterValues.endDate)
                            : null
                        }
                        onChange={handleEndDate}
                        // renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                  </Grid>
                </LocalizationProvider>
              </Grid>
            </Box>
          </Grid>

          <Grid size={{ xs: 12, md: 2 }}>
            <InputLabel className="fromLabel">Age From:</InputLabel>
            <TextField
              type="number"
              sx={{
                width: { sm: 50, md: 100 },
                "& .MuiInputBase-root": {
                  height: 40,
                },
              }}
              InputProps={{ inputProps: { min: 0 } }}
              placeholder="from"
              value={filterValues.ageFrom}
              onChange={(e) => {
                handleFilterValues({ name: "ageFrom", value: e.target.value });
              }}
              onKeyDown={(event) => {
                if (event.key === "-" || event.key === "+") {
                  event.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 2 }}>
            <InputLabel className="fromLabel">Age To:</InputLabel>
            <TextField
              type="number"
              sx={{
                width: { sm: 50, md: 100 },
                "& .MuiInputBase-root": {
                  height: 40,
                },
              }}
              InputProps={{ inputProps: { min: 0 } }}
              placeholder="to"
              value={filterValues.ageTo}
              onChange={(e) => {
                handleFilterValues({ name: "ageTo", value: e.target.value });
              }}
              onKeyDown={(event) => {
                if (event.key === "-" || event.key === "+") {
                  event.preventDefault();
                }
              }}
            />
          </Grid>

          <Grid size={{ xs: 12, md: 10 }}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Schedule Status</InputLabel>
              <div className="fromGroup-chips">
                {Object.values(EntityScheduleStatus).map(
                  (item: string, index: number) => (
                    <Card
                      className={
                        filterValues.ScheduleStatus === item
                          ? "fromGroup-chip-active"
                          : ""
                      }
                      onClick={(
                        e: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) =>
                        handleFilterValues({
                          name: "ScheduleStatus",
                          value: item,
                        })
                      }
                      key={item}
                    >
                      {item}
                    </Card>
                  )
                )}
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Box className="stepperForm__footer b-0">
        <Button
          color="inherit"
          className="border-button"
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            setFilterValues({
              businessUnit: [],
              insuranceProvider: [],
              clientStatus: [],
              providerStatus: [],
              ScheduleStatus: "",
              intakeStage: [],
              onBoardingStage: [],
              clientConcerns: [],
              providerTasks: [],
              startDate: null,
              endDate: null,
              ageFrom: "",
              ageTo: "",
            });
            dispatch(dataUpdated({ dataUpdate: false }));
          }}
        >
          Clear Filter
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            closeModal(false, { ...filterValues });
            if (filterValues.startDate && filterValues.endDate) {
              dispatch(
                GetUtilzationClients({
                  startDate: filterValues.startDate,
                  endDate: filterValues.endDate,
                  isClient: null,
                })
              );
            }
          }}
          className="button"
        >
          Apply Filter
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default MapFilter;
